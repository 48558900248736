import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import fetchWithJWT from '../../../../functions/fetchWithJWT';
import { formatDateForBigQuery, startOfDay, endOfDay } from '../../../../functions/formatDate';
import { handleApiResponse } from '../../../../functions/handleApiResponse';
import { getFiltersParams, getSortParams } from '../../../../functions/getQueryString';

import FiltersPannel from '../../../widgets/vtmn/FiltersPanel';
import ExportButton from '../../../widgets/vtmn/ExportButton';
import DateRangePicker from '../../../widgets/vtmn/filters/DateRangePicker';
import StoreSelect from '../../../widgets/vtmn/filters/discrepancies/StoreSelect';
import InputFilter from '../../../widgets/vtmn/filters/InputFilter';
import WarehouseSectorSelect from '../../../widgets/vtmn/filters/WarehouseSectorSelect';
import MovementSelect from '../../../widgets/vtmn/filters/MovementSelect';
import RfidFilter from '../../../widgets/vtmn/filters/RfidFilter';
import UniverseFilter from '../../../widgets/vtmn/filters/UniverseFilter';
import NumrangeFilter from '../../../widgets/vtmn/filters/NumrangeFilter';
import Toggle from '../../../widgets/vtmn/filters/Toggle';
import StayedAtWarehouseLabel from '../../StayedAtWarehouseLabel';
import SelectColumns from '../../../widgets/vtmn/filters/SelectColumns';
import ControlsAddressSelect from '../../../widgets/vtmn/filters/discrepancies/ControlsAddressSelect';
import ControlsContenantSelect from '../../../widgets/vtmn/filters/discrepancies/ControlsContenantSelect';
import ControlsPickerSelect from '../../../widgets/vtmn/filters/discrepancies/ControlsPickerSelect';
import BoolSelect from '../../../widgets/vtmn/filters/BoolSelect';
import Combobox from '../../../widgets/vtmn/form/Combobox';

const exportReport = (warehouse, fields) => (user, updateTokens, oauthToken, { filters, sort }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (formatedFilters.receptionDateFrom) {
    formatedFilters.receptionDateFrom = formatDateForBigQuery(formatedFilters.receptionDateFrom);
  }
  if (formatedFilters.receptionDateTo) {
    formatedFilters.receptionDateTo = formatDateForBigQuery(formatedFilters.receptionDateTo);
  }
  if (formatedFilters.controlDateFrom) {
    formatedFilters.controlDateFrom = startOfDay(formatedFilters.controlDateFrom);
  }
  if (formatedFilters.controlDateTo) {
    formatedFilters.controlDateTo = endOfDay(formatedFilters.controlDateTo);
  }
  if (formatedFilters.stayedAtWarehouse) {
    formatedFilters.stayedAtWarehouse = 'true';
  }
  if (formatedFilters.notHere) {
    formatedFilters.notHere = 'true';
  }
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/controls/report/export`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      oauthToken,
      filters: getFiltersParams({ filters: formatedFilters }),
      sort: getSortParams({ sort }),
      fields,
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const DiscrepancyTypeFilter = ({
  selected,
  onChange,
  invertable,
  inverted,
  onInvert,
}) => {
  const { t } = useTranslation();
  const options = [{
    id: 'expedition',
    label: <div className="vtmn-py-2">{t('controlReport.columns.discrepancyTypeVelues.expedition', 'Expedition')}</div>,
  }, {
    id: 'unexpected',
    label: <div className="vtmn-py-2">{t('controlReport.columns.discrepancyTypeVelues.unexpected', 'Unexpected')}</div>,
  }, {
    id: 'wrong_size',
    label: <div className="vtmn-py-2">{t('controlReport.columns.discrepancyTypeVelues.wrongSize', 'Wrong size')}</div>,
  }];
  return (
    <Combobox
      label={t('controlReport.columns.discrepancyType', 'Discrepancy type')}
      options={options}
      selected={selected}
      onChange={onChange}
      invertable={invertable}
      inverted={inverted}
      onInvert={onInvert}
      valueClassName="[&>div]:vtmn-py-0"
    />
  );
};

const AdditionalFilters = ({
  filters,
  setFilters,
  invertedFilters,
  setInvertedFilters,
  user,
  updateTokens,
}) => {
  const { t } = useTranslation();
  const { warehouse } = useParams();

  return (
    <>
      <ControlsPickerSelect
        controlId="filter-picker"
        label={t('controlReport.columns.picker', 'Picker')}
        user={user}
        updateTokens={updateTokens}
        selected={filters.picker}
        onChange={(picker) => setFilters({ ...filters, picker })}
        invertable
        inverted={invertedFilters.picker}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          picker: !invertedFilters.picker,
        })}
      />
      <DiscrepancyTypeFilter
        selected={filters.discrepancyType}
        onChange={(discrepancyType) => setFilters({ ...filters, discrepancyType })}
        invertable
        inverted={invertedFilters.discrepancyType}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          discrepancyType: !invertedFilters.discrepancyType,
        })}
      />

      <StoreSelect
        user={user}
        updateTokens={updateTokens}
        selected={filters.store}
        onChange={(store) => setFilters({ ...filters, store })}
        invertable
        inverted={invertedFilters.store}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          store: !invertedFilters.store,
        })}
      />

      <WarehouseSectorSelect
        user={user}
        updateTokens={updateTokens}
        warehouse={warehouse}
        byDiscrepancies={true}
        selected={filters.sector}
        onChange={(sector) => setFilters({ ...filters, sector })}
      />

      <InputFilter
        controlId="filter-delivery"
        label={t('controlReport.columns.delivery', 'Delivery')}
        value={filters.delivery}
        onChange={(delivery) => setFilters({ ...filters, delivery })}
        invertable
        inverted={invertedFilters.delivery}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          delivery: !invertedFilters.delivery,
        })}
      />

      <InputFilter
        controlId="filter-parcel"
        label={t('controlReport.columns.parcel', 'Parcel')}
        value={filters.parcel}
        onChange={(parcel) => setFilters({ ...filters, parcel })}
        invertable
        inverted={invertedFilters.parcel}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          parcel: !invertedFilters.parcel,
        })}
      />

      <InputFilter
        controlId="filter-item"
        label={t('controlReport.columns.item', 'Item')}
        value={filters.item}
        onChange={(item) => setFilters({ ...filters, item })}
        invertable
        inverted={invertedFilters.item}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          item: !invertedFilters.item,
        })}
      />

      <MovementSelect
        selected={filters.movementCategory}
        onChange={(movementCategory) => setFilters({ ...filters, movementCategory })}
      />

      <RfidFilter
        selected={filters.articleFlag}
        onChange={(articleFlag) => setFilters({ ...filters, articleFlag })}
        invertable
        inverted={invertedFilters.articleFlag}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          articleFlag: !invertedFilters.articleFlag,
        })}
      />

      <NumrangeFilter
        controlId="filter-price"
        label={t('controlReport.columns.price', 'Selling price')}
        valueFrom={filters.priceFrom}
        valueTo={filters.priceTo}
        onChange={(priceFrom, priceTo) => setFilters({ ...filters, priceFrom, priceTo })}
      />
      <NumrangeFilter
        controlId="filter-cession-price"
        label={t('controlReport.columns.cessionPrice', 'Cession price')}
        valueFrom={filters.cessionPriceFrom}
        valueTo={filters.cessionPriceTo}
        onChange={(cessionPriceFrom, cessionPriceTo) => setFilters({
          ...filters,
          cessionPriceFrom,
          cessionPriceTo,
        })}
      />

      <InputFilter
        controlId="filter-model-id"
        label={t('controlReport.columns.modelId', 'Model Id')}
        value={filters.modelId}
        onChange={(modelId) => setFilters({ ...filters, modelId })}
        invertable
        inverted={invertedFilters.modelId}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          modelId: !invertedFilters.modelId,
        })}
      />
      <InputFilter
        controlId="filter-item-lib"
        label={t('controlReport.columns.itemLib', 'Item description')}
        value={filters.itemLib}
        onChange={(itemLib) => setFilters({ ...filters, itemLib })}
        invertable
        inverted={invertedFilters.itemLib}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          itemLib: !invertedFilters.itemLib,
        })}
      />
      <InputFilter
        controlId="filter-family"
        label={t('controlReport.columns.family', 'Family')}
        value={filters.familyLabel}
        onChange={(familyLabel) => setFilters({ ...filters, familyLabel })}
        invertable
        inverted={invertedFilters.familyLabel}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          familyLabel: !invertedFilters.familyLabel,
        })}
      />
      <InputFilter
        controlId="filter-sub-department"
        label={t('controlReport.columns.subDepartment', 'Sub department')}
        value={filters.subDepartmentLabel}
        onChange={(subDepartmentLabel) => setFilters({ ...filters, subDepartmentLabel })}
        invertable
        inverted={invertedFilters.subDepartmentLabel}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          subDepartmentLabel: !invertedFilters.subDepartmentLabel,
        })}
      />
      <UniverseFilter
        controlId="filter-universe"
        user={user}
        updateTokens={updateTokens}
        selected={filters.universeLabel}
        onChange={(universeLabel) => setFilters({ ...filters, universeLabel })}
      />

      <NumrangeFilter
        controlId="filter-shipping-list"
        label={t('controlReport.columns.shippingList', 'Shipping list')}
        valueFrom={filters.qtyConfirmedFrom}
        valueTo={filters.qtyConfirmedTo}
        onChange={(qtyConfirmedFrom, qtyConfirmedTo) => setFilters({ ...filters, qtyConfirmedFrom, qtyConfirmedTo })}
      />
      <NumrangeFilter
        controlId="filter-before-gate"
        label={t('controlReport.columns.readInWarehouse', 'Read in warehouse')}
        valueFrom={filters.qtyReadBeforeWarehouseGateFrom}
        valueTo={filters.qtyReadBeforeWarehouseGateTo}
        onChange={(qtyReadBeforeWarehouseGateFrom, qtyReadBeforeWarehouseGateTo) => setFilters({
          ...filters,
          qtyReadBeforeWarehouseGateFrom,
          qtyReadBeforeWarehouseGateTo,
        })}
      />
      <NumrangeFilter
        controlId="filter-store-gate"
        label={t('controlReport.columns.storeGate', 'Store inbound')}
        valueFrom={filters.qtyReadByStoreGateFrom}
        valueTo={filters.qtyReadByStoreGateTo}
        onChange={(qtyReadByStoreGateFrom, qtyReadByStoreGateTo) => setFilters({
          ...filters,
          qtyReadByStoreGateFrom,
          qtyReadByStoreGateTo,
        })}
      />
      <NumrangeFilter
        controlId="filter-missing"
        label={t('controlReport.columns.missing', 'Missing')}
        valueFrom={filters.missingFrom}
        valueTo={filters.missingTo}
        onChange={(missingFrom, missingTo) => setFilters({
          ...filters,
          missingFrom,
          missingTo,
        })}
      />
      <NumrangeFilter
        controlId="filter-unexpected"
        label={t('controlReport.columns.unexpected', 'Unexpected')}
        valueFrom={filters.unexpectedQtyFrom}
        valueTo={filters.unexpectedQtyTo}
        onChange={(unexpectedQtyFrom, unexpectedQtyTo) => setFilters({
          ...filters,
          unexpectedQtyFrom,
          unexpectedQtyTo,
        })}
      />
      <NumrangeFilter
          controlId="filter-realized-movement"
          label={t('controlReport.columns.realizedMovement', 'Realized movement')}
          valueFrom={filters.realizedMovementFrom}
          valueTo={filters.realizedMovementTo}
          onChange={(realizedMovementFrom, realizedMovementTo) => setFilters({
            ...filters,
            realizedMovementFrom,
            realizedMovementTo,
          })}
        />

      <NumrangeFilter
        controlId="filter-value"
        label={t('controlReport.columns.sellingValue', 'Selling value')}
        valueFrom={filters.valueFrom}
        valueTo={filters.valueTo}
        onChange={(valueFrom, valueTo) => setFilters({
          ...filters,
          valueFrom,
          valueTo,
        })}
      />
      <NumrangeFilter
        controlId="filter-cession-value"
        label={t('controlReport.columns.cessionValue', 'Cession value')}
        valueFrom={filters.cessionValueFrom}
        valueTo={filters.cessionValueTo}
        onChange={(cessionValueFrom, cessionValueTo) => setFilters({
          ...filters,
          cessionValueFrom,
          cessionValueTo,
        })}
      />

      <Toggle
        controlId="filter-stayed-at-warehouse"
        className="[&>div]:!vtmn-justify-start"
        label={<StayedAtWarehouseLabel/>}
        value={filters.stayedAtWarehouse}
        onChange={() => setFilters({
          ...filters,
          stayedAtWarehouse: !filters.stayedAtWarehouse,
        })}
      />

      <Toggle
        controlId="filter-not-here"
        className="[&>div]:!vtmn-justify-start"
        label={t('controlReport.columns.notHere', 'Contenant not here')}
        value={filters.notHere}
        onChange={() => setFilters({
          ...filters,
          notHere: !filters.notHere,
        })}
      />
    </>
  )
}

const hasFilters = (filters) => {
  // check all filters except: receptionDateFrom, receptionDateTo
  const keys = Object.keys(filters).filter(x => x !== 'controlDateFrom' && x !== 'controlDateTo');
  const values = keys.map(k => filters[k]).filter(v => v);
  const filtered = values.length !== 0;
  return filtered;
}

const MainFilters = ({
  filters,
  setFilters,
  invertedFilters,
  setInvertedFilters,
  user,
  updateTokens,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <DateRangePicker
        controlId="filter-control-date"
        label={t('controlReport.columns.controlDate', 'Control date')}
        valueFrom={filters.controlDateFrom}
        valueTo={filters.controlDateTo}
        onChange={(controlDateFrom, controlDateTo) => setFilters({
          ...filters,
          controlDateFrom,
          controlDateTo,
        })}
      />
      <DateRangePicker
        controlId="filter-date"
        label={t('controlReport.columns.receptionDate', 'Reception date')}
        valueFrom={filters.receptionDateFrom}
        valueTo={filters.receptionDateTo}
        onChange={(receptionDateFrom, receptionDateTo) => setFilters({
          ...filters,
          receptionDateFrom,
          receptionDateTo,
        })}
      />

      <ControlsAddressSelect
        controlId="filter-address"
        label={t('controlReport.columns.address', 'Address')}
        user={user}
        updateTokens={updateTokens}
        selected={filters.address}
        onChange={(address) => setFilters({ ...filters, address })}
        invertable
        inverted={invertedFilters.address}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          address: !invertedFilters.address,
        })}
      />
      <ControlsContenantSelect
        controlId="filter-contenant"
        label={t('controlReport.columns.contenant', 'Contenant')}
        user={user}
        updateTokens={updateTokens}
        selected={filters.contenant}
        onChange={(contenant) => setFilters({ ...filters, contenant })}
        invertable
        inverted={invertedFilters.contenant}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          contenant: !invertedFilters.contenant,
        })}
      />
      <BoolSelect
        controlId="filter-confirmed"
        label={t('controlReport.columns.confirmed', 'Confirmed')}
        selected={filters.confirmed}
        onChange={(confirmed) => setFilters({ ...filters, confirmed })}
      />
    </>
  )
}

const Filters = ({
  user,
  updateTokens,
  filters,
  totals,
  setFilters,
  onClear,
  sort,
  invertedFilters,
  setInvertedFilters,
  fields,
  fieldsSelection,
  setFieldsSelection,
}) => {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState('partial');
  const isVisible = visibility !== 'hidden';
  const isFull = visibility === 'full';
  const filtered = hasFilters(filters);
  const { warehouse } = useParams();
  const memoizedExportReport = useMemo(
    () => exportReport(warehouse, fieldsSelection),
    [warehouse, fieldsSelection]
  );
  return (
    <FiltersPannel
      filtered={filtered}
      onClear={onClear}
      listClassName="vtmn-flex vtmn-flex-row vtmn-flex-wrap [&>div]:vtmn-mr-5 [&>div]:vtmn-w-[280px]"
      mainFilters={
        <MainFilters
          filters={filters}
          setFilters={setFilters}
          invertedFilters={invertedFilters}
          setInvertedFilters={setInvertedFilters}
          user={user}
          updateTokens={updateTokens}
        />
      }
      aditionalFilters={
        <AdditionalFilters
          filters={filters}
          setFilters={setFilters}
          invertedFilters={invertedFilters}
          setInvertedFilters={setInvertedFilters}
          user={user}
          updateTokens={updateTokens}
        />
      }
      exportButton={
        <ExportButton
          size="small"
          user={user}
          updateTokens={updateTokens}
          filters={filters}
          invertedFilters={invertedFilters}
          sort={sort}
          totalItems={totals && totals.items}
          exportReport={memoizedExportReport}
        />
      }
      selectColumns={
        <SelectColumns
          fields={fields}
          fieldsSelection={fieldsSelection}
          setFieldsSelection={setFieldsSelection}
        />
      }
    />
  );
};

export default Filters;
