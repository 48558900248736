import React from 'react';
import { twMerge } from "tailwind-merge";
import {
  VpFormControl,
  VpFormLabel,
  VpToggle,
} from "@vtmn-play/react";

const Toggle = ({
  className,
  label,
  rightLabel,
  value,
  onChange,
  key,
}) => {
  return (
    <div className={twMerge("vtmn-justify-center", className)}>
      <VpFormControl
        className="form-content vtmn-h-6 vtmn-flex vtmn-flex-row vtmn-items-center vtmn-justify-center"
        disabled={false}
        required={false}
        status='success'
      >
        {label && (
          <VpFormLabel className="!vtmn-mb-0 vtmn-mr-1">{label}</VpFormLabel>
        )}
        <VpToggle
          key={key}
          size="small"
          name="toggle"
          value="toogle-input-value"
          onChange={(e) => {
            onChange && onChange(e.currentTarget.checked);
          }}
          checked={value || false}
        />
        {rightLabel && (
          <VpFormLabel className="!vtmn-mb-0 vtmn-ml-1">{rightLabel}</VpFormLabel>
        )}
      </VpFormControl>
    </div>
  )
}

export default Toggle;
