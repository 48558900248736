import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formatDateForBigQuery } from '../../../../functions/formatDate';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import { handleApiResponse } from '../../../../functions/handleApiResponse';
import { getFiltersParams, getSortParams } from '../../../../functions/getQueryString';

import FiltersPannel from '../../../widgets/vtmn/FiltersPanel';
import ExportButton from '../../../widgets/vtmn/ExportButton';
import DateRangePicker from '../../../widgets/vtmn/filters/DateRangePicker';
import StoreSelect from '../../../widgets/vtmn/filters/discrepancies/StoreSelect';
import InputFilter from '../../../widgets/vtmn/filters/InputFilter';
import RfidFilter from '../../../widgets/vtmn/filters/RfidFilter';
import NumrangeFilter from '../../../widgets/vtmn/filters/NumrangeFilter';

const exportReport = (warehouse) => (user, updateTokens, oauthToken, { filters, sort }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (warehouse) {
    formatedFilters.warehouse = warehouse;
  }
  if (formatedFilters.receptionDateFrom) {
    formatedFilters.receptionDateFrom = formatDateForBigQuery(formatedFilters.receptionDateFrom);
  }
  if (formatedFilters.receptionDateTo) {
    formatedFilters.receptionDateTo = formatDateForBigQuery(formatedFilters.receptionDateTo);
  }
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/unexpected/matches/export`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      oauthToken,
      filters: getFiltersParams({ filters: formatedFilters }),
      sort: getSortParams({ sort }),
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const hasFilters = (filters) => {
  // check all filters except: receptionDateFrom, receptionDateTo
  const keys = Object.keys(filters).filter(x => x !== 'receptionDateFrom' && x !== 'receptionDateTo');
  const values = keys.map(k => filters[k]).filter(v => v);
  const filtered = values.length !== 0;
  return filtered;
}

const MainFilters = ({
  filters,
  setFilters,
  invertedFilters,
  setInvertedFilters,
  user,
  updateTokens,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <DateRangePicker
        controlId="filter-date"
        label={t('unexpectedMatches.filters.date', 'Date')}
        valueFrom={filters.receptionDateFrom}
        valueTo={filters.receptionDateTo}
        onChange={(receptionDateFrom, receptionDateTo) => setFilters({
          ...filters,
          receptionDateFrom,
          receptionDateTo,
        })}
      />

      <StoreSelect
        controlId="store-unexpected-id"
        label={t('unexpectedMatches.filters.store', 'Store unexpected')}
        user={user}
        updateTokens={updateTokens}
        selected={filters.store}
        onChange={(store) => setFilters({ ...filters, store })}
        invertable
        inverted={invertedFilters.store}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          store: !invertedFilters.store,
        })}
      />

      <StoreSelect
        controlId="store-expected-id"
        label={t('unexpectedMatches.filters.matchingStore', 'Store expected')}
        user={user}
        updateTokens={updateTokens}
        selected={filters.matchingStore}
        onChange={(matchingStore) => setFilters({ ...filters, matchingStore })}
        invertable
        inverted={invertedFilters.matchingStore}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          matchingStore: !invertedFilters.matchingStore,
        })}
      />

      <InputFilter
        controlId="filter-epc"
        label={t('unexpectedMatches.filters.epc', 'EPC')}
        value={filters.epc}
        onChange={(epc) => setFilters({ ...filters, epc })}
        invertable
        inverted={invertedFilters.epc}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          epc: !invertedFilters.epc,
        })}
      />

      <InputFilter
        controlId="filter-delivery"
        label={t('unexpectedMatches.filters.delivery', 'Delivery')}
        value={filters.delivery}
        onChange={(delivery) => setFilters({ ...filters, delivery })}
        invertable
        inverted={invertedFilters.delivery}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          delivery: !invertedFilters.delivery,
        })}
      />

      <InputFilter
        controlId="filter-item"
        label={t('unexpectedMatches.filters.item', 'Item')}
        value={filters.item}
        onChange={(item) => setFilters({ ...filters, item })}
        invertable
        inverted={invertedFilters.item}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          item: !invertedFilters.item,
        })}
      />
    </>
  )
}

const AdditionalFilters = ({
  filters,
  setFilters,
  invertedFilters,
  setInvertedFilters,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <RfidFilter
        selected={filters.articleFlag}
        onChange={(articleFlag) => setFilters({ ...filters, articleFlag })}
        invertable
        inverted={invertedFilters.articleFlag}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          articleFlag: !invertedFilters.articleFlag,
        })}
      />

      <NumrangeFilter
        controlId="filter-price"
        label={t('unexpectedMatches.filters.price', 'Selling price')}
        valueFrom={filters.priceFrom}
        valueTo={filters.priceTo}
        onChange={(priceFrom, priceTo) => setFilters({ ...filters, priceFrom, priceTo })}
      />
      <NumrangeFilter
        controlId="filter-cession-price"
        label={t('unexpectedMatches.filters.cessionPrice', 'Cession price')}
        valueFrom={filters.cessionPriceFrom}
        valueTo={filters.cessionPriceTo}
        onChange={(cessionPriceFrom, cessionPriceTo) => setFilters({
          ...filters,
          cessionPriceFrom,
          cessionPriceTo,
        })}
      />

      <InputFilter
        controlId="filter-model-id"
        label={t('unexpectedMatches.filters.modelId', 'Model Id')}
        value={filters.modelId}
        onChange={(modelId) => setFilters({ ...filters, modelId })}
        invertable
        inverted={invertedFilters.modelId}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          modelId: !invertedFilters.modelId,
        })}
      />
      <InputFilter
        controlId="filter-item-lib"
        label={t('unexpectedMatches.filters.itemLib', 'Item description')}
        value={filters.itemLib}
        onChange={(itemLib) => setFilters({ ...filters, itemLib })}
        invertable
        inverted={invertedFilters.itemLib}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          itemLib: !invertedFilters.itemLib,
        })}
      />

      <NumrangeFilter
        controlId="filter-unexpected"
        label={t('unexpectedMatches.filters.unexpected', 'Unexpected')}
        valueFrom={filters.unexpectedQtyFrom}
        valueTo={filters.unexpectedQtyTo}
        onChange={(unexpectedQtyFrom, unexpectedQtyTo) => setFilters({
          ...filters,
          unexpectedQtyFrom,
          unexpectedQtyTo,
        })}
      />
    </>
  )
}

const Filters = ({
  user,
  updateTokens,
  filters,
  totals,
  setFilters,
  onClear,
  sort,
  invertedFilters,
  setInvertedFilters,
}) => {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState('partial');
  const isVisible = visibility !== 'hidden';
  const isFull = visibility === 'full';
  const filtered = hasFilters(filters);
  const { warehouse } = useParams();
  const memoizedExportReport = useMemo(() => exportReport(warehouse), [warehouse]);
  return (
    <FiltersPannel
      filtered={filtered}
      onClear={onClear}
      listClassName="vtmn-flex vtmn-flex-row vtmn-flex-wrap [&>div]:vtmn-mr-5 [&>div]:vtmn-w-[280px]"
      mainFilters={
        <MainFilters
          filters={filters}
          setFilters={setFilters}
          invertedFilters={invertedFilters}
          setInvertedFilters={setInvertedFilters}
          user={user}
          updateTokens={updateTokens}
        />
      }
      aditionalFilters={
        <AdditionalFilters
          filters={filters}
          setFilters={setFilters}
          invertedFilters={invertedFilters}
          setInvertedFilters={setInvertedFilters}
        />
      }
      exportButton={
        <ExportButton
          size="small"
          user={user}
          updateTokens={updateTokens}
          filters={filters}
          invertedFilters={invertedFilters}
          sort={sort}
          totalItems={totals && totals.items}
          exportReport={memoizedExportReport}
        />
      }
    />
  );
};

export default Filters;
