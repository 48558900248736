import * as React from "react"
import { cva } from "class-variance-authority";

import { cn } from "../../lib/utils"

const badgeVariants = cva(
  "vp-caption vtmn-inline-flex vtmn-items-center vtmn-rounded-md vtmn-border vtmn-px-2.5 vtmn-py-0.5 vtmn-transition-colors focus:vtmn-outline-none focus:vtmn-ring-2 focus:vtmn-ring-ring focus:vtmn-ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "vtmn-border-transparent vtmn-bg-primary vtmn-text-primary-foreground",
        secondary:
          "vtmn-border-transparent vtmn-bg-secondary vtmn-text-secondary-foreground",
        destructive:
          "vtmn-border-transparent vtmn-bg-destructive vtmn-text-destructive-foreground",
        outline: "vtmn-text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

function Badge({
  className,
  variant,
  ...props
}) {
  return (<div className={cn(badgeVariants({ variant }), className)} {...props} />);
}

export { Badge, badgeVariants }
