import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import useRequest from '../../functions/useRequest';

import Drawer from '../widgets/vtmn/Drawer';
import StoreSelect from '../widgets/vtmn/form/StoreSelect';
import DatePicker from '../widgets/vtmn/filters/DatePicker';
import TextArea from '../widgets/vtmn/form/TextArea';
import Toggle from '../widgets/vtmn/filters/Toggle';

const createRobot = ({ user, updateTokens, robot }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/robots`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify(robot),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateRobot = ({ user, updateTokens, id, robot }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/robots/${id}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(robot),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const prepareSave = (robot) => ({
  ...robot,
  commentIsBlocking: (robot.comment && robot.commentIsBlocking) || false,
});

const RobotForm = ({
  user,
  updateTokens,
  data,
  isOpen,
  onClose,
  callbackFn,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(data);

  const title = data && data.id ? t('deploymentRobot.edit', 'Edit robot') : t('deploymentRobot.new', 'New robot');

  const [{
    loading: saving,
    error: saveError,
  }, saveRobot] = useRequest(data && data.id ? updateRobot : createRobot, {
    onSuccess: () => {
      callbackFn && callbackFn();
      onClose && onClose();
    },
  });

  useEffect(() => {
    setFormData(data)
  }, [data]);

  const saveData = () => saveRobot({
    id: data && data.id,
    robot: prepareSave(formData),
    user,
    updateTokens,
  });

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={title}
      onSave={saveData}
      disabledSave={saving}
    >
      <div className="vtmn-px-1 [&>div]:vtmn-mb-3">
        <StoreSelect
          label={t('storeSelect.label', 'Store')}
          user={user}
          updateTokens={updateTokens}
          value={formData.storeId}
          onChange={(value) => setFormData({
            ...formData,
            storeId: value,
          })}
        />
        <DatePicker
          label={t('deploymentRobot.arrivalDate', 'Date of arrival of the robot in the store')}
          value={formData.arrivalDate}
          onChange={(value) => setFormData({
            ...formData,
            arrivalDate: value,
          })}
        />
        <DatePicker
          label={t('deploymentRobot.training', 'Training session date')}
          value={formData.trainingSession}
          onChange={(value) => setFormData({
            ...formData,
            trainingSession: value,
          })}
        />
        <TextArea
          label={t('deploymentRobot.comment', 'Comment')}
          value={formData.comment}
          onChange={(value) => setFormData({
            ...formData,
            comment: value,
          })}
        />
        <Toggle
          className="[&>div]:vtmn-justify-start"
          label={t('deploymentRobot.commentIsBlocking', 'Comment is a blocking point')}
          value={formData.commentIsBlocking ? true : false}
          onChange={(value) => setFormData({
            ...formData,
            commentIsBlocking: value,
          })}
        />
      </div>
    </Drawer>
  )
}

export default RobotForm;
