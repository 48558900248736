import React from 'react';
import { useTranslation } from 'react-i18next';

import Combobox from '../form/Combobox';

const getMovementCategoryOptions = (t) => {
  const options = [
    {
      id: 'as_suggested',
      label: <div className="vtmn-py-2">{t('declareToControl.filters.movementCategoryValues.asSuggested', 'Same movement as suggested')}</div>,
    },
    {
      id: 'bigger',
      label: <div className="vtmn-py-2">{t('declareToControl.filters.movementCategoryValues.bigger', 'Bigger movement than suggested')}</div>,
    },
  ];
  return options;
};

const MovementFilter = ({
  selected,
  onChange,
  invertable,
  inverted,
  onInvert,
}) => {
  const { t } = useTranslation();
  const options = getMovementCategoryOptions(t);
  return (
    <Combobox
      label={t('declareToControl.filters.movementCategory', 'Stock movement category')}
      options={options}
      selected={selected}
      onChange={onChange}
      invertable={invertable}
      inverted={inverted}
      onInvert={onInvert}
      withSearch={false}
      valueClassName="[&>div]:vtmn-py-0"
    />
  );
};

export default MovementFilter;
