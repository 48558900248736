import * as React from "react"
import { DayPicker } from "react-day-picker"

import { cn } from "../../lib/utils"
import { buttonVariants } from "./button"
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons"

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}) {
  return (
    (<DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("vtmn-p-3", className)}
      classNames={{
        months: "vtmn-flex vtmn-flex-row vtmn-items-baseline sm:vtmn-flex-row vtmn-space-y-4 sm:vtmn-space-x-4 sm:vtmn-space-y-0",
        month: "vtmn-space-y-4",
        caption: "vp-body-s vtmn-flex vtmn-justify-center vtmn-pt-1 vtmn-relative vtmn-items-center",
        caption_label: "vtmn-font-medium",
        nav: "vtmn-space-x-1 vtmn-flex vtmn-items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          // "vtmn-h-7 vtmn-w-7 vtmn-bg-transparent vtmn-p-0 vtmn-opacity-50 hover:vtmn-opacity-100"
          "vtmn-h-6 vtmn-w-6 vtmn-rounded-full vp-icon-button--small vp-icon-button--secondary"
        ),
        nav_button_previous: "vtmn-absolute vtmn-left-1",
        nav_button_next: "vtmn-absolute vtmn-right-1",
        table: "vtmn-w-full vtmn-border-collapse vtmn-space-y-1",
        head_row: "vtmn-flex",
        head_cell:
          "vtmn-text-muted-foreground vtmn-rounded-md vtmn-w-6 vtmn-font-normal vtmn-text-[0.8rem]",
        row: "vtmn-flex vtmn-w-full vtmn-mt-2",
        cell: cn(
          "vp-caption vtmn-relative vtmn-p-0 vtmn-text-center hover:vtmn-bg-background-tertiary focus-within:vtmn-relative focus-within:vtmn-z-20",
          "[&:has([aria-selected])]:vtmn-background-brand-primary-new [&:has([aria-selected].day-outside)]:vtmn-background-brand-primary-new/50",
          "[&:has([aria-selected].day-range-end)]:vtmn-rounded-r-md",
          props.mode === "range"
            ? "[&:has(>.day-range-end)]:vtmn-rounded-r-md [&:has(>.day-range-start)]:vtmn-rounded-l-md first:[&:has([aria-selected])]:vtmn-rounded-l-md last:[&:has([aria-selected])]:vtmn-rounded-r-md"
            : "[&:has([aria-selected])]:vtmn-rounded-md"
        ),
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "vtmn-h-6 vtmn-w-6 vtmn-p-0 vtmn-font-normal aria-selected:vtmn-opacity-100"
        ),
        day_selected:
          "vtmn-background-brand-secondary-new vtmn-text-primary-foreground hover:vtmn-bg-background-tertiary hover:vtmn-text-primary-foreground focus:vtmn-background-brand-primary-new focus:vtmn-text-primary-foreground",
        // day_today: "vtmn-bg-background-brand-secondary vtmn-text-accent-foreground",
        day_range_start: "vtmn-background-brand-primary-new vtmn-text-white",
        day_range_end: "vtmn-background-brand-primary-new vtmn-text-white",
        day_outside:
          "vtmn-day-outside vtmn-text-muted-foreground aria-selected:vtmn-bg-hover-accent/50 aria-selected:vtmn-text-muted-foreground",
        day_disabled: "vtmn-text-muted-foreground vtmn-opacity-50",
        day_range_middle:
          "aria-selected:vtmn-bg-hover-accent aria-selected:vtmn-text-accent-foreground",
        day_hidden: "vtmn-invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ className, ...props }) => (
          <ChevronLeftIcon className={cn("vtmn-h-4 vtmn-w-4", className)} {...props} />
        ),
        IconRight: ({ className, ...props }) => (
          <ChevronRightIcon className={cn("vtmn-h-4 vtmn-w-4", className)} {...props} />
        ),
      }}
      {...props} />)
  );
}
Calendar.displayName = "Calendar"

export { Calendar }
