import React, { useEffect, useState } from 'react';
import _ from 'lodash'

import { cn } from "../../../shadcn/lib/utils";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../shadcn/components/ui/table";
import { VpSelect, VpSelectOption, VpIconButton, VpIcon } from "@vtmn-play/react";
import { VpChevronBackwardIcon, VpChevronForwardIcon } from "@vtmn-play/icons/react";

const enrichColumns = (column) => {
  column.accessorKey = column.field || undefined;
  column.id = column.id || column.field;
  column.header = ({ table }) => {
    const { sort = null, setSort = () => {} } = table.options?.meta || {};

    return (
      <div
        className={cn(
          "vp-subtitle-m vtmn-flex vtmn-flex-row vtmn-items-center",
          column.sortable ? "vtmn-cursor-pointer" : "",
          column.className,
        )}
        onClick={() => {
          if (!column.sortable) return;
          
          const nextDirection = (
            sort && sort.field === column.field && sort.direction !== 'DESC'
          ) ? 'DESC' : 'ASC';
          setSort({
            field: column.field,
            direction: nextDirection,
          })
        }}
      >
        {column.title}
        {column.sortable
          ? sort && sort.field === column.field
            ? (
              <VpIcon
                name={sort && sort.direction === 'ASC' ? "arrow-down" : "arrow-up"}
                size="16"
                className="vtmn-ml-2"
              />
            )
            : (
              <VpIcon
                name="arrow-up-down"
                size="16"
                className="vtmn-ml-2"
              />
            )
          : ''
        }
        {column.toolbar && (
          <>
            {column.toolbar({ table })}
          </>
        )}
      </div>
    )
  }
  return column;
};

const Footer = ({ pagination, totals, setPagination }) => {
  const isFirst = pagination && pagination.page === 0;
  const isLast = pagination && pagination.page + 1 === totals.pages;

  return (
    <div id="footer" className="vtmn-flex vtmn-justify-between vtmn-items-center vtmn-mt-4 vtmn-px-4 vtmn-pb-4">
      <span>Total {totals.items > 0 ? totals.items : 0}</span>
      <div className="vtmn-flex vtmn-items-center">
        <div className="vtmn-flex vtmn-items-center vtmn-mr-2">
          <span className="vtmn-mr-2">Page size</span>
          <VpSelect
            size="small"
            value={`${pagination.size}`}
            onChange={(event) => setPagination({
              page: 0,
              size: Number(event.target.value),
            })}
          >
            <VpSelectOption value={20}>20</VpSelectOption>
            <VpSelectOption value={50}>50</VpSelectOption>
            <VpSelectOption value={100}>100</VpSelectOption>
            <VpSelectOption value={200}>200</VpSelectOption>
          </VpSelect>
        </div>
        <VpIconButton
          size="small"
          variant="secondary"
          onClick={() => setPagination({ ...pagination, page: pagination.page - 1 })}
          disabled={isFirst || totals.pages === 0}
          aria-label="Previous"
        >
          <VpChevronBackwardIcon />
        </VpIconButton>
        <span className="vtmn-mx-2 vtmn-whitespace-nowrap">
          {pagination.page + 1} of {totals.pages}
        </span>
        <VpIconButton
          size="small"
          variant="secondary"
          onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })}
          disabled={isLast || totals.pages === 0}
          aria-label="Next"
        >
          <VpChevronForwardIcon />
        </VpIconButton>
      </div>
    </div>
  )
}

const DataTable = ({
  columns,
  data,
  meta,
  pagination,
  setPagination,
  totals,
  className,
  setCheckedRows,
  summaryRow,
}) => {
  const enrichedColumns = columns.map(enrichColumns);
  const [rowSelection, setRowSelection] = useState({})

  const table = useReactTable({
    data,
    columns: enrichedColumns,
    meta,
    manualPagination: true, //turn off client-side pagination
    rowCount: totals && totals.items,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onRowSelectionChange: (fn) => {
      setCheckedRows && setCheckedRows(fn);
      setRowSelection(fn);
    },
    state: {
      rowSelection,
    }
  });

  return (
    <div className="vtmn-mb-5 vtmn-h-full">
      <Table className={cn("vtmn-table-border-gray", className)}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} className={header.column.columnDef.className}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className={cell.column.columnDef.className}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
          {!!summaryRow && !!table.getRowModel().rows.length && (
            <TableRow
              key="summary-row"
            >
              {table.getRowModel().rows[0].getVisibleCells().map((cell) => (
                <TableCell key={`summary-row-${cell.id}`}>
                  {cell.column.columnDef.id && summaryRow[cell.column.columnDef.id]
                    ? (
                        <span className="vp-subtitle-m">
                          {cell.column.columnDef.cell({
                            getValue: () => summaryRow[cell.column.columnDef.id],
                            row: {
                              original: summaryRow
                            },
                            renderValue: () => {}
                          })}
                        </span>
                      )
                    : ''
                  }
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
      {pagination && (
        <Footer
          pagination={pagination}
          totals={totals}
          setPagination={setPagination}
        />
      )}
    </div>
  );
}

DataTable.Footer = Footer;

export default DataTable;
