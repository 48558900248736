import React, { useState } from 'react';
import { VpIconButton, VpIcon } from "@vtmn-play/react";
import { VpSettingsIcon } from "@vtmn-play/icons/react";

import { cn } from '../../../../shadcn/lib/utils';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "../../../../shadcn/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../shadcn/components/ui/popover";

const SelectColumns = ({
  fields,
  fieldsSelection,
  setFieldsSelection,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
    >
    <PopoverTrigger asChild>
      <VpIconButton
        size="small"
        variant="secondary"
        className="vtmn-mr-2"
      >
        <VpSettingsIcon />
      </VpIconButton>
    </PopoverTrigger>
    <PopoverContent
      align="start"
      side="bottom"
      className={cn(
        "vtmn-w-auto vtmn-p-1",
        "!vtmn-z-50 vtmn-bg-white vtmn-left-0",
      )}
    >
      <Command className="vtmn-p-1">
        <CommandList>
          <CommandEmpty>Not found.</CommandEmpty>
          <CommandGroup>
            {fields.map(({ title, field }) => (
              <CommandItem
                key={field}
                className={cn(
                  "vtmn-cursor-pointer"
                )}
                value={field}
                onSelect={(value) => {
                  setFieldsSelection({
                    ...fieldsSelection,
                    [value]: !fieldsSelection[value],
                  });
                }}
              >
                <div className="vtmn-flex vtmn-flex-row vtmn-w-full">
                  <div className="vtmn-flex vtmn-flex-row vtmn-w-full vtmn-items-center vtmn-p-3">
                    <span
                      className={cn(
                        "vtmn-w-full vtmn-typo_text-2",
                      )}
                    >
                      {title}
                    </span>
                    {!!fieldsSelection[field] && (
                      <VpIcon name="check" />
                    )}
                  </div>
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </Command>
    </PopoverContent>
    </Popover>

    // <DropdownButton
    //   className="select-columns"
    //   show={show}
    //   size="sm"
    //   title={
    //     <img src={settingsIcon} alt="robot-status" />
    //   }
    //   onToggle={(isOpen, event, { source }) => {
    //     if (isOpen) {
    //       setShow(true);
    //     } else {
    //       if (source !== 'select') {
    //         setShow(false);
    //       }
    //     }
    //   }}
    // >
    //   {fields.map(({ title, field }) => (
    //     <Dropdown.Item
    //       className="d-flex"
    //       as="div"
    //       role="button"
    //       onClick={(e) => {
    //         setFieldsSelection({
    //           ...fieldsSelection,
    //           [field]: !fieldsSelection[field],
    //         });
    //       }}
    //     >
    //       <Form.Check
    //         type="checkbox"
    //         id={`select-columns-${field}`}
    //         className="mr-2"
    //         checked={!!fieldsSelection[field]}
    //         readOnly
    //       />
    //       {title}
    //     </Dropdown.Item>
    //   ))}
    // </DropdownButton>
  )
};

export default SelectColumns;
