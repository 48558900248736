import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  VpFormControl,
  VpFormError,
  VpFormHelper,
  VpFormLabel,
  VpInput,
} from "@vtmn-play/react";

import { cn } from '../../../../shadcn/lib/utils';
import useDebounce from "../../../../functions/useDebounce";

import Toggle from './Toggle';

const InputFilter = ({
  label,
  value,
  onChange,
  className,
  disabled,
  invertable,
  inverted,
  onInvert,
  onBlur,
  error,
  success,
}) => {
  const { t } = useTranslation();
  const [stateValue, setStateValue] = useState(value);
  const debouncedValue = useDebounce(stateValue, 500);

  useEffect(
    () => { setStateValue(value); },
    [value],
  );

  useEffect(
    () => {
      if (value !== debouncedValue) {
        onChange(debouncedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );
  
  return (
    <VpFormControl
      className={cn(
        "vtmn-flex vtmn-flex-col form-content",
        invertable && "[&_label]:vtmn-mb-[2px]",
        className,
      )}
      status={!!error ? "error" : (success && "success")}
    >
      {!!label && (
        <VpFormLabel className="!vtmn-items-start">
          <div className={cn(
            "vtmn-flex vtmn-flex-row vtmn-items-center vtmn-w-full",
            invertable && "vtmn-justify-between"
          )}>
            <span>{label || t('declareToControl.filters.store','Store')}</span>
            {invertable && (
              <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-ml-4">
                <span className="vp-caption vtmn-mr-3">
                  {t('filters.excludes', ' excludes')}
                </span>
                <Toggle value={inverted || false} onChange={onInvert} />
              </div>
            )}
          </div>
        </VpFormLabel>
      )}
      <VpInput
        size="small"
        value={stateValue}
        disabled={disabled}
        onChange={(e) => {
          if (e) setStateValue(e.target.value);
        }}
        onBlur={onBlur}
      />
      {!!error && (
        <VpFormError className="vtmn-flex !vtmn-flex-row">
          <div className="!vp-caption">{error}</div>
        </VpFormError>
      )}
    </VpFormControl>
  )
}

export default InputFilter;
