import * as React from "react"
import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { ChevronDown } from "lucide-react"

import { cn } from "../../lib/utils"

const Accordion = AccordionPrimitive.Root

const AccordionItem = React.forwardRef(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn("vtmn-border-border-primary vtmn-border-b", className)} {...props} />
))
AccordionItem.displayName = "AccordionItem"

const AccordionTrigger = React.forwardRef(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header asChild className="vtmn-flex vtmn-px-4 vtmn-py-3 hover:vtmn-new-bg-background-brand-secondary">
    <span>
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          "vtmn-flex vtmn-flex-1 vtmn-items-center vtmn-justify-between vtmn-font-medium vtmn-transition-all [&[data-state=open]>svg]:vtmn-rotate-180",
          className
        )}
        {...props}>
        {children}
        <ChevronDown
          className="vtmn-h-4 vtmn-w-4 vtmn-shrink-0 vtmn-transition-transform vtmn-duration-200" />
      </AccordionPrimitive.Trigger>
    </span>
  </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="vtmn-overflow-hidden vtmn-text-sm vtmn-transition-all data-[state=closed]:vtmn-animate-accordion-up data-[state=open]:vtmn-animate-accordion-down"
    {...props}>
    <div className={cn("vtmn-pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
))

AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent }
