import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { VpAsset, VpIcon, VpLoader } from "@vtmn-play/react";

import capitalize from '../../functions/capitalize';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn/components/ui/tooltip";
import DataTable from "../widgets/vtmn/DataTable";
import ErrorMessage from '../errorMessage';
import MonitoringTooltipContent from './MonitoringTooltipContent';

const getStoresData = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/stores/pilot/monitoring`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const columns = [{
  title: <Trans i18nKey="storesMonitoring.country">Country</Trans>,
  field: 'countryCode',
  cell: ({ getValue }) => <VpAsset name={`flag-${getValue().toLowerCase()}`} width="24" />,
}, {
  title: <Trans i18nKey="storesMonitoring.store">Store</Trans>,
  field: 'id',
}, {
  title: <Trans i18nKey="storesMonitoring.description">Description</Trans>,
  field: 'name',
  cell: ({ getValue }) => capitalize(getValue()),
}, {
  title: <Trans i18nKey="storesMonitoring.receptionReads">Reception last readings</Trans>,
  id: 'reception-reading',
  cell: ({ row }) => {
    const gateLinkto = `/${row.original.countryId}/${row.original.id}/monitoring/gate`;

    return (
      <MonitoringCellValue
        id={row.original.id}
        val={row.original.monitoring.gateTagsReceived}
        maxVal={row.original.monitoring.gateTagsExpected}
        linkto={gateLinkto}
        racket
        hasRacket={row.original.features.hasRacket}
      />
    )
  },
}, {
  title: <Trans i18nKey="storesMonitoring.robotReads">Robot last readings</Trans>,
  id: 'robot-reading',
  cell: ({ row }) => {
    const robotLinkto = `/${row.original.countryId}/${row.original.id}/monitoring/robot`;

    console.log(row.original)
    return (
      <MonitoringCellValue
        id={row.original.id}
        val={row.original.monitoring.robotTotalUnsoldTags}
        maxVal={row.original.monitoring.robotMaxUnsoldTags}
        linkto={robotLinkto}
        robot
        hasRobot={row.original.features.hasRobot}
      />
    )
  },
}];

const MonitoringCellValue = ({
  id,
  val,
  maxVal,
  linkto,
  robot,
  racket,
  hasRobot,
  hasRacket,
}) => {
  console.log(id, 'val', val, 'maxVal', maxVal)
  if (
    ((val === null || val === undefined) && (maxVal === null || maxVal === undefined))
    || (robot && !hasRobot)
    || (racket && !hasRacket)
  ) {
    return <div className="vtmn-flex">-</div>;
  }

  let valStr;
  if (val === null || val === undefined) {
    valStr = '-';
  } else {
    valStr = `${val}`;
  }

  let maxValStr;
  if (maxVal === null || maxVal === undefined) {
    maxValStr = '-';
  } else {
    maxValStr = `${maxVal}`;
  }

  let className = '';
  let icon = '';
  if (!val || !maxVal) {
    className = 'vtmn-text-danger';
    icon = 'thumb-down';
  } else if (val > maxVal * 0.9) {
    className = 'vtmn-text-success';
    icon = 'thumb-up';
  } else if (val > maxVal * 0.5) {
    className = 'vtmn-text-warning';
    icon = 'thumb-down';
  } else {
    className = 'vtmn-text-danger';
    icon = 'thumb-down';
  }

  return (
    <>
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div className={`vtmn-flex vtmn-flex-row vtmn-items-center ${className}`} >
            <Link to={linkto} className={className}>
              {valStr}
              {' / '}
              {maxValStr}
            </Link>
            <VpIcon name={icon} size="20" className="vtmn-ml-1" />
          </div>
        </TooltipTrigger>
        <TooltipContent side="bottom">
          <MonitoringTooltipContent robot={robot} racket={racket} />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
    </>
  );
}

const AdminStoresMonitoring = ({ user, updateTokens }) => {
  const [{
    loading,
    data,
    error,
  }, fetchStoresData] = useRequest(getStoresData);
  useEffect(() => {
    fetchStoresData(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="adminStoresMonitoring mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
      {loading && (
        <div className="vtmn-w-full vtmn-h-screen vtmn-flex vtmn-items-center vtmn-justify-center">
          <VpLoader size="extra-large" />
        </div>
      )}
      {!loading && error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <DataTable
          className="vtmn-h-full"
          columns={columns}
          data={data || []}
        />
      )}
    </div>
  )
}

export default AdminStoresMonitoring;
