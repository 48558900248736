import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import useRequest from '../../functions/useRequest';
import getQueryString from '../../functions/getQueryString';

import Drawer from '../widgets/vtmn/Drawer';
import StoreSelect from '../widgets/vtmn/form/StoreSelect';
import CurrencyFilter from '../widgets/vtmn/filters/CurrencyFilter';
import NumberInput from '../widgets/vtmn/form/NumberInput';
import TimeDuration from '../widgets/vtmn/form/TimeDuration';
import Toggle from '../widgets/vtmn/filters/Toggle';

const getCountrySettings = (user, updateTokens, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;
  let queryString = getQueryString({ filters });
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/country/settings?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const getSettings = ({
  user,
  updateTokens,
  siteId,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/settings/${siteId}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const createSettings = ({
  user,
  updateTokens,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/settings`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateSettings = ({
  user,
  updateTokens,
  storeId,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/settings/${storeId}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const SettingsForm = ({
  user,
  updateTokens,
  siteId,
  isOpen,
  onClose,
  callbackFn,
  isNewSettings,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [countrySettings, setCountrySettings] = useState({});

  const title = !isNewSettings
    ? t('efficiencyDeployment.settings.edit', 'Edit measurement efficiency settings')
    : t('efficiencyDeployment.settings.new', 'New measurement efficiency settings');

  const [{
    loading: loadingCountry,
    error: errorCountry,
  }, fetchCountrySettings] = useRequest(
    getCountrySettings, {
      onSuccess: setCountrySettings,
      onError: () => setCountrySettings({}),
    }
  );

  const [{
    loading,
    error,
  }, fetchSettings] = useRequest(getSettings, {
    onSuccess: setFormData
  });

  useEffect(() => {
    if (!siteId) {
      return;
    }

    console.log('siteId', siteId)
    fetchSettings({
      siteId,
      user,
      updateTokens,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, fetchSettings]);

  useEffect(() => {
    if(!siteId) {
      return;
    }
    fetchCountrySettings(user, updateTokens, { site_id: siteId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  const [{
    loading: saving,
    error: saveError,
  }, saveSettings] = useRequest(isNewSettings ? createSettings : updateSettings, {
    onSuccess: () => {
      callbackFn && callbackFn();
      onClose && onClose();
    },
  });

  const saveData = () => {
    saveSettings({
      storeId: siteId,
      settings: {
        id: siteId,
        ...formData,
      },
      user,
      updateTokens,
    });
  }

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={title}
      onSave={saveData}
    >
      <div className="vtmn-px-1 [&>div]:vtmn-mb-3">
        <StoreSelect
          label={t('storeSelect.label', 'Store')}
          user={user}
          updateTokens={updateTokens}
          value={siteId || formData.id}
          disabled={!!siteId}
          onChange={(value) => setFormData({
            ...formData,
            id: value,
          })}
        />
        <CurrencyFilter
          label={t('efficiencyDeployment.settings.form.currency', 'Currency')}
          selected={formData.storeCurrency || countrySettings.currency || ''}
          onChange={(value) => setFormData({ ...formData, storeCurrency: value || null })}
        />
        <NumberInput
          label={t('efficiencyDeployment.settings.form.costPerHour', 'Cost per hour')}
          step={0.01}
          min={0}
          max={1000000}
          value={formData.storeCostPerHour || countrySettings.cost_per_hour || ''}
          onChange={(value) => setFormData({ ...formData, storeCostPerHour: value || null })}
        />
        <NumberInput
          label={t('efficiencyDeployment.settings.form.unloadingTeammates', 'Unloading teammates')}
          step={1}
          min={0}
          max={1000}
          value={formData.unloadingTeammates || countrySettings.unloading_teammates || ''}
          onChange={(value) => setFormData({ ...formData, unloadingTeammates: value || null })}
        />
        <TimeDuration
          label={t('efficiencyDeployment.settings.form.unloadingDuration', 'Unloading duration')}
          value={formData.unloadingDuration || countrySettings.unloading_duration}
          onChange={(value) => setFormData({ ...formData, unloadingDuration: value || null })}
        />
        <NumberInput
          label={t('efficiencyDeployment.settings.form.shelvingTeammates', 'Shelving teammates')}
          step={1}
          min={0}
          max={1000}
          value={formData.shelvingTeammates || countrySettings.shelving_teammates || ''}
          onChange={(value) => setFormData({ ...formData, shelvingTeammates: value || null })}
        />
        <NumberInput
          label={t('efficiencyDeployment.settings.form.shelvingProductivity', 'Shelving productivity')}
          step={0.01}
          min={0}
          max={1000000}
          value={formData.shelvingProductivity || countrySettings.shelving_productivity || ''}
          onChange={(value) => setFormData({ ...formData, shelvingProductivity: value || null })}
        />
        <Toggle
          key={`${siteId}-${formData.storeEfficiencyActive}`}
          className="vtmn-flex !vtmn-justify-start"
          label={t('efficiencyDeployment.settings.form.active', 'Active')}
          value={formData.storeEfficiencyActive || false}
          onChange={() => setFormData({ ...formData, storeEfficiencyActive: !formData.storeEfficiencyActive || null })}
        />
      </div>
    </Drawer>
  )
}

export default SettingsForm;