import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MDEditor from '@uiw/react-md-editor';
import { Link } from "react-router-dom";
import { VpButton, VpLoader, VpIconButton, VpLink } from "@vtmn-play/react";
import { VpAddIcon, VpEditIcon, VpEyeIcon } from "@vtmn-play/icons/react";

import useRequest from '../../functions/useRequest';
import useTableRequest from '../../functions/useTableRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import getQueryString from '../../functions/getQueryString';
import handleApiResponse, { handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import { formatDate } from '../../functions/formatDate';

import DataTable from "../widgets/vtmn/DataTable";
import ErrorMessage from '../errorMessage';
import Confirm from '../widgets/vtmn/Confirm';
import ReleaseDrawer from './ReleaseDrawer';

const getReleases = (user, updateTokens, { pagination }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({ pagination });
  const url = `${process.env.REACT_APP_base_URL}/api/releases?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const postPublish = (user, updateTokens, { id }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/releases/${id}/publish`;
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const columns = [{
  title: <Trans i18nKey="releases.headers.version">Version</Trans>,
  field: 'version',
  cell: ({ getValue, row }) => (
    <Link to={`/admin/releases/${row.original.id}`}>
      <VpLink>{getValue()}</VpLink>
    </Link>
  )
}, {
  title: <Trans i18nKey="releases.headers.image">Image</Trans>,
  field: 'image',
  cell: ({ row, table }) => {
    const { token } = table.options.meta;
    return (
      <div className='vtmn-w-[80px]'>
        <img src={`${process.env.REACT_APP_base_URL}/api/files/${row.original.image}?token=${token}`} alt="slide" />
      </div>
    )
  }
}, {
  title: <Trans i18nKey="releases.headers.type">Type</Trans>,
  field: 'type',
}, {
  title: <Trans i18nKey="releases.headers.summary">Summary</Trans>,
  field: 'summary',
  cell: ({ getValue }) => (
    <MDEditor.Markdown className="mobile-markdown" source={getValue()} />
  )
}, {
  title: <Trans i18nKey="releases.headers.creationDate">Creation date</Trans>,
  field: 'creation_date',
  cell: ({ getValue }) => formatDate(new Date(getValue()))
}, {
  id: 'actions',
  cell: ({ row, table }) => {
    const { onEditRelease, setPublishedRelease } = table.options.meta;

    return (
      <div className='vtmn-flex vtmn-flex-row vtmn-items-center'>
        <VpIconButton
          size="small"
          variant="secondary"
          aria-label="Edit"
          onClick={() => onEditRelease(row.original)}
        >
          <VpEditIcon />
        </VpIconButton>
        <VpButton
          size="small"
          variant="secondary"
          startSlot={<VpEyeIcon />}
          disabled={row.original.published}
          className='vtmn-ml-3'
          onClick={() => setPublishedRelease(true)}
        >
          {!row.original.published
            ? <Trans i18nKey="releases.publish">Publish</Trans>
            : <Trans i18nKey="releases.published">Published</Trans>
          }
        </VpButton>
      </div>
    )
  }
}];

const AdminReleases = ({ user, updateTokens }) => {
  const { token } = user;
  const { t } = useTranslation();
  const [editableRelease, setEditableRelease] = useState({});
  const [publishedRelease, setPublishedRelease] = useState();
  const [showForm, setShowForm] = useState(false);

  const [{
      data,
      loading,
      error,
      pagination,
      totals,
    }, // eslint-disable-next-line
    fetchReleases, setFilters, setPagination, setSort, updateDataRow
  ] = useTableRequest(getReleases);

  useEffect(() => {
    fetchReleases(user, updateTokens, { pagination });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const setPublished = ({ releaseId }) => {
    const releaseIndex = data.findIndex((r) => r.id === releaseId);
    if (releaseIndex === -1 || errorPublish) {
      return;
    }
    updateDataRow(releaseIndex, {
      ...data[releaseIndex],
      published: true,
    });
  }

  const [{
    error: errorPublish,
  }, doPublish] = useRequest(
    postPublish, {
      onSuccess: setPublished
    });

  const afterSaveRelease = () => {
    fetchReleases(user, updateTokens);
    setEditableRelease({});
  }

  const doPublishRelease = () => {
    doPublish(user, updateTokens, { id: publishedRelease });
    setPublishedRelease();
  }

  const hasError = !!error || !!errorPublish;

  return (
    <div className="adminGroups mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
      <div className="vtmn-flex vtmn-flex-row vtmn-justify-end vtmn-px-4 vtmn-mb-3">
        <VpButton
          size="small"
          className="nowrap !vtmn-bg-content-positive"
          startSlot={<VpAddIcon />}
          shape='squared'
          onClick={() => {
            setEditableRelease({})
            setShowForm(true);
          }}
        >
          <Trans i18nKey="releases.addRelease">Add release</Trans>
        </VpButton>
      </div>
      {loading && (
        <div className="vtmn-w-full vtmn-h-screen vtmn-flex vtmn-items-center vtmn-justify-center">
          <VpLoader size="extra-large" />
        </div>
      )}
      {!loading && hasError && <ErrorMessage error={error || errorPublish} />}
      {!loading && !error && data && (
        <DataTable
          className="vtmn-h-full"
          columns={columns}
          data={data || []}
          totals={totals}
          pagination={pagination}
          setPagination={setPagination}
          meta={{
            token,
            onEditRelease: (release) => {
              setEditableRelease(release);
              setShowForm(true);
            },
            setPublishedRelease
          }}
        />
      )}
      <ReleaseDrawer
        user={user}
        updateTokens={updateTokens}
        isOpen={showForm}
        onClose={() => setShowForm(false)}
        release={editableRelease}
        callbackFn={afterSaveRelease}
      />
      <Confirm
        title={t('releases.publishSlideConfirm.warning', 'Warning')}
        body={t('releases.publishSlideConfirm.text', 'Are you sure you want to publish this release?')}
        show={!!publishedRelease}
        onHide={() => setPublishedRelease()}
        loading={false}
        onConfirm={doPublishRelease}
      />
    </div>
  );
};

export default AdminReleases;
