import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { formatCurrency } from '../../../functions/formatMoney';
import { numberRenderer } from './index';

import InputFilter from '../../widgets/vtmn/filters/InputFilter';
import TimeDuration from '../../widgets/vtmn/form/TimeDuration';
import InvolvementInput from './InvolvementInput';

const Planned = ({ efficiency, setEfficiency }) => {
  const { t } = useTranslation();
  const currency = formatCurrency(efficiency.currency);
  const calculated = {
    teamUnloadingHours: (efficiency.planned_unloading_involvement
      ? _.sum(efficiency.planned_unloading_involvement) 
      : efficiency.planned_unloading_teammates) * (efficiency.planned_unloading_duration / 3600),
    teamShelvingHours: (efficiency.planned_shelving_involvement
      ? _.sum(efficiency.planned_shelving_involvement) 
      : efficiency.planned_shelving_teammates) * (efficiency.planned_shelving_duration / 3600),
    shelvingDuration: (efficiency.planned_quantities * 3600)
      / (efficiency.planned_shelving_involvement
        ? _.sum(efficiency.planned_shelving_involvement) 
        : efficiency.planned_shelving_teammates)
      / efficiency.planned_shelving_productivity
  };

  return (
    <div className="vtmn-grid vtmn-grid-cols-2 [&>div]:vtmn-mt-3 vtmn-pl-1">
      <InputFilter
        label={t('efficiencyAdmin.columns.quantities', 'Quantities')}
        value={efficiency.planned_quantities}
        disabled={true}
        className="vtmn-mr-3"
      />
      <InputFilter
        label={t('efficiencyAdmin.columns.shelvingProductivity', 'Shelving productivity')}
        value={efficiency.planned_shelving_productivity}
        onChange={(planned_shelving_productivity) => setEfficiency({ ...efficiency, planned_shelving_productivity })}
      />
      <InvolvementInput
        label={t('efficiencyAdmin.columns.unloadingTeammates', 'Unloading teammates')}
        teammates={efficiency.planned_unloading_teammates}
        involvement={efficiency.planned_unloading_involvement}
        onChange={(unloading_teammates, unloading_involvement) => setEfficiency({
          ...efficiency,
          planned_unloading_teammates: unloading_teammates,
          planned_unloading_involvement: unloading_involvement,
        })}
        maxValue={efficiency.planned_unloading_duration}
        className="vtmn-mr-3"
      />
      <InvolvementInput
        label={t('efficiencyAdmin.columns.shelvingTeammates', 'Shelving teammates')}
        teammates={efficiency.planned_shelving_teammates}
        involvement={efficiency.planned_shelving_involvement}
        onChange={(shelving_teammates, shelving_involvement) => setEfficiency({
          ...efficiency,
          planned_shelving_teammates: shelving_teammates,
          planned_shelving_involvement: shelving_involvement,
        })}
        maxValue={efficiency.planned_shelving_duration}
      />

      <TimeDuration
        label={t('efficiencyAdmin.columns.unloadingDuration', 'Unloading duration')}
        value={efficiency.planned_unloading_duration}
        onChange={(planned_unloading_duration) => setEfficiency({ ...efficiency, planned_unloading_duration })}
        className="vtmn-mr-3"
      />
      <TimeDuration
        disabled
        label={t('efficiencyAdmin.columns.shelvingDuration', 'Shelving duration')}
        value={calculated.shelvingDuration || efficiency.planned_shelving_duration}
      />

      <InputFilter
        disabled
        label={`${t('efficiencyAdmin.columns.plannedUnloadingTeamHours', 'Teammate unloading hours')}`}
        value={numberRenderer(calculated.teamUnloadingHours || efficiency.planned_unloading_hours)}
        className="vtmn-mr-3"
      />
      <InputFilter
        disabled
        label={`${t('efficiencyAdmin.columns.plannedShealvingTeamHours', 'Teammate shelving hours')}`}
        value={numberRenderer(calculated.teamShelvingHours || efficiency.planned_shelving_hours)}
      />
      <InputFilter
        disabled
        label={`${t('efficiencyAdmin.columns.unloadingInvestment', 'Unloading investment')} (${currency})`}
        //need to think about whether it is worth using the current cost_per_hour value
        value={numberRenderer((calculated.teamUnloadingHours * efficiency.cost_per_hour) || efficiency.planned_unloading_investment)}
        className="vtmn-mr-3"
      />
      <InputFilter
        disabled
        label={`${t('efficiencyAdmin.columns.shelvingInvestment', 'Shelving investment')} (${currency})`}
        value={numberRenderer((calculated.teamShelvingHours * efficiency.cost_per_hour) || efficiency.planned_shelving_investment)}
      />
    </div>
  );
};

export default Planned;
