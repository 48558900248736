import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  VpAsset,
  VpLoader,
  VpDivider,
  VpLink,
} from "@vtmn-play/react";

import getPilotSites from "../functions/getPilotSites.js";
import handleApiResponse from '../functions/handleApiResponse';
import fetchWithJWT from '../functions/fetchWithJWT';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../shadcn/components/ui/accordion";
import Search from './widgets/vtmn/filters/Search.js';
import ErrorMessage from './errorMessage';

const getCountries = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/countries`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return countries;
};

export default class ChooseStore extends Component {
  state = {
    search: '',
    sites: [],
    error: null,
    loading: true,
    countriesDict: {},
  }

  componentDidMount(){
    const { history, user } = this.props;
    const adminAccess = user.rights.indexOf('adminPanel') > -1;
    const savedSelectedSite = localStorage.selectedSite !== 'undefined' ? localStorage.selectedSite : undefined;
    const url = window.location.href;
    const isFromAuth = url.indexOf('?code=') !== -1 && url.indexOf('&state=') !== -1;

    if ((savedSelectedSite && isFromAuth) || !adminAccess) {
      const site = JSON.parse(savedSelectedSite || null);
      this.redirectToSiteHome(site);
    } else {
      if (isFromAuth) {
        // cleanup address sile after
        history.push('/');
      }
      this.loadSites();
      this.loadCountries();
    }
  }

  redirectToSiteHome = async (site) => {
    const { history, logOut } = this.props;
    let selectedSite = site;
    try {
      this.setState({ loading: true });
      const sites = await getPilotSites(this.props.user, this.props.updateTokens);
      this.setState({ loading: false });
      if (!sites.length) {
        logOut();
      }
      selectedSite = sites.find(x => site && x.id === `${site.id}`) || sites[0];
      localStorage.selectedSite = JSON.stringify(selectedSite);
    } catch (e) {
      this.setState({ loading: false });
    }

    const linkto = selectedSite.isWarehouse
      ? `/${selectedSite.countryId}/warehouse/${selectedSite.id}/contenants/control`
      : `/${selectedSite.countryId}/${selectedSite.id}/real_time/store_reception`;
    history.push(linkto);
  }

  loadSites = () => {
    this.setState({ loading: true });
    getPilotSites(this.props.user, this.props.updateTokens)
    .then(sites => {
      this.setState({ sites: sites || [], loading: false });
    })
    .catch(error => {
      this.setState({ error, loading: false });
      console.warn(`Error while searching for sites`);
    });
  }

  updateCountries = (sites) => {
    const countries = {};
    sites.forEach((site) => {
      if (!countries[site.countryCode]) {
        countries[site.countryCode] = [site];
      } else {
        countries[site.countryCode].push(site);
      }
    });
    return Object.keys(countries).length ? countries : null;
  }

  loadCountries = () => {
    this.setState({ loading: true });
    getCountries(this.props.user, this.props.updateTokens)
    .then((c) => {
      const countriesDict = {};
      c.forEach((country) => {
        countriesDict[country.code] = country;
      });
      this.setState({ countriesDict, loading: false });
    })
    .catch(error => {
      this.setState({ error, loading: false });
      console.warn(`Error countries loading`);
    });
  }

  onSiteClick = (site) => {
    localStorage.selectedSite = JSON.stringify(site);
  }

  displaySite = (site) => {
    const linkto = site.isWarehouse
      ? `/${site.countryId}/warehouse/${site.id}/contenants/control`
      : `/${site.countryId}/${site.id}/real_time/store_reception`;
    return (
      <div className="vtmn-flex vtmn-flex-col vtmn-w-full vtmn-items-center">
        <Link
          to={linkto}
          onClick={() => this.onSiteClick(site)}
          className="vtmn-w-full vtmn-h-full !vtmn-pl-4 vp-body-s hover:vtmn-new-bg-background-brand-secondary"
          style={{lineHeight: '3rem'}}
        >
          <VpLink>
            {`${site.id} - ${this.capitalize(site.name)}`}
          </VpLink>
        </Link>
        <VpDivider className='!vtmn-w-full'/>
      </div>
    )
  }

  renderCountryRow = (countryCode, sites) => {
    const { countriesDict } = this.state;

    return (
      <AccordionItem value={countryCode}>
        <AccordionTrigger>
          <div className="vtmn-flex vtmn-items-center">
            <div className="vtmn-mr-3">
              <VpAsset className="vtmn-mr-2" name={`flag-${countryCode.toLowerCase()}`} width="24" />
            </div>
            <div className="vtmn-typo_text-2 vtmn-flex vtmn-items-center">
              {countriesDict[countryCode] && countriesDict[countryCode].name}
            </div>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="vtmn-mb-0 [&>div:last-child>div.vp-divider]:vtmn-hidden vtmn-w-full">
            {sites && sites.map(this.displaySite)}
          </div>
        </AccordionContent>
      </AccordionItem>
    );
  }

  renderCountryTree = (countries) => {
    const view = [];
    for(let countryCode in countries) {
      view.push(
        this.renderCountryRow(countryCode, countries[countryCode]),
      );
    }
    return view;
  }

  capitalize = (name) => {
    const splitted = name.split(" ");
    return splitted.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(", ")
  }

  filterSites = (sites, search) => {
    if (!sites) {
      return [];
    }
    if (!search) {
      return sites;
    }
    const filtered = sites.filter(site => {
      const toSearch = `${site.id} ${site.name}`.toLowerCase();
      const matches = toSearch.indexOf(search.toLowerCase()) !== -1;
      return matches;
    })
    return filtered;
  }

  render() {
    const { error, search, sites, loading } = this.state;
    const filtered = this.filterSites(sites, search);
    const countries = this.updateCountries(filtered);

    return(
      <div className="chooseStore mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
        <div className="vtmn-mt-5 vtmn-px-4">
          <Search
            loading={loading}
            value={search}
            onChange={(search) => this.setState({ search })}
            onRefresh={this.loadSites}
          />
        </div>
        {error && <ErrorMessage error={error} />}
        {(!countries || loading) && (
          <div className="vtmn-w-full vtmn-h-screen vtmn-flex vtmn-items-center vtmn-justify-center">
            <VpLoader size="extra-large" />
          </div>
        )}
        {countries && !loading && !error && (
          <div className="vtmn-flex vtmn-mt-5">
            <Accordion type="single" collapsible className="vtmn-w-full">
              {this.renderCountryTree(countries)}
            </Accordion>
          </div>
        )}
      </div>
    )
  }
}
