import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import {
  VpFormControl,
  VpFormError,
  VpFormHelper,
  VpFormLabel,
  VpInput,
  VpIconButton,
  VpButton,
} from "@vtmn-play/react";
import { VpSubtractIcon, VpAddIcon } from "@vtmn-play/icons/react";

import { cn } from '../../../../shadcn/lib/utils';

import Modal from '../../../widgets/vtmn/Modal';

const QtyModal = ({ value, onChange, show, onHide }) => {
  const { t } = useTranslation();
  const [stateValue, setStateValue] = useState();

  useEffect(() => setStateValue(value), [value]);
  const onSave = () => {
    onChange(stateValue);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={t('warehouseContenantsControl.ipQty', 'IP+ quantity')}
    >
      <VpFormControl
        className={cn(
          "vtmn-w-full vtmn-flex vtmn-flex-row vtmn-items-center",
        )}
      >
        <VpFormLabel className="!vtmn-w-auto vtmn-whitespace-nowrap !vtmn-mb-0 vtmn-mr-3">
          <Trans i18nKey="warehouseContenantsControl.foundedQty">
            Found quantity
          </Trans>
        </VpFormLabel>
        <VpIconButton
          size="small"
          variant="secondary"
          aria-label="minus"
          className="!vtmn-self-end vtmn-mr-3"
          onClick={() => setStateValue(stateValue - 1)}
        >
          <VpSubtractIcon />
        </VpIconButton>
        <VpInput
          size="small"
          className="vtmn-w-full"
          value={stateValue}
          onChange={(val) => setStateValue(val)}
        />
        <VpIconButton
          size="small"
          variant="secondary"
          aria-label="plus"
          className="!vtmn-self-end vtmn-ml-3"
          onClick={() => setStateValue(stateValue + 1)}
        >
          <VpAddIcon />
        </VpIconButton>
      </VpFormControl>

      {/* <Modal.Body className="pt-0" >
        <InputGroup id="qty-value" className="input-new-qty mb-3">
          <Form.Label column lg="4" className="text-lg-right">
            <Trans i18nKey="warehouseContenantsControl.foundedQty">
              Found quantity
            </Trans>
            {': '}
          </Form.Label>
          <Button className="founded-qty-edit-btn" onClick={() => setStateValue(stateValue - 1)}>-</Button>
          <Form.Control
            step={1}
            type="number"
            pattern="[0-9]*"
            value={stateValue}
            onChange={(event) => { setStateValue(event.target.value); }}
          />
          <Button className="founded-qty-edit-btn" onClick={() => setStateValue(stateValue + 1)}>+</Button>
        </InputGroup>
      </Modal.Body> */}

      <Modal.Footer className="vtmn-flex vtmn-justify-center">
        <VpButton
          shape="squared"
          size="small"
          onClick={onSave}
        >
          <Trans i18nKey="shared.ok">OK</Trans>
        </VpButton>
      </Modal.Footer>
    </Modal>
  );
}

export default QtyModal;
