import React from 'react';
import {
  VpFormControl,
  VpFormHelper,
  VpFormLabel,
} from "@vtmn-play/react";

import { cn } from "../../../../shadcn/lib/utils"

const NumberInput = ({
  label,
  disabled,
  value,
  className,
  helper,
  onChange,
  step,
  min,
  max,
}) => {
  return (
    <div className={cn("filter-select", className)}>
      <VpFormControl
        className="form-content"
        disabled={disabled}
        // required={isRequired}
      >
        {label && <VpFormLabel>{label}</VpFormLabel>}
        <input
          className='vp-input vp-input--small'
          type="number"
          step={step}
          min={min}
          max={max}
          value={value}
          onChange={(event) => {
            console.log(event?.target.value)
            if (event && onChange) onChange(event?.target.value);
          }}
        />
        {helper && (
          <VpFormHelper>{helper}</VpFormHelper>
        )}
      </VpFormControl>
    </div>
  )
}

export default NumberInput;
