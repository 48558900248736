import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';
import { VpButton, VpLoader, VpIconButton } from "@vtmn-play/react";
import { VpAddIcon, VpEditIcon, VpDeleteBinIcon, VpArrowUpIcon, VpArrowDownIcon } from "@vtmn-play/icons/react";

import useRequest from '../../../functions/useRequest';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import handleApiResponse from '../../../functions/handleApiResponse';

import DataTable from "../../widgets/vtmn/DataTable";
import ErrorMessage from '../../errorMessage';
import Confirm from '../../widgets/vtmn/Confirm';
import ReleasePreview from './ReleasePreview';
import SlideDrawer from './SlideDrawer';

const getSlides = (user, updateTokens, releaseId) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/releases/${releaseId}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const setSlideOrder = (user, updateTokens, { releaseId, order }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/releases/${releaseId}/slide/ordering`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ order }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

const deleteSlide = (user, updateTokens, { releaseId, slideId }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/releases/${releaseId}/slide/${slideId}`;
  return fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
} 

const columns = [{
  title: <Trans i18nKey="releases.slideHeaders.image">Image</Trans>,
  field: 'image',
  cell: ({ row, table }) => {
    const { token } = table.options.meta;
    return (
      <div className='vtmn-w-[80px]'>
        <img src={`${process.env.REACT_APP_base_URL}/api/files/${row.original.image}?token=${token}`} alt="slide" />
      </div>
    )
  }
}, {
  title: <Trans i18nKey="releases.slideHeaders.title">Title</Trans>,
  field: 'title',
}, {
  title: <Trans i18nKey="releases.slideHeaders.description">Description</Trans>,
  field: 'description',
}, {
  id: 'actions',
  cell: ({ row, table }) => {
    const { onEditSlide, upRow, downRow, onRemove } = table.options.meta;

    return (
      <div className='vtmn-flex vtmn-flex-row vtmn-items-center [&>button]:vtmn-mr-1'>
        <VpIconButton
          size="small"
          variant="secondary"
          aria-label="Edit"
          onClick={() => onEditSlide(row.original)}
        >
          <VpEditIcon />
        </VpIconButton>
        <VpIconButton
          size="small"
          variant="secondary"
          aria-label="Remove"
          onClick={() => onRemove(row.original)}
        >
          <VpDeleteBinIcon />
        </VpIconButton>
        <VpIconButton
          size="small"
          variant="secondary"
          aria-label="move-up"
          onClick={() => upRow(row.index)}
        >
          <VpArrowUpIcon />
        </VpIconButton>
        <VpIconButton
          size="small"
          variant="secondary"
          aria-label="move-down"
          onClick={() => downRow(row.index)}
        >
          <VpArrowDownIcon />
        </VpIconButton>
      </div>
    )
  }
}];

const AdminReleasePage = ({ user, updateTokens }) => {
  const { token } = user;
  const { releaseId } = useParams();
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [release, setRelease] = useState({});
  const [editableSlide, setEditableSlide] = useState({});
  const [removedSlide, setRemovedSlide] = useState();
  const [showForm, setShowForm] = useState(false);

  const [{
    loading,
    error,
  }, fetchSlides] = useRequest(
    getSlides, {
      onSuccess: (release) => {
        setData(release.slides);
        setRelease(_.omit(release, 'slides'));
      },
      onError: () => setData(null),
    }
  );

  const [{
    error: changeError,
  }, changeOrder] = useRequest(setSlideOrder);

  const [{
    error: deleteError,
  }, removeSlide] = useRequest(deleteSlide);

  useEffect(() => {
    fetchSlides(user, updateTokens, releaseId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseId]);

  const afterSaveSlide = () => {
    fetchSlides(user, updateTokens, releaseId);
    setEditableSlide({});
  }

  const changeSlideIndex = async (index, newIndex) => {
    if (newIndex < 0 || newIndex === data.length) {
      return;
    }

    if (newIndex < index) {
      const upOrder = [
        ...data.slice(0, newIndex),
        data[index],
        data[newIndex],
        ...data.slice(index + 1),
      ];
      await changeOrder(user, updateTokens, {
        releaseId,
        order: upOrder.map((s, i) => ({ id: s.id, order: i + 1 })),
      });
      setData(upOrder);
    } else {
      const downOrder = [
        ...data.slice(0, index),
        data[newIndex],
        data[index],
        ...data.slice(newIndex + 1),
      ];
      await setSlideOrder(user, updateTokens, {
        releaseId,
        order: downOrder.map((s, i) => ({ id: s.id, order: i + 1 })),
      });
      setData(downOrder);
    }
  }

  const doRemoveSlide = async () => {
    await removeSlide(user, updateTokens, {
      releaseId,
      slideId: removedSlide,
    });
    const removedIndex = data.findIndex((s) => s.id === removedSlide);
    if (removedIndex === -1) {
      return;
    }
    setData([
      ...data.slice(0, removedIndex),
      ...data.slice(removedIndex + 1),
    ]);
    setRemovedSlide();
  }

  const hasError = !!error || !!changeError || !!deleteError

  return (
    <div className="adminGroups mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2 vtmn-px-4">
      <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-justify-between vtmn-mb-3">
        {!!releaseId && !!release.version && (
          <span>
            Version: {release.version} ({release.type})<br/>
          </span>
        )}
        <VpButton
          size="small"
          className="nowrap !vtmn-bg-content-positive"
          startSlot={<VpAddIcon />}
          shape='squared'
          onClick={() => {
            setEditableSlide({})
            setShowForm(true);
          }}
        >
          <Trans i18nKey="releases.addSlide">Add slide</Trans>
        </VpButton>
      </div>
      
      {loading && (
        <div className="vtmn-w-full vtmn-h-screen vtmn-flex vtmn-items-center vtmn-justify-center">
          <VpLoader size="extra-large" />
        </div>
      )}
      {!loading && hasError && <ErrorMessage error={error || changeError || deleteError} />}
      {!loading && !error && data && (
        <div className="slides-wrapper vtmn-flex vtmn-flex-row vtmn-w-full [&>div:last-child]:vtmn-w-full">
          {!!data.length && (
            <ReleasePreview
              user={user}
              slides={[
                {
                  title: release.version,
                  description: release.summary,
                  image: release.image,
                },
                ...data,
              ]}
            />
          )}
          <DataTable
            className="vtmn-h-full"
            columns={columns}
            data={data || []}
            meta={{
              token,
              onEditSlide: (slide) => {
                setEditableSlide(slide);
                setShowForm(true);
              },
              upRow: (i) => changeSlideIndex(i, i - 1),
              downRow: (i) => changeSlideIndex(i, i + 1),
              onRemove: (s) => setRemovedSlide(s.id),
            }}
          />
        </div>
      )}
      <SlideDrawer
        user={user}
        updateTokens={updateTokens}
        isOpen={showForm}
        onClose={() => setShowForm(false)}
        slide={editableSlide}
        callbackFn={afterSaveSlide}
      />
      <Confirm
        title={t('releases.removeSlideConfirm.warning', 'Warning')}
        body={t('releases.removeSlideConfirm.text', 'Are you sure you want to remove selected slide?')}
        show={!!removedSlide}
        onHide={() => setRemovedSlide()}
        loading={false}
        onConfirm={doRemoveSlide}
      />
    </div>
  );
}

export default AdminReleasePage;
