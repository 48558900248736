import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next';
import {
  VpFormControl,
  VpFormLabel,
  VpInput,
  VpButton,
} from "@vtmn-play/react";

import { cn } from '../../../shadcn/lib/utils';

import Modal from '../../widgets/vtmn/Modal';

const QuantityEditModal = ({ deliveries, onChange, show, onHide, minValue }) => {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(deliveries);

  const close = () => {
    onChange([...localValue]);
    onHide();
  }

  return (
    <Modal
      show={show}
      onHide={close}
      title={t('efficiencyAdmin.columns.quantities', 'Quantities')}
    >
      <div>
        {localValue.map((d, i) => (
          <VpFormControl
            className={cn("vtmn-flex vtmn-flex-col vtmn-w-full")}
            key={`qty-${i}`}
          >
            <VpFormLabel className="!vtmn-items-start">
            {`${d.id}: `}
            </VpFormLabel>
            <VpInput
              size="small"
              value={d.qty}
              onChange={(e) => {
                const val = parseInt(e.target.value, 10);

                setLocalValue([
                  ...localValue.slice(0, i),
                  {
                    ...localValue[i],
                    qty: val <= minValue ? minValue : val,
                  },
                  ...localValue.slice(i + 1)
                ])
              }}
            />
          </VpFormControl>
        ))}
      </div>
      <Modal.Footer className="vtmn-flex vtmn-justify-center">
        <VpButton
          shape="squared"
          size="small"
          onClick={close}
          // className="vtmn-w-full"
        >
          <Trans i18nKey="shared.ok">OK</Trans>
        </VpButton>
      </Modal.Footer>
    </Modal>
  );
}

const QuantityEdit = ({ label, onChange, minValue, deliveries, className }) => {
  const [show, setShow] = useState(false);
  const value = deliveries.reduce((acc, d) => acc + d.qty, 0);

  return (
    <div className={cn({className})}>
      <VpFormControl
        className={cn("vtmn-flex vtmn-flex-col vtmn-w-full")}
      >
        <VpFormLabel className="!vtmn-items-start">
          {label}
        </VpFormLabel>
        <VpInput
          size="small"
          value={value}
          onChange={(e) => {
            setShow(true)
          }}
          onClick={() => setShow(true)}
          className="!vtmn-cursor-pointer"
        />
        <QuantityEditModal
          show={show}
          onHide={() => setShow(false)}
          onChange={onChange}
          deliveries={deliveries}
          minValue={minValue || 0}
        />
      </VpFormControl>
    </div>
  );
}

export default QuantityEdit;
