import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { VpLoader } from "@vtmn-play/react";

import useTableRequest from '../../functions/useTableRequest';
import { formatDateTime } from '../../functions/formatDate';
import { handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import fetchWithJWT from '../../functions/fetchWithJWT';
import getQueryString from '../../functions/getQueryString';
import { formatDateForBigQuery } from '../../functions/formatDate';

import DataTable from "../../components/widgets/vtmn/DataTable";
import Filters from './Filters';

const getPreloadStates = (user, updateTokens, { filters, pagination, sort }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters};
  if (formatedFilters.processed_date_from) {
    formatedFilters.processed_date_from = formatDateForBigQuery(formatedFilters.processed_date_from);
  }
  if (formatedFilters.processed_date_to) {
    formatedFilters.processed_date_to = formatDateForBigQuery(formatedFilters.processed_date_to);
  }
  if (formatedFilters.start_date_from) {
    formatedFilters.start_date_from = formatDateForBigQuery(formatedFilters.start_date_from);
  }
  if (formatedFilters.start_date_to) {
    formatedFilters.start_date_to = formatDateForBigQuery(formatedFilters.start_date_to);
  }
  if (formatedFilters.end_date_from) {
    formatedFilters.end_date_from = formatDateForBigQuery(formatedFilters.end_date_from);
  }
  if (formatedFilters.end_date_to) {
    formatedFilters.end_date_to = formatDateForBigQuery(formatedFilters.end_date_to);
  }

  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
    sort,
  });
  
  const url = `${process.env.REACT_APP_base_URL}/api/states/preloads?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const columns = [
  {
    field: "preloadCode",
    title: <Trans i18nKey="adminPreloadState.code">Code</Trans>,
    sortable: true,
  }, {
    field: "siteId",
    title: <Trans i18nKey="adminPreloadState.siteId">Site ID</Trans>,
    sortable: true,
  }, {
    field: "startDate",
    title: <Trans i18nKey="adminPreloadState.startDate">Start date</Trans>,
    sortable: true,
    cell: ({ row }) => row.getValue("startDate") ? formatDateTime(new Date(row.getValue("startDate"))) : null,
  }, {
    field: "endDate",
    title: <Trans i18nKey="adminPreloadState.endDate">End date</Trans>,
    sortable: true,
    cell: ({ row }) => row.getValue("endDate") ? formatDateTime(new Date(row.getValue("endDate"))) : null,
  }, {
    field: "processedRows",
    title: <Trans i18nKey="adminPreloadState.processedRows">Processed rows</Trans>,
    sortable: true,
  }, {
    field: "totalRows",
    title: <Trans i18nKey="adminPreloadState.totalRows">Total rows</Trans>,
    sortable: true,
  }, {
    field: "lastProcessedDate",
    title: <Trans i18nKey="adminPreloadState.lastDate">Last processed date</Trans>,
    sortable: true,
    cell: ({ row }) => row.getValue("lastProcessedDate") ? formatDateTime(new Date(row.getValue("lastProcessedDate"))) : null,
  }, {
    field: "inProgress",
    title: <Trans i18nKey="adminPreloadState.status">Status</Trans>,
    cell: ({ row }) => row.getValue("inProgress")
      ? (<span className="stamp-in-progress">In progress</span>)
      : (<span className="stamp-ended">Ended</span>)
  }, {
    field: "error",
    title: <Trans i18nKey="adminPreloadState.error">Error</Trans>,
    sortable: true,
    cell: ({ row }) => <div style={{width: '200px'}}>{row.getValue("error")}</div>
  },
];

const AdminPreloadState = ({ user, updateTokens }) => {
  const initialFilters = {
    code: null,
    store: [],
    status: null,
  };
  const [{
    loading,
    data,
    error,
    filters,
    sort,
    pagination,
    totals,
  }, fetchPreloadStates, setFilters, setPagination, setSort] = useTableRequest(
    getPreloadStates, {
      initialState: {
        filters: initialFilters,
      }
    }
  );

  useEffect(() => {
    fetchPreloadStates(user, updateTokens, {
      filters,
      pagination,
      sort,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination, sort]);

  return (
    <div className="adminUsers mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
      <div className="vtmn-px-4">
        <Filters
          user={user}
          updateTokens={updateTokens}
          filters={filters}
          setFilters={setFilters}
          onClear={() => setFilters({has_error: false})}
        />
      </div>
      {loading && (
        <div className="vtmn-w-full vtmn-h-screen vtmn-flex vtmn-items-center vtmn-justify-center">
          <VpLoader size="extra-large" />
        </div>
      )}
      {!loading && !error && data && (
        <DataTable
          columns={columns}
          data={data}
          totals={totals}
          pagination={pagination}
          setPagination={setPagination}
          meta={{
            sort, setSort
          }}
        />
      )}
    </div>
  );
};

export default AdminPreloadState;
