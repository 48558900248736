import React from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '../../shadcn/lib/utils';

import { getCards } from './cards';
import TopPerformer from './TopPerformer';

const EfficiencyCardLine = ({ unit, value, title, isHeader }) => {
  return (
    <div className={cn(
      "vtmn-flex vtmn-flex-col",
      isHeader && "vtmn-mb-2"
    )}>
      <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
        <span className={cn(
          isHeader && "vp-subtitle-m"
        )}>
          {value && <span className="vp-subtitle-m vtmn-mr-1">{value}</span>}
          <span>{title}</span>
        </span>
      </div>
      <span className="vp-caption">{unit}</span>
    </div>
  );
};

const EfficiencyCard = ({ icon, header, lines }) => {
  return (
    <div
      className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-w-[320px] vtmn-shadow-100 vtmn-mr-6 vtmn-mb-6 vtmn-p-3"
    >
      <div className="vtmn-flex vtmn-max-w-[60px]">
        <img
          className="vtmn-max-h-[60px]"
          src={icon}
          alt="icon"
        />
      </div>
      <div className="vtmn-flex vtmn-flex-col vtmn-pl-4">
        <EfficiencyCardLine
          isHeader
          unit={header.unit}
          value={header.value}
          title={header.title}
        />
        {lines.map((x, index) => (
          <EfficiencyCardLine
            key={index}
            unit={x.unit}
            value={x.value}
            title={x.title}
          />
        ))}
      </div>
    </div>
  );
};

const EfficiencyView = ({ dashboard, mode }) => {
  const { t } = useTranslation();
  const isStoreView = mode === 'store';
  const isCountryView = mode === 'country';
  const cards = getCards(t, dashboard, isCountryView);
  return (
    <div className="efficiency-view vtmn-px-4">
      {isStoreView && dashboard.top_performer?.leader && (
        <TopPerformer
          leader={dashboard.top_performer?.leader}
          date={dashboard.top_performer?.start_date}
          productivity={dashboard.top_performer?.productivity}
        />
      )}
      <div className="vtmn-flex vtmn-flex-row vtmn-flex-wrap">
        {cards.map(({ icon, header, lines }, index) => (
          <EfficiencyCard
            key={index}
            icon={icon}
            header={header}
            lines={lines}
          />
        ))}
      </div>
    </div>
  );
};

export default EfficiencyView;
