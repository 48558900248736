import React, { useEffect, useState } from 'react';
import { format } from "date-fns"
import {
  VpFormControl,
  VpFormError,
  VpFormHelper,
  VpFormLabel,
} from "@vtmn-play/react"
import { VpCalendarIcon } from "@vtmn-play/icons/react";

import { cn } from "../../../../shadcn/lib/utils"
import { Button } from "../../../../shadcn/components/ui/button"
import { Calendar } from "../../../../shadcn/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../shadcn/components/ui/popover"

const DatePicker = ({
  label,
  className,
  onChange,
  value,
}) => {

  return (
    <VpFormControl
      className={cn(
        "vtmn-flex vtmn-flex-col form-content",
        "vtmn-w-full vtmn-grid vtmn-bg-card [&>button]:vtmn-px-3",
        className
      )}
    >
      <VpFormLabel className="!vtmn-items-start">
        {label}
      </VpFormLabel>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn(
              "vtmn-px-7 !vtmn-justify-start vtmn-text-left vtmn-font-normal",
              "vp-select vp-select--default vp-select--small",
              !value && "text-muted-foreground"
            )}
          >
            <VpCalendarIcon className="mr-2 h-4 w-4" />
            {value ? format(value, "dd/MM/yyyy") : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent align="start" className="vtmn-bg-card vtmn-bg-background-primary vtmn-w-auto vtmn-p-0">
          <Calendar
            mode="single"
            selected={value}
            onSelect={onChange}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </VpFormControl>
  )
}

export default DatePicker;
