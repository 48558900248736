import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { VpButton, VpAsset } from "@vtmn-play/react";
import { VpAddIcon } from "@vtmn-play/icons/react";

import FiltersPannel from '../widgets/vtmn/FiltersPanel';
import InputFilter from '../widgets/vtmn/filters/InputFilter';

const MainFilters = ({
  filters,
  setFilters,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <InputFilter
        controlId="filter-group-name"
        label={t('storeGroups.name', 'Name')}
        value={filters.name}
        onChange={(name) => setFilters({ ...filters, name })}
      />
      <InputFilter
        controlId="filter-group-email"
        label={t('storeGroups.ownerEmail', "Owner's email")}
        value={filters.owner_email}
        onChange={(owner_email) => setFilters({ ...filters, owner_email })}
      />
    </>
  );
};

const Filters = ({
  filters,
  setFilters,
  onClear,
  onAddClick,
}) => (
  <FiltersPannel
    onClear={onClear}
    listClassName="vtmn-flex vtmn-flex-row vtmn-flex-wrap [&>div]:vtmn-mr-5"
    mainFilters={
      <MainFilters
        filters={filters}
        setFilters={setFilters}
      />
    }
    exportButton={
      <VpButton
        size="small"
        shape="squared"
        startSlot={<VpAddIcon />}
        className="nowrap"
        onClick={() => onAddClick && onAddClick()}
      >
        <Trans i18nKey="storeGroups.addGroup">Add group</Trans>
      </VpButton>
    }
  />
);

export default Filters;