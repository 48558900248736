import React from 'react';

import { cn } from '../../../shadcn/lib/utils';
import { Tabs as ShadTabs, TabsContent, TabsList, TabsTrigger } from "../../../shadcn/components/ui/tabs"

const Tabs = ({ className, children, defaultValue, onValueChange }) => {
  return (
    <ShadTabs
      defaultValue={defaultValue}
      className={cn("vtmn-w-full", className)}
      onValueChange={onValueChange}
    >
      {children}
    </ShadTabs>
  )
}

Tabs.Header = TabsList;
Tabs.Item = TabsTrigger;
Tabs.Content = TabsContent;

export default Tabs;
