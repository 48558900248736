import React from 'react';

import Combobox from './form/Combobox';
import useRequest from '../../../functions/useRequest';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import handleApiResponse from '../../../functions/handleApiResponse';

const setRole = (user, updateTokens, role) => {
  const { token, refreshToken, tokenExpireDate } = user
  const url = `${process.env.REACT_APP_base_URL}/api/auth/role`
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({ role }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse)
}

const roles = [
  'superAdmin',
  'countryLeader',
  'supportEngineer',
  'efficiencyManager',
  'regionLeader',
  'warehouseManager',
  'warehouseCrew',
  'regionalManager',
  'storeOperationManager',
  'basic',
];

const options = roles.map((r) => ({
  id: r,
  label: r,
}));

const RoleSelect = ({ user, updateTokens }) => {
  const [{
    loading,
    error,
  }, changeRole] = useRequest(setRole, {
    onSuccess: () => window.location.replace("/"),
  });

  return (
    <Combobox
      options={options}
      selected={user.role || 'superAdmin'}
      onChange={(role) => changeRole(user, updateTokens, role)}
      withSearch={false}
      className="[&_svg]:vtmn-h-5 [&_button]:!vtmn-px-1 [&_button]:!vtmn-pl-3 [&_button]:vtmn-leading-4"
    />
  )
}

export default RoleSelect;
