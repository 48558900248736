// src/components/multi-select.tsx

import * as React from "react";
import _ from 'lodash';
import { cva } from "class-variance-authority";
import {
  VpButton,
  VpIconButton,
} from "@vtmn-play/react";
import { VpCloseIcon } from "@vtmn-play/icons/react";
import {
  Check,
  ChevronDown,
  XIcon,
} from "lucide-react";

import { cn } from "../../lib/utils.js";
import { Separator } from "./separator";
import { Button } from "./button";
import { Badge } from "./badge";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "./command";

/**
 * Variants for the multi-select component to handle different styles.
 * Uses class-variance-authority (cva) to define different styles based on "variant" prop.
 */
const multiSelectVariants = cva(
  "vtmn-transition vtmn-ease-in-out vtmn-delay-150 vtmn-duration-300 multiselect-value-chip",
  {
    variants: {
      variant: {
        default:
          "vtmn-border-foreground/10 vtmn-text-foreground vtmn-bg-card hover:vtmn-bg-card/80",
        secondary:
          "vtmn-border-foreground/10 vtmn-bg-secondary vtmn-text-secondary-foreground hover:vtmn-bg-secondary/80",
        destructive:
          "vtmn-border-transparent vtmn-bg-destructive vtmn-text-destructive-foreground hover:vtmn-bg-destructive/80",
        inverted: "inverted",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export const MultiSelect = React.forwardRef(
  (
    {
      label,
      options,
      onValueChange,
      variant,
      values = [],
      placeholder = "Select options",
      animation = 0,
      maxCount = 3,
      modalPopover = true,
      asChild = false,
      className,
      withSearch = false,
      ...props
    },
    ref
  ) => {
    const [selectedValues, setSelectedValues] =
      React.useState([]);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [isAnimating, setIsAnimating] = React.useState(false);

    React.useEffect(() => {
      if (_.isEqual(values, selectedValues)) return;
      setSelectedValues(values);
    }, [values]);

    const handleInputKeyDown = (
      event
    ) => {
      if (event.key === "Enter") {
        setIsPopoverOpen(true);
      } else if (event.key === "Backspace" && !event.currentTarget.value) {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.pop();
        setSelectedValues(newSelectedValues);
        onValueChange(newSelectedValues);
      }
    };

    const toggleOption = (option) => {
      const newSelectedValues = selectedValues.includes(option)
        ? selectedValues.filter((value) => value != option)
        : [...selectedValues, option];
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const handleClear = () => {
      setSelectedValues([]);
      onValueChange([]);
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev);
    };

    const clearExtraOptions = () => {
      const newSelectedValues = selectedValues.slice(0, maxCount);
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const toggleAll = () => {
      if (selectedValues.length === options.length) {
        handleClear();
      } else {
        const allValues = options.map((option) => option.id);
        setSelectedValues(allValues);
        onValueChange(allValues);
      }
    };

    return (
      <Popover
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        modal={modalPopover}
        sx={{
          border: "#900 solid 1px"
        }}
      >
        <div className="vtmn-flex vtmn-flex-col vtmn-min-w-full [&>button]:vtmn-px-3">
        {!!label && <label className="vp-form-label">{label}</label>}
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            {...props}
            onClick={handleTogglePopover}
            className={cn(
              "vtmn-flex vtmn-h-6 vtmn-p-1 vtmn-rounded-100 vtmn-border vtmn-items-center vtmn-justify-between vtmn-bg-inherit hover:vtmn-bg-inherit [&_svg]:vtmn-pointer-events-auto [&[data-state=open]>svg]:vtmn-rotate-180",
              className,
              "vp-select vp-select--default vp-select--small"
              // isPopoverOpen ? "vtmn-active-box-shadow-border-active vtmn-border-border-active" : "vtmn-border-grey-light-1",
            )}
          >
            {selectedValues.length > 0 ? (
              <div className="vtmn-flex vtmn-justify-between vtmn-items-center vtmn-w-full">
                <div className="vtmn-flex vtmn-flex-wrap vtmn-items-center">
                  {selectedValues.slice(0, maxCount).map((value) => {
                    const option = options.find((o) => o.id === value);
                    return (
                      <Badge
                        key={value}
                        className={cn(
                          "vp-chip vp-chip--small",
                          multiSelectVariants({ variant })
                        )}
                        onChange={() => console.log('onChange')}
                      >
                        {option?.label}
                        <VpIconButton
                          className="multiselect-value-chip-close"
                          size="small"
                          variant="ghost"
                          onClick={(event) => {
                            event.stopPropagation();
                            toggleOption(value);
                          }}
                        >
                          <VpCloseIcon />
                        </VpIconButton>
                      </Badge>
                    );
                  })}
                  {selectedValues.length > maxCount && (
                    <Badge
                      className={cn(
                        "vp-chip vp-chip--small",
                        multiSelectVariants({ variant })
                      )}
                      style={{ animationDuration: `${animation}s` }}
                    >
                      {`+ ${selectedValues.length - maxCount} more`}
                      <VpIconButton
                        className="multiselect-value-chip-close"
                        size="small"
                        variant="ghost"
                        onClick={(event) => {
                          event.stopPropagation();
                          clearExtraOptions();
                        }}
                      >
                        <VpCloseIcon />
                      </VpIconButton>
                    </Badge>
                  )}
                </div>
                <div className="vtmn-flex vtmn-items-center vtmn-justify-between">
                  <XIcon
                    className="vtmn-h-4 vtmn-mx-2 vtmn-cursor-pointer vtmn-text-muted-foreground"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClear();
                    }}
                  />
                  <Separator
                    orientation="vertical"
                    className="vtmn-flex vtmn-min-h-6 vtmn-h-full"
                  />
                  <ChevronDown size={20} className="vtmn-cursor-pointer vtmn-text-muted-foreground" />
                </div>
              </div>
            ) : (
              <div className="vtmn-flex vtmn-items-center vtmn-justify-between vtmn-w-full vtmn-mx-auto">
                <span className="vp-body-m vtmn-text-muted-foreground vtmn--mr-3">
                  {placeholder}
                </span>
                <ChevronDown size={20} className="vtmn-cursor-pointer vtmn-text-muted-foreground" />
              </div>
            )}
          </Button>
        </PopoverTrigger>
        </div>
        <PopoverContent
          className="vtmn-dropdown_items vtmn-min-w-max vtmn-p-1"
          align="start"
          onEscapeKeyDown={() => setIsPopoverOpen(false)}
        >
          <Command>
            {!!withSearch && (
              <div className="vtmn-p-1">
                <CommandInput
                  placeholder="Search..."
                  onKeyDown={handleInputKeyDown}
                  className={cn(
                    "vp-input vp-input--small"
                  )}
                />
              </div>
            )}
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                <CommandItem
                  key="all"
                  onSelect={toggleAll}
                  className="vtmn-cursor-pointer vtmn-rounded hover:vtmn-bg-hover-secondary-transparent"
                >
                  <div className="vtmn-flex vtmn-flex-row vtmn-w-full vtmn-items-center vtmn-p-3">
                    <span className={cn(
                      "vtmn-w-full vp-body-m",
                      selectedValues.length === options.length && "vtmn-text-content-action"
                    )}>(Select All)</span>
                    {selectedValues.length === options.length && <Check className="vtmn-h-4 vtmn-w-4 vtmn-text-content-action" />}
                  </div>
                </CommandItem>
                {options.map((option) => {
                  const isSelected = selectedValues.includes(option.id);
                  return (
                    <CommandItem
                      key={option.id}
                      onSelect={() => toggleOption(option.id)}
                      className="vtmn-cursor-pointer vtmn-rounded hover:vtmn-bg-hover-secondary-transparent"
                    >
                      <div className="vtmn-flex vtmn-flex-row vtmn-w-full">
                        <div className="vtmn-flex vtmn-flex-row vtmn-w-full vtmn-items-center vtmn-px-3">
                          {option.icon}
                          <span className={cn(
                            "vtmn-w-full vp-body-m",
                            isSelected && "vtmn-text-content-action"
                          )}>{option.label}</span>
                          {isSelected && <Check className="vtmn-h-4 vtmn-w-4 vtmn-text-content-action" />}
                        </div>
                      </div>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              <CommandSeparator />
              <CommandGroup>
                <div className="vtmn-flex vtmn-items-center vtmn-justify-between vtmn-flex-row">
                  {selectedValues.length > 0 && (
                    <div className="vtmn-flex vtmn-w-full vtmn-justify-center">
                      <VpButton
                        size="small"
                        shape="squared"
                        className="nowrap"
                        variant="secondary"
                        onClick={handleClear}
                      >
                        Clear
                      </VpButton>
                    </div>
                  )}
                  <div className="vtmn-flex vtmn-w-full vtmn-justify-center">
                    <VpButton
                      size="small"
                      shape="squared"
                      className="nowrap"
                      variant="secondary"
                      onClick={() => setIsPopoverOpen(false)}
                    >
                      Close
                    </VpButton>
                  </div>
                </div>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);

MultiSelect.displayName = "MultiSelect";