import React from 'react';
import { useTranslation } from 'react-i18next';
import formatMoney from '../../../functions/formatMoney';
import ProgressBar from './ProgressBar';

const ProgressDetailsContent = ({
  indicator,
  currency,
}) => {
  const { t } = useTranslation();
  const notCheckedLabel = t(
    'liveStoreReception.progress.notChecked',
    'Not checked:',
  );
  const readByRobotLabel = t(
    'liveStoreReception.progress.notUnexpected',
    'Not unexpected:',
  );
  const revisedAtMovementsLabel = t(
    'liveStoreReception.progress.revisedAtMovements',
    'Has movement correction:',
  );
  const confirmedMissingLabel = t(
    'liveStoreReception.progress.confirmedUnexpected',
    'Confirmed unexpected:',
  );
  const notCheckedQties = indicator.total
    - indicator.foundAtMovementsQties
    - indicator.revisedAtMovementsQties
    - indicator.missingAtMovementsQties;

  return (
    <div className="vtmn-flex vtmn-flex-col">
      <div className="vtmn-my-3">
        <ProgressBar indicator={indicator} currency={currency} />
      </div>

      <div className="vtmn-grid vtmn-grid-cols-2">
        <span className="vtmn-text-right vtmn-pr-2">{readByRobotLabel}</span>
        <span>
          {indicator.foundAtMovementsQties}
          {indicator.foundAtMovementsValue ? ` = ${formatMoney(indicator.foundAtMovementsValue, currency)}` : null}
        </span>
      </div>
      <div className="vtmn-grid vtmn-grid-cols-2">
        <span className="vtmn-text-right vtmn-pr-2">{revisedAtMovementsLabel}</span>
        <span>
          {indicator.revisedAtMovementsQties}
          {indicator.revisedAtMovementsValue ? ` = ${formatMoney(indicator.revisedAtMovementsValue, currency)}` : null}
        </span>
      </div>
      <div className="vtmn-grid vtmn-grid-cols-2">
        <span className="vtmn-text-right vtmn-pr-2">{confirmedMissingLabel}</span>
        <span>
          {indicator.missingAtMovementsQties}
          {indicator.missingAtMovementsValue ? ` = ${formatMoney(indicator.missingAtMovementsValue, currency)}` : null}
        </span>
      </div>
      <div className="vtmn-grid vtmn-grid-cols-2">
        <span className="vtmn-text-right vtmn-pr-2">{notCheckedLabel}</span>
        <span>
          {notCheckedQties}
          {indicator.additionalValue ? ` = ${formatMoney(indicator.additionalValue, currency)}` : null}
        </span>
      </div>
    </div>
  );
};

export default ProgressDetailsContent;