import React, { useState, useEffect } from 'react';
import { VpAsset } from "@vtmn-play/react";

import useRequest from '../../../../functions/useRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import handleApiResponse from '../../../../functions/handleApiResponse';
import MultiSelectFilter from './MultiSelectFilter';

const getCountries = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/countries?sort=name`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return countries;
};

const CountryMultiSelect = ({ user, updateTokens, label, selected, onChange }) => {
  const [options, setOptions] = useState([]);

  const [{
    loading,
    data,
    error,
  }, fetchCountries] = useRequest(getCountries);
  
  useEffect(() => {
    fetchCountries(user, updateTokens);
  }, [fetchCountries, user, updateTokens]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const countryOptions = data.map(x => ({
      id: x.id,
      label: (
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-py-2">
          <VpAsset className="vtmn-mr-2" name={`flag-${x.code.toLowerCase()}`} width="24" />
          {x.name}
        </div>
      ),
    }));
    setOptions(countryOptions);
  }, [data]);

  const onSelectedChange = (values) => {
    onChange && onChange(values);
  }

  return (
    <MultiSelectFilter
      className="vtmn-w-full"
      label={label}
      options={options}
      selected={selected}
      onChange={onSelectedChange}
    />
  )
}

export default CountryMultiSelect;
