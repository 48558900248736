import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import { VpLoader } from "@vtmn-play/react";

import useRequest from '../../functions/useRequest'
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import { formatDate } from '../../functions/formatDate';

import Tabs from "../widgets/vtmn/Tabs";
import ErrorMessage from '../errorMessage';
import { getEfficiencies } from '../efficiencyAdmin/api';
import COLORS from '../../consts/colors';

const pagination = {
  page: 0,
  size: 100,
};

const sort = {
  field: 'unloading_start_date',
  direction: 'ASC',
};

const getSite = (user, updateTokens, id) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/stores/pilot/${id}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const getTabLabel = (t, tab) => {
  const labelMap = {
    teammate_hours: t('efficiencyDashboard.tabs.teammateHours', 'Teammate hours'),
    overall_productivity: t('efficiencyDashboard.tabs.overallProductivity', 'Overall productivity'),
    shelving_productivity: t('efficiencyDashboard.tabs.shelvingProductivity', 'Shelving productivity'),
    total_duration: t('efficiencyDashboard.tabs.duration', 'Duration'),
  }
  return labelMap[tab] || tab;
};

const getDatasetValues = (efficiencies, costPerHour) => {
  const quantities= efficiencies.map((x) => x.quantities || 0);

  const teammateHours = efficiencies.map((x) => Number(((
    (x.unloading_teammates_involved || 0) * (x.unloading_duration || 0)
    + (x.shelving_teammates_involved || 0) * (x.shelving_duration || 0)
  ) / 3600).toFixed(2)));

  const plannedTeammateHours = efficiencies.map((x) => Number((
    costPerHour ? (x.planned_total_investment || 0) / costPerHour : 0
  ).toFixed(2)));

  const shelvingProductivity = efficiencies.map((x) => x.shelving_productivity || 0);
  const plannedShelvingProductivity = efficiencies.map((x) => x.planned_shelving_productivity || 0);

  const overallProductivity = efficiencies.map((x) => x.overall_productivity || 0);
  const plannedOverallProductivity = efficiencies.map((x) => x.planned_overall_productivity || 0);

  const duration = efficiencies.map((x) => Number(((x.total_duration || 0) / 3600).toFixed(2)));
  const plannedDuration = efficiencies.map((x) => Number(((x.planned_total_duration || 0) / 3600).toFixed(2)));

  const maxHours = Math.ceil(_.max([_.max(teammateHours), _.max(plannedTeammateHours)]) * 1.2);
  const maxShelvingProductivity = Math.ceil(_.max([_.max(shelvingProductivity), _.max(plannedShelvingProductivity)]) * 1.2);
  const maxDuration = Math.ceil(_.max([_.max(duration), _.max(plannedDuration)]) * 1.2);
  const maxOverallProductivity = Math.ceil(_.max([_.max(overallProductivity), _.max(plannedOverallProductivity)]) * 1.2);


  return {
    quantities,
    teammateHours,
    plannedTeammateHours,
    maxHours,
    shelvingProductivity,
    plannedShelvingProductivity,
    maxShelvingProductivity,
    duration,
    plannedDuration,
    maxDuration,
    overallProductivity,
    plannedOverallProductivity,
    maxOverallProductivity,
  }
}

const getDatasets = (t, tab, efficiencies, costPerHour) => {
  const {
    quantities,
    teammateHours,
    plannedTeammateHours,
    maxHours,
    shelvingProductivity,
    plannedShelvingProductivity,
    maxShelvingProductivity,
    duration,
    plannedDuration,
    maxDuration,
    overallProductivity,
    plannedOverallProductivity,
    maxOverallProductivity,
  } = getDatasetValues(efficiencies, costPerHour);
  const quantitiesDataset = {
    label: t('efficiencyDashboard.chart.quantities', 'Quantities'),
    type: 'bar',
    data: quantities,
    backgroundColor: COLORS.VTMN_BRAND_DIGITAL_LIGHT_1,
    yAxisID: 'yLeft',
  };
  const teammateHoursDataset = {
    type: 'line',
    data: teammateHours,
    borderColor: COLORS.VTMN_SUCCESS,
    backgroundColor: COLORS.VTMN_SUCCESS,
    fill: false,
    yAxisID: 'yRight',
    label: t('efficiencyDashboard.chart.teammateHours', 'Teammate hours'),
  };
  const plannedTeammateHoursDataset = {
    type: 'line',
    data: plannedTeammateHours,
    borderColor: COLORS.VTMN_BRAND_DIGITAL,
    backgroundColor: COLORS.VTMN_BRAND_DIGITAL,
    fill: false,
    yAxisID: 'yRight',
    label: t('efficiencyDashboard.chart.plannedTeammateHours', 'Planned teammate hours'),
  };
  const productivityDataset = {
    type: 'line',
    data: shelvingProductivity,
    borderColor: COLORS.VTMN_SUCCESS,
    backgroundColor: COLORS.VTMN_SUCCESS,
    fill: false,
    yAxisID: 'yRight',
    label: t('efficiencyDashboard.chart.productivity', 'Productivity'),
  };
  const plannedProductivityDataset = {
    type: 'line',
    data: plannedShelvingProductivity,
    borderColor: COLORS.VTMN_BRAND_DIGITAL,
    backgroundColor: COLORS.VTMN_BRAND_DIGITAL,
    fill: false,
    yAxisID: 'yRight',
    label: t('efficiencyDashboard.chart.plannedProductivity', 'Planned productivity'),
  };
  const durationDataset = {
    type: 'line',
    data: duration,
    borderColor: COLORS.VTMN_SUCCESS,
    backgroundColor: COLORS.VTMN_SUCCESS,
    fill: false,
    yAxisID: 'yRight',
    label: t('efficiencyDashboard.chart.duration', 'Duration'),
  };
  const plannedDurationDataset = {
    type: 'line',
    data: plannedDuration,
    borderColor: COLORS.VTMN_BRAND_DIGITAL,
    backgroundColor: COLORS.VTMN_BRAND_DIGITAL,
    fill: false,
    yAxisID: 'yRight',
    label: t('efficiencyDashboard.chart.plannedDuration', 'Planned duration'),
  };
  const plannedOverallProductivityDataset = {
    type: 'line',
    data: plannedOverallProductivity,
    borderColor: COLORS.VTMN_BRAND_DIGITAL,
    backgroundColor: COLORS.VTMN_BRAND_DIGITAL,
    fill: false,
    yAxisID: 'yRight',
    label: t('efficiencyDashboard.chart.plannedOverallProductivity', 'Planned overall productivity'),
  };
  const overallProductivityDataset = {
    type: 'line',
    data: overallProductivity,
    borderColor: COLORS.VTMN_SUCCESS,
    backgroundColor: COLORS.VTMN_SUCCESS,
    fill: false,
    yAxisID: 'yRight',
    label: t('efficiencyDashboard.chart.overallProductivity', 'Overall productivity'),
  };

  if (tab === 'teammate_hours') {
    return {
      datasets: [plannedTeammateHoursDataset, teammateHoursDataset, quantitiesDataset],
      rightMax: maxHours,
      rightLabel: getTabLabel(t, tab)
    }
  } else if (tab === 'overall_productivity') {
    return {
      datasets: [plannedOverallProductivityDataset, overallProductivityDataset, quantitiesDataset],
      rightMax: maxOverallProductivity,
      rightLabel: getTabLabel(t, tab)
    }
  } else if (tab === 'shelving_productivity') {
    return {
      datasets: [plannedProductivityDataset, productivityDataset , quantitiesDataset],
      rightMax: maxShelvingProductivity,
      rightLabel: getTabLabel(t, tab)
    }
  } else if (tab === 'total_duration') {
    return {
      datasets: [plannedDurationDataset, durationDataset, quantitiesDataset],
      rightMax: maxDuration,
      rightLabel: getTabLabel(t, tab)
    }
  }

  return {
    datasets: [],
    rightMax: 0,
    rightLabel: '',
  };
}

const StoreEfficiencyChart = ({ user, updateTokens, filters }) => {
  const { t } = useTranslation();
  const { store } = useParams();
  // teammate_hours / shelving_productivity / total_duration
  const [tab, setTab] = useState('teammate_hours');
  const [{
    loading: efficienciesLoading,
    data: efficienciesData,
    error: efficienciesError,
  }, fetchEfficiencies] = useRequest(getEfficiencies);
  const efficiencies = efficienciesData?.result || [];
  useEffect(() => {
    fetchEfficiencies(user, updateTokens, {
      filters: {
        site_ids: [store],
        unloading_start_date_from: filters.unloading_start_date_from,
        unloading_start_date_to: filters.unloading_start_date_to,
        status: 'complete',
        without_incorrect: true,
      },
      pagination,
      sort,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  const [{
    loading: storeLoading,
    data: storeInfo,
    error: storeError,
  }, fetchSite] = useRequest(getSite);
  useEffect(() => {
    if (store) {
      fetchSite(user, updateTokens, store);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store])

  const loading = efficienciesLoading || storeLoading;
  const error = efficienciesError || storeError;

  if (loading) {
    return <VpLoader size="extra-large" />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!efficiencies || !efficiencies.length || !storeInfo) {
    // no data
    return null;
  }

  const { costPerHour } = storeInfo;

  const {
    datasets,
    rightMax,
    rightLabel,
  } = getDatasets(t, tab, efficiencies, costPerHour);

  const data = {
    labels: efficiencies.map((x) => formatDate(new Date(x.unloading_start_date))),
    datasets,
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: 'label',
      itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
      callbacks: {
        title: (tooltipItem, data) => {
          const efficiency = efficiencies[tooltipItem[0].index];
          const date = efficiency.create_date ? formatDate(new Date(efficiency.create_date)) : null;
          return `${efficiency.delivery} (${date})\n${efficiency.leader}`
        },
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index] || 0;
          const formated = Number(currentValue.toFixed(2));
          const label = `${dataset.label}: ${formated}`;
          return label;
        },
        afterLabel: (tooltipItem, data) => {
          return '';
        },
      }
    },
    scales: {
      yAxes: [
        {
          id: 'yLeft',
          position: 'left',
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            fontSize: 14,
            labelString: t('efficiencyDashboard.chart.quantities', 'Quantities'),
          },
        },
        {
          id: 'yRight',
          position: 'right',
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            max: rightMax,
          },
          scaleLabel: {
            display: true,
            fontSize: 14,
            labelString: rightLabel,
          },
        }
      ],
    }
  };

  return (
    <div className="efficiency-chart vtmn-mt-7">
      <span className="vp-subtitle-l">
        {t('efficiencyDashboard.chart.title', 'Measurement history')}
      </span>
      <Tabs
        defaultValue="teammate_hours"
        onValueChange={setTab}
        className="vtmn-h-full vtmn-mt-3"
      >
        <Tabs.Header>
          <Tabs.Item value="teammate_hours">
            {getTabLabel(t, 'teammate_hours')}
          </Tabs.Item>
          <Tabs.Item value="overall_productivity">
            {getTabLabel(t, 'overall_productivity')}
          </Tabs.Item>
          <Tabs.Item value="shelving_productivity">
            {getTabLabel(t, 'shelving_productivity')}
          </Tabs.Item>
          <Tabs.Item value="total_duration">
            {getTabLabel(t, 'total_duration')}
          </Tabs.Item>
        </Tabs.Header>
        <Tabs.Content value={tab} className="vtmn-h-full">
          <Bar data={data} options={options} />
        </Tabs.Content>
      </Tabs>
    </div>
  );
};

export default StoreEfficiencyChart;
