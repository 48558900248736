import React from 'react';
import {
  VpFormControl,
  VpFormLabel,
  VpTextarea
} from "@vtmn-play/react"

import { cn } from "../../../../shadcn/lib/utils"

const TextArea = ({
  label,
  className,
  onChange,
  value,
}) => {

  return (
    <VpFormControl
      className={cn(
        "vtmn-flex vtmn-flex-col form-content",
        "vtmn-w-full vtmn-grid vtmn-bg-card [&>button]:vtmn-px-3",
        className
      )}
    >
      <VpFormLabel className="!vtmn-items-start">
        {label}
      </VpFormLabel>
      <VpTextarea
        value={value}
        onChange={(event) => {
          if (event && onChange) onChange(event?.currentTarget?.value);
        }}
      />
    </VpFormControl>
  )
}

export default TextArea;
