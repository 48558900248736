import React, { useState, useEffect } from 'react';
import {
  VpFormControl,
  VpFormLabel,
} from "@vtmn-play/react";

import { cn } from "../../../../shadcn/lib/utils"
import useRequest from '../../../../functions/useRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import handleApiResponse from '../../../../functions/handleApiResponse';

import Combobox from '../form/Combobox';

const getAvailableStoreGroups = (user, updateTokens, countryId) => {
  const { token, refreshToken, tokenExpireDate } = user;
  let url = `${process.env.REACT_APP_base_URL}/api/groups/stores`;
  if (countryId) {
    url += `?country_id=${countryId}`;
  } else {
    url += `?no_country=true`;
  }
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const StoresGroupSelect = ({
  user,
  updateTokens,
  label,
  selected,
  onChange,
  disabled,
  className,
  countryId,
}) => {
  const [options, setOptions] = useState([]);

  const [{
    data,
  }, fetchGroups] = useRequest(getAvailableStoreGroups);
  
  useEffect(() => {
    fetchGroups(user, updateTokens, countryId);
  }, [fetchGroups, user, updateTokens, countryId]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const countryOptions = data.map(x => ({
      id: x.id,
      label: (
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-py-2">
          {x.name}
        </div>
      ),
    }));
    setOptions(countryOptions);
  }, [data]);

  const onSelect = (id) => {
    const record = data.find((r) => r.id === id);
    onChange && onChange(record);
  }

  return (
    <div className={cn("filter-select vtmn-min-w-[200px]", className)}>
      <VpFormControl
        className="form-content"
        disabled={disabled}
        // required={isRequired}
      >
        {label && (
          <VpFormLabel>{label}</VpFormLabel>
        )}
         <Combobox
          options={options}
          selected={selected}
          onChange={onSelect}
          withSearch={false}
          valueClassName="[&>div]:vtmn-py-0"
        />
      </VpFormControl>
    </div>
  )
}

export default StoresGroupSelect;
