import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next'
import { VpLoader } from "@vtmn-play/react";

import fetchWithJWT from '../../functions/fetchWithJWT'
import handleApiResponse from '../../functions/handleApiResponse'
import getQueryString from '../../functions/getQueryString'
import useRequest from '../../functions/useRequest'
import { useUserFilters } from '../../functions/filtersKeeper';

import { CountryModeFilters, StoreModeFilters, getDefaultFilters, parseFilters } from './filters'


import ErrorMessage from '../errorMessage'
import EfficiencyView from './EfficiencyView'
import BreakdownTable from './BreakdownTable'
import StoreEfficiencyChart from './StoreEfficiencyChart'
import OutliersWarning from './OutliersWarning'

const PAGE_NAME = 'efficiencyDashboard';

const getDashboard = (user, updateTokens, filters, withTopPerformer) => {
  const { token, refreshToken, tokenExpireDate } = user;
  let queryString = getQueryString({ filters });
  if (withTopPerformer) {
    queryString += '&with_top_performer=true';
  }
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/dashboard?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse)
}

const EfficiencyDashboard = ({ user, updateTokens, mode, title }) => {
  const { t } = useTranslation();
  const { store, country } = useParams();
  const isStoreView = mode === 'store';
  const isCountryView = mode === 'country' || mode === 'admin';

  const initialFilters = getDefaultFilters(mode);
  const [filters, setFilters] = useState(initialFilters);
  const filtersPacking = (filters) => 
    _.omit(filters, ['unloading_start_date_to', 'unloading_start_date_from', 'country_id']);
  const filtersUnpacking = (saved) => [saved];
  const {
    isLoading: isLoadingFilters,
    error: actionError,
    setFilters: setUserFilters,
  } = useUserFilters({
    page: PAGE_NAME,
    user,
    updateTokens,
    initial: initialFilters,
    setTableFilters: setFilters,
    filtersPacking,
    filtersUnpacking,
  });

  const [
    { loading, error, data: dashboard },
    fetchDashboard,
  ] = useRequest(getDashboard);

  const noData = !loading && !error && dashboard && !dashboard.delivery_count;
  const isReady = !loading && !error && !noData && dashboard;

  useEffect(() => {
    if (isLoadingFilters) {
      return;
    }
    if (isCountryView && !filters.country_id) {
      return;
    }

    const parsedFilters = parseFilters(filters, mode, store);
    fetchDashboard(user, updateTokens, parsedFilters, isStoreView);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, mode, filters, isLoadingFilters]);

  useEffect(() => {
    if (isLoadingFilters) {
      return;
    }
    if (!country) {
      return;
    }

    setFilters({ ...filters, country_id: country});
  }, [country, isLoadingFilters]);

  return (
    <>

    <div className="efficiency-dashboard mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
      <div className="vtmn-flex vtmn-flex-col vtmn-py-2 vtmn-px-4">
        
        {isCountryView && (
          <CountryModeFilters
            filters={filters}
            setFilters={setFilters}
            user={user}
            updateTokens={updateTokens}
          />
        )}
        {isStoreView && (
          <StoreModeFilters
            filters={filters}
            setFilters={setFilters}
            user={user}
            updateTokens={updateTokens}
          />
        )}
      </div>

      {loading && (
        <div className="vtmn-w-full vtmn-h-screen vtmn-flex vtmn-items-center vtmn-justify-center">
          <VpLoader size="extra-large" />
        </div>
      )}
      {!loading && error && <ErrorMessage error={error} />}
      {!!actionError && <ErrorMessage error={actionError} />}
      {noData && (
        <div className="vtmn-p-4 vp-body-m">{t('efficiencyDashboard.noData', 'No KPI data to display')}</div>
      )}
      {isReady && (
        <EfficiencyView dashboard={dashboard} mode={mode} />
      )}
      <OutliersWarning
        user={user}
        updateTokens={updateTokens}
        filters={filters}
        storeView={isStoreView}
        cleanRecords={dashboard ? dashboard.delivery_count : 0}
        mode={mode}
        store={store}
        country={country}
      />
      {isCountryView && !noData && (
        <div className="vtmn-mt-6">
          <span className="vp-subtitle-l vtmn-px-4">
            <Trans i18nKey="efficiencyDashboard.breakdown.title">Breakdown by store</Trans>
          </span>
          <BreakdownTable
            user={user}
            updateTokens={updateTokens}
            filters={filters}
            showInvestment={dashboard ? !!dashboard.show_investment : false}
          />
        </div>
      )}
      {isStoreView && isReady && (
        <StoreEfficiencyChart
          user={user}
          updateTokens={updateTokens}
          filters={filters}
        />
      )}
    </div>
    </>
  );
}

export default EfficiencyDashboard;
