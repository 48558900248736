import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formatDateForBigQuery } from '../../../../functions/formatDate';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import { handleApiResponse } from '../../../../functions/handleApiResponse';
import { getFiltersParams, getSortParams } from '../../../../functions/getQueryString';

import FiltersPannel from '../../../widgets/vtmn/FiltersPanel';
import ExportButton from '../../../widgets/vtmn/ExportButton';
import DateRangePicker from '../../../widgets/vtmn/filters/DateRangePicker';
import WarehouseFilter from '../../../widgets/vtmn/filters/discrepancies/WarehouseSelect';
import StoreSelect from '../../../widgets/vtmn/filters/discrepancies/StoreSelect';
import InputFilter from '../../../widgets/vtmn/filters/InputFilter';
import RfidFilter from '../../../widgets/vtmn/filters/RfidFilter';
import UniverseFilter from '../../../widgets/vtmn/filters/UniverseFilter';
import NumrangeFilter from '../../../widgets/vtmn/filters/NumrangeFilter';
import MovementCategoriesFilter from './MovementCategoriesFilter';
import Toggle from '../../../widgets/vtmn/filters/Toggle';
import StayedAtWarehouseLabel from '../../StayedAtWarehouseLabel';

const exportReport = (discrepancyType, warehouse) => (user, updateTokens, oauthToken, { filters, sort }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters, discrepancyType };
  if (warehouse) {
    formatedFilters.warehouse = warehouse;
  }
  if (formatedFilters.receptionDateFrom) {
    formatedFilters.receptionDateFrom = formatDateForBigQuery(formatedFilters.receptionDateFrom);
  }
  if (formatedFilters.receptionDateTo) {
    formatedFilters.receptionDateTo = formatDateForBigQuery(formatedFilters.receptionDateTo);
  }
  if (discrepancyType === 'transport') {
    formatedFilters.withTags = true;
  }
  if (formatedFilters.stayedAtWarehouse) {
    formatedFilters.stayedAtWarehouse = 'true';
  }
  const url = `${process.env.REACT_APP_base_URL}/api/reporting/discrepancies/export`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      oauthToken,
      filters: getFiltersParams({ filters: formatedFilters }),
      sort: getSortParams({ sort }),
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const hasFilters = (filters) => {
  // check all filters except: receptionDateFrom, receptionDateTo
  const keys = Object.keys(filters).filter(x => x !== 'receptionDateFrom' && x !== 'receptionDateTo');
  const values = keys.map(k => filters[k]).filter(v => v);
  const filtered = values.length !== 0;
  return filtered;
}

const MainFilters = ({
  filters,
  setFilters,
  invertedFilters,
  setInvertedFilters,
  withWarehouse,
  isWrongSize,
  user,
  updateTokens,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <DateRangePicker
        className="vtmn-max-w-[280px]"
        label={t('reportingDiscrepancies.filters.date', 'Date')}
        valueFrom={filters.receptionDateFrom}
        valueTo={filters.receptionDateTo}
        onChange={(receptionDateFrom, receptionDateTo) => setFilters({
          ...filters,
          receptionDateFrom,
          receptionDateTo,
        })}
      />

      {withWarehouse && (
        <WarehouseFilter
          user={user}
          updateTokens={updateTokens}
          selected={filters.warehouse}
          onChange={(warehouse) => setFilters({ ...filters, warehouse })}
          invertable
          inverted={invertedFilters.warehouse}
          onInvert={() => setInvertedFilters({
            ...invertedFilters,
            warehouse: !invertedFilters.warehouse,
          })}
        />
      )}

      <StoreSelect
        user={user}
        updateTokens={updateTokens}
        selected={filters.store}
        onChange={(store) => setFilters({ ...filters, store })}
        invertable
        inverted={invertedFilters.store}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          store: !invertedFilters.store,
        })}
      />

      <InputFilter
        controlId="filter-delivery"
        label={t('reportingDiscrepancies.filters.delivery', 'Delivery')}
        value={filters.delivery}
        onChange={(delivery) => setFilters({ ...filters, delivery })}
        invertable
        inverted={invertedFilters.delivery}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          delivery: !invertedFilters.delivery,
        })}
      />

      <InputFilter
        controlId="filter-item"
        label={
          isWrongSize
            ? t('reportingDiscrepancies.filters.receivedItem', 'Received item')
            : t('reportingDiscrepancies.filters.item', 'Item')
        }
        value={filters.item}
        onChange={(item) => setFilters({ ...filters, item })}
        invertable
        inverted={invertedFilters.item}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          item: !invertedFilters.item,
        })}
      />

      {isWrongSize && (
        <InputFilter
          controlId="filter-wrongsize-item"
          label={t('reportingDiscrepancies.filters.wrongSizeItem', 'Missing item')}
          value={filters.wrongSizeItem}
          onChange={(wrongSizeItem) => setFilters({ ...filters, wrongSizeItem })}
          invertable
          inverted={invertedFilters.wrongSizeItem}
          onInvert={() => setInvertedFilters({
            ...invertedFilters,
            wrongSizeItem: !invertedFilters.wrongSizeItem,
          })}
        />
      )}
    </>
  );
};

const AdditionalFilters = ({
  filters,
  setFilters,
  invertedFilters,
  setInvertedFilters,
  isExpedition,
  isTransport,
  user,
  updateTokens,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {(isExpedition || isTransport) && (
        <InputFilter
          controlId="filter-pallet"
          label={t('reportingDiscrepancies.filters.pallet', 'Pallet')}
          value={filters.pallet}
          onChange={(pallet) => setFilters({ ...filters, pallet })}
          invertable
          inverted={invertedFilters.pallet}
          onInvert={() => setInvertedFilters({
            ...invertedFilters,
            pallet: !invertedFilters.pallet,
          })}
        />
      )}

      {(isExpedition || isTransport) && (
        <InputFilter
          controlId="filter-parcel"
          label={t('reportingDiscrepancies.filters.parcel', 'Parcel')}
          value={filters.parcel}
          onChange={(parcel) => setFilters({ ...filters, parcel })}
          invertable
          inverted={invertedFilters.parcel}
          onInvert={() => setInvertedFilters({
            ...invertedFilters,
            parcel: !invertedFilters.parcel,
          })}
        />
      )}

      <RfidFilter
        selected={filters.articleFlag}
        onChange={(articleFlag) => setFilters({ ...filters, articleFlag })}
        invertable
        inverted={invertedFilters.articleFlag}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          articleFlag: !invertedFilters.articleFlag,
        })}
      />

      <NumrangeFilter
        controlId="filter-price"
        label={t('reportingDiscrepancies.filters.price', 'Selling price')}
        valueFrom={filters.priceFrom}
        valueTo={filters.priceTo}
        onChange={(priceFrom, priceTo) => setFilters({ ...filters, priceFrom, priceTo })}
      />
      <NumrangeFilter
        controlId="filter-cession-price"
        label={t('reportingDiscrepancies.filters.cessionPrice', 'Cession price')}
        valueFrom={filters.cessionPriceFrom}
        valueTo={filters.cessionPriceTo}
        onChange={(cessionPriceFrom, cessionPriceTo) => setFilters({
          ...filters,
          cessionPriceFrom,
          cessionPriceTo,
        })}
      />

      <InputFilter
        controlId="filter-model-id"
        label={t('reportingDiscrepancies.filters.modelId', 'Model Id')}
        value={filters.modelId}
        onChange={(modelId) => setFilters({ ...filters, modelId })}
        invertable
        inverted={invertedFilters.modelId}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          modelId: !invertedFilters.modelId,
        })}
      />
      <InputFilter
        controlId="filter-item-lib"
        label={t('reportingDiscrepancies.filters.itemLib', 'Item description')}
        value={filters.itemLib}
        onChange={(itemLib) => setFilters({ ...filters, itemLib })}
        invertable
        inverted={invertedFilters.itemLib}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          itemLib: !invertedFilters.itemLib,
        })}
      />
      <InputFilter
        controlId="filter-family"
        label={t('reportingDiscrepancies.filters.family', 'Family')}
        value={filters.familyLabel}
        onChange={(familyLabel) => setFilters({ ...filters, familyLabel })}
        invertable
        inverted={invertedFilters.familyLabel}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          familyLabel: !invertedFilters.familyLabel,
        })}
      />
      <InputFilter
        controlId="filter-sub-department"
        label={t('reportingDiscrepancies.filters.subDepartment', 'Sub department')}
        value={filters.subDepartmentLabel}
        onChange={(subDepartmentLabel) => setFilters({ ...filters, subDepartmentLabel })}
        invertable
        inverted={invertedFilters.subDepartmentLabel}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          subDepartmentLabel: !invertedFilters.subDepartmentLabel,
        })}
      />
      <UniverseFilter
        controlId="filter-universe"
        user={user}
        updateTokens={updateTokens}
        selected={filters.universeLabel}
        onChange={(universeLabel) => setFilters({ ...filters, universeLabel })}
      />

      <MovementCategoriesFilter
        selected={filters.movementCategories}
        onChange={(movementCategories) => setFilters({ ...filters, movementCategories })}
        invertable
        inverted={invertedFilters.movementCategories}
        isExpedition={isExpedition}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          movementCategories: !invertedFilters.movementCategories,
        })}
      />

      {(isExpedition || isTransport) ? (
        <>
          <NumrangeFilter
            controlId="filter-shipping-list"
            label={t('reportingDiscrepancies.filters.shippingList', 'Shipping list')}
            valueFrom={filters.qtyConfirmedFrom}
            valueTo={filters.qtyConfirmedTo}
            onChange={(qtyConfirmedFrom, qtyConfirmedTo) => setFilters({ ...filters, qtyConfirmedFrom, qtyConfirmedTo })}
          />

          <NumrangeFilter
            controlId="filter-before-gate"
            label={t('reportingDiscrepancies.filters.readInWarehouse', 'Read in warehouse')}
            valueFrom={filters.qtyReadBeforeWarehouseGateFrom}
            valueTo={filters.qtyReadBeforeWarehouseGateTo}
            onChange={(qtyReadBeforeWarehouseGateFrom, qtyReadBeforeWarehouseGateTo) => setFilters({
              ...filters,
              qtyReadBeforeWarehouseGateFrom,
              qtyReadBeforeWarehouseGateTo,
            })}
          />

          <NumrangeFilter
            controlId="filter-store-gate"
            label={t('reportingDiscrepancies.filters.storeGate', 'Store inbound')}
            valueFrom={filters.qtyReadByStoreGateFrom}
            valueTo={filters.qtyReadByStoreGateTo}
            onChange={(qtyReadByStoreGateFrom, qtyReadByStoreGateTo) => setFilters({
              ...filters,
              qtyReadByStoreGateFrom,
              qtyReadByStoreGateTo,
            })}
          />

          <NumrangeFilter
            controlId="filter-missing"
            label={t('reportingDiscrepancies.filters.missing', 'Missing')}
            valueFrom={filters.missingFrom}
            valueTo={filters.missingTo}
            onChange={(missingFrom, missingTo) => setFilters({
              ...filters,
              missingFrom,
              missingTo,
            })}
          />
        </>
      ) : (
        <NumrangeFilter
          controlId="filter-unexpected"
          label={t('reportingDiscrepancies.filters.unexpected', 'Unexpected')}
          valueFrom={filters.unexpectedQtyFrom}
          valueTo={filters.unexpectedQtyTo}
          onChange={(unexpectedQtyFrom, unexpectedQtyTo) => setFilters({
            ...filters,
            unexpectedQtyFrom,
            unexpectedQtyTo,
          })}
        />
      )}

      <NumrangeFilter
        controlId="filter-realized-movement"
        label={t('reportingDiscrepancies.filters.realizedMovement', 'Realized movement')}
        valueFrom={filters.realizedMovementFrom}
        valueTo={filters.realizedMovementTo}
        onChange={(realizedMovementFrom, realizedMovementTo) => setFilters({
          ...filters,
          realizedMovementFrom,
          realizedMovementTo,
        })}
      />

      <NumrangeFilter
        controlId="filter-value"
        label={t('reportingDiscrepancies.filters.sellingValue', 'Selling value')}
        valueFrom={filters.valueFrom}
        valueTo={filters.valueTo}
        onChange={(valueFrom, valueTo) => setFilters({
          ...filters,
          valueFrom,
          valueTo,
        })}
      />

      <NumrangeFilter
        controlId="filter-cession-value"
        label={t('reportingDiscrepancies.filters.cessionValue', 'Cession value')}
        valueFrom={filters.cessionValueFrom}
        valueTo={filters.cessionValueTo}
        onChange={(cessionValueFrom, cessionValueTo) => setFilters({
          ...filters,
          cessionValueFrom,
          cessionValueTo,
        })}
      />

      {isExpedition && (
        <Toggle
        className="[&>div]:!vtmn-justify-start"
          label={<StayedAtWarehouseLabel/>}
          value={filters.stayedAtWarehouse}
          onChange={() => setFilters({
            ...filters,
            stayedAtWarehouse: !filters.stayedAtWarehouse,
          })}
        />
      )}
    </>
  );
};

const Filters = ({
  user,
  updateTokens,
  filters,
  totals,
  discrepancyType,
  setFilters,
  onClear,
  sort,
  withWarehouse,
  invertedFilters,
  setInvertedFilters,
}) => {
  const isExpedition = discrepancyType === 'expedition';
  const isTransport = discrepancyType === 'transport';
  const isWrongSize = discrepancyType === 'wrong_size';
  const filtered = hasFilters(filters);
  const { warehouse } = useParams();
  const memoizedExportReport = useMemo(() => exportReport(discrepancyType, warehouse), [discrepancyType, warehouse]);
  return (
    <FiltersPannel
      filtered={filtered}
      onClear={onClear}
      listClassName="vtmn-flex vtmn-flex-row vtmn-flex-wrap [&>div]:vtmn-mr-5 [&>div]:vtmn-w-[280px]"
      className="vtmn-mx-3"
      mainFilters={
        <MainFilters
          filters={filters}
          setFilters={setFilters}
          invertedFilters={invertedFilters}
          setInvertedFilters={setInvertedFilters}
          withWarehouse={withWarehouse}
          isWrongSize={isWrongSize}
          user={user}
          updateTokens={updateTokens}
        />
      }
      aditionalFilters={
        <AdditionalFilters
          filters={filters}
          setFilters={setFilters}
          invertedFilters={invertedFilters}
          setInvertedFilters={setInvertedFilters}
          isExpedition={isExpedition}
          isTransport={isTransport}
          user={user}
          updateTokens={updateTokens}
        />
      }
      exportButton={
        <ExportButton
          size="small"
          user={user}
          updateTokens={updateTokens}
          filters={filters}
          invertedFilters={invertedFilters}
          sort={sort}
          totalItems={totals && totals.items}
          exportReport={memoizedExportReport}
        />
      }
    />
  );
};

export default Filters;
