import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'

import useRequest from '../../functions/useRequest';
import getPilotSites from '../../functions/getPilotSites';

import DateRangePicker from '../widgets/vtmn/filters/DateRangePicker';
import CountrySelect from '../widgets/vtmn/filters/CountrySelect';
import StoresGroupSelect from '../widgets/vtmn/filters/StoresGroupSelect';
import StoreMultiSelect from '../widgets/vtmn/filters/StoreMultiSelect';

export const getDefaultFilters = (mode) => {
  const isCountryView = mode === 'country';
  const to = new Date();
  const from = new Date();
  from.setDate(from.getDate() - 30);
  const filters = {
    country_id: undefined,
    site_ids: undefined,
    site_ids_inverted: false,
    unloading_start_date_from: from,
    unloading_start_date_to: to,
  };
  if (isCountryView) {
    // default country - UK
    filters.country_id = 7;
  }
  return filters;
}

const startOfDay = (date) => {
  if (date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.toISOString();
  }
  return date;
}

const endOfDay = (date) => {
  if (date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
    return date.toISOString();
  }
  return date;
}

export const parseFilters = (filters, mode, store) => {
  const { site_ids_inverted, site_ids: site_ids_filter } = filters;
  let site_ids = site_ids_inverted ? undefined : site_ids_filter;
  let not_site_ids = site_ids_inverted ? site_ids_filter : undefined;
  const isStoreView = mode === 'store';
  if (isStoreView) {
    site_ids = [store];
    not_site_ids = undefined;
  }
  return {
    ...filters,
    site_ids_inverted: undefined,
    site_ids,
    not_site_ids,
    unloading_start_date_from: startOfDay(filters.unloading_start_date_from),
    unloading_start_date_to: endOfDay(filters.unloading_start_date_to),
  }
}

export const CountryModeFilters = ({ filters, setFilters, user, updateTokens }) => {
  const { t } = useTranslation();
  const [group, setGroup] = useState();

  useEffect(() => {
    if(!group || !group.id) {
      setFilters({ ...filters, site_ids: null });
      return;
    }
    setFilters({ ...filters, site_ids: group.stores.map(({ id }) => id) });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  const onChangeCountry = (country_id) => {
    setFilters({
      ...filters,
      country_id,
    });
  };

  const onChangeGroup = (group) => {
    if(!group || !group.id) {
      setFilters({ ...filters, site_ids: null, group: null });
      return;
    }
    setFilters({
      ...filters,
      group: group.id,
      site_ids: group.stores.map(({ id }) => id),
    });
  }

  return (
    <div className="vtmn-flex vtmn-flex-col">
      <CountrySelect
        user={user}
        updateTokens={updateTokens}
        selected={filters.country_id}
        onChange={onChangeCountry}
        className="vtmn-max-w-[250px] vtmn-mb-3"
      />

      <div className="vtmn-flex vtmn-flex-row vtmn-items-end [&>div]:vtmn-mr-5">
        <DateRangePicker
          label={t('efficiencyDashboard.filters.date', 'Date')}
          valueFrom={filters.unloading_start_date_from}
          valueTo={filters.unloading_start_date_to}
          onChange={(unloading_start_date_from, unloading_start_date_to) =>
            setFilters({
              ...filters,
              unloading_start_date_from,
              unloading_start_date_to,
            })
          }
          className="vtmn-max-w-[250px]"
        />
        <StoresGroupSelect
          user={user}
          updateTokens={updateTokens}
          countryId={filters.country_id}
          onChange={onChangeGroup}
          selected={filters && filters.group ? filters.group : null}
          // onChange={setGroup}
          // selected={group && group.id ? group.id : null}
          label={t('navbar.administrating.groupOfStores','Group of stores')}
        />
        <StoreMultiSelect
          user={user}
          updateTokens={updateTokens}
          selected={filters.site_ids}
          onChange={(site_ids) => setFilters({ ...filters, site_ids: site_ids })}
          countryId={filters.country_id}
          label={t('efficiencyDashboard.filters.stores','Stores')}
          invertable
          inverted={filters.site_ids_inverted}
          onInvert={() => setFilters({
            ...filters,
            site_ids_inverted: !filters.site_ids_inverted,
          })}
        />
      </div>
    </div>
  )
};

const useSelectedSite = (user, updateTokens) => {
  const { store } = useParams();
  const [{ data: sites }, fetchSites] = useRequest(getPilotSites);
  useEffect(() => {
    fetchSites(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const site = store && sites && sites.find((s) => s.id.toString() === store);
  return site;
};

export const StoreModeFilters = ({ filters, setFilters, user, updateTokens }) => {
  const { t } = useTranslation();
  const store = useSelectedSite(user, updateTokens);
  const title =  store ? `${store.id} - ${store.name}` : null;
  return (
    <>
      <span className="vp-subtitle-l">{title}</span>
      <div className="d-flex filter-card">
        <DateRangePicker
          className="vtmn-max-w-[200px]"
          label={t('efficiencyDashboard.filters.date', 'Date')}
          valueFrom={filters.unloading_start_date_from}
          valueTo={filters.unloading_start_date_to}
          onChange={(unloading_start_date_from, unloading_start_date_to) =>
            setFilters({
              ...filters,
              unloading_start_date_from,
              unloading_start_date_to,
            })
          }
        />
      </div>
    </>
  )
};

