import React, { Component, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  VpPrice,
  VpSticker,
} from "@vtmn-play/react";

import { handleApiResponse } from '../../functions/handleApiResponse';
import fetchWithJWT from '../../functions/fetchWithJWT';
import useRequest from '../../functions/useRequest';
import { cn } from '../../shadcn/lib/utils';

import logoRFID from '../RFID-Icon.svg';
import warehouse_inactive from '../../images/pictos_warehouse/warehouse_inactive.png';
import warehouse_checked from '../../images/pictos_warehouse/warehouse_checked.png';
import warehouse_notChecked from '../../images/pictos_warehouse/warehouse_not-checked.png';
import ItemModal from './itemModal';
import { CHECK_STOCK_OUT_RISK_INTERVAL } from './index'

const getValue = (value, locale, currency) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    // only show fractions digits if != 0
    minimumFractionDigits: value % 1 == 0 ? 0 : 2,
    maximumFractionDigits: value % 1 == 0 ? 0 : 2,
  }).format(value);

const getStock = (user, updateTokens, store) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/${store}/stock/${this.props.item_id}`;

  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse)
}

const Item = (props) => {
  const { t } = useTranslation();
  const [stock, setStock] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const {
    user,
    updateTokens,
    store,
    item_id,
    qtiesReadBeforeGateTotal,
    qtiesPredictedTotal,
    article_flag,
    reception,
    price,
    currency,
    unexpectedMatchesSize,
    qtiesReceivedTotal,
    pixlId,
    isFoundAtMovements,
    isMissingAtMovements,
    isRevisedAtMovements,
    item_lib,
    universe_label,
    department_label,
    sub_department_label,
    family_label,
    brand_name,
    model_id,
    model_lib,
  } = props;

  useEffect(() => {
    fetchStock(user, updateTokens, store);
  }, [user, updateTokens, store]);

  const decideWarehouseColor = () => {
    // warehouse - read more then expected
    const isGreen = qtiesPredictedTotal <= qtiesReadBeforeGateTotal;
    if (isGreen) {
      return "green";
    }

    return "red";
  }

  const displayWarehouse = (warehouseColor) => {
    switch (warehouseColor){
      case 'green':
        return (
          <img
            className="warehouseIcon"
            src={warehouse_checked}
            alt="warehouseIcon green"
            title={t(
              'itemComponent.greenTitle',
              'All expected quantities were scanned properly at the warehouse'
            )}
          />
        );
      case 'red':
        return (
          <img
            className="warehouseIcon"
            src={warehouse_notChecked}
            alt="warehouseIcon red"
            title={t(
              'itemComponent.redTitle',
              'Not all quantities were scanned at the warehouse during picking'
            )}
          />
        );
      default:
        return (
          <img
            className="warehouseIcon"
            src={warehouse_inactive}
            alt="warehouseIcon grey"
          />
        );
    }
  }

  const displayRobotIcon = (isFoundAtMovements, isMissingAtMovements, isRevisedAtMovements) => {
    if (isFoundAtMovements) {
      return (
        <div className="robotIcon">
          <i className="robotFoundIcon vtmn-icon_decision"
            title={t('itemComponent.robotFoundTitle', 'Found during inventory')}
          ></i>
        </div>
      );
    }
    if (isMissingAtMovements) {
      return (
        <div className="robotIcon">
          <i className="robotMissingIcon vtmn-icon_decision"
            title={t('itemComponent.robotMissingTitle', 'Confirmed missing due to movements')}
          ></i>
        </div>
      );
    }
    if (isRevisedAtMovements) {
      return (
        <div className="robotIcon">
          <i className="robotRevisedIcon vtmn-icon_decision"
            title={t('itemComponent.robotRevisedTitle', 'Has movement correction')}
          ></i>
        </div>
      );
    }
  }

  const displayRFID = () => {
    if (article_flag !== 'P' && article_flag !== 'L'){
      return(
        <img className="not-rfid" src={logoRFID} alt="RFID-icon"></img>
      )
    }
  }

  const displayUnexpectedMatchesSizeIcon = (match) => {
    const titleStart = t('itemComponent.unexpectedMatchesSize', 'Same missing quantity for another size of this model');
    const title = `${titleStart} (${match.missingItemId} - ${match.missingItem.item_lib})`;

    return (
      <div className="sizeMatchIconWrapper" key={match.missingItemId}>
        <i className="sizeMatchIcon vtmn-icon_notif_alert"
          title={title}
        ></i>
      </div>
    );
  }

  let myClass = "itemInParcel clickableText";
  if (qtiesPredictedTotal === qtiesReceivedTotal){
    myClass = myClass + " text-success";
  }
  const warehouseColor = decideWarehouseColor();
  const picture = "https://contents.mediadecathlon.com/p" + pixlId + "/a.jpg?f=60x60";
  const stockOutRisk =
    stock !== null &&
    reception &&
    reception.status === 'RECEIVED' &&
    dayjs().diff(reception.status_change_date, 'h') <= CHECK_STOCK_OUT_RISK_INTERVAL &&
    qtiesPredictedTotal - qtiesReceivedTotal >= stock;

  const [{
    loading,
    error,
  }, fetchStock] = useRequest(
    getStock, {
      onSuccess: setStock,
      onError: () => console.warn(`Error in getStock for item ${item_id} : ${error}`),
    }
  );

  return (
    <div
      className={cn(
        "itemInParcel vtmn-relative vtmn-cursor-pointer vtmn-flex vtmn-flex-row vtmn-shadow-100",
        // "vtmn-mt-5 last:vtmn-mb-5",
        stockOutRisk ? "stock-out-risk vtmn-border" : "",
        qtiesPredictedTotal === qtiesReceivedTotal ? "vtmn-text-success" : "",
      )}
      onClick={() => {
        if (showModal) return
        setShowModal(true);
      }}
    >
      <div className="vtmn-w-1/5 vtmn-relative vtmn-p-2 vtmn-mr-3 vtmn-h-[84px]">
        <img src={picture} alt="item" />
        {price && currency && (
          <VpSticker
            size="small"
            variant="primary"
            className={cn(
              "!vtmn-absolute vtmn-top-0 vtmn-right-0",
            )}
          >
            <VpPrice className="!vtmn-flex">
              <span className="vp-caption">
                {getValue(price, "en-US", currency)}
              </span>
            </VpPrice>
          </VpSticker>
        )}
      </div>
      <div className="vtmn-flex vtmn-flex-col">
        <span className="vp-body-s">
          {item_id}
          {' : '}
          {t('itemComponent.expected', 'expected')}
          {' '}
          {qtiesPredictedTotal}
          {' - '}
          {t('itemComponent.read', 'read')}
          {' '}
          {qtiesReceivedTotal}
        </span>
        <span className="vp-body-s">
          {item_lib}
        </span>
        <div className="vtmn-flex vtmn-flex-row">
          {displayRFID()}
          {displayRobotIcon(
            isFoundAtMovements,
            isMissingAtMovements,
            isRevisedAtMovements,
          )}
          {unexpectedMatchesSize && unexpectedMatchesSize.map(displayUnexpectedMatchesSizeIcon)}
          {!!qtiesPredictedTotal && displayWarehouse(warehouseColor)}
        </div>
      </div>
      {stockOutRisk && (
        <div className="stock-out-warning">
          {t('itemComponent.stockOutRisk', 'Stock out risk')}
        </div>
      )}
      <ItemModal
        {...props}
        key={item_id}
        show={showModal}
        onClose={() => {
          setTimeout(() => setShowModal(false), 100);
        }}
        itemId={item_id}
        itemLib={item_lib}
        universeLabel={universe_label}
        departmentLabel={department_label}
        subDepartmentLabel={sub_department_label}
        familyLabel={family_label}
        brandName={brand_name}
        modelId={model_id}
        modelLib={model_lib}
        articleFlag={article_flag}
        stock={stock}
      />
    </div>
  )
}

export default Item;
