import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn/components/ui/tooltip";
import DateRangePicker from '../widgets/vtmn/filters/DateRangePicker';
import CountrySelect from '../widgets/vtmn/filters/CountrySelect';
import StoresGroupSelect from '../widgets/vtmn/filters/StoresGroupSelect';
import StoreMultiSelect from '../widgets/vtmn/filters/StoreMultiSelect';
import InputFilter from '../widgets/vtmn/filters/InputFilter';
import Combobox from '../widgets/vtmn/form/Combobox';
import FiltersPannel from '../widgets/vtmn/FiltersPanel';
import SelectColumns from '../widgets/vtmn/filters/SelectColumns';
import { getStatusOptions, getRecordTypes } from './statuses';

const hasFilters = (filters) => {
  // check all filters except: create_date_from, create_date_to
  const keys = Object.keys(filters).filter(x => x !== 'unloading_start_date_from' && x !== 'unloading_start_date_to');
  const values = keys.map(k => filters[k]).filter(v => v);
  const filtered = values.length !== 0;
  return filtered;
}

const MainFilters = ({
  filters,
  setFilters,
  invertedFilters,
  setInvertedFilters,
  user,
  updateTokens,
}) => {
  const { t } = useTranslation();
  const [group, setGroup] = useState(null);

  useEffect(() => {
    // compare with undefined because site_ids can be predefined
    if(group === undefined) {
      setFilters({ ...filters, site_ids: null });
      return;
    }
    if(group && group.stores.length) {
      setFilters({ ...filters, site_ids: group.stores.map(({ id }) => id) });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return (
    // <div className="vtmn-flex vtmn-flex-row vtmn-flex-wrap [&>div]:vtmn-mr-5">
    <>
      <DateRangePicker
        className="vtmn-w-full vtmn-filter-min-width vtmn-filter-max-width"
        label={t('efficiencyAdmin.columns.date', 'Date')}
        valueFrom={filters.unloading_start_date_from}
        valueTo={filters.unloading_start_date_to}
        onChange={(unloading_start_date_from, unloading_start_date_to) => {
          console.log(unloading_start_date_from, unloading_start_date_to)
          setFilters({
          ...filters,
          unloading_start_date_from,
          unloading_start_date_to,
        })}}
      />
      <CountrySelect
        user={user}
        updateTokens={updateTokens}
        selected={filters.country_id}
        label={t('countrySelect.label', 'Country')}
        onChange={(country_id) => setFilters({
          ...filters,
          country_id,
        })}
      />
      <StoresGroupSelect
        user={user}
        updateTokens={updateTokens}
        onChange={setGroup}
        countryId={filters.country_id}
        selected={group && group.id ? group.id : null}
        label={t('navbar.administrating.groupOfStores','Group of stores')}
      />
      <StoreMultiSelect
        user={user}
        updateTokens={updateTokens}
        selected={filters.site_ids}
        onChange={(site_ids) => setFilters({ ...filters, site_ids: site_ids })}
        countryId={filters.country_id}
        label={t('efficiencyDashboard.filters.stores','Stores')}
        invertable
        inverted={invertedFilters.site_ids}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          site_ids: !invertedFilters.site_ids,
        })}
      />
      <InputFilter
        // controlId="filter-delivery"
        label={t('efficiencyAdmin.columns.delivery', 'Delivery')}
        value={filters.delivery}
        onChange={(delivery) => setFilters({ ...filters, delivery })}
      />
    </>
  );
};

const AdditionalFilters = ({
  filters,
  setFilters,
  invertedFilters,
  setInvertedFilters,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <InputFilter
        controlId="filter-leader"
        label={t('efficiencyAdmin.columns.leader', 'Leader')}
        value={filters.leader}
        onChange={(leader) => setFilters({ ...filters, leader })}
        className="vtmn-mr-5"
      />
      <Combobox
        label={t('efficiencyAdmin.columns.status', 'Status')}
        options={getStatusOptions(t).map((s) => ({
          ...s,
          label: <div className="vtmn-py-2">{s.label}</div>
        }))}
        selected={filters.status}
        onChange={(status) => setFilters({ ...filters, status })}
        className="vtmn-min-w-[200px]"
        valueClassName="[&>div]:vtmn-py-0"
        withSearch={false}
      />
    </>
  );
};

const Filters = ({
  filters,
  setFilters,
  onClear,
  invertedFilters,
  setInvertedFilters,
  fields,
  fieldsSelection,
  setFieldsSelection,
  user,
  updateTokens,
  removeButton,
}) => {
  const { t } = useTranslation();
  const filtered = hasFilters(filters);
  return (
    <div className="vtmn-px-4">
      <FiltersPannel
        filtered={filtered}
        onClear={onClear}
        listClassName="vtmn-flex vtmn-flex-row vtmn-flex-wrap [&>div]:vtmn-mr-5 [&>div]:vtmn-w-[280px]"
        mainFilters={
          <MainFilters
            filters={filters}
            setFilters={setFilters}
            invertedFilters={invertedFilters}
            setInvertedFilters={setInvertedFilters}
            user={user}
            updateTokens={updateTokens}
          />
        }
        aditionalFilters={
          <AdditionalFilters
            filters={filters}
            setFilters={setFilters}
            invertedFilters={invertedFilters}
            setInvertedFilters={setInvertedFilters}
          />
        }
        exportButton={removeButton}
        generalFilters={
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
                  <span className="vtmn-mr-2 vtmn-ml-4">
                    {t('efficiencyAdmin.recordTypes.label', 'Type of records')}:
                  </span>
                  <Combobox
                    withSearch={false}
                    options={getRecordTypes(t).map((s) => ({
                      ...s,
                      label: <div className="vtmn-py-2">{s.label}</div>
                    }))}
                    selected={filters.records_type}
                    onChange={(records_type) => setFilters({
                      ...filters,
                      records_type,
                    })}
                    className="vtmn-min-w-[200px]"
                    valueClassName="[&>div]:vtmn-py-0"
                  />
                </div>
                
              </TooltipTrigger>
              <TooltipContent side="right">
                <div className="vtmn-w-[200px]">
                  <Trans i18nKey="efficiencyAdmin.tooltipForTypes">
                    Potential incorrect records are the one with of productivity
                    not in the 50 - 250 qty/hour/teammate range and where shelving duration is under a minute
                  </Trans>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        }
        selectColumns={
          <SelectColumns
            fields={fields}
            fieldsSelection={fieldsSelection}
            setFieldsSelection={setFieldsSelection}
          />
        }
      />
    </div>
  );
};

export default Filters;
