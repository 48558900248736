import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import MDEditor from '@uiw/react-md-editor';
import { VpLoader, VpLink } from "@vtmn-play/react";

import useTableRequest from '../../functions/useTableRequest';
import getQueryString from '../../functions/getQueryString';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import { formatDate } from '../../functions/formatDate';

import DataTable from "../widgets/vtmn/DataTable";
import ErrorMessage from '../errorMessage';
import ReleaseSlidesModal from './ReleaseSlidesModal';

const columns = [{
  title: <Trans i18nKey="releases.headers.version">Version</Trans>,
  field: 'version',
  cell: ({ getValue, row, table }) => (
    <VpLink onClick={() => table.options.meta.setSelected(row.original.id)}>
      {getValue()}
    </VpLink>
  )
}, {
  title: <Trans i18nKey="releases.headers.image">Image</Trans>,
  field: 'image',
  cell: ({ row, table }) => {
    const { token } = table.options.meta;
    return (
      <div className='vtmn-w-[80px]'>
        <img src={`${process.env.REACT_APP_base_URL}/api/files/${row.original.image}?token=${token}`} alt="slide" />
      </div>
    )
  }
}, {
  title: <Trans i18nKey="releases.headers.summary">Summary</Trans>,
  field: 'summary',
  cell: ({ getValue }) => (
    <MDEditor.Markdown className="mobile-markdown" source={getValue()} />
  )
}, {
  title: <Trans i18nKey="releases.headers.publishDate">Publish date</Trans>,
  field: 'publish_date',
  cell: ({ getValue }) => formatDate(new Date(getValue()))
}];

const getReleases = (user, updateTokens, { pagination }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({ pagination });
  const url = `${process.env.REACT_APP_base_URL}/api/releases/list/web?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const Releases = ({ user, updateTokens }) => {
  const { token } = user;
  const [selected, setSelected] = useState(null);

  const [{
    data,
    loading,
    error,
    pagination,
    totals,
  },
  fetchReleases,
  setFilters, //eslint-disable-line
  setPagination] = useTableRequest(getReleases);

  useEffect(() => {
    fetchReleases(user, updateTokens, { pagination });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <div className="warehouseContenantsControl mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
      {loading && (
        <div className="vtmn-w-full vtmn-h-screen vtmn-flex vtmn-items-center vtmn-justify-center">
          <VpLoader size="extra-large" />
        </div>
      )}
      {!loading && !!error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <DataTable
          className="vtmn-h-full"
          columns={columns}
          data={data || []}
          totals={totals}
          pagination={pagination}
          setPagination={setPagination}
          meta={{
            token,
            setSelected,
          }}
        />
      )}
      {selected && (
        <ReleaseSlidesModal
          show={!!selected}
          user={user}
          updateTokens={updateTokens}
          id={selected}
          onClose={() => setSelected(null)}
        />
      )}
    </div>
  )
}

export default Releases;
