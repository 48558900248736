import React from 'react';
import InputMask from 'react-input-mask';
import {
  VpFormControl,
  VpFormHelper,
  VpFormLabel,
  VpInput,
} from "@vtmn-play/react";

import { cn } from '../../../../shadcn/lib/utils';
import { formatDurationSec } from '../../../../functions/formatDate';

const lpad = (x) => `00${x}`.slice(-2);

const DurationEdit = ({ label, disabled, value, onChange, helper, className }) => {
  const onEdit = (e) => {
    const rawVal = e?.target.value;
    let nextTextVal = rawVal.replace(/[^\d:]/g, '');
    let [h,m,s] = nextTextVal.split(':')
    h = Number(h) || 0;
    m = Number(m) || 0;
    s = Number(s) || 0;
    if (h > 99) h = 99;
    if (m > 60) m = 60;
    if (s > 60) s = 60;
    nextTextVal = `${lpad(h)}:${lpad(m)}:${lpad(s)}`;
    const nextVal = s + m * 60 + h * 3600;
    return onChange && onChange(nextVal);
  };

  return (
    <div className={cn(className)}>
      <VpFormControl
        className="form-content"
        disabled={disabled}
        // required={isRequired}
      >
        {label && <VpFormLabel>{label}</VpFormLabel>}
        <InputMask mask="99:99:99" value={formatDurationSec(value)} onChange={onEdit}>
          {(inputProps) => (
            <VpInput
              {...inputProps}
              size="small"
            />
          )}
        </InputMask>
        {helper && (
          <VpFormHelper>{helper}</VpFormHelper>
        )}
      </VpFormControl>
    </div>
  );
};

export default DurationEdit;
