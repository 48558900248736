import React from 'react';
import { Trans } from 'react-i18next';
import { VpButton, VpLoader } from "@vtmn-play/react";

import Modal from './Modal';

const Confirm = ({
  show,
  onHide,
  title,
  loading,
  body,
  buttonOpts,
  onConfirm,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      title={title || <Trans i18nKey="shared.confirm">Confirm</Trans>}
    >
      {loading ? <VpLoader size="medium" /> : body}
      <Modal.Footer className="vtmn-flex vtmn-justify-right">
        <VpButton
          shape="squared"
          size="small"
          variant="secondary"
          onClick={onHide}
          disabled={loading}
        >
          {buttonOpts?.noText || <Trans i18nKey="shared.cancel">Cancel</Trans>}
        </VpButton>
        <VpButton
          shape="squared"
          size="small"
          onClick={onConfirm}
          disabled={loading}
        >
          {buttonOpts?.text || <Trans i18nKey="shared.confirm">Confirm</Trans>}
        </VpButton>
      </Modal.Footer>
    </Modal>
  );
};

export default Confirm;
