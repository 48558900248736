import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import useRequest from '../../functions/useRequest';

import Drawer from '../widgets/vtmn/Drawer';
import StoreSelect from '../widgets/vtmn/form/StoreSelect';
import DatePicker from '../widgets/vtmn/filters/DatePicker';
import TextArea from '../widgets/vtmn/form/TextArea';
import Toggle from '../widgets/vtmn/filters/Toggle';

const createRacket = ({
  user,
  updateTokens,
  racket,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/rackets`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify(racket),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateRacket = ({
  user,
  updateTokens,
  id,
  racket,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/rackets/${id}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(racket),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const RacketForm = ({
  user,
  updateTokens,
  data,
  isOpen,
  onClose,
  callbackFn,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(data);

  const title = t('deploymentRacket.enabling', 'Enabling rackets');

  const [{
    loading: saving,
    error: saveError,
  }, saveRacket] = useRequest(data && data.id ? updateRacket : createRacket, {
    onSuccess: () => {
      callbackFn && callbackFn();
      onClose && onClose();
    },
  });

  useEffect(() => {
    setFormData(data)
  }, [data])

  const saveData = () => saveRacket({
    id: data && data.id,
    racket: formData,
    user,
    updateTokens,
  });

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={title}
      onSave={saveData}
      disabledSave={saving}
    >
      <div className="vtmn-px-1 [&>div]:vtmn-mb-3">
        <StoreSelect
          label={t('storeSelect.label', 'Store')}
          user={user}
          updateTokens={updateTokens}
          value={formData.storeId}
          onChange={(value) => setFormData({
            ...formData,
            storeId: value,
          })}
        />
        <DatePicker
          label={t('"deploymentRacket.trainingDate', 'Training date')}
          value={formData.trainingDate}
          onChange={(value) => setFormData({
            ...formData,
            trainingDate: value,
          })}
        />
        <DatePicker
          label={t('deploymentRacket.ReceptionControlStartDate', 'Racket reception control start date')}
          value={formData.startDate}
          onChange={(value) => setFormData({
            ...formData,
            startDate: value,
          })}
        />
        <TextArea
          label={t('deploymentRobot.comment', 'Comment')}
          value={formData.comment}
          onChange={(value) => setFormData({
            ...formData,
            comment: value,
          })}
        />
        <Toggle
          className="[&>div]:vtmn-justify-start"
          label={t('deploymentRacket.isActive', 'Active')}
          value={formData.isActive ? true : false}
          onChange={(value) => setFormData({
            ...formData,
            isActive: value,
          })}
        />
      </div>
    </Drawer>
  )
}

export default RacketForm;
