import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { VpIconButton, VpLoader, VpAsset } from "@vtmn-play/react";
import { VpEditIcon, VpDeleteBinIcon } from "@vtmn-play/icons/react";

import useRequest from '../../functions/useRequest';
import handleApiResponse from '../../functions/handleApiResponse';
import fetchWithJWT from '../../functions/fetchWithJWT';
import getQueryString from '../../functions/getQueryString';

import DataTable from "../../components/widgets/vtmn/DataTable";
import ErrorMessage from '../errorMessage';
import Confirm from '../widgets/vtmn/Confirm';
import Filters from './Filters';
import GroupForm from './GroupForm';

const getGroupsDeployment = (user, updateTokens, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({ filters });
  const url = `${process.env.REACT_APP_base_URL}/api/groups/stores?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const deleteGroup = (
  user,
  updateTokens,
  userId,
) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/groups/stores/${userId}`;
  return fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const columns = [{
  field: "name",
  title: <Trans i18nKey="storeGroups.name">Name</Trans>,
}, {
  field: "countries",
  title: <Trans i18nKey="storeGroups.countries">Countries</Trans>,
  cell: ({ getValue }) => getValue().map((c) => (
    <div key={c.id} className="group-stores">
      <VpAsset className="vtmn-mr-2" name={`flag-${c.code.toLowerCase()}`} width="24" />
      {c.name}
    </div>
  ))
}, {
  field: "stores",
  title: <Trans i18nKey="storeGroups.stores">Stores</Trans>,
  cell: ({ getValue }) => getValue().map((c) => (
    <div key={c.id} className="group-stores">
      <VpAsset className="vtmn-mr-2" name={`flag-${c.code.toLowerCase()}`} width="24" />
      {c.id} - {c.name}
    </div>
  ))
}, {
  field: "owner_email",
  title: <Trans i18nKey="storeGroups.ownerEmail">Owner's email</Trans>,
}, {
  id: "actions",
  cell: ({ row, table }) => {
    const { deleteCallback, setShowForm } = table.options.meta;

    return (
      <div className="vtmn-flex vtmn-flex-row vtmn-justify-end">
        <VpIconButton
          size="small"
          variant="secondary"
          aria-label="Edit"
          className="vtmn-mr-2"
          onClick={() => setShowForm(row.original)}
        >
          <VpEditIcon />
        </VpIconButton>
        <VpIconButton
          size="small"
          variant="secondary"
          onClick={() => deleteCallback(row.original)}
          aria-label="Delete"
        >
          <VpDeleteBinIcon />
        </VpIconButton>
      </div>
    )
  }
}, ]

const StoreGroups = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [deletedGroup, setDeletedGroup] = useState(null);
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({});
  const [editedRecord, setEditedRecord] = useState({});
  const [showForm, setShowForm] = useState(false);

  const [{
    loading,
    error,
  }, fetchGroupsDeployment] = useRequest(
    getGroupsDeployment, {
      onSuccess: setData,
      onError: () => setData(null),
    }
  );
  useEffect(() => {
    fetchGroupsDeployment(user, updateTokens, filters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const updateList = () => {
    const record = data.find((r) => r.id === deletedGroup);
    if (!record) {
      return;
    }
    const tData = data.filter((r) => r.id !== deletedGroup);
    setData(tData);
    setDeletedGroup();
  };

  const [{
    loading: loadingDelete,
    error: errorDelete,
  }, fetchDelete] = useRequest(
    deleteGroup, {
      onSuccess: updateList
    }
  );
  const removeGroup = async () => {
    if (!deletedGroup) {
      return;
    }
    await fetchDelete(user, updateTokens, deletedGroup);
  };

  return (
    <div className="adminGroups mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
      {!loading && !error && (
        <div className='vtmn-px-4'>
          <Filters
            filters={filters}
            setFilters={setFilters}
            onClear={() => setFilters({})}
            onAddClick={() => {
              setEditedRecord({});
              setShowForm(true);
            }}
          />
        </div>
      )}
      {loading && (
        <div className="vtmn-w-full vtmn-h-screen vtmn-flex vtmn-items-center vtmn-justify-center">
          <VpLoader size="extra-large" />
        </div>
      )}
      {!loadingDelete && errorDelete && <ErrorMessage error={errorDelete} />}
      {!loading && error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <DataTable
          columns={columns}
          data={data || []}
          meta={{
            deleteCallback: (group) => {
              setShowConfirm(true);
              setDeletedGroup(group.id);
            },
            setShowForm: (record) => {
              setEditedRecord(record);
              setShowForm(true);
            },
          }}
        />
      )}
      <GroupForm
        user={user}
        updateTokens={updateTokens}
        group={editedRecord}
        isOpen={showForm}
        onClose={() => {
          setEditedRecord({});
          setShowForm(false);
        }}
        callbackFn={() => {
          fetchGroupsDeployment(user, updateTokens, filters);
        }}
      />
      <Confirm
        title={t('storeGroups.deleteConfirm.warning', 'Warning')}
        body={t('storeGroups.deleteConfirm.text', 'Are you sure you want to delete the group of stores?')}
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        loading={loadingDelete}
        onConfirm={async () => {
          await removeGroup();
          setShowConfirm(false);
        }}
      />
    </div>
  );
};

export default StoreGroups;
