import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { VpAsset } from "@vtmn-play/react";

import Combobox from './form/Combobox';

export const LOCALES_MAP = {
  en: {
    code: 'en-US',
    flag: 'flag-gb',
    alt: 'English',
  },
  bg: {
    code: 'bg-BG',
    flag: 'flag-bg',
    alt: 'Bulgarian',
  },
  zh: {
    code: 'zh-ZH',
    flag: 'flag-cn',
    alt: 'Chinese',
  },
  cz: {
    code: 'cz-CZ',
    flag: 'flag-cz',
    alt: 'Czech',
  },
  nl: {
    code: 'nl-NL',
    flag: 'flag-nl',
    alt: 'Dutch',
  },
  fr: {
    code: 'fr-FR',
    flag: 'flag-fr',
    alt: 'French',
  },
  de: {
    code: 'de-DE',
    flag: 'flag-de',
    alt: 'German',
  },
  it: {
    code: 'it-IT',
    flag: 'flag-it',
    alt: 'Italian',
  },
  pl: {
    code: 'pl-PL',
    flag: 'flag-pl',
    alt: 'Polish',
  },
  pt: {
    code: 'pt-PT',
    flag: 'flag-pt',
    alt: 'Portuguese',
  },
  br: {
    code: 'br',
    flag: 'flag-br',
    alt: 'Brazilian Portuguese',
  },
  ro: {
    code: 'ro-RO',
    flag: 'flag-ro',
    alt: 'Romanian',
  },
  ru: {
    code: 'ru-RU',
    flag: 'flag-ru',
    alt: 'Russian',
  },
  es: {
    code: 'es-ES',
    flag: 'flag-es',
    alt: 'Spanish',
  },
  tr: {
    code: 'tr-TR',
    flag: 'flag-tr',
    alt: 'Turkish',
  },
}

const options = Object.values(LOCALES_MAP).map((item) => ({
  id: item.code,
  label: (
    <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-py-2">
      <VpAsset name={item.flag} />
    </div>
  )
}))

const LangSelect = () => {
  const { i18n } = useTranslation()
  const currentLocale = LOCALES_MAP[i18n.resolvedLanguage]

  return (
    <Combobox
      options={options}
      selected={currentLocale.code}
      onChange={(value) => i18n.changeLanguage(value)}
      withSearch={false}
      className="[&_svg]:vtmn-h-5 [&_button]:!vtmn-px-1 [&_button]:!vtmn-pl-3"
      valueClassName="[&>div]:vtmn-py-0"
    />
  )
}

export default LangSelect;
