import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { VpButton } from "@vtmn-play/react";
import { VpAddIcon, VpCloseIcon, VpDeleteBinIcon } from "@vtmn-play/icons/react";

import { formatDateTime } from '../../../../functions/formatDate';
import formatMoney from '../../../../functions/formatMoney';
import useDebounce from '../../../../functions/useDebounce';

import InputFilter from '../../../widgets/vtmn/filters/InputFilter';

let counter = 0;

export const getFields = ({
  t,
  discrepancyType,
}) => {
  return [
    {
      title: t('declareToControl.columns.store', 'Store'),
      field: 'store',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.delivery', 'Delivery'),
      field: 'delivery',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.date', 'Date'),
      field: 'receptionDate',
      sortable: true,
      default: true,
      cell: ({ getValue }) => getValue() ? formatDateTime(new Date(getValue())) : null,
    },
    ...(discrepancyType === 'expedition' ? [{
      title: t('declareToControl.columns.parcels', 'Parcels'),
      field: 'content',
      sortable: false,
      default: true,
      cell: ({ getValue }) => (getValue() || []).map(x => <div key={x.parcel}>{x.parcel}</div>),
    }] : []),
    {
      title: (
        discrepancyType === 'wrong_size'
          ? t('declareToControl.columns.receivedItem', 'Received item')
          : t('declareToControl.columns.item', 'Item')
      ),
      field: 'item',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.modelId', 'Model Id'),
      field: 'modelId',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.rfid', 'RFID'),
      field: 'articleFlag',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.price', 'Selling price'),
      field: 'price',
      align: 'right',
      sortable: true,
      default: true,
      cell: ({ getValue, row }) => formatMoney(getValue(), row.original.currency, '-'),
    },
    {
      title: t('declareToControl.columns.cessionPrice', 'Cession price'),
      field: 'cessionPrice',
      align: 'right',
      sortable: true,
      default: true,
      cell: ({ getValue, row }) => formatMoney(getValue(), row.original.cessionCurrency, '-'),
    },
    {
      title: t('declareToControl.columns.itemLib', 'Item description'),
      field: 'itemLib',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.family', 'Family'),
      field: 'familyLabel',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.subDepartment', 'Sub department'),
      field: 'subDepartmentLabel',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.universe', 'Universe'),
      field: 'universeLabel',
      sortable: true,
      default: true,
    },
    ...(discrepancyType === 'expedition' ? [
      {
        title: t('declareToControl.columns.shippingList', 'Shipping list'),
        field: 'qtyConfirmed',
        sortable: true,
        default: true,
      },
      {
        title: t('declareToControl.columns.pickedParty', 'Picked party'),
        field: 'picked_party',
        sortable: false,
        default: true,
        cell: ({ getValue }) => (getValue() || []).join(', '),
      },
      {
        title: t('declareToControl.columns.readInWarehouse', 'Read in warehouse'),
        field: 'qtyReadBeforeWarehouseGate',
        sortable: true,
        default: true,
      },
      {
        title: t('declareToControl.columns.readInStore', 'Read in store'),
        field: 'qtyReadByStoreGate',
        sortable: true,
        default: true,
      },
      {
        title: t('declareToControl.columns.missing', 'Missing'),
        field: 'missing',
        sortable: true,
        default: true,
        cell: ({ getValue }) => getValue(),
      },
      {
        title: t('declareToControl.columns.realizedMovement', 'Realized movement'),
        field: 'realizedMovement',
        sortable: true,
        default: true,
        cell: ({ getValue }) => getValue(),
      },
    ] : [
      {
        title: t('declareToControl.columns.unexpected', 'Unexpected'),
        field: 'unexpectedQty',
        sortable: true,
        default: true,
        cell: ({ getValue }) => getValue(),
      },
      {
        title: t('declareToControl.columns.realizedMovement', 'Realized movement'),
        field: 'realizedMovement',
        sortable: true,
        default: true,
        cell: ({ getValue }) => getValue(),
      },
    ]),
    {
      title: t('declareToControl.columns.sellingValue', 'Selling value'),
      field: 'value',
      align: 'right',
      sortable: true,
      default: true,
      width: 100,
      cell: ({ getValue, row }) => formatMoney(getValue(), row.original.currency, '-'),
    },
    {
      title: t('declareToControl.columns.cessionValue', 'Cession value'),
      field: 'cessionValue',
      align: 'right',
      sortable: true,
      default: true,
      width: 100,
      cell: ({ getValue, row }) => formatMoney(getValue(), row.original.cessionCurrency, '-'),
    },
    ...(discrepancyType === 'wrong_size' ? [
      {
        title: t('declareToControl.columns.wrongSizes', 'Missing items'),
        field: 'wrongSizes',
        sortable: false,
        default: true,
        cell: ({ getValue }) =>
        getValue() && getValue().length
            ? getValue().map((x, index) => <div key={index}>{`${x.item} - ${x.itemLib}`}</div>)
            : null,
      },
    ] : []),
  ];
};

const InputCell = ({ getValue, row, column, table }) => {
  const { onInputBlur, updateDataRow } = table.options.meta;
  const {
    id,
    controlId,
    address,
    contenant,
    picker,
    warehouseSector
  } = row.original;
  const saved = (getValue() && row.original.saved) || false;
  const saving = row.original.saving || false;
  const disabled = row.original.controlStatus === 'completed';
  const error = (row.original.error && row.original.error[column.id]) || null;

  const initialValue = getValue()
  const [value, setValue] = useState(initialValue)
  const debouncedValue = useDebounce(value, 500);

  useEffect(
    () => {
      if (getValue() !== debouncedValue) {
        updateDataRow(row.index, { [column.id]: debouncedValue || undefined });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <div className="vtmn-flex">
      <InputFilter
        disabled={disabled || !!saving}
        value={value}
        onChange={(value) => setValue(value)}
        onBlur={() => onInputBlur({
          id,
          controlId,
          address,
          contenant,
          picker,
          warehouseSector,
          rowIndex: row.index,
        })}
        error={!saving && error && (error || <Trans i18nKey="defaultError">Error</Trans>)}
        success={saved}
      />
    </div>
  )
}

const getInputColumns = ({
  t,
  insertDataRow,
  deleteDataRow,
  onCancelControl,
}) => [
  {
    title: t('declareToControl.columns.actions', 'Actions'),
    field: 'actions',
    sortable: false,
    default: true,
    width: 80,
    cell: ({ row }) => {
      const id = row.original.id;
      const controlId = row.original.controlId;
      const disabled = row.original.saving || false;
      const canCopy = !row.original.clientSideId;
      const canRemove = !!row.original.clientSideId;
      const canCancel = row.original.controlStatus === 'new';
      const onCopyRow = () => {
        counter += 1;
        insertDataRow(row.index + 1, {
          ...row.original,
          saved: false,
          error: null,
          controlId: undefined,
          controlStatus: null,
          address: undefined,
          contenant: undefined,
          picker: undefined,
          clientSideId: counter,
        });
      };
      const onCancel = () => onCancelControl({
        id,
        controlId,
        rowIndex: row.index,
      });
      const onRemove = () => deleteDataRow(row.index);
      return (
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
          {canCopy && (
            <VpButton
              className="!vtmn-p-1 vtmn-mr-1"
              shape="squared"
              size="small"
              onClick={onCopyRow}
              disabled={disabled}
              startSlot={<VpAddIcon />}
            />
          )}
          {canCancel && (
            <VpButton
              className="!vtmn-p-1 vtmn-mr-1"
              shape="squared"
              size="small"
              onClick={onCancel}
              disabled={disabled}
              startSlot={<VpCloseIcon />}
            />
          )}
          {canRemove && (
            <VpButton
              className="!vtmn-p-1"
              shape="squared"
              size="small"
              onClick={onRemove}
              disabled={disabled}
              startSlot={<VpDeleteBinIcon />}
            />
          )}
        </div>
      );
    }
  },
  {
    title: t('declareToControl.columns.address', 'Address to check'),
    field: 'address',
    sortable: false,
    default: true,
    width: 200,
    cell: InputCell,
  },
  {
    title: t('declareToControl.columns.contenant', 'Contenant to check'),
    field: 'contenant',
    sortable: false,
    default: true,
    width: 200,
    cell: InputCell,
  },
  {
    title: t('declareToControl.columns.picker', 'Picker'),
    field: 'picker',
    sortable: false,
    default: true,
    width: 200,
    cell: InputCell,
  },
  {
    title: t('declareToControl.columns.warehouseSector', 'Warehouse sector'),
    field: 'warehouse_sector',
    sortable: false,
    default: true,
    width: 200,
    cell: InputCell,
  },
];

export const getDefaultSelection = (fields) => {
  const defaultSelection = {};
  for (const { field } of fields.filter(x => x.default)) {
    defaultSelection[field] = true;
  }
  return defaultSelection;
};

export const getSelectedFields = ({
  t,
  fields,
  selection,
  insertDataRow,
  deleteDataRow,
  onCancelControl,
}) => {
  const selected = [
    ...getInputColumns({
      t,
      insertDataRow,
      deleteDataRow,
      onCancelControl,
    }),
    ...fields.filter(x => selection[x.field])
  ];
  return selected;
};
