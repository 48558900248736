import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import useRequest from '../../functions/useRequest';

import Drawer from '../widgets/vtmn/Drawer';
import InputFilter from '../widgets/vtmn/filters/InputFilter';
import CountryMultiSelect from '../widgets/vtmn/filters/CountryMultiSelect';
import StoreMultiSelect from '../widgets/vtmn/filters/StoreMultiSelect';

const createSettings = ({
  user,
  updateTokens,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/groups/stores`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateSettings = ({
  user,
  updateTokens,
  groupId,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/groups/stores/${groupId}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const GroupForm = ({
  user,
  updateTokens,
  isOpen,
  onClose,
  group,
  callbackFn,
}) => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState({});

  const title = group && group.id
  ? t('storeGroups.editGroup', 'Edit group')
  : t('storeGroups.newGroup', 'New group');

  const [{
    loading: saving,
    error: saveError,
  }, saveSettings] = useRequest(group && group.id ? updateSettings : createSettings, {
    onSuccess: () => {
      callbackFn && callbackFn();
      onClose && onClose();
    },
  });

  useEffect(() => {
    setSettings({
      ...group,
      countries: group.countries ? group.countries.map((c) => c.id) : [],
      stores: group.stores ? group.stores.map((c) => c.id) : [],
    });
  }, [group]);

  const onSave = () => saveSettings({
    groupId: group && group.id ? group.id : null,
    settings,
    user,
    updateTokens,
  });

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={title}
      onSave={onSave}
    >
      <div className="vtmn-px-1 [&>div]:vtmn-mb-3">
        <InputFilter
          label={t('storeGroups.name', 'Name')}
          value={settings.name}
          onChange={(name) => setSettings({
            ...settings,
            name,
          })}
        />
        <CountryMultiSelect
          label={t('storeGroups.countries', 'Countries')}
          user={user}
          updateTokens={updateTokens}
          selected={settings.countries}
          onChange={(countries) => setSettings({
            ...settings,
            countries,
          })}
        />
        <StoreMultiSelect
          label={t('storeGroups.stores', 'Stores')}
          user={user}
          updateTokens={updateTokens}
          selected={settings.stores}
          onChange={(stores) => setSettings({
            ...settings,
            stores,
          })}
        />
        
      </div>
    </Drawer>
  )
}

export default GroupForm;
