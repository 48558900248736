import React from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '../../../../shadcn/lib/utils';

import MultiSelectFilter from '../../../widgets/vtmn/filters/MultiSelectFilter';
import Toggle from '../../../widgets/vtmn/filters/Toggle';

export const getMovementCategories = (t, discrepancyType) => {
  const categories = {
    'control_not_done': t('reportingDiscrepancies.columns.movementCategoriesValues.controlNotDone', 'Stock control not done'),
    'as_suggested': discrepancyType === 'expedition'
      ? t('reportingDiscrepancies.columns.movementCategoriesValues.confirmedMissing', 'Confirmed missing')
      : t('reportingDiscrepancies.columns.movementCategoriesValues.asSuggested', 'Unexpected confirmed'),
    'no_tag': t('reportingDiscrepancies.columns.movementCategoriesValues.noTag', 'RFID reading mistake'),
    'smaller': t('reportingDiscrepancies.columns.movementCategoriesValues.smaller', 'Smaller movement than suggested'),
    'bigger': t('reportingDiscrepancies.columns.movementCategoriesValues.bigger', 'Bigger movement than suggested'),
    'grand_total': t('reportingDiscrepancies.grandTotal', 'Grand total'),
  };
  return categories;
};

export const getMovementCategoriesOptions = (t, isExpedition) => {
  const options = [
    {
      id: 'control_not_done',
      label: <div className="vtmn-py-2">{t('reportingDiscrepancies.columns.movementCategoriesValues.controlNotDone', 'Stock control not done')}</div>,
    },
    {
      id: 'as_suggested',
      label: isExpedition
        ? <div className="vtmn-py-2">{t('reportingDiscrepancies.columns.movementCategoriesValues.confirmedMissing', 'Confirmed missing')}</div>
        : <div className="vtmn-py-2">{t('reportingDiscrepancies.columns.movementCategoriesValues.asSuggested', 'Unexpected confirmed')}</div>,
    },
    {
      id: 'no_tag',
      label: <div className="vtmn-py-2">{t('reportingDiscrepancies.columns.movementCategoriesValues.noTag', 'RFID reading mistake')}</div>,
    },
    {
      id: 'smaller',
      label: <div className="vtmn-py-2">{t('reportingDiscrepancies.columns.movementCategoriesValues.smaller', 'Smaller movement than suggested')}</div>,
    },
    {
      id: 'bigger',
      label: <div className="vtmn-py-2">{t('reportingDiscrepancies.columns.movementCategoriesValues.bigger', 'Bigger movement than suggested')}</div>,
    },
  ];
  return options;
};

const MovementCategoriesFilter = ({
  selected,
  onChange,
  invertable,
  inverted,
  onInvert,
  isExpedition,
}) => {
  const { t } = useTranslation();
  const options = getMovementCategoriesOptions(t, isExpedition);
  return (
    <MultiSelectFilter
      label={(
        <div className={cn(
          "vtmn-flex vtmn-flex-row vtmn-items-center vtmn-w-full",
          invertable && "vtmn-justify-between"
        )}>
          <span>{t('reportingDiscrepancies.columns.movementCategories', 'Movement categories')}</span>
          {invertable && (
            <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
              <span className="vp-caption vtmn-mr-3">
                {t('filters.excludes', ' excludes')}
              </span>
              <Toggle value={inverted || false} onChange={onInvert} />
            </div>
          )}
        </div>
      )}
      options={options}
      selected={selected}
      onChange={onChange}
      invertable={invertable}
      inverted={inverted}
      onInvert={onInvert}
      withSearch={false}
      className={cn(
        "vtmn-min-w-[250px]",
        invertable && "[&_label]:vtmn-mb-[2px]",
      )}
    />
  );
};

export default MovementCategoriesFilter;
