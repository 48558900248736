import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Modal from '../../widgets/vtmn/Modal';
import ProgressDetailsContent from './ProgressDetailsContent';

const ProgressDetailsModal = ({
  indicator,
  currency,
  show,
  onHide,
  delivery,
  sport,
}) => {
  const { t } = useTranslation();
  let title = t(
    'liveStoreReception.progressDetails.title',
    {
      defaultValue: 'Delivery {{delivery}} - {{sport}}',
      delivery,
      sport,
    }
  );

  return (
    <Modal
      className="readingRateModal"
      show={show}
      onHide={onHide}
      title={title}
    >
      <div>
        <ProgressDetailsContent
          indicator={indicator}
          currency={currency}
        />
      </div>
    </Modal>
  );
};

export default ProgressDetailsModal;
