import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { VpButton, VpLoader } from '@vtmn-play/react';

import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';

import Modal from '../widgets/vtmn/Modal';
import TextArea from '../widgets/vtmn/form/TextArea';

const cteateContact = (
  siteId,
  contactInfo,
  user,
  updateTokens,
) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/${siteId}/contacts`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({ contactInfo }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateContact = (
  contactId,
  siteId,
  contactInfo,
  user,
  updateTokens,
) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/${siteId}/contacts/${contactId}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ contactInfo }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const saveContact = (
  contactId,
  siteId,
  contactInfo,
  user,
  updateTokens,
) => (
  contactId
    ? updateContact(contactId, siteId, contactInfo, user, updateTokens)
    : cteateContact(siteId, contactInfo, user, updateTokens)
);

const ContactDialog = ({
  user,
  updateTokens,
  siteId,
  contactId,
  contactInfo,
  open,
  onClose,
  onSaved,
}) => {
  const [stateContact, setStateContact] = useState(contactInfo);
  useEffect(() => { setStateContact(contactInfo) }, [contactInfo]);
  const { t } = useTranslation();
  const title = t('adminDeploymentManagement.editContact', 'Edit contact');

  const [{
    loading: saving,
    error,
  }, save] = useRequest(saveContact, { onSuccess: onSaved });

  return (
    <Modal
      show={open}
      onHide={onClose}
      title={title}
    >
      <div>
        <TextArea
          disabled={saving}
          value={stateContact || ''}
          onChange={(value) => setStateContact(value)}
          isInvalid={!!error}
        />

        {!saving && error && (
          <span className='vp-caption vtmn-text-danger'>
            {(error && error.message) || <Trans i18nKey="defaultError">Error</Trans>}
          </span>
        )}
      </div>

      <Modal.Footer>
        <VpButton
          shape="squared"
          size="small"
          variant='secondary'
          onClick={onClose}
        >
          <Trans i18nKey="dialog.cancel">Cancel</Trans>
        </VpButton>
        <VpButton
          shape="squared"
          size="small"
          onClick={() => save(contactId, siteId, stateContact, user, updateTokens)}
          disabled={saving}
        >
          <div className='vtmn-flex vtmn-flex-row vtmn-items-center'>
            {saving && <VpLoader size="sm" animation="border" variant="light" className="buttonSpinner" />}
            <Trans i18nKey="dialog.save">Save</Trans>
          </div>
        </VpButton>
      </Modal.Footer>
    </Modal>
  );
}

export default ContactDialog;
