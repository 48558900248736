import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { VpDivider } from "@vtmn-play/react";

import Modal from '../widgets/vtmn/Modal';
import ProgressBar from './ProgressBar';
import ProgressDetailsContent from './unexpectedTab/ProgressDetailsContent';

import '../../stylesheets/liveReception.css';

const ReadingRateModal = ({
  show,
  onHide,
  delivery,
  sport,
  pallet,
  indicator,
  currency,
}) => {
  const { t } = useTranslation();
  const readInWarehouseLabel = t(
    'liveStoreReception.progress.readInWarehouse',
    'Read in warehouse:',
  );
  const readByGateLabel = t(
    'liveStoreReception.progress.readInStore',
    'Read in store:',
  );
  const readByRobotLabel = t(
    'liveStoreReception.progress.readByRobot',
    'Found by stock movement:',
  );
  const confirmedMissingLabel = t(
    'liveStoreReception.progress.confirmedMissing',
    'Confirmed missing:',
  );
  const revisedAtMovementsLabel = t(
    'liveStoreReception.progress.revisedAtMovements',
    'Has movement correction:',
  );
  const notCheckedLabel = t(
    'liveStoreReception.progress.notChecked',
    'Not checked:',
  );
  let title = t(
    'liveStoreReception.readingRateModal.totalTitle',
    {
      defaultValue: 'Delivery {{delivery}} reading rate',
      delivery,
    }
  );
  if (sport) {
    title = t(
      'liveStoreReception.readingRateModal.sportTitle',
      {
        defaultValue: 'Delivery {{delivery}} - {{sport}} reading rate',
        delivery,
        sport,
      }
    );
  } else if (pallet) {
    title = t(
      'liveStoreReception.readingRateModal.palletTitle',
      {
        defaultValue: 'Delivery {{delivery}} - pallet {{pallet}} reading rate',
        delivery,
        pallet,
      }
    );
  }
  if (!indicator) {
    return null;
  }
  const {
    receivedWithTagTotalQties,
    warehouseWithTagTotalQties,
    foundAtMovementsQties,
    missingAtMovementsQties,
    revisedAtMovementsQties,
    leftQties,
    receivedValue,
    warehouseValue,
    foundAtMovementsValue,
    missingAtMovementsValue,
    revisedAtMovementsValue,
    leftValue,
  } = indicator;

  return (
    <Modal
      key="readingRateModal"
      show={show}
      onHide={onHide}
      title={title}
    >
      <div className="vtmn-flex vtmn-flex-col">
        <ProgressBar indicator={indicator} currency={currency} />
        <div className="vtmn-my-4">
          <VpDivider />
        </div>
        
        <div className="vtmn-grid vtmn-grid-cols-2">
          <span className="vtmn-text-right vtmn-pr-2">{readInWarehouseLabel}</span>
          <span>
            {warehouseWithTagTotalQties}
            {warehouseValue ? ` = ${warehouseValue} ${currency}` : null}
          </span>
        </div>
        <div className="vtmn-grid vtmn-grid-cols-2">
          <span className="vtmn-text-right vtmn-pr-2">{readByGateLabel}</span>
          <span>
            {receivedWithTagTotalQties}
            {receivedValue ? ` = ${receivedValue} ${currency}` : null}
          </span>
        </div>
        <div className="vtmn-grid vtmn-grid-cols-2">
          <span className="vtmn-text-right vtmn-pr-2">{readByRobotLabel}</span>
          <span>
            {foundAtMovementsQties}
            {foundAtMovementsValue ? ` = ${foundAtMovementsValue} ${currency}` : null}
          </span>
        </div>
        <div className="vtmn-grid vtmn-grid-cols-2">
          <span className="vtmn-text-right vtmn-pr-2">{confirmedMissingLabel}</span>
          <span>
            {missingAtMovementsQties}
            {missingAtMovementsValue ? ` = ${missingAtMovementsValue} ${currency}` : null}
          </span>
        </div>
        <div className="vtmn-grid vtmn-grid-cols-2">
          <span className="vtmn-text-right vtmn-pr-2">{revisedAtMovementsLabel}</span>
          <span>
            {revisedAtMovementsQties}
            {revisedAtMovementsValue ? ` = ${revisedAtMovementsValue} ${currency}` : null}
          </span>
        </div>
        <div className="vtmn-grid vtmn-grid-cols-2">
          <span className="vtmn-text-right vtmn-pr-2">{notCheckedLabel}</span>
          <span>
            {leftQties}
            {leftValue ? ` = ${leftValue} ${currency}` : null}
          </span>
        </div>

        {indicator && indicator.unexpected && (
          <div className="vtmn-mt-5">
            <span className="vp-subtitle-m">
              <Trans i18nKey="liveStoreReception.card.headers.unexpected">Unexpected</Trans>
            </span>
            <ProgressDetailsContent
              indicator={indicator.unexpected}
              currency={currency}
            />
          </div>
        )}
      </div>
    </Modal>
  )
};

export default ReadingRateModal;
