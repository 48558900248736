import React from 'react';
import { Trans } from 'react-i18next'

import {
  VpButton,
  VpIconButton,
} from "@vtmn-play/react";
import { VpCloseIcon } from "@vtmn-play/icons/react";
import {
  Drawer as ShadDrawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "../../../shadcn/components/ui/drawer";

const Drawer = ({
  title,
  onSave,
  open,
  onClose,
  children,
  className,
  buttonTitle,
  disabledSave = false,
}) => {
  return (
    <ShadDrawer open={open} onOpenChange={onClose} direction="right" className="vtmn-z-100">
      <DrawerContent className={className}>
        <div className="vtmn-px-7 vtmn-w-full vtmn-h-full vtmn-flex vtmn-flex-col">
          <DrawerHeader className="vtmn-flex vtmn-flex-row">
            <span className="vp-title-s vtmn-w-full vtmn-text-left">{title}</span>
            <VpIconButton
              size="small"
              variant="secondary"
              aria-label="close"
              onClick={onClose}
            >
              <VpCloseIcon />
            </VpIconButton>
          </DrawerHeader>
          <div className="vtmn-flex vtmn-flex-col vtmn-h-auto vtmn-flex-auto vtmn-overflow-auto">
            <div className="vtmn-h-auto vtmn-overflow-auto">
              {children}
            </div>
            <DrawerFooter className="vtmn-mb-3">
              <VpButton
                shape="squared"
                size="medium"
                style={{ flex: "1" }}
                onClick={onSave}
                className="vtmn-w-full"
                disabled={disabledSave}
              >
                {buttonTitle
                  ? buttonTitle
                  : (
                    <Trans i18nKey="usersManagement.save">Save</Trans>
                  )
                }
              </VpButton>
            </DrawerFooter>
          </div>
        </div>
      </DrawerContent>
    </ShadDrawer>
  )
}

export default Drawer;