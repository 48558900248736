import React, { useState } from 'react';
import {
  VpButton,
  VpCheckboxChip,
  VpBadge,
  VpFormControl,
  VpFormError,
  VpFormLabel,
} from "@vtmn-play/react";
import { VpEqualizerIcon, VpCloseIcon, VpMore2Icon, VpAlignVerticallyIcon } from "@vtmn-play/icons/react";
import { Trans } from 'react-i18next';
import { twMerge } from "tailwind-merge";

import { cn } from '../../../shadcn/lib/utils';

const FiltersPannel = ({
  filtered,
  onClear,
  mainFilters,
  aditionalFilters,
  generalFilters,
  exportButton,
  selectColumns,
  listClassName,
  className
}) => {
  const [visibility, setVisibility] = useState('partial');
  const isVisible = visibility !== 'hidden';
  const isFull = visibility === 'full';

  return (
    <div className={cn("vtmn-mb-5 vtmn-mt-3", className)}>
      <div className="vtmn-flex vtmn-flex-row">
        <div className={twMerge(
          "vtmn-flex vtmn-w-full vtmn-mb-2", 
          isVisible && "[&_.vtmx-chevron-down-line]:vtmn-rotate-180"
        )}>
          {!!selectColumns && selectColumns}
          <VpCheckboxChip
            size="small"
            className="vtmn-mb-0"
            id="checkbox-chip-example"
            name="my-input-play-name"
            checked={isVisible}
            onChange={() => {
              setVisibility(isVisible ? 'hidden' : 'partial');
            }}
          >
            <VpEqualizerIcon size={16} />
            <Trans i18nKey="filters.filters">Filters</Trans>
            {filtered && <VpBadge />}
          </VpCheckboxChip>
          {!!generalFilters && generalFilters}
        </div>
        {exportButton}
      </div>
      {isVisible && (
        <div className={cn(
          "vtmn-flex vtmn-flex-wrap vtmn-mt-3 vtmn-items-end [&>div]:vtmn-mb-3 mobile:vtmn-flex-col",
          listClassName
        )}>
          {mainFilters}
          {isFull && aditionalFilters}
          <div className="vtmn-flex vtmn-flex-row mobile:vtmn-mt-3">
            {!!aditionalFilters && (
              <VpButton
                size="small"
                shape="squared"
                variant="secondary"
                endSlot={isFull ? <VpAlignVerticallyIcon /> : <VpMore2Icon />}
                onClick={() => setVisibility(isFull ? 'partial' : 'full')}
                className="vtmn-mr-3"
              >
                {!isFull && <Trans i18nKey="filters.more">More</Trans>}
                {isFull && <Trans i18nKey="filters.less">Less</Trans>}
              </VpButton>
            )}
            {!!onClear && (
              <VpButton
                size="small"
                shape="squared"
                variant="secondary"
                startSlot={<VpCloseIcon />}
                onClick={onClear}
              >
                <Trans i18nKey="filters.clear">Clear</Trans>
              </VpButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default FiltersPannel;
