import * as React from "react"
import * as TooltipPrimitive from "@radix-ui/react-tooltip"

import { cn } from "../../lib/utils"

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Portal>
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        "vtmn-z-50 vtmn-overflow-hidden vtmn-text-white vtmn-bg-background-primary-reversed vtmn-px-3 vtmn-py-1.5 vp-caption",
        "vtmn-animate-in vtmn-fade-in-0 vtmn-zoom-in-95 data-[state=closed]:vtmn-animate-out data-[state=closed]:vtmn-fade-out-0",
        "data-[state=closed]:vtmn-zoom-out-95 data-[side=bottom]:vtmn-slide-in-from-top-2 data-[side=left]:vtmn-slide-in-from-right-2",
        "data-[side=right]:vtmn-slide-in-from-left-2 data-[side=top]:vtmn-slide-in-from-bottom-2",
        className
      )}
      {...props}
    />
  </TooltipPrimitive.Portal>
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }
