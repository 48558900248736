import React from 'react';
import { currencySymbolMap } from 'currency-symbol-map';

import Combobox from '../form/Combobox';

const currencyOptions = Object.entries(currencySymbolMap).map(([ key, value ]) => ({
  id: key,
  label: <div className="vtmn-py-2">{`${key} (${value})`}</div>,
}));

const CurrencyFilter = ({
  controlId,
  label,
  selected,
  onChange,
}) => {
  return (
    <Combobox
      label={label}
      options={currencyOptions}
      selected={selected || ''}
      onChange={onChange}
      valueClassName="[&>div]:vtmn-py-0"
      withSearch={false}
    />
  )
}

export default CurrencyFilter;
