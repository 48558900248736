import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  VpButton,
  VpIconButton,
  VpAsset,
  VpIcon,
  VpLoader,
} from "@vtmn-play/react";
import { VpAddIcon, VpEditIcon } from "@vtmn-play/icons/react";

import DataTable from "../../components/widgets/vtmn/DataTable";
import Modal from '../widgets/vtmn/Modal';
import Toggle from '../widgets/vtmn/filters/Toggle';
import ContactDialog from './ContactDialog';
import RobotForm from './RobotForm';
import RacketForm from './RacketForm';
import SettingsForm from './SettingsForm';
import ErrorMessage from '../errorMessage';
import { cn } from "../../shadcn/lib/utils";
import useRequest from '../../functions/useRequest';
import handleApiResponse from '../../functions/handleApiResponse';
import capitalize from '../../functions/capitalize';
import hasRights from '../../functions/hasRights';
import fetchWithJWT from '../../functions/fetchWithJWT';

const getStoresDeployment = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/stores/pilot/deployment`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const columns = [
  {
    field: "id",
    title: <Trans i18nKey="storesDeployment.site">Site</Trans>,
  }, {
    field: "name",
    title: <Trans i18nKey="storesDeployment.description">Description</Trans>,
    cell: ({ row }) => capitalize(row.getValue("name"))
  }, {
    field: "countryCode",
    title: <Trans i18nKey="storesDeployment.country">Country</Trans>,
    cell: ({ row }) => (
      <VpAsset className="vtmn-mr-2" name={`flag-${row.getValue("countryCode").toLowerCase()}`} width="24" />
    )
  }, {
    field: "contactInfo",
    title: <Trans i18nKey="storesDeployment.contacts">Contacts</Trans>,
    cell: ({ row, table }) => {
      const { onEditContact, canCreateRobot, canCreateRacket } = table.options.meta;

      return (
        <div className="vtmn-flex contact-wrapper">
          <div className="contact-val" onClick={() => onEditContact(row.original)} >
            {row.getValue("contactInfo")}
          </div>
          {(canCreateRobot || canCreateRacket) && (
            <VpIcon
              name="edit"
              className='vtmn-cursor-pointer'
              title={<Trans i18nKey="storesDeployment.editContactInfo">Edit contact info</Trans>}
              onClick={() => onEditContact(row.original)}
            />
          )}
        </div>
      )
    }
  }, {
    field: "robots",
    title: <Trans i18nKey="storesDeployment.robots">Robots</Trans>,
    cell: ({ row, table }) => {
      const { canCreateRobot, onRobotEdit } = table.options.meta;
      const isWarehouse = row.getValue("id").toString().startsWith('w');

      return (
        <div className="vtmn-flex vtmn-justify-center">
          {row.getValue("robots").map(r => (
            <VpButton
              size="small"
              className="nowrap"
              onClick={() => onRobotEdit(r)}
            >
              <Trans i18nKey="storesDeployment.activeRacket">Active</Trans>
            </VpButton>
          ))}
          {(!row.getValue("robots") || !row.getValue("robots").length) && (
            (canCreateRobot && !isWarehouse) ? (
              <VpIconButton
                size="small"
                variant="secondary"
                aria-label={<Trans i18nKey="storesDeployment.addRobot">Add robot</Trans>}
                onClick={() => onRobotEdit({ storeId: row.getValue("id") })}
              >
                <VpAddIcon />
              </VpIconButton>
            ) : '-'
          )}
        </div>
      )
    }
  }, {
    field: "rackets",
    title: <Trans i18nKey="storesDeployment.rackets">Rackets</Trans>,
    cell: ({ row, table }) => {
      const { canCreateRacket, onRacketEdit } = table.options.meta;

      return (
        <div className="vtmn-flex vtmn-justify-center">
          {row.getValue("rackets").map(r => (
            <VpButton
              size="small"
              className={cn("nowrap !vtmn-bg-content-positive")}
              onClick={() => onRacketEdit(r)}
              variant={r.isActive ? 'primary' : 'secondary'}
            >
              {(
                r.isActive
                  ? <Trans i18nKey="storesDeployment.activeRacket">Active</Trans>
                  : <Trans i18nKey="storesDeployment.inactiveRacket">Inactive</Trans>
              )}
            </VpButton>
          ))}
          {(!row.getValue("rackets") || !row.getValue("rackets").length) && (
            canCreateRacket ? (
              <VpIconButton
                size="small"
                variant="secondary"
                aria-label={<Trans i18nKey="storesDeployment.enableRackets">Enable rackets</Trans>}
                onClick={() => onRacketEdit({ storeId: row.getValue("id") })}
              >
                <VpAddIcon />
              </VpIconButton>
            ) : '-'
          )}
        </div>
      )
    }
  }, {
    field: "isActive",
    title: <Trans i18nKey="storesDeployment.activityState">Activity</Trans>,
    cell: ({ row, table }) => {
      const { canCreateRacket, onSwitchActive } = table.options.meta;

      return (
        <Toggle
          className="vtmn-justify-center"
          value={row.getValue("isActive") ? true : false}
          onChange={() => onSwitchActive(row.original)}
          disabled={!canCreateRacket}
        />
      )
    }
  }, {
    id: "actions",
    title: <Trans i18nKey="storesDeployment.settings">Settings</Trans>,
    cell: ({ row, table }) => {
      const { onSettingsEdit } = table.options.meta;
      const { id } = row.original;

      return (
        <div className="vtmn-flex vtmn-justify-center">
          <VpIconButton
            size="small"
            variant="secondary"
            aria-label="Edit"
            onClick={() => onSettingsEdit(id)}
          >
            <VpEditIcon />
          </VpIconButton>
        </div>
      )
    }
  }
];

const Confirm = ({ show, onCancel, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('storesDeployment.activeConfirm.warning', 'Warning')}
      show={show}
      onHide={onCancel}
    >
      <p>{t('storesDeployment.activeConfirm.text', 'You are changing the site\'s activity status.')}</p>
      <p>{t('storesDeployment.activeConfirm.continue', 'Continue?')}</p>
      <Modal.Footer>
        <VpButton
          shape="squared"
          size="small"
          variant="secondary"
          onClick={onCancel}
          className="vtmn-w-full"
        >
          <Trans i18nKey="storesDeployment.activeConfirm.close">Close</Trans>
        </VpButton>
        <VpButton
          shape="squared"
          size="small"
          onClick={onConfirm}
          className="vtmn-w-full"
        >
          <Trans i18nKey="storesDeployment.activeConfirm.confirm">Confirm</Trans>
        </VpButton>
      </Modal.Footer>
    </Modal>
  );
};

const switchActivity = (user, updateTokens, storeId, isActive) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/${storeId}/activity`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ active: !isActive }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const AdminDeployment = ({ user, updateTokens }) => {
  const canCreateRobot = hasRights(user, 'createRobot');
  const canCreateRacket = hasRights(user, 'createRacket');
  const hasPanel = canCreateRobot || canCreateRacket;
  const [data, setData] = useState(null);
  const [contact, setContact] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [switchedSite, setSwitchedSite] = useState(null);
  const [robotFormShow, setRobotFormShow] = useState(false);
  const [robotData, setRobotData] = useState({});
  const [racketFormShow, setRacketFormShow] = useState(false);
  const [racketData, setRacketData] = useState({});
  const [settingsFormShow, setSettingsFormShow] = useState(false);
  const [editableSiteId, setEditableSiteId] = useState();

  const [{
    loading,
    error,
  }, fetchStoresDeployment] = useRequest(
    getStoresDeployment, {
      onSuccess: setData,
      onError: () => setData(null),
    }
  );

  useEffect(() => {
    fetchStoresDeployment(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateActivity = () => {
    const tData = [...data];
    const record = tData.find((r) => r.id === switchedSite.id);
    if (!record) {
      return;
    }
    record.isActive = !record.isActive;
    setData(tData);
    setSwitchedSite();
  };

  const [{
    loading: loadingActivity,
    error: errorActivity,
  }, fetchActivity] = useRequest(
    switchActivity, {
      onSuccess: updateActivity
    }
  );

  const changeActivity = () => {
    if (!switchedSite || !switchedSite.id) {
      return;
    }
    fetchActivity(user, updateTokens, switchedSite.id, switchedSite.isActive);
  };

  return (
    <div className="adminDeploymentManagement mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
      {hasPanel && (
        <div className="vtmn-w-full vtmn-my-2 vtmn-flex vtmn-flex-row vtmn-justify-end vtmn-px-4">
          {canCreateRobot && (
            <VpButton
              size="small"
              shape="squared"
              startSlot={<VpAddIcon />}
              className="nowrap"
              onClick={() => setRobotFormShow(true)}
            >
              <Trans i18nKey="storesDeployment.addRobot">Add robot</Trans>
            </VpButton>
          )}
          {canCreateRacket && (
            <VpButton
              size="small"
              shape="squared"
              startSlot={<VpAddIcon />}
              className="nowrap vtmn-ml-3"
              onClick={() => setRacketFormShow(true)}
            >
              <Trans i18nKey="storesDeployment.enableRackets">Enable rackets</Trans>
            </VpButton>
          )}
        </div>
      )}

      {loading && (
        <div className="vtmn-w-full vtmn-h-full vtmn-flex vtmn-items-center vtmn-justify-center">
          <VpLoader size="extra-large" />
        </div>
      )}
      {!loadingActivity && errorActivity && <ErrorMessage error={errorActivity} />}
      {!loading && error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <DataTable
          columns={columns}
          data={data}
          meta={{
            canCreateRobot,
            canCreateRacket,
            onEditContact: (site) => setContact({
              siteId: site.id,
              contactId: site.contactId,
              contactInfo: site.contactInfo,
            }),
            onSwitchActive: (site) => {
              console.log('site', site)
              setShowConfirm(true);
              setSwitchedSite(site);
            },
            onRobotEdit: (robot) => {
              setRobotData(robot);
              setRobotFormShow(true);
            },
            onRacketEdit: (racket) => {
              setRacketData(racket)
              setRacketFormShow(true);
            },
            onSettingsEdit: (siteId) => {
              setEditableSiteId(siteId);
              setSettingsFormShow(true);
            },
          }}
        />
      )}

      <RobotForm
        user={user}
        updateTokens={updateTokens}
        data={robotData}
        isOpen={robotFormShow}
        onClose={() => {
          setRobotData({});
          setRobotFormShow(false);
        }}
        callbackFn={() => fetchStoresDeployment(user, updateTokens)}
      />
      <RacketForm
        user={user}
        updateTokens={updateTokens}
        data={racketData}
        isOpen={racketFormShow}
        onClose={() => {
          setRacketData({});
          setRacketFormShow(false);
        }}
        callbackFn={() => fetchStoresDeployment(user, updateTokens)}
      />
      <SettingsForm
        user={user}
        updateTokens={updateTokens}
        siteId={editableSiteId}
        isOpen={settingsFormShow}
        onClose={() => {
          setSettingsFormShow(false);
        }}
        callbackFn={() => fetchStoresDeployment(user, updateTokens)}
      />
      
      <ContactDialog
        user={user}
        updateTokens={updateTokens}
        siteId={contact && contact.siteId}
        contactId={contact && contact.contactId}
        contactInfo={contact && contact.contactInfo}
        open={!!contact}
        onClose={() => setContact(null)}
        onSaved={() => {
          setContact(null);
          fetchStoresDeployment(user, updateTokens);
        }}
      />
      <Confirm
        show={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => {
          setShowConfirm(false);
          changeActivity();
        }}
      />
    </div>
  );
};

export default AdminDeployment;
