import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { VpAsset } from "@vtmn-play/react";

import { cn } from "../../../../shadcn/lib/utils";
import useRequest from '../../../../functions/useRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import getQueryString from '../../../../functions/getQueryString';
import handleApiResponse from '../../../../functions/handleApiResponse';

import MultiSelectFilter from './MultiSelectFilter';
import Toggle from './Toggle';

const getAvailableStores = (user, updateTokens, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({
    filters
  });

  const url = `${process.env.REACT_APP_base_URL}/api/stores?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const getWarehouses = (user, updateTokens, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({
    filters
  });

  const url = `${process.env.REACT_APP_base_URL}/api/warehouses?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const StoreMultiSelect = ({
  user,
  updateTokens,
  label,
  selected,
  onChange,
  className,
  disabled,
  countryId,
  invertable,
  inverted,
  onInvert,
}) => {
  const { t } = useTranslation();
  const [{
    loading: storesLoading,
    data: storesData,
    error: storesError,
  }, fetchAvailableStores] = useRequest(getAvailableStores);
  const [{
    loading: warehouseLoading,
    data: warehouseData,
    error: warehouseError,
  }, fetchWarehouses] = useRequest(getWarehouses);
  const loading = storesLoading || warehouseLoading;
  const error = storesError || warehouseError;

  useEffect(() => {
    fetchAvailableStores(user, updateTokens, {countryId});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);
  useEffect(() => {
    fetchWarehouses(user, updateTokens, {countryId});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  const options = useMemo(() => {
    const stores = (storesData && storesData.map(store => ({
      label: (
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-py-2">
          <VpAsset className="vtmn-mr-2" name={`flag-${store.countryCode.toLowerCase()}`} width="24" />
          <span className='vtmn-overflow-ellipsis vtmn-overflow-hidden'>
            {store.id} - {store.name}
          </span>
        </div>
      ),
      id: store.id,
    }))) || [];
    const warehouses = (warehouseData && warehouseData.map(warehouse => ({
      label: (
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-py-2">
          <VpAsset className="vtmn-mr-2" name={`flag-${warehouse.countryCode.toLowerCase()}`} width="24" />
          <span className='vtmn-overflow-ellipsis vtmn-overflow-hidden'>
            {warehouse.id} - {warehouse.name}
          </span>
        </div>
      ),
      id: warehouse.id,
    }))) || [];
    return [
      ...stores,
      ...warehouses,
    ];
  }, [storesData, warehouseData]);

  return (
    <MultiSelectFilter
      label={(
        <div className={cn(
          "vtmn-flex vtmn-flex-row vtmn-items-center vtmn-w-full",
          invertable && "vtmn-justify-between"
        )}>
          <span>{label}</span>
          {invertable && (
            <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
              <span className="vp-caption vtmn-mr-3">
                {t('filters.excludes', ' excludes')}
              </span>
              <Toggle value={inverted || false} onChange={onInvert} />
            </div>
          )}
        </div>
      )}
      options={options}
      selected={selected}
      onChange={onChange}
      disabled={disabled}
      className={cn(
        "vtmn-min-w-[200px]",
        invertable && "[&_label]:vtmn-mb-[2px]",
        className
      )}
      valueClassName="[&_svg]:vtmn-hidden [&_button_svg]:vtmn-flex [&_span]:vtmn-w-[44px]"
    />
  )
}

export default StoreMultiSelect;
