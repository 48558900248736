import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { VpButton, VpAsset } from "@vtmn-play/react";
import { VpAddIcon } from "@vtmn-play/icons/react";

import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';

import FiltersPannel from '../widgets/vtmn/FiltersPanel';
import MultiSelectFilter from '../widgets/vtmn/filters/MultiSelectFilter';
import InputFilter from '../widgets/vtmn/filters/InputFilter';
import ErrorMessage from '../errorMessage';

export const USER_ROLES = [{
  id: 'leader',
  label: 'Country Efficiency Leader'
}, {
  id: 'manager',
  label: 'Efficiency Manager'
}]
const getCountries = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/countries?sort=name`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return countries;
};

const MainFilters = ({
  filters,
  setFilters,
  user,
  updateTokens,
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);

  const [{
    loading,
    data,
    error,
  }, fetchCountries] = useRequest(getCountries);

  useEffect(() => {
    fetchCountries(user, updateTokens);
  }, [fetchCountries, user, updateTokens]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const countryOptions = data.map(x => ({
      id: x.id,
      icon: (
        <VpAsset className="vtmn-mr-2" name={`flag-${x.code.toLowerCase()}`} width="24" />
      ),
      label: x.name,
    }));
    setOptions(countryOptions);
  }, [data]);

  const isReady = !loading && !error;

  return (
    <>
      {isReady && (
        <>
          <MultiSelectFilter
            className="vtmn-w-full vtmn-filter-min-width vtmn-filter-max-width"
            label={t('usersManagement.country', 'Country')}
            options={options}
            selected={filters.countries}
            onChange={(countries) => setFilters({
              ...filters,
              countries,
            })}
          />
          <MultiSelectFilter
            className="vtmn-w-full vtmn-filter-min-width vtmn-filter-max-width"
            label={t('usersManagement.userRole', 'User role')}
            options={USER_ROLES}
            selected={filters.roles}
            onChange={(roles) => setFilters({
              ...filters,
              roles,
            })}
          />
          <InputFilter
            className="vtmn-w-full vtmn-filter-min-width vtmn-filter-max-width"
            label={t('usersManagement.email', 'Email')}
            value={filters.email}
            onChange={(email) => setFilters({ ...filters, email })}
          />
        </>
      )}
      {error && <ErrorMessage error={error} />}
    </>
  );
};

const Filters = ({
  filters,
  setFilters,
  onClear,
  user,
  updateTokens,
  onAddUser,
}) => {

  return (
    <FiltersPannel
      onClear={onClear}
      listClassName="vtmn-flex vtmn-flex-row vtmn-flex-wrap [&>div]:vtmn-mr-5"
      mainFilters={
        <MainFilters
          filters={filters}
          setFilters={setFilters}
          user={user}
          updateTokens={updateTokens}
        />
      }
      exportButton={
        <VpButton
          size="small"
          shape="squared"
          startSlot={<VpAddIcon />}
          className="nowrap"
          onClick={() => onAddUser && onAddUser()}
        >
          <Trans i18nKey="usersManagement.addUser">Add user</Trans>
        </VpButton>
      }
    />
  );
}

export default Filters;
