import React from 'react';
import _ from 'lodash';
import { Trans } from 'react-i18next';
import SportCard from './SportCard';
import { calculateItemProps } from '../displayItem';

const sportHasVisibleItems = (sport, indicator, filters, filterValues) => {
  if (!Object.keys(indicator.items)) {
    return false;
  }
  const hasVisibleItems = Object.values(indicator.items).map((item) => {
    const { visible } = calculateItemProps({
      allParcels: item.expectedPickingLinesForThisItem,
      itemDetails: item.details,
      movement: item.movement,
      filters,
      displayFullyReceivedItems: false,
      filterValues,
    });
    return visible;
  }).filter(x => x).length > 0;

  return hasVisibleItems;
}

const UnexpectedTab = ({
  user,
  updateTokens,
  unexpectedSportItems,
  filters,
  filterValues,
  warehouseReadingActivated,
  store,
  currency,
  delivery,
}) => {

  return (
    <div className="vtmn-columns-3 mobile:vtmn-columns-1">
      {!Object.keys(unexpectedSportItems).length && (
        <div className="vp-body-s">
          <Trans i18nKey="liveStoreReception.card.noUnexpected">There is no unexpected tag read</Trans>
        </div>
      )}
      {_.map(unexpectedSportItems, (indicator, sport) => (
        sportHasVisibleItems(sport, indicator, filters, filterValues) &&
        <SportCard
          key={sport}
          user={user}
          updateTokens={updateTokens}
          sport={sport}
          store={store}
          filters={filters}
          filterValues={filterValues}
          warehouseReadingActivated={warehouseReadingActivated}
          indicator={indicator}
          currency={currency}
          delivery={delivery}
        />
      ))}
    </div>
  );
};

export default UnexpectedTab;
