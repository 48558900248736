import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import {
  VpButton,
  VpLoader,
} from "@vtmn-play/react";
import { VpArrowBackwardIcon, VpArrowForwardIcon } from "@vtmn-play/icons/react";

import useTableRequest from '../../../../functions/useTableRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import { handleApiResponse, handlePaginatedApiResponse } from '../../../../functions/handleApiResponse';
import getQueryString from '../../../../functions/getQueryString';
import { formatDate, startOfDay, endOfDay } from '../../../../functions/formatDate';
import { useUserFilters } from '../../../../functions/filtersKeeper';
import { cn } from '../../../../shadcn/lib/utils';

import UniverseFilter from '../../../widgets/vtmn/filters/UniverseFilter';
import WarehouseSectorSelect from '../../../widgets/vtmn/filters/WarehouseSectorSelect';
import Search from '../../../widgets/vtmn/filters/Search';
import DatePicker from '../../../widgets/vtmn/filters/DatePicker';
import Toggle from '../../../widgets/vtmn/filters/Toggle';
import DataTable from '../../../widgets/vtmn/DataTable';

import ErrorMessage from '../../../errorMessage';
import ReleaseNotification from '../../../releases/ReleaseNotification';
import QtyModal from './QtyModal';

const PAGE_NAME = 'contenantsControl';

const getDiscrepancyControls = (user, updateTokens, { filters, pagination }, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = {
    ...filters,
    insertDate_from: filters.insertDate ? startOfDay(new Date(filters.insertDate)) : null,
    insertDate_to: filters.insertDate ? endOfDay(new Date(filters.insertDate)) : null,
  };

  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
  })
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/controls?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const completeDiscrepancyControls = (user, updateTokens, { warehouse, confirmed, id, wrongSizeItem }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/controls/${id}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ confirmed, wrongSizeItem }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

const updateDiscrepancyControls = (user, updateTokens, { warehouse, id }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/controls/${id}/not_here`;
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

const updateDiscrepancyControlQty = (user, updateTokens, { qty, warehouse, confirmed, id }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/controls/${id}/qty`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ quantity: qty, confirmed }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const ContenantControl = ({ controlItem, onComplete, setIPValue }) => {
  const isWrongSize = controlItem.discrepancyType === 'wrong_size';
  const { wrongSizes, no_contenant } = controlItem;

  return (
    <div className="vtmn-relative vtmn-inline-block vtmn-w-full vtmn-mb-5 vtmn-shadow-100 vtmn-p-3">
      <div className='vtmn-absolute vtmn-right-3'>
        <span className='vp-caption'>
          {formatDate(new Date(controlItem.insertDate))}
        </span>
      </div>
      <div className="vtmn-flex vtmn-flex-col">
        <div className="vtmn-flex vtmn-flex-row vtmn-pr-10">
          <span className="vp-subtitle-m">
            <Trans i18nKey="warehouseContenantsControl.address">Address:</Trans>
          </span>
          <span className="vp-body-m vtmn-ml-3">{controlItem.address}</span>
        </div>
        <div className="vtmn-flex vtmn-flex-row">
          <span className="vp-subtitle-m">
            <Trans i18nKey="warehouseContenantsControl.contenant">Contenant:</Trans>
          </span>
          <span className="vp-body-m vtmn-ml-3">{controlItem.contenant}</span>
        </div>
        <div className="vtmn-flex vtmn-flex-row">
          <span className="vp-subtitle-m">
            <Trans i18nKey="warehouseContenantsControl.itemCode">Item code:</Trans>
          </span>
          <span className="vp-body-m vtmn-ml-3">{controlItem.item}</span>
        </div>
        <div className="vtmn-flex vtmn-flex-row">
          <span className="vp-subtitle-m">
            <Trans i18nKey="warehouseContenantsControl.universeLabel">Universe label:</Trans>
          </span>
          <span className="vp-body-m vtmn-ml-3">{controlItem.universeLabel}</span>
        </div>
        {isWrongSize ? (
          <>
            {wrongSizes.map(({ item }) => (
              <div className="vtmn-flex vtmn-flex-row">
                <span className="vp-subtitle-m">
                  <Trans i18nKey="warehouseContenantsControl.wrongSize">Suspected wrong item code:</Trans>
                </span>
                <span className="vp-body-m vtmn-ml-3">{item}</span>
              </div>
            ))}
            <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-mt-3">
              <VpButton
                size="small"
                shape="squared"
                className="vtmn-mr-3 vtmn-bg-success"
                variant="primary"
                onClick={() => onComplete({ id: controlItem.id, confirmed: false })}
              >
                <Trans i18nKey="warehouseContenantsControl.no">No</Trans>
              </VpButton>
              {wrongSizes.map(({ item }) => (
                <>
                  <VpButton
                    size="small"
                    shape="squared"
                    className="vtmn-mr-3 vtmn-bg-warning"
                    variant="negative"
                    onClick={() => onComplete({ id: controlItem.id, confirmed: true, wrongSizeItem: item })}
                  >
                    <Trans i18nKey="warehouseContenantsControl.yes">Yes</Trans>
                  </VpButton>
                  {!no_contenant && (
                    <VpButton
                      size="small"
                      shape="squared"
                      className="vtmn-mr-3"
                      variant="primary"
                      onClick={() => onComplete({ id: controlItem.id, notHere: true })}
                    >
                      <Trans i18nKey="warehouseContenantsControl.noContenant">No contenant</Trans>
                    </VpButton>
                  )}
                </>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="vtmn-flex vtmn-flex-row">
              <span className="vp-subtitle-m">
                <Trans i18nKey="warehouseContenantsControl.difference">Quantity difference expected</Trans>
                { controlItem.missing > 0 ? ' ≥' : ' ≤'}
              </span>
              <span className="vp-body-m vtmn-ml-3">
                {controlItem.missing > 0 ? '+' : null}
                {controlItem.missing}
              </span>
            </div>
            <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-mt-3 [&>button]:vtmn-w-full [&>button]:vtmn-whitespace-nowrap">
              <VpButton
                size="small"
                shape="squared"
                className="vtmn-mr-3 vtmn-bg-success"
                variant="primary"
                onClick={() => onComplete({ id: controlItem.id, confirmed: false })}
              >
                <Trans i18nKey="warehouseContenantsControl.qtyIsOk">Qty is ok</Trans>
              </VpButton>
              <VpButton
                size="small"
                shape="squared"
                className="vtmn-mr-3 vtmn-bg-warning"
                variant="negative"
                onClick={() => setIPValue({ id: controlItem.id, value: controlItem.missing })}
              >
                <Trans i18nKey="warehouseContenantsControl.ipPlus">IP+</Trans>
              </VpButton>
              {!no_contenant && (
                <VpButton
                  size="small"
                  shape="squared"
                  className="vtmn-mr-3"
                  variant="negative"
                  onClick={() => onComplete({ id: controlItem.id, notHere: true })}
                >
                  <Trans i18nKey="warehouseContenantsControl.noContenant">No contenant</Trans>
                </VpButton>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const ControlList = ({
  data,
  totals,
  loading,
  error,
  setPagination,
  onComplete,
  pagination,
}) => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const [editedControl, setEditedControl] = useState();
  const [ipDefaultValue, setIpDefaultValue] = useState();
  if (loading) {
    return (
      <div className="vtmn-w-full vtmn-h-screen vtmn-flex vtmn-items-center vtmn-justify-center">
        <VpLoader size="extra-large" />
      </div>
    );
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!data) {
    return;
  }
  if (data.length === 0) {
    return (
      <span className="vtmn-ml-4 vp-body-m">
        <Trans i18nKey="warehouseContenantsControl.notFound">No contenants to control found</Trans>
      </span>
    );
  }

  const setIPValue = ({ id, value }) => {
    setModalShow(true);
    setEditedControl(id);
    setIpDefaultValue(value);
  };

  const changeItemQty = (value) => {
    return onComplete({
      id: editedControl,
      newQty: value,
      confirmed: true,
    });
  };

  return (
    <>
      <div className="vtmn-columns-3 mobile:vtmn-columns-1">
        {data.map(controlItem => (
          <ContenantControl
            key={controlItem.id}
            controlItem={controlItem}
            onComplete={onComplete}
            setIPValue={setIPValue}
          />
        ))}
      </div>

      <QtyModal
        value={ipDefaultValue}
        onChange={changeItemQty}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <div className="control-mobile-pagination">
      <VpButton
          size="small"
          shape="squared"
          className="vtmn-mr-3"
          variant="primary"
          onClick={() => setPagination({ ...pagination, page: pagination.page - 1})}
          disabled={pagination.page === 0}
          startSlot={<VpArrowBackwardIcon />}
        >
          {t('warehouseContenantsControl.previous', 'Previous')}
        </VpButton>
        <span className="vp-body-m">
          {pagination.page + 1}
        </span>
        <VpButton
          size="small"
          shape="squared"
          className="vtmn-ml-3"
          variant="primary"
          onClick={() => setPagination({ ...pagination, page: pagination.page + 1})}
          disabled={pagination.page >= totals.pages - 1}
          endSlot={<VpArrowForwardIcon />}
        >
          {t('warehouseContenantsControl.next', 'Next')}
        </VpButton>
      </div>
      <div className="control-desktop-pagination">
        <DataTable.Footer
          pagination={pagination}
          totals={totals}
          setPagination={setPagination}
        />
      </div>
    </>
  );
};

const Control = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const { warehouse } = useParams();
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const initialFilters = {
    notHere: false,
  };

  const [
    { loading, error, data, totals, pagination, filters },
    fetchDiscrepancyControls, setTableFilters, setPagination
  ] = useTableRequest(getDiscrepancyControls, {
    initialState: {
      pagination: {
        page: 0,
        size: 20,
      },
      filters: initialFilters,
    }
  });

  const filtersPacking = (filters) => _.omit(filters, ['search']);
  const filtersUnpacking = (saved) => [saved];
  const {
    isLoading: isLoadingFilters,
    error: actionError,
    setFilters,
  } = useUserFilters({
    page: PAGE_NAME,
    user,
    updateTokens,
    initial: initialFilters,
    setTableFilters,
    filtersPacking,
    filtersUnpacking,
  });

  useEffect(() => {
    setSaveError(actionError);
  }, [actionError]);

  useEffect(() => {
    if (isLoadingFilters) {
      return;
    }


    fetchDiscrepancyControls(
      user,
      updateTokens,
      { filters: {
        search: filters.search,
        universeLabel: filters.universeLabel,
        sector: filters.sector,
        notHere: filters.notHere,
      }, pagination },
      warehouse,
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse, filters.universeLabel, filters.search, filters.sector, filters.notHere, pagination, isLoadingFilters]);

  const onComplete = async ({ id, confirmed, wrongSizeItem, notHere, newQty }) => {
    try {
      setSaving(true);
      if (notHere) {
        await updateDiscrepancyControls(
          user,
          updateTokens,
          { warehouse, id },
        );
      } else if (!_.isNil(newQty)) {
        await updateDiscrepancyControlQty(
          user,
          updateTokens,
          { warehouse, id, confirmed, qty: newQty },
        );
      } else {
        await completeDiscrepancyControls(
          user,
          updateTokens,
          { warehouse, id, confirmed, wrongSizeItem },
        );
      }

      setPagination({ ...pagination, page: 0 });
      setSaving(false);
      setSaveError(null);
    } catch (error) {
      setSaving(false);
      setSaveError(error);
    }
  }

  useEffect(() => {
    fetchDiscrepancyControls(
      user,
      updateTokens,
      { filters: {
        search: filters.search,
        universeLabel: filters.universeLabel,
        sector: filters.sector,
        notHere: filters.notHere,
        insertDate: filters.insertDate,
      }, pagination },
      warehouse,
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse, filters.universeLabel, filters.search, filters.sector, filters.notHere, pagination]);

  return (
    <div className="warehouseContenantsControl mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
      <div className={cn(
        "vtmn-mb-5 vtmn-px-4 vtmn-w-full vtmn-flex vtmn-flex-row vtmn-items-end [&>div]:vtmn-w-full [&>div]:vtmn-mr-5",
        "mobile:vtmn-flex-col mobile:[&>div]:!vtmn-mr-0 mobile:[&>div]:vtmn-mb-3"
      )}>
        <UniverseFilter
          user={user}
          updateTokens={updateTokens}
          selected={filters.universeLabel}
          onChange={(universeLabel) => setFilters({ ...filters, universeLabel })}
          placeholder="Universe"
        />
        <WarehouseSectorSelect
          user={user}
          updateTokens={updateTokens}
          warehouse={warehouse}
          byDiscrepancies={false}
          selected={filters.sector}
          onChange={(sector) => setFilters({ ...filters, sector })}
          placeholder="Warehouse sector"
        />
        <Search
          label={t('"warehouseContenantsControl.search', 'Search')}
          loading={loading || saving}
          value={filters.search}
          onChange={(search) => setFilters({ ...filters, search })}
          onRefresh={() => {
            setSaveError(null);
            setPagination({ ...pagination, page: 0 });
          }}
        />
        <DatePicker
          label={t('"warehouseContenantsControl.insertDate', 'Insert date')}
          value={filters.insertDate}
          onChange={(insertDate) => setFilters({ ...filters, insertDate })}
        />
        <Toggle
          className="vtmn-flex !vtmn-justify-start"
          controlId="filter-not-here"
          label={t('warehouseContenantsControl.contenantNotHere', 'Contenant not there')}
          value={filters.notHere}
          onChange={() => setFilters({
            ...filters,
            notHere: !filters.notHere,
          })}
        />
      </div>
      
      <ControlList
        data={data}
        totals={totals}
        loading={loading || saving}
        error={error || saveError}
        onComplete={onComplete}
        setPagination={setPagination}
        pagination={pagination}
      />
      <ReleaseNotification user={user} updateTokens={updateTokens} />
    </div>
  );
};

export default Control;

