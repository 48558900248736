import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useRequest from '../../../functions/useRequest';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import handleApiResponse from '../../../functions/handleApiResponse';

import Drawer from '../../widgets/vtmn/Drawer';
import InputFilter from '../../widgets/vtmn/filters/InputFilter';
import FileEditor from '../../widgets/FileEditor';

const initialContent = {
  title: '',
  description: '',
  image: '',
};

const saveSlide = ({ user, updateTokens, releaseId, content }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const slideId = content.id ? `/${content.id}` : '';
  const url = `${process.env.REACT_APP_base_URL}/api/releases/${releaseId}/slide${slideId}`;
  return fetchWithJWT(url, {
    method: content.id ? 'PUT' : 'POST',
    body: JSON.stringify(content),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const SlideDrawer = ({
  user,
  updateTokens,
  isOpen,
  onClose,
  slide,
  callbackFn,
}) => {
  const { token } = user;
  const { t } = useTranslation();
  const { releaseId } = useParams();
  const [content, setContent] = useState(initialContent);

  const title = slide && slide.id
    ? t('releases.editSlide', 'Edit slide')
    : t('releases.addSlide', 'Add slide');

  const [{
    loading: saving,
    error: saveError,
  }, doSaveSlide] = useRequest(saveSlide, {
    onSuccess: onClose,
  });
  
  useEffect(() => {
    if (!slide || !slide.id) {
      setContent(initialContent);
      return;
    }
    setContent(slide);
  }, [slide]);

  const onSave = async () => {
    await doSaveSlide({
      releaseId,
      content,
      user,
      updateTokens,
    });
    callbackFn && callbackFn();
  }
    

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={title}
      onSave={onSave}
      className="!vtmn-w-1/2"
      disabledSave={saving}
    >
      <div className="vtmn-flex vtmn-flex-row vtmn-mb-3">
        {!!content.image && (
          <div className='vtmn-shadow-100 vtmn-mr-3 vtmn-w-[200px] vtmn-min-w-[200px]'>
            <img src={`${process.env.REACT_APP_base_URL}/api/files/${content.image}?token=${token}`} alt="slide" />
          </div>
        )}
        <div className='vtmn-flex vtmn-flex-col vtmn-w-full [&>div]:vtmn-mb-3 vtmn-px-1'>
          <InputFilter
            label={t('releases.slideForm.title', 'Title')}
            value={content.title}
            onChange={(value) => setContent({ ...content, title: value })}
          />
          <InputFilter
            label={t('releases.slideForm.description', 'Description')}
            value={content.description}
            onChange={(value) => setContent({ ...content, description: value })}
          />
          <FileEditor
            id="robot-mass-plan-file"
            user={user}
            updateTokens={updateTokens}
            url={content.image}
            setUrl={(massPlanFile) => {
              setContent({ ...content, image: massPlanFile });
            }}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default SlideDrawer;
