import React from 'react';

import { Slider as ShadSlider } from "../../../../shadcn/components/ui/slider";

const Slider = ({
  min = 0,
  max = 100,
  step = 1,
  onChange,
  value,
}) => {
  return (
    <ShadSlider
      defaultValue={[value]}
      max={max}
      step={step}
      onValueChange={([ v ]) => onChange(v)}
      // onValueCommit={([ v ]) => onChange(v)}
    />
  )
}

export default Slider;
