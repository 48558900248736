import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import useRequest from '../../functions/useRequest';
import getQueryString from '../../functions/getQueryString';

import Drawer from '../widgets/vtmn/Drawer';
import CurrencyFilter from '../widgets/vtmn/filters/CurrencyFilter';
import NumberInput from '../widgets/vtmn/form/NumberInput';
import TimeDuration from '../widgets/vtmn/form/TimeDuration';
import Toggle from '../widgets/vtmn/filters/Toggle';

const getSettings = ({
  user,
  updateTokens,
  countryId,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  let queryString = getQueryString({ filters: { country_id: countryId } });
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/country/settings?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateSettings = ({
  user,
  updateTokens,
  countryId,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/countries/settings/${countryId}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const SettingsForm = ({
  user,
  updateTokens,
  countryId,
  isOpen,
  onClose,
  callbackFn,
}) => {
  console.log('countryId', countryId)
  const { t } = useTranslation();
  const [settings, setSettings] = useState({});

  const title = countryId
    ? t('efficiencyCountryDeployment.edit', 'Edit country measurement efficiency settings')
    : t('efficiencyCountryDeployment.new', 'New country measurement efficiency settings');

  const [{
    loading,
    error,
  }, fetchSettings] = useRequest(getSettings, {
    onSuccess: setSettings
  });

  useEffect(() => {
    fetchSettings({ user, updateTokens, countryId });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  const [{
    loading: saving,
    error: saveError,
  }, saveSettings] = useRequest(updateSettings, {
    onSuccess: () => {
      callbackFn && callbackFn();
      onClose && onClose();
    },
  });

  const onSave = () => saveSettings({
    countryId,
    settings,
    user,
    updateTokens,
  });

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={title}
      onSave={onSave}
    >
      <div className="vtmn-px-1 [&>div]:vtmn-mb-3">
        <NumberInput
          label={t('efficiencyCountryDeployment.settingsForm.costPerHour', 'Cost per hour')}
          step={0.01}
          min={0}
          max={1000000}
          value={settings.cost_per_hour || ''}
          onChange={(value) => setSettings({ ...settings, cost_per_hour: value || null })}
        />
        <CurrencyFilter
          label={t('efficiencyCountryDeployment.settingsForm.currency', 'Currency')}
          selected={settings.currency}
          onChange={(value) => setSettings({ ...settings, currency: value || null })}
        />
        <NumberInput
          label={t('efficiencyCountryDeployment.settingsForm.unloadingTeammates', 'Unloading teammates')}
          step={1}
          min={0}
          max={1000}
          value={settings.unloading_teammates || ''}
          onChange={(value) => setSettings({ ...settings, unloading_teammates: value || null })}
        />
        <TimeDuration
          label={t('efficiencyCountryDeployment.settingsForm.unloadingDuration', 'Unloading duration')}
          value={settings.unloading_duration || ''}
          onChange={(value) => setSettings({ ...settings, unloading_duration: value || null })}
        />
        <NumberInput
          label={t('efficiencyCountryDeployment.settingsForm.shelvingTeammates', 'Shelving teammates')}
          step={1}
          min={0}
          max={1000}
          value={settings.shelving_teammates || ''}
          onChange={(value) => setSettings({ ...settings, shelving_teammates: value || null })}
        />
        <NumberInput
          label={t('efficiencyCountryDeployment.settingsForm.shelvingProductivity', 'Shelving productivity')}
          step={0.01}
          min={0}
          max={1000000}
          value={settings.shelving_productivity || ''}
          onChange={(value) => setSettings({ ...settings, shelving_productivity: value || null })}
        />
        <Toggle
          key={`do_unloading_notify-${settings.do_unloading_notify}`}
          className="vtmn-flex !vtmn-justify-start"
          label={t('efficiencyCountryDeployment.settingsForm.unloadingNotify', 'Notify that unloading time has expired')}
          value={settings.do_unloading_notify || false}
          onChange={() => setSettings({ ...settings, do_unloading_notify: !settings.do_unloading_notify || null })}
        />
        <TimeDuration
          label={t('efficiencyCountryDeployment.settingsForm.unloadingNotifyDelay', 'Unloading time notify delay')}
          value={settings.unloading_notify_delay || ''}
          onChange={(value) => setSettings({ ...settings, unloading_notify_delay: value || null })}
        />
        <Toggle
          key={`do_total_notify-${settings.do_total_notify}`}
          className="vtmn-flex !vtmn-justify-start"
          label={t('efficiencyCountryDeployment.settingsForm.overallNotify', 'Notify that overall time has expired')}
          value={settings.do_total_notify || false}
          onChange={() => setSettings({ ...settings, do_total_notify: !settings.do_total_notify || null })}
        />
        <TimeDuration
          label={t('efficiencyCountryDeployment.settingsForm.overallNotifyDelay', 'Overall time notify delay')}
          value={settings.total_notify_delay || ''}
          onChange={(value) => setSettings({ ...settings, total_notify_delay: value || null })}
        />
        <Toggle
          key={`show_top_performer-${settings.show_top_performer}`}
          className="vtmn-flex !vtmn-justify-start"
          label={t('efficiencyCountryDeployment.settingsForm.showTopPerformer', 'Show top performer')}
          value={settings.show_top_performer || false}
          onChange={() => setSettings({ ...settings, show_top_performer: !settings.show_top_performer || null })}
        />
        
      </div>
    </Drawer>
  )
}

export default SettingsForm;