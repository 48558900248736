import React from 'react';
import { Trans } from 'react-i18next';
import { VpButton } from "@vtmn-play/react";

import Modal from './Modal';

const ErrorDialog = ({
  error,
  setError,
  title,
}) => (
  <Modal
    show={!!error}
    onHide={() => setError(null)}
    title={title || <Trans i18nKey="shared.error">Error</Trans>}
  >
    <div>
      {error?.message || <Trans i18nKey="shared.defaultError">Error occured</Trans>}
    </div>
    <Modal.Footer className="vtmn-flex vtmn-justify-center">
      <VpButton
        shape="squared"
        size="small"
        onClick={() => setError(null)}
      >
        <Trans i18nKey="shared.ok">OK</Trans>
      </VpButton>
    </Modal.Footer>
  </Modal>
);

export default ErrorDialog;

