import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { VpIcon, VpPrice, VpSticker } from "@vtmn-play/react";

import { formatDateTime } from '../../functions/formatDate';
import { cn } from '../../shadcn/lib/utils';

import LOCALES_MAP from '../widgets/vtmn/LangSelect';
import Modal from '../widgets/vtmn/Modal';
import logoRFID from '../RFID-Icon.svg';
import warehouse_inactive from '../../images/pictos_warehouse/warehouse_inactive.png';
import warehouse_checked from '../../images/pictos_warehouse/warehouse_checked.png';
import warehouse_notChecked from '../../images/pictos_warehouse/warehouse_not-checked.png';

const getValue = (value, locale, currency) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    // only show fractions digits if != 0
    minimumFractionDigits: value % 1 == 0 ? 0 : 2,
    maximumFractionDigits: value % 1 == 0 ? 0 : 2,
  }).format(value);

const ItemModal = ({
  show,
  onClose,
  itemId,
  itemLib,
  universeLabel,
  departmentLabel,
  subDepartmentLabel,
  familyLabel,
  brandName,
  pixlId,
  price,
  currency,
  modelId,
  modelLib,
  articleFlag,

  qtiesReceivedTotal,
  qtiesReadBeforeGateTotal,
  qtiesPredictedTotal,
  isFoundAtMovements,
  isMissingAtMovements,
  isRevisedAtMovements,
  movement,
  unexpected,
  unexpectedMatchesSize,
  stock = null,
}) => {
  const { t, i18n } = useTranslation();
  const currentLocale = LOCALES_MAP[i18n.resolvedLanguage];
  const picture = "https://contents.mediadecathlon.com/p" + pixlId + "/a.jpg?f=170x170";

  const displayRFID = () => {
    if (articleFlag !== 'P' && articleFlag !== 'L') {
      return (
        <img className="not-rfid" src={logoRFID} alt="RFID-icon"></img>
      )
    }
  }

  const decideWarehouseColor = () => {
    // warehouse - read more then expected
    const isGreen = qtiesPredictedTotal <= qtiesReadBeforeGateTotal;
    if (isGreen) {
      return "green";
    }

    return "red";
  }

  const warehouseColor = decideWarehouseColor();

  const displayWarehouse = (warehouseColor) => {
    switch (warehouseColor) {
      case 'green':
        return (
          <img
            className="warehouseIcon"
            src={warehouse_checked}
            alt="warehouseIcon green"
            title={t(
              'itemComponent.greenTitle',
              'All expected quantities were scanned properly at the warehouse'
            )}
          />
        );
      case 'red':
        return (
          <img
            className="warehouseIcon"
            src={warehouse_notChecked}
            alt="warehouseIcon red"
            title={t(
              'itemComponent.redTitle',
              'Not all quantities were scanned at the warehouse during picking'
            )}
          />
        );
      default:
        return (
          <img
            className="warehouseIcon"
            src={warehouse_inactive}
            alt="warehouseIcon grey"
          />
        );
    }
  }

  const warehouseSectionOfModal = (warehouseColor) => {
    return (
      <div className="warehouseSectionOfModal vp-caption">
        {displayWarehouse(warehouseColor)}
        <div>
          <Trans i18nKey="itemComponent.modal.expected">Expected</Trans>
          {' '}
          {qtiesPredictedTotal}
        </div>
        {!unexpected && (
          <div>
            <Trans i18nKey="itemComponent.modal.beforeGateRead">In warehouse</Trans>
            {' '}
            {qtiesReadBeforeGateTotal}
          </div>
        )}
        <div>
          <Trans i18nKey="itemComponent.modal.storeRead">In store</Trans>
          {' '}
          {qtiesReceivedTotal}
        </div>
        {!!movement && !!movement.delta && (
          <div>
            <Trans i18nKey="itemComponent.modal.delta">Delta</Trans>
            {' '}
            {movement.delta}
          </div>
        )}
      </div>
    )
  }

  const displayRobotIcon = (isFoundAtMovements, isMissingAtMovements, isRevisedAtMovements) => {
    if (isFoundAtMovements) {
      return (
        <div className="robotIcon">
          <i className="robotFoundIcon vtmn-icon_decision"
            title={t('itemComponent.robotFoundTitle', 'Found during inventory')}
          ></i>
        </div>
      );
    }
    if (isMissingAtMovements) {
      return (
        <div className="robotIcon">
          <i className="robotMissingIcon vtmn-icon_decision"
            title={t('itemComponent.robotMissingTitle', 'Confirmed missing due to movements')}
          ></i>
        </div>
      );
    }
    if (isRevisedAtMovements) {
      return (
        <div className="robotIcon">
          <i className="robotRevisedIcon vtmn-icon_decision"
            title={t('itemComponent.robotRevisedTitle', 'Has movement correction')}
          ></i>
        </div>
      );
    }
  }

  const displayRobotIconSection = () => {
    const hasIcon = (
      isFoundAtMovements
      || isMissingAtMovements
      || isRevisedAtMovements
    );
    if (!hasIcon || !movement) {
      return;
    }
    const controlUser = movement.lastMovementUser;
    const controlDate = movement.lastMovementDate && formatDateTime(new Date(movement.lastMovementDate));
    const controlUserLabel = t('itemComponent.controlUser', 'Control user');
    const controlDateLabel = t('itemComponent.controlDate', 'Control date');

    return (
      <>
        <br />
        <div className="displayRobotIconSection">
          {displayRobotIcon(
            isFoundAtMovements,
            isMissingAtMovements,
            isRevisedAtMovements,
          )}
          <div>
            {controlUserLabel}
            {' : '}
            {controlUser}
          </div>
          <div>
            {controlDateLabel}
            {' : '}
            {controlDate}
          </div>
        </div>
      </>
    );
  }

  const displayUnexpectedMatchesSizeIconSections = () => {
    // if (unexpectedMatchesSize && unexpectedMatchesSize.length) {
      return unexpectedMatchesSize.map(match => (
        <React.Fragment key={match.missingItemId}>
          <br />
          <div className="displayUnexpectedMatchesSizeIconSection">
            {displayUnexpectedMatchesSizeIcon(match)}
            <div>
              {t('itemComponent.unexpectedMatchesSize', 'Same missing quantity for another size of this model')}
              {': '}
              {match.missingItemId}
              {' - '}
              {match.missingItem.item_lib}
            </div>
          </div>
        </React.Fragment>
      ));
    // }
  }

  const displayUnexpectedMatchesSizeIcon = (match) => {
    const titleStart = t('itemComponent.unexpectedMatchesSize', 'Same missing quantity for another size of this model');
    const title = `${titleStart} (${match.missingItemId} - ${match.missingItem.item_lib})`;

    return (
      <div className="sizeMatchIconWrapper" key={match.missingItemId}>
        <i className="sizeMatchIcon vtmn-icon_notif_alert"
          title={title}
        ></i>
      </div>
    );
  }

  const displayStock = () => {
    if (stock !== null) {
      return (
        <div className="stock vp-body-s">
          <Trans i18nKey="itemComponent.totalStock">Total stock in CubeInStore</Trans>
          {' : '}
          {stock}
        </div>
      )
    }
  }

  return (
    <Modal
      key={itemId}
      show={show}
      onHide={(e) => onClose && onClose(e)}
      className="productModal"
      title={`${itemId} - ${itemLib}`}
    >
      <div className="vtmn-flex vtmn-flex-col">
        <div className="vtmn-flex vtmn-flex-wrap vp-body-s !vtmn-lowercase vtmn-items-center [&>svg]:vtmn-mx-1">
          {universeLabel}
          <VpIcon
            name="chevron-right"
            size="16"
          />
          {departmentLabel}
          <VpIcon
            name="chevron-right"
            size="16"
          />
          {subDepartmentLabel}
          <VpIcon
            name="chevron-right"
            size="16"
          />
          {familyLabel}
        </div>
        <div className="vtmn-flex vtmn-mt-6">
          <div className="vtmn-flex vtmn-flex-col vtmn-mr-5">
            <div className="vtmn-flex vtmn-flex-row">
              <span className="vp-overline">
                {brandName}
              </span>
              {displayRFID()}
            </div>
            <div className="vtmn-w-[178px] vtmn-relative vtmn-pt-2 vtmn-pr-2 vtmn-h-[178px]">
              <img src={picture} alt="item" />
              {price && currency && (
                <VpSticker
                  size="medium"
                  variant="primary"
                  className={cn(
                    "!vtmn-absolute vtmn-top-0 vtmn-right-0",
                  )}
                >
                  <VpPrice className="!vtmn-flex">
                    <span className="vp-subtitle-m">
                      {getValue(price, currentLocale, currency)}
                    </span>
                  </VpPrice>
                </VpSticker>
              )}
            </div>
          </div>
          <div className="vtmn-flex vtmn-flex-col">
            <span className="vp-body-s">
              {modelId} - {modelLib}
            </span>
            <br />
            {!!qtiesPredictedTotal && warehouseSectionOfModal(warehouseColor)}
            {displayRobotIconSection()}
            {unexpectedMatchesSize && displayUnexpectedMatchesSizeIconSections()}
            <br />
            {displayStock()}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ItemModal;
