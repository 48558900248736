import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { getFields, getDefaultSelection, getSelectedFields} from './fields';
import { formatDateForBigQuery, formatDateTimeForBigQuery } from '../../../../functions/formatDate';
import useTableRequest from '../../../../functions/useTableRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import { handleApiResponse, handlePaginatedApiResponse } from '../../../../functions/handleApiResponse';
import getQueryString from '../../../../functions/getQueryString';
import { useUserFilters } from '../../../../functions/filtersKeeper';
import getFiltersWithInverted from '../../../../functions/getFiltersWithInverted';

import DataTable from "../../../widgets/vtmn/DataTable";
import Tabs from '../../../widgets/vtmn/Tabs';
import Filters from './Filters';
import PriceSettingsForm from './PriceSettingsForm';

const PAGE_NAME = 'declareToControl';
const EXCLUDE_FROM_KEEPING = [
  'receptionDateFrom',
  'receptionDateTo',
  'stayedAtWarehouse',
  'realizedMovementFrom',
  'realizedMovementTo',
  'missingFrom',
  'missingTo',
  'unexpectedQtyFrom',
  'unexpectedQtyTo',
  'qtyReadByStoreGateFrom',
  'qtyReadByStoreGateTo',
  'qtyReadBeforeWarehouseGateFrom',
  'qtyReadBeforeWarehouseGateTo',
  'qtyConfirmedFrom',
  'qtyConfirmedTo',
];

const getDiscrepancies = (user, updateTokens, { filters, pagination, sort }, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (formatedFilters.receptionDateFrom) {
    formatedFilters.receptionDateFrom = formatDateForBigQuery(formatedFilters.receptionDateFrom);
  }
  if (formatedFilters.receptionDateTo) {
    formatedFilters.receptionDateTo = formatDateTimeForBigQuery(formatedFilters.receptionDateTo);
  }
  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
    sort,
  })
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const postToControl = (user, updateTokens, { warehouse, discrepancyId, address, contenant, picker, warehouseSector }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/${discrepancyId}`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({ address, contenant, picker, warehouseSector }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateControl = (user, updateTokens, { warehouse, discrepancyId, controlId, address, contenant, picker, warehouseSector }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/${discrepancyId}/${controlId}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ address, contenant, picker, warehouseSector }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const removeControl = (user, updateTokens, { warehouse, discrepancyId, controlId }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/${discrepancyId}/${controlId}`;
  return fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

const getOnInputBlur = ({ t, updateDataRow, user, updateTokens, warehouse }) => ({
  id,
  controlId,
  address,
  contenant,
  picker,
  warehouseSector,
  rowIndex,
}) => {
  if (_.isNil(address) || _.isNil(contenant) || _.isNil(picker)) {
    return;
  }
  let errorSector;
  if (warehouseSector && !/^\d{2}$/.test(warehouseSector)) {
    errorSector = t('declareToControl.errors.twoDigits', 'Only 2 digits as warehouse sector');
  }
  if (errorSector) {
    updateDataRow(rowIndex, {
      address,
      contenant,
      picker,
      warehouseSector,
      saving: false,
      error: {
        warehouseSector: errorSector,
      },
    });
    return;
  }
  updateDataRow(rowIndex, {
    address,
    contenant,
    picker,
    warehouseSector,
    saving: true,
    error: null,
  });
  (
    controlId
      ? updateControl(user, updateTokens, { warehouse, discrepancyId: id, controlId, address, contenant, picker, warehouseSector })
      : postToControl(user, updateTokens, { warehouse, discrepancyId: id, address, contenant, picker, warehouseSector })
  )
  .then(({ id: controlId }) => {
    updateDataRow(rowIndex, {
      address,
      contenant,
      controlId,
      controlStatus: 'new',
      saving: false,
      saved: true,
      error: null,
      clientSideId: undefined,
    });
  })
  .catch(error => {
    updateDataRow(rowIndex, {
      address,
      contenant,
      saving: false,
      saved: false,
      error,
    });
  });
};

const getOnCancelControl = ({ updateDataRow, user, updateTokens, warehouse, data }) => ({ id, controlId, rowIndex }) => {
  updateDataRow(rowIndex, {
    saving: true,
    saved: false,
    error: null,
  });
  removeControl(user, updateTokens, { warehouse, discrepancyId: id, controlId })
  .then(() => {
    const newIndex = data.findIndex((x) => x.controlId === controlId);
    if (newIndex !== -1) {
      updateDataRow(newIndex, {
        controlId: undefined,
        controlStatus: null,
        address: undefined,
        contenant: undefined,
        saving: false,
        saved: false,
        error: null,
      });
    }
  })
  .catch(error => {
    const newIndex = data.findIndex((x) => x.saving && x.controlId === controlId);
    updateDataRow(newIndex, {
      saving: false,
      saved: false,
      error,
    });
  });
};

const DeclareToControlTab = ({ user, updateTokens, discrepancyType }) => {
  const { t } = useTranslation();
  const { warehouse } = useParams();
  const [savedFilters, setSavedFilters] = useState({});
  const initialFilters = {
    receptionDateFrom: dayjs().startOf('day').subtract(7, 'day').toDate(),
    receptionDateTo: dayjs().endOf('day').toDate(),
  };
  // const [invertedFilters, setInvertedFilters] = useState({});
  const [showPriceSettings, setShowPriceSettings] = useState(false);
  const [
    {
      loading,
      data,
      error,
      filters,
      pagination,
      sort,
      totals,
    },
    fetchDiscrepancies,
    setTableFilters,
    setPagination,
    setSort,
    updateDataRow,
    insertDataRow,
    deleteDataRow,
  ] = useTableRequest(
    getDiscrepancies, {
      initialState: {
        filters: initialFilters,
      }
    }
  );

  const filtersPacking = (filters, invertedFilters) => ({
    ...savedFilters,
    common: {
      filters: _.omit(filters, EXCLUDE_FROM_KEEPING),
      invertedFilters: _.omit(invertedFilters, EXCLUDE_FROM_KEEPING),
    },
    [discrepancyType]: {
      filters: _.omit(
        _.pick(filters, EXCLUDE_FROM_KEEPING),
        ['receptionDateFrom', 'receptionDateTo']
      ),
      invertedFilters: _.omit(
        _.pick(invertedFilters, EXCLUDE_FROM_KEEPING),
        ['receptionDateFrom', 'receptionDateTo']
      ),
    }
  });
  const filtersUnpacking = (saved) => {
    setSavedFilters(saved);
    const formated = {
      ...saved.common.filters,
      ...(saved[discrepancyType] ? saved[discrepancyType].filters : {}),
    };

    return [
      formated,
      {
        ...saved.common.invertedFilters,
        ...(saved[discrepancyType] ? saved[discrepancyType].invertedFilters : {}),
      },
    ]
  };
  const {
    isLoading: isLoadingFilters,
    error: actionError,
    setFilters,
    invertedFilters,
    setInvertedFilters,
  } = useUserFilters({
    page: PAGE_NAME,
    user,
    updateTokens,
    initial: {
      common: {
        filters: initialFilters,
      },
    },
    setTableFilters,
    filtersPacking,
    filtersUnpacking,
  });

  const updateData = ({ page, size = pagination.size}) => {
    if (page === pagination.page) {
      fetchDiscrepancies(user, updateTokens, {
        filters: {
          ...getFiltersWithInverted(filters, invertedFilters),
          ...(discrepancyType !== 'wrong_size' ? { controllable: true } : {}),
          discrepancyType,
        },
        pagination,
        sort,
      }, warehouse);
      return;
    }
    setPagination({ page, size });
  };

  useEffect(() => {
    if (isLoadingFilters) {
      return;
    }

    updateData(pagination);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse, discrepancyType, filters, invertedFilters, pagination, sort, isLoadingFilters]);
  const onInputBlur = getOnInputBlur({ t, updateDataRow, user, updateTokens, warehouse });
  const onCancelControl = getOnCancelControl({ updateDataRow, user, updateTokens, warehouse, data });
  const allFields = getFields({
    t,
    discrepancyType,
  });
  const [fieldsSelection, setFieldsSelection] = useState(getDefaultSelection(allFields));
  const fields = getSelectedFields({
    fields: allFields,
    selection: fieldsSelection,
    t,
    insertDataRow,
    deleteDataRow,
    onCancelControl,
  });

  return (
    <>
      <div className="vtmn-px-4">
        <Filters
          user={user}
          updateTokens={updateTokens}
          discrepancyType={discrepancyType}
          filters={filters}
          setFilters={setFilters}
          onClear={() => {
            setFilters(initialFilters);
            setInvertedFilters({});
          }}
          invertedFilters={invertedFilters}
          setInvertedFilters={setInvertedFilters}
          totals={totals}
          sort={sort}
          fields={allFields}
          fieldsSelection={fieldsSelection}
          setFieldsSelection={setFieldsSelection}
          updateData={updateData}
          showPriceSettings={() => setShowPriceSettings(true)}
        />
      </div>
      <DataTable
        className="vtmn-h-full"
        columns={fields}
        data={data || []}
        totals={totals}
        pagination={pagination}
        setPagination={setPagination}
        meta={{
          sort, setSort, onInputBlur, updateDataRow
        }}
      />
      <PriceSettingsForm
        user={user}
        updateTokens={updateTokens}
        isOpen={showPriceSettings}
        onClose={() => setShowPriceSettings(false)}
      />
    </>
  )
};

const DeclareToControl = ({ user, updateTokens }) => {
  return (
    <>
      <div className="warehouseContenantsDeclareToControl mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
        <Tabs defaultValue="expedition">
          <Tabs.Header>
            <Tabs.Item value="expedition">
              <Trans i18nKey="declareToControl.expedition">Expedition</Trans>
            </Tabs.Item>
            <Tabs.Item value="unexpected">
              <Trans i18nKey="declareToControl.unexpected">Unexpected</Trans>
            </Tabs.Item>
            <Tabs.Item value="wrong_size">
              <Trans i18nKey="declareToControl.wrongSize">Wrong size</Trans>
            </Tabs.Item>
          </Tabs.Header>
          <Tabs.Content value="expedition">
            <DeclareToControlTab
              user={user}
              updateTokens={updateTokens}
              discrepancyType="expedition"
            />
          </Tabs.Content>
          <Tabs.Content value="unexpected">
            <DeclareToControlTab
              user={user}
              updateTokens={updateTokens}
              discrepancyType="unexpected"
            />
          </Tabs.Content>
          <Tabs.Content value="wrong_size">
            <DeclareToControlTab
              user={user}
              updateTokens={updateTokens}
              discrepancyType="wrong_size"
            />
          </Tabs.Content>
        </Tabs>
      </div>
    </>
  )
}

export default DeclareToControl;

