import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  VpButton,
  VpDrawer,
  VpDrawerBackdrop,
  VpDrawerContainer,
  VpDrawerDialog,
  VpDrawerHeader,
  VpDrawerBody,
  VpDrawerFooter,
  VpDrawerCloseButton,
} from "@vtmn-play/react";

import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';

import InputFilter from '../widgets/vtmn/filters/InputFilter';
import Combobox from '../widgets/vtmn/form/Combobox';
import CountryMultiSelect from '../widgets/vtmn/filters/CountryMultiSelect';
import { USER_ROLES } from './Filters';

const createSettings = ({
  user,
  updateTokens,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/users`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateSettings = ({
  user,
  updateTokens,
  userId,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/users/${userId}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const UserSettings = ({ user, updateTokens, record, isOpen, onClose, tableUpdate }) => {
  const { t } = useTranslation();
  const userId = record && record.id ? record.id : null;
  const isNew = !record || !record.id;
  const title = isNew
    ? t('usersManagement.newUser', 'New user')
    : t('usersManagement.editUser', 'Edit user');
  const [settings, setSettings] = useState(record);

  useEffect(() => {
    // if (!record) return
    let countries = [];
    if (record && record.countries) {
      countries = record.countries.map((c) => c.id);
    }
    
    setSettings({
      ...record,
      countries,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const [{
    loading: saving,
    error: saveError,
  }, saveSettings] = useRequest(isNew ? createSettings : updateSettings, {
    onSuccess: () => {
      onClose && onClose();
      tableUpdate && tableUpdate();
    },
  });

  const onSave = () => saveSettings({
    userId,
    settings,
    user,
    updateTokens,
  });

  // const hasError = !loading && !saving && (error || saveError);
  // const isReady = !loading && !saving && !error && !!settings;
  // const isValid = settings && settings.email && settings.countries && settings.countries.length;

  return (
    <VpDrawer
      open={isOpen}
      onClose={onClose}
      // onOpen={() => console.log('onOpen')}
    >
      <VpDrawerContainer>
        <VpDrawerBackdrop />
        <VpDrawerDialog aria-label="Drawer dialog for demonstration">
          <VpDrawerHeader>
            {title}
            <VpDrawerCloseButton aria-label="Close demonstration drawer" />
          </VpDrawerHeader>
          <VpDrawerBody>
            <div className="vtmn-px-1 [&>div]:vtmn-mb-3">
              <InputFilter
                className="vtmn-w-full"
                label={t('usersManagement.email', 'Email')}
                value={settings && settings.email ? settings.email : ''}
                onChange={(email) => setSettings({ ...settings, email })}
              />
              <CountryMultiSelect
                user={user}
                updateTokens={updateTokens}
                label={t('usersManagement.country', 'Country')}
                selected={settings && settings.countries ? settings.countries : []}
                onChange={(countries) => setSettings({
                  ...settings,
                  countries,
                })}
              />
              <Combobox
                className="vtmn-w-full"
                label={t('usersManagement.userRole', 'User role')}
                options={USER_ROLES}
                selected={settings && settings.countryLeader ? 'leader' : 'manager'}
                onChange={(role) => setSettings({
                  ...settings,
                  countryLeader: role === 'leader' ? true : null,
                })}
              />
            </div>
          </VpDrawerBody>
          <VpDrawerFooter>
            <VpButton
              shape="squared"
              size="medium"
              style={{ flex: "1" }}
              onClick={onSave}
            >
              <Trans i18nKey="usersManagement.save">Save</Trans>
            </VpButton>
          </VpDrawerFooter>
        </VpDrawerDialog>
      </VpDrawerContainer>
    </VpDrawer>
  );
};

export default UserSettings;
