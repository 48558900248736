import React, { useState, useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Link } from "react-router-dom";
import { VpAsset, VpIcon, VpLink, VpInput } from "@vtmn-play/react";

import { cn } from "../../../shadcn/lib/utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "../../../shadcn/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../shadcn/components/ui/popover";
import useRequest from '../../../functions/useRequest';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import { handleApiResponse } from '../../../functions/handleApiResponse';
import getPilotSites from '../../../functions/getPilotSites'

const getCountries = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/countries`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return countries;
};

const capitalize = (name) => {
  const splitted = name.split(" ");
  return splitted.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ")
};

const onSiteClick = (site) => {
  localStorage.selectedSite = JSON.stringify(site);
};

const groupByCountry = (arr) => {
  const cObj = {};
  arr.forEach((site) => {
    if (!cObj[site.countryCode]) {
      cObj[site.countryCode] = [site];
    } else {
      cObj[site.countryCode].push(site);
    }
  });
  return cObj;
};

const SiteRow = ({ site, onSelect }) => {
  const { path } = useRouteMatch();
  const { warehouse, store } = useParams();

  const wasWarehouse = !!warehouse;

  let linkto;
  if (!warehouse && !store) {
    linkto = site.isWarehouse
      ? `/${site.countryId}/warehouse/${site.id}/contenants/control`
      : `/${site.countryId}/${site.id}/real_time/store_reception`;
  } else if (wasWarehouse && site.isWarehouse) {
    linkto = path.replace('/:country/', `/${site.countryId}/`).replace('/:warehouse/', `/${site.id}/`);
  } else if (!wasWarehouse && !site.isWarehouse) {
    linkto = path.replace('/:country/', `/${site.countryId}/`).replace('/:store/', `/${site.id}/`);
  } else if (wasWarehouse && !site.isWarehouse) {
    linkto = path.replace('/warehouse', '').replace('/:country/', `/${site.countryId}/`).replace('/:warehouse/', `/${site.id}/`);
  } else {
    linkto = path.replace('/:country/', `/${site.countryId}/`).replace('/:store/', `/warehouse/${site.id}/`);
  }

  const clickHandler = () => {
    onSiteClick(site);
    onSelect && onSelect();
  }

  return (
    <Link to={linkto} onClick={clickHandler}>
      <div className="hover:vtmn-new-bg-hover-secondary-transparent vtmn-px-3 vtmn-py-2">
        <VpLink>{site.id} - {capitalize(site.name)}</VpLink>
      </div>
    </Link>
  );
}

const CountryRow = ({ code, name, id, sites, siteSearch, onSelect  }) => {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setCollapsed(siteSearch ? false : true);
  }, [siteSearch]);

  const toogle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCollapsed(!collapsed);
  }

  return (
    <div onClick={toogle}>
      <div
        className={cn(
          "vtmn-flex vtmn-flex-row vtmn-items-center vtmn-justify-between",
          "hover:vtmn-bg-transparent"
        )}
      >
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
          <VpAsset name={`flag-${code.toLowerCase()}`} />
          <span className="vtmn-ml-2">{name}</span>
        </div>
        <VpIcon
            name={!collapsed ? "chevron-up" : "chevron-down"}
            size="24"
            // className={cn(
            //   collapsed ? "vtmn-hidden" : "vtmn-block"
            // )}
          />
      </div>
      {!collapsed && (
        <div className="vtmn-flex vtmn-flex-col vtmn-mt-3">
          {sites.map((site) => (
            <SiteRow key={site.id} site={site} onSelect={onSelect} />
          ))}
        </div>
      )}
    </div>
  )
}

const SiteSelectMenu = ({ user, updateTokens, className }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { store, warehouse } = useParams();
  const [options, setOptions] = useState([]);
  const [siteSearch, setSiteSearch] = useState('');

  const [{
    loading,
    data: sites,
    error: errorSites,
  }, fetchSites] = useRequest(getPilotSites);

  const [{
    error,
    data: countries,
  }, fetchCountries] = useRequest(getCountries);

  useEffect(() => {
    fetchCountries(user, updateTokens);
    fetchSites(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!sites || !sites.length) return;
    if (!countries || !countries.length) return;

    let filtered = sites;

    if (siteSearch) {
      filtered = sites.filter(
        site => `${site.countryCode} ${site.id} - ${site.name}`.toLowerCase().indexOf(siteSearch.toLowerCase()) !== -1
      );
    }

    const cObj = groupByCountry(filtered);

    const opt = countries.filter(({ code }) => !!cObj[code]).map(({ code, name, id }) => ({
      id,
      label: (
        <CountryRow
          code={code}
          name={name}
          id={id}
          sites={cObj[code]}
          onSelect={() => setIsPopoverOpen(false)}
          siteSearch={siteSearch}
        />
      )
    }));

    setOptions(opt);
  }, [sites, countries, siteSearch]);

  const getSelectedSiteTitle = () => {
    let selectedSiteTitle = 'Not selected';

    if (!sites) {
      return selectedSiteTitle;
    }
    if (store) {
      const myStore = sites.filter(s => s.id.toString() === store);
      const storeName = (myStore[0] && myStore[0].name) || 'Unknown';
      selectedSiteTitle = (
        <>
          {myStore[0].countryCode && (
            <VpAsset
              height={20}
              className="vtmn-mr-2"
              name={`flag-${myStore[0].countryCode.toLowerCase()}`}
            />
          )}
          <span>{`${store} - ${capitalize(storeName)}`}</span>
        </>
      );
    }
    if (warehouse) {
      const myWarehouse = sites.filter(s => s.id.toString() === warehouse);
      const warehouseName = (myWarehouse[0] && myWarehouse[0].name) || 'Unknown';
      selectedSiteTitle = (
        <>
          {myWarehouse[0].countryCode && (
            <VpAsset
              height={20}
              className="vtmn-mr-2"
              name={`flag-${myWarehouse[0].countryCode.toLowerCase()}`}
            />
          )}
          <span>{`${warehouse} - ${capitalize(warehouseName)}`}</span>
        </>
      );
    }
    return selectedSiteTitle;
  };

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
    >
      <PopoverTrigger asChild>
        <div
          className={cn(
            "vtmn-flex vtmn-flex-row vtmn-items-center vtmn-justify-between vtmn-p-3 vtmn-cursor-pointer hover:vtmn-new-bg-background-brand-secondary",
            className
          )}
        >
          <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
            <VpIcon name="store" size="24" />
            <div
              className={cn(
                "vtmn-flex vtmn-flex-row vtmn-items-center vp-body-m vtmn-mx-2 nowrap",
                "vtmn-block"
              )}
              style={{transition: 'display .3s ease-in-out'}}
            >
              {getSelectedSiteTitle()}
            </div>
          </div>
          <VpIcon
            name={isPopoverOpen ? "chevron-up" : "chevron-down"}
            size="24"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="center"
        side="bottom"
        className={cn(
          "vtmn-w-auto vtmn-p-1",
          "!vtmn-z-50 vtmn-bg-white vtmn-left-0",
        )}
      >
        <Command className="vtmn-p-1">
          <VpInput
            size="small"
            placeholder="Search..."
            value={siteSearch}
            onChange={(event) => {
              if (event) setSiteSearch(event.target.value);
            }}
          />
          <CommandList>
            <CommandEmpty>Not found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.id}
                  className={cn(
                    "vtmn-cursor-pointer"
                  )}
                  value={option.id}
                  onSelect={() => {
                    setIsPopoverOpen(false)
                  }}
                >
                  <div className="vtmn-flex vtmn-flex-row vtmn-w-full">
                    <div className="vtmn-flex vtmn-flex-row vtmn-w-full vtmn-items-center vtmn-p-3">
                      <span
                        className={cn(
                          "vtmn-w-full vtmn-typo_text-2",
                        )}
                      >
                        {option.label}
                      </span>
                    </div>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export default SiteSelectMenu;