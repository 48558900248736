import React from 'react';
import { twMerge } from "tailwind-merge";
import { MultiSelect } from "../../../../shadcn/components/ui/multi-select";

const MultiSelectFilter = ({
  label,
  options = [],
  selected = [],
  onChange,
  invertable,
  inverted,
  onInvert,
  disabled,
  loading,
  error,
  placeholder,
  className,
  valueClassName,
  withSearch = true,
}) => {
  const onValueChange = (values) => {
    onChange && onChange(values);
  };

  return (
    <div className={twMerge("filter-select", className)}>
      <MultiSelect
        label={label}
        options={options || []}
        onValueChange={(values) => onValueChange(values)}
        values={selected || []}
        placeholder={placeholder}
        variant="inverted"
        maxCount={2}
        className={valueClassName}
        withSearch={withSearch}
      />
    </div>
  );
}

export default MultiSelectFilter;
