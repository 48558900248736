import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import getSymbolFromCurrency from 'currency-symbol-map';
import { VpFormControl, VpFormLabel, VpLoader, VpButton, VpIcon } from "@vtmn-play/react";
import { VpDeleteBinIcon, VpAddIcon } from "@vtmn-play/icons/react";

import fetchWithJWT from '../../../../functions/fetchWithJWT';
import handleApiResponse from '../../../../functions/handleApiResponse';
import useRequest from '../../../../functions/useRequest';

import Drawer from '../../../widgets/vtmn/Drawer';
import ErrorMessage from '../../../errorMessage';
import CurrencyFilter from '../../../widgets/vtmn/filters/CurrencyFilter';
import NumberInput from '../../../widgets/vtmn/form/NumberInput';

const getDefaultCurrency = (user, updateTokens, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/${warehouse}/currency`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
};

const getMinCessionPrices = (user, updateTokens, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/admin`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
};

const saveMinCessionPrices = (user, updateTokens, { warehouse, prices }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/admin`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify(prices),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

const CessionPriceItem = ({ price, value, currency, delPrice, onChangePrice, onChangeValue, onChangeCurrency }) => {
  const { t } = useTranslation();
  return (
    <div className='vtmn-flex vtmn-flex-row vtmn-w-full vtmn-mb-4'>
      <div className='vtmn-flex vtmn-flex-col vtmn-w-1/2 vtmn-mr-3'>
        <NumberInput
        label={t('declareToControl.uploading.minPrice', 'Minimum cession price')}
          step={0.01}
          min={0}
          max={1000000}
          value={price || 0}
          onChange={onChangePrice}
        />
        <NumberInput
          label={t('declareToControl.uploading.minValue', 'Minimum cession value')}
          step={0.01}
          min={0}
          max={1000000}
          value={value || 0}
          onChange={onChangeValue}
        />
      </div>
      <div className='vtmn-flex vtmn-flex-row vtmn-items-center vtmn-w-1/2'>
        <div className='vtmn-flex vtmn-flex-row vtmn-items-end vtmn-w-full [&>div]:!vtmn-w-full'>
          <CurrencyFilter
            label={t('declareToControl.admin.currency', 'Currency')}
            selected={currency || ''}
            onChange={onChangeCurrency}
          />
          <VpButton
            size="small"
            variant='secondary'
            shape='squared'
            className='!vtmn-self-auto vtmn-ml-3'
            onClick={delPrice}
            startSlot={<VpDeleteBinIcon />}
          />
        </div>
      </div>
    </div>
  );
};

const PriceSettingsForm = ({
  user,
  updateTokens,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const [prices, setPrices] = useState([]);
  const [success, setSuccess] = useState(false);
  const { warehouse } = useParams();

  const [{
    loading: currencyLoading,
    data: currency,
    error: currencyError,
  }, fetchDefaultCurrency] = useRequest(getDefaultCurrency);

  const [{
    loading,
    data,
    error,
  }, fetchMinCessionPrices] = useRequest(getMinCessionPrices);

  const [{
    loading: saving,
    error: saveError,
  }, savePrices] = useRequest(saveMinCessionPrices, {
    onSuccess: () => {
      setSuccess(true);
      onClose && onClose();
    },
  });

  useEffect(() => {
    fetchDefaultCurrency(user, updateTokens, warehouse);
  }, [warehouse, user, updateTokens]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    fetchMinCessionPrices(user, updateTokens, warehouse);
  }, [isOpen, warehouse, user, updateTokens]);

  useEffect(() => {
    if (data && data.length) {
      setPrices(data);
      return;
    }
    setPrices([]);
  }, [data])

  const addPrice = () => {
    setPrices([...prices, {
      price: 0,
      currency: !prices.length ? currency : null,
    }]);
  }

  const delPrice = (index) => {
    const tmpArr = [ ...prices ];
    tmpArr.splice(index, 1);
    setPrices([...tmpArr]);
  }

  const changePrice = (price, index) => {
    setPrices([
      ...prices.slice(0, index),
      price,
      ...prices.slice(index + 1),
    ]);
  }

  const onSave = () => savePrices(
    user,
    updateTokens,
    {
      warehouse,
      prices,
    }
  );

  const hasError = !!currencyError || !!error || !!saveError;
  const invalidatedPrices = prices.filter(({ price, currency }) => price <= 0 || !currency)

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={t('declareToControl.admin.title', 'Warehouse administration')}
      onSave={onSave}
      disabledSave={!!invalidatedPrices.length}
    >
      <div className="vtmn-flex vtmn-flex-col [&>div]:vtmn-mb-3 vtmn-px-1">
        {prices.map((p, i) => (
          <div key={i}>
            <CessionPriceItem
              price={p.price}
              value={p.value}
              currency={p.currency}
              delPrice={() => delPrice(i)}
              onChangePrice={(value) => changePrice(
                { ...p, price: value }, i
              )}
              onChangeValue={(value) => changePrice(
                { ...p, value: value }, i
              )}
              onChangeCurrency={(value) => changePrice(
                { ...p, currency: value }, i
              )}
            />
          </div>
        ))}
        <VpFormControl
          className="form-content"
        >
          {!prices.length && (
            <VpFormLabel>
              <Trans i18nKey="declareToControl.uploading.minPrice">
                Minimum cession price
              </Trans>
            </VpFormLabel>
          )}
          <VpButton
            size="small"
            variant='secondary'
            shape='squared'
            // className='!vtmn-self-auto vtmn-ml-3'
            onClick={addPrice}
            startSlot={<VpAddIcon />}
          />
        </VpFormControl>
        
      </div>
    </Drawer>
  )
}

export default PriceSettingsForm;
