import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../shadcn/components/ui/dialog";

const Modal = ({
  show,
  onHide,
  title,
  children,
}) => {
  return (
    <Dialog open={show} onOpenChange={(open) => onHide && onHide()}>
      <DialogContent className="sm:max-w-[425px] vtmn-overflow-auto vtmn-max-h-full">
        <DialogHeader className="vtmn-flex vtmn-flex-row ">
          {title && (
            <DialogTitle className="vp-subtitle-l vtmn-w-full vtmn-text-left vtmn-pr-7">
              {title}
            </DialogTitle>
          )}
        </DialogHeader>
        <div>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  )
}

Modal.Footer = DialogFooter;

export default Modal;
