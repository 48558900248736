import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  VpDivider, VpSticker,
} from "@vtmn-play/react";

import { formatCurrency } from '../../../functions/formatMoney';
import { getStatusOptions } from '../statuses';

import Drawer from '../../widgets/vtmn/Drawer';
import InputFilter from '../../widgets/vtmn/filters/InputFilter';
import TimeDuration from '../../widgets/vtmn/form/TimeDuration';
import Combobox from '../../widgets/vtmn/form/Combobox';
import Planned from './Planned';
import Realized from './Realized';

export const getTime = (val) => val ? new Date(val).getTime() : 0;
export const numberRenderer = (val) => _.isNil(val) ? null : Number(val.toFixed(2));

const EditForm = ({ efficiency, setEfficiency }) => {
  const { t } = useTranslation();
  if (!efficiency) {
    return null;
  }

  const currency = formatCurrency(efficiency.currency);
  const overallDuration = efficiency.shelving_finish_date
    ? (getTime(efficiency.shelving_finish_date) - getTime(efficiency.unloading_start_date)) / 1000
    : 0;
  const duration = {
    unloading: efficiency.unloading_finish_date && efficiency.unloading_start_date
      ? (getTime(efficiency.unloading_finish_date) - getTime(efficiency.unloading_start_date)) / 1000
      : 0,
    shelving: efficiency.shelving_finish_date && efficiency.shelving_start_date
      ? (getTime(efficiency.shelving_finish_date) - getTime(efficiency.shelving_start_date)) / 1000
      : 0,
  };
  duration.total = duration.unloading + duration.shelving;

  const teammateHours = {
    unloading: (efficiency.unloading_involvement
      ? _.sum(efficiency.unloading_involvement) 
      : efficiency.unloading_teammates) * (duration.unloading / 3600),
    shelving: (efficiency.shelving_involvement
      ? _.sum(efficiency.shelving_involvement) 
      : efficiency.shelving_teammates) * (duration.shelving / 3600),
  }
  const quantities = efficiency.deliveries.reduce((acc, d) => acc + d.qty, 0);
  const totalHours = teammateHours.unloading + teammateHours.shelving;
  const overallProductivity = totalHours ? quantities / totalHours : 0;

  return (
    <div className="vtmn-flex vtmn-flex-col">
      <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-mb-4">
        <span className="vp-caption vtmn-mr-3">
          {t('efficiencyAdmin.columns.deliveries', 'Deliveries')}:
        </span>
        <VpSticker size="small" variant="secondary">
          {efficiency.deliveries.map((d) => d.id).join(', ')}
        </VpSticker>
      </div>
      <div className="vtmn-flex vtmn-flex-row [&>div]:vtmn-p-1">
        <InputFilter
          label={t('efficiencyAdmin.columns.leader', 'Leader')}
          value={efficiency.leader}
          disabled={true}
        />
        <InputFilter
          label={t('efficiencyAdmin.columns.store', 'Store')}
          value={ `${efficiency.site_id} - ${efficiency.site_name}`}
          disabled={true}
        />
        <InputFilter
          label={t('efficiencyAdmin.columns.parcels', 'Parcels')}
          value={efficiency.parcels}
          disabled={true}
        />
        <InputFilter
          label={t('efficiencyAdmin.columns.pallets', 'Pallets')}
          value={efficiency.pallets}
          disabled={true}
        />
        <InputFilter
          label={`${t('efficiencyAdmin.columns.costPerHour', 'Cost per hour')} (${currency})`}
          value={efficiency.cost_per_hour}
          disabled={true}
        />
      </div>
      <div className="vtmn-flex vtmn-flex-row vtmn-w-full vtmn-my-7">
        <div className="vtmn-flex vtmn-flex-col vtmn-w-full vtmn-mr-4">
          <span className="vp-subtitle-l">Planned</span>
          <Planned efficiency={efficiency} setEfficiency={setEfficiency} />
        </div>
        <VpDivider orientation="vertical" />
        <div className="vtmn-flex vtmn-flex-col vtmn-w-full vtmn-ml-4">
          <span className="vp-subtitle-l">Realized</span>
          <Realized efficiency={efficiency} setEfficiency={setEfficiency} />
        </div>
      </div>
      
      <div className="vtmn-flex vtmn-flex-row vtmn-mt-4 [&>div]:vtmn-p-1">
        <TimeDuration
          disabled
          label={t('efficiencyAdmin.columns.totalDuration', 'Total duration')}
          value={(overallDuration > duration.total ? duration.total : overallDuration) || efficiency.total_duration}
        />
        <InputFilter
          label={`${t('efficiencyAdmin.columns.totalInvestment', 'Total investment')} (${currency})`}
          value={numberRenderer((totalHours * efficiency.cost_per_hour) || efficiency.total_investment)}
          disabled={true}
        />
        <InputFilter
          label={`${t('efficiencyAdmin.columns.totalTeammatesHours', 'Total teammates hours')}`}
          value={numberRenderer(totalHours)}
          disabled={true}
        />
        <InputFilter
          label={`${t('efficiencyAdmin.columns.overallProductivity', 'Overall productivity')}`}
          value={numberRenderer(overallProductivity)}
          disabled={true}
        />
      </div>
    </div>
  )
}

const EditDrawer = ({
  efficiency,
  onSave,
  isOpen,
  onHide,
}) => {
  const { t } = useTranslation();
  const [stateEfficiency, setStateEfficiency] = useState(efficiency);

  useEffect(() => {
    setStateEfficiency(efficiency);
  }, [efficiency]);

  return (
    <Drawer
      open={isOpen}
      onClose={onHide}
      title={(
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
          {t('efficiencyAdmin.editMeasurement', 'Edit measurement')}
          <Combobox
            options={getStatusOptions(t).map((s) => ({
              ...s,
              label: <div className="vtmn-py-2">{s.label}</div>
            }))}
            selected={stateEfficiency && stateEfficiency.status}
            onChange={(status) => setStateEfficiency({ ...efficiency, status })}
            className="vtmn-min-w-[200px] vtmn-ml-4"
            valueClassName="[&>div]:vtmn-py-0"
            withSearch={false}
          />
        </div>
      )}
      onSave={() => onSave(stateEfficiency)}
      className="!vtmn-w-2/3"
    >
      <EditForm
        efficiency={stateEfficiency}
        setEfficiency={setStateEfficiency}
      />
    </Drawer>
  )
}

export default EditDrawer;
