import React from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import {
  VpFormControl,
  VpFormError,
  VpFormHelper,
  VpFormLabel,
} from "@vtmn-play/react";

import { cn } from '../../../shadcn/lib/utils';


const DaterangeFilter = ({
  controlId,
  label,
  valueFrom,
  valueTo,
  onChange,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <VpFormControl
      className={cn(
        "vtmn-flex vtmn-flex-col form-content",
        className,
      )}
    >
      {!!label && (
        <VpFormLabel className="!vtmn-items-start">
          {label}
        </VpFormLabel>
      )}
      <div className="vtmn-flex vtmn-flex-row">
        <DatePicker
          size="sm"
          placeholderText={t('filters.from', 'From')}
          isClearable
          selected={valueFrom}
          onChange={(nextValueFrom) => onChange(nextValueFrom, valueTo)}
          dateFormat="dd/MM/yyyy"
          className='vp-input vp-input--small'
        />
        <DatePicker
          size="sm"
          placeholderText={t('filters.to', 'To')}
          isClearable
          selected={valueTo}
          onChange={(nextValueTo) => onChange(valueFrom, nextValueTo)}
          dateFormat="dd/MM/yyyy"
          className='vp-input vp-input--small'
        />
      </div>
    </VpFormControl>
  );
}

export default DaterangeFilter;
