import React, { useState } from 'react'
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import {
  VpFormControl,
  VpFormLabel,
  VpInput,
  VpButton,
  VpIconButton,
} from "@vtmn-play/react";
import { VpSubtractIcon, VpAddIcon } from "@vtmn-play/icons/react";

import { cn } from '../../../shadcn/lib/utils';
import useDebounce from '../../../functions/useDebounce';

import Modal from '../../widgets/vtmn/Modal';
import Slider from '../../widgets/vtmn/form/Slider';

const fillInvolvement = (teammates, involvement, max) => {
  let nextTeammates = teammates || 0;
  if (nextTeammates < 0) {
    nextTeammates = 0;
  }
  if (nextTeammates > max) {
    nextTeammates = max;
  }
  const nextInvolvement = involvement.slice(0, nextTeammates);
  while (nextTeammates > nextInvolvement.length) {
    nextInvolvement.push(1)
  }
  return [nextTeammates, nextInvolvement];
};

const InvolvementModal = ({
  controlId,
  label,
  onChange,
  teammates,
  involvement,
  show,
  onHide,
  maxValue,
}) => {
  const max = 100;
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(involvement.map((v) => v * maxValue));
  const [prevValue, setPrevValue] = useState(involvement.map((v) => v * maxValue));

  const teammateLabel = t('efficiencyAdmin.teammate','Teammate');

  const onEditTeammate = (val) => {
    const [nextTeammates, nextInvolvement] = fillInvolvement(val, involvement, max);
    const [ , prevInvolvement] = fillInvolvement(val, prevValue.map((x) => x / maxValue), max);
    onChange(nextTeammates, nextInvolvement);
    setLocalValue(nextInvolvement.map((v) => v * maxValue));
    setPrevValue(prevInvolvement.map((v) => v * maxValue));
  }

  const handleChange = (val, i) => {
    setLocalValue((prev) => {
      const nextValue = [...prev];
      nextValue[i] = _.round((val * 60 > maxValue ? maxValue : val * 60), 2);
      return nextValue;
    })
  }

  const close = () => {
    setPrevValue([...localValue]);
    onHide();
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={t('efficiencyAdmin.involvement', 'Involvement')}
    >
      <div className="vtmn-flex vtmn-flex-col">
        <div className="vtmn-flex vtmn-flex-row vtmn-w-full">
          <VpIconButton
            size="small"
            variant="secondary"
            aria-label="minus"
            className="!vtmn-self-end vtmn-mr-3"
            onClick={() => onEditTeammate(teammates - 1)}
          >
            <VpSubtractIcon />
          </VpIconButton>
          <VpFormControl
            className={cn("vtmn-flex vtmn-flex-col vtmn-w-full")}
          >
            <VpFormLabel className="!vtmn-items-start">
              {label}
            </VpFormLabel>
            <VpInput
              size="small"
              value={teammates}
              onChange={onEditTeammate}
            />
          </VpFormControl>
          <VpIconButton
            size="small"
            variant="secondary"
            aria-label="plus"
            className="!vtmn-self-end vtmn-ml-3"
            onClick={() => onEditTeammate(teammates + 1)}
          >
            <VpAddIcon />
          </VpIconButton>
        </div>
        {localValue.map((val, index) => {
          return (
            <div className="vtmn-flex vtmn-flex-row vtmn-w-full vtmn-items-end vtmn-mt-4">
              <VpFormControl
                className={cn("vtmn-flex vtmn-flex-col vtmn-w-full !vtmn-mb-0")}
                key={`slider-${index}`}
              >
                <VpFormLabel className="!vtmn-items-start">
                  {teammateLabel} {index + 1}
                </VpFormLabel>
                <Slider
                  value={Math.ceil((val / 60) / 5) * 5}
                  min={0}
                  // make it a multiple of 5, otherwise the slider cannot be moved to the far right position
                  max={Math.ceil((maxValue / 60) / 5) * 5}
                  step={5}
                  onChange={(val) => handleChange(val, index)}
                />
              </VpFormControl>
              <span className="vp-body-m vtmn-ml-3 vtmn-pb-1">
                {`${Math.floor(val/3600)}:${Math.ceil((val%3600) / 60).toString().padStart(2, '0')}`}
              </span>
            </div>
          )
        })}
        
      </div>
      <Modal.Footer className="vtmn-flex vtmn-justify-center">
        <VpButton
          shape="squared"
          size="small"
          onClick={close}
        >
          <Trans i18nKey="shared.ok">OK</Trans>
        </VpButton>
      </Modal.Footer>
    </Modal>
  )
}

const InvolvementInput = ({ label, teammates, involvement, onChange, controlId, maxValue, className }) => {
  const involved = Number(_.sum(involvement)?.toFixed(2)) || teammates;
  const [show, setShow] = useState(false);

  return (
    <VpFormControl
      className={cn("vtmn-flex vtmn-flex-col form-content", className)}
    >
      <VpFormLabel className="!vtmn-items-start">
        {label}
      </VpFormLabel>
      <VpInput
        size="small"
        value={`${teammates} (${involved})`}
        onChange={(e) => {
          setShow(true)
        }}
        onClick={() => setShow(true)}
        className="!vtmn-cursor-pointer"
      />
      <InvolvementModal
        label={label}
        show={show}
        onHide={() => setShow(false)}
        onChange={onChange}
        teammates={teammates}
        involvement={involvement}
        maxValue={maxValue}
      />
    </VpFormControl>
  )
}

export default InvolvementInput;
