import React, { useState, useEffect } from 'react';
import {
  VpAsset,
} from "@vtmn-play/react";

import { cn } from "../../../../shadcn/lib/utils";
import useRequest from '../../../../functions/useRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import handleApiResponse from '../../../../functions/handleApiResponse';

import Combobox from '../form/Combobox';

const getCountries = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/countries?sort=name`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return countries;
};

const CountrySelect = ({ user, updateTokens, label, selected, onChange, disabled, className }) => {
  const [options, setOptions] = useState([]);

  const [{
    loading,
    data,
    error,
  }, fetchCountries] = useRequest(getCountries);
  
  useEffect(() => {
    fetchCountries(user, updateTokens);
  }, [fetchCountries, user, updateTokens]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const countryOptions = data.map(x => ({
      id: x.id,
      code: x.code,
      label: (
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-py-2">
          <VpAsset className="vtmn-mr-2" name={`flag-${x.code.toLowerCase()}`} width="24" />
          {x.name}
        </div>
      ),
    }));
    setOptions(countryOptions);
  }, [data]);
  
  return (
    <Combobox
      options={options}
      selected={selected}
      onChange={onChange}
      withSearch={false}
      valueClassName="[&>div]:vtmn-py-0"
      label={label}
      className={cn("vtmn-min-w-[200px]", className)}
      disabled={disabled}
    />
  )
}

export default CountrySelect;
