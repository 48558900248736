import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { VpIconButton, VpDivider, VpIcon, VpBreadcrumbs, VpBreadcrumbsItem, VpBadge } from "@vtmn-play/react";
import { VpLogoutIcon } from "@vtmn-play/icons/react";
import { Trans, useTranslation } from 'react-i18next';

import { cn } from '../../../shadcn/lib/utils';
import useRequest from '../../../functions/useRequest';
import getStoreFeatures from '../../../functions/getStoreFeatures';
import getStoreNotifications from '../../../functions/getStoreNotifications';

import LangSelect from './LangSelect';
import RoleSelect from './RoleSelect';
import SiteSelectMenu from './SiteSelectMenu';
import logoEnd2end from '../../logoEnd2end-nobg.png';
import smallLogo from '../../logo-nobg.png';
import DecathlonLogo from '../../../images/dcthl.svg';

const breadcrumbsDict = {
  '/admin/store/monitoring/robots': ["RFID Delivery control", "Monitoring robots"],
  '/admin/store/monitoring': ["RFID Delivery control", "Monitoring"],
  '/admin/reporting/discrepancies': ["RFID Delivery control", "Store discrepancies"],
  '/admin/reporting/usage_monitoring': ["RFID Delivery control", "Usage monitoring dashboard"],
  '/admin/reporting/efficiency': ["Efficiency", "Country efficiency"],
  '/reporting/efficiency': ["Efficiency", "Store efficiency"],
  '/admin/efficiency/deployment': ["Efficiency", "Stores deployment"],
  '/admin/efficiency/country/deployment': ["Efficiency", "Country settings"],
  '/admin/efficiency/efficiency_monitoring': ["Efficiency", "Metabase dashboard"],
  '/admin/efficiency': ["Efficiency", "Records Edit"],
  '/admin/preload/state': ["Admin", "Preload states"],
  '/admin/store/deployment': ["Admin", "Deployment"],
  '/admin/users': ["Admin", "Users"],
  '/admin/store_groups': ["Admin", "Group of stores"],
  '/admin/releases': ["Admin", "Releases"],
  '/efficiency/measurements': ["Efficiency", "Records Edit"],
  '/reporting/efficiency_monitoring': ["Efficiency", "Metabase dashboard"],
  '/real_time/store_reception': ["RFID Delivery control", "Control receptions"],
  '/reporting/discrepancies': ["RFID Delivery control", "Discrepancies"],
  '/monitoring/gate': ["RFID Delivery control", "Monitoring delivery control"],
  '/reporting/usage_monitoring': ["RFID Delivery control", "Metabase dashboard"],
  '/monitoring/robot': ["RFID Robot", "Robot inventories"],
  '/contenants/declare': ["RFID Delivery control", "Declare to control"],
  '/contenants/control': ["RFID Delivery control", "Control"],
  '/contenants/report': ["RFID Delivery control", "Picking control results"],
  '/reporting/wrongStore': ["RFID Delivery control", "Sent to wrong store"],
  '/reporting/usage_monitoring': ["Monitoring", "Usage monitoring dashboard"],
  '/releases': ["Help", "Releases"],
}

const getBreadCrumbs = () => {
  const url = window.location.pathname;
  const crumbs = Object.keys(breadcrumbsDict).find((b) => {
    const regex = new RegExp(b);
    return regex.test(url);
  })

  return breadcrumbsDict[crumbs];
}

const openDocumentation = (language) => {
  const links = [
    {
      language: 'EN',
      link:
        'https://docs.google.com/presentation/d/1qhdDH8iVUVPeQB8lpVWqmIGBon1IE07EnV3u02tYvlg',
    },
  ]
  window.open(links.find((link) => link.language === language).link)
}

const SubItem = ({ children, link, onClick, afterClick }) => {
  return (
    <div className="vtmn-pl-7 vtmn-flex vtmn-flex-row vtmn-items-center vtmn-justify-between vtmn-p-3 vtmn-cursor-pointer hover:vtmn-new-bg-background-brand-secondary">
      <div className="vtmn-flex vtmn-ml-1 vtmn-flex-row vtmn-items-center">
        {link && (
          <Link to={link} className="vp-body-m" onClick={afterClick}>
            <span className="vp-body-m nowrap">{children}</span>
          </Link>
        )}
        {onClick && !link && (
          <span className="vp-body-m nowrap" onClick={onClick}>
            {children}
          </span>
        )}
      </div>
    </div>
  )
}

const useOutsideHandler = (ref, callbackFn) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callbackFn && callbackFn();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Item = ({ label, collapsed, icon, children }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideHandler(wrapperRef, () => setShowSideMenu(false));

  useEffect(() => {
    setShowSideMenu(false);
    setShowSubMenu(false);
  }, [collapsed]);

  return (
    <div ref={wrapperRef}>
      <div
        className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-justify-between vtmn-p-3 vtmn-cursor-pointer hover:vtmn-new-bg-background-brand-secondary"
        onClick={() => !collapsed ? setShowSubMenu(!showSubMenu) : setShowSideMenu(!showSideMenu)}
      >
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
          <VpIcon name={icon} size="24" />
          <span
            className={cn(
              "vp-body-m vtmn-mx-2 nowrap",
              collapsed ? "vtmn-hidden" : "vtmn-block"
            )}
            style={{transition: 'display .3s ease-in-out'}}
          >
            {label}
          </span>
        </div>
        <VpIcon
          name={showSubMenu ? "chevron-up" : "chevron-down"}
          size="24"
          className={cn(
            collapsed ? "vtmn-hidden" : "vtmn-block"
          )}
        />
      </div>
      {children && (
        <div
          className={cn(
            "vtmn-flex-col",
            collapsed ? "vtmn-hidden" : "vtmn-flex",
            showSubMenu ? "vtmn-flex vtmn-h-auto" : "vtmn-hidden vtmn-h-0"
          )}
          style={{transition: 'height .3s ease-in-out, display .3s ease-in-out'}}
        >
          {children}
        </div>
      )}
      {collapsed && showSideMenu && children && (
        <div
          className="vtmn-z-50 vtmn-absolute vtmn-shadow-100 vtmn-new-bg-background-secondary vtmn-ml-8 [&>div]:vtmn-pl-4"
          style={{marginTop: '-48px'}}
        >
          {children}
        </div>
      )}
    </div>
  )
}

const AdminMenu = ({ user, updateTokens, collapsed, afterClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <Item
        label={t('navbar.rfidDeliveryControl', 'RFID Delivery control')}
        collapsed={collapsed}
        icon="package"
      >
        <SubItem afterClick={afterClick} link="/admin/store/monitoring">
          <Trans i18nKey="navbar.administrating.storeMonitoring">
            Monitoring
          </Trans>
        </SubItem>
        <SubItem afterClick={afterClick} link="/admin/store/monitoring/robots">
          <Trans i18nKey="navbar.administrating.storeMonitoringRobots">
            Monitoring robots
          </Trans>
        </SubItem>
        <SubItem afterClick={afterClick} link="/admin/reporting/discrepancies">
          <Trans i18nKey="navbar.administrating.reporting.discrepancies">
            Store discrepancies
          </Trans>
        </SubItem>
        <SubItem afterClick={afterClick} link="/admin/reporting/usage_monitoring">
          <Trans i18nKey="navbar.administrating.reporting.usageMonitoring">
            Usage monitoring dashboard
          </Trans>
        </SubItem>
      </Item>
      <Item label={t('navbar.efficiency', 'Efficiency')} collapsed={collapsed} icon="timer">
        <SubItem afterClick={afterClick} link="/admin/reporting/efficiency">
          <Trans i18nKey="efficiencyDashboard.navTitle">
            Country efficiency
          </Trans>
        </SubItem>
        {user.rights.indexOf('adminEfficiency') > -1 && (
          <SubItem afterClick={afterClick} link="/admin/efficiency">
            <Trans i18nKey="efficiencyAdmin.navTitle">
              Records Edit
            </Trans>
          </SubItem>
        )}
        {user.rights.indexOf('adminEfficiency') > -1 && (
          <SubItem afterClick={afterClick} link="/admin/efficiency/deployment">
            <Trans i18nKey="navbar.administrating.efficiencyManagement">
              Stores deployment
            </Trans>
          </SubItem>
        )}
        {user.rights.indexOf('adminEfficiency') > -1 && (
          <SubItem afterClick={afterClick} link="/admin/efficiency/country/deployment">
            <Trans i18nKey="navbar.administrating.countryEfficiency">
              Country settings
            </Trans>
          </SubItem>
        )}
        {user.rights.indexOf('adminEfficiency') > -1 && (
          <SubItem afterClick={afterClick} link="/admin/efficiency/efficiency_monitoring">
            <Trans i18nKey="navbar.efficiencyDashboard">
              Metabase dashboard
            </Trans>
          </SubItem>
        )}
      </Item>
      <Item label={t('navbar.administrating.admin', 'Admin')} collapsed={collapsed} icon="settings">
        {user.rights.indexOf('showPreloads') > -1 && (
          <SubItem afterClick={afterClick} link="/admin/preload/state">
            <Trans i18nKey="navbar.administrating.reporting.preloadStates">
              Preload states
            </Trans>
          </SubItem>
        )}
        <SubItem afterClick={afterClick} link="/admin/store/deployment">
          <Trans i18nKey="navbar.administrating.deploymentManagement">
            Deployment
          </Trans>
        </SubItem>
        {(user.rights.indexOf('userManagement') > -1 ||
          user.rights.indexOf('userManagementCountry') > -1) &&
        (
          <SubItem afterClick={afterClick} link="/admin/users">
            <Trans i18nKey="navbar.administrating.usersManagement">
              Users
            </Trans>
          </SubItem>
        )}
        {user.rights.indexOf('storeGroupManagement') > -1 && (
          <SubItem afterClick={afterClick} link="/admin/store_groups">
            <Trans i18nKey="navbar.administrating.groupOfStores">
              Group of stores
            </Trans>
          </SubItem>
        )}
        {user.rights.indexOf('changeLogManagement') > -1 && (
          <SubItem afterClick={afterClick} link="/admin/releases">
            <Trans i18nKey="navbar.administrating.releases">
              Releases
            </Trans>
          </SubItem>
        )}
      </Item>
      
      <VpDivider className="!vtmn-my-3" />
      <Item label={t('navbar.help.toggle', 'Help')} collapsed={collapsed} icon="question">
        <SubItem onClick={() => openDocumentation('EN')}>
          <Trans i18nKey="navbar.help.english">User guide in English</Trans>
        </SubItem>
      </Item>
    </>
  )
}

const SiteMenu = ({ user, updateTokens, collapsed, afterClick }) => {
  const { t } = useTranslation();
  const { store, country } = useParams();
  const [storeFeatures, setStoreFeatures] = useState({
    hasRobot: false,
    hasGate: false,
    hasEfficiency: false,
  });
  const [storeNotifications, setStoreNotifications] = useState([]);
  
  const [{
    loading: loadingFeatures,
    error: errorFeatures,
  }, fetchStoreFeatures] = useRequest(getStoreFeatures, {
    onSuccess: setStoreFeatures,
  });
  const [{
    loading: loadingNotifications,
    error: errorNotifications,
  }, fetchStoreNotifications] = useRequest(getStoreNotifications, {
    onSuccess: setStoreNotifications,
  });

  useEffect(() => {
    fetchStoreFeatures(store, user, updateTokens);
    fetchStoreNotifications(store, user, updateTokens);
  }, []);

  const getMonitoringNotificationCount = () => {
    const monitoringNotifications = [
      'GATE_PERCENT_OF_READ',
      'ROBOT_PERCENT_OF_MAX',
    ];
    const count = storeNotifications.filter(
      (notification) =>
        monitoringNotifications.indexOf(notification.code) !== -1,
    ).length;
    return count;
  }

  const hasNotification = (code) => {
    const has = !!storeNotifications.find(
      (notification) => notification.code === code,
    )
    return has
  }

  const getNotificationTooltip = (code) => {
    const notification = storeNotifications.find(
      (notification) => notification.code === code,
    )
    let tooltip = ''
    if (notification) {
      const { severity } = notification
      if (code === 'GATE_PERCENT_OF_READ') {
        if (severity === 'danger') {
          tooltip = t(
            'monitoring.tooltips.gateLess50',
            'last read less then 50% of expected',
          )
        } else {
          tooltip = t(
            'monitoring.tooltips.gateLess90',
            'last read less then 90% of expected',
          )
        }
      } else if (code === 'ROBOT_PERCENT_OF_MAX') {
        if (severity === 'danger') {
          tooltip = t(
            'monitoring.tooltips.robotLess50',
            'last read less then 50% of max',
          )
        } else {
          tooltip = t(
            'monitoring.tooltips.robotLess90',
            'last read less then 90% of max',
          )
        }
      }
    }
    return tooltip
  }

  const monitoringNotificationCount = getMonitoringNotificationCount();
  const hasRobotNotification = hasNotification('ROBOT_PERCENT_OF_MAX');
  const robotNotificationTooltip = getNotificationTooltip(
    'ROBOT_PERCENT_OF_MAX',
  );

  return (
    <>
      <SiteSelectMenu user={user} updateTokens={updateTokens} collapsed={collapsed} className="vtmn-hidden mobile:vtmn-flex" />
      {storeFeatures.hasEfficiency && (
        <Item
          label={t('navbar.efficiency', 'Efficiency')}
          collapsed={collapsed}
          icon="timer"
        >
          <SubItem afterClick={afterClick} link={`/${country}/${store}/reporting/efficiency`}>
            <Trans i18nKey="navbar.control.storeEfficiency">
              Store efficiency
            </Trans>
          </SubItem>
          {user.rights.indexOf('countryEfficiency') > -1 && (
            <SubItem afterClick={afterClick} link={`/${country}/${store}/admin/reporting/efficiency`}>
              <Trans i18nKey="navbar.countryEfficiency">
                Country efficiency
              </Trans>
            </SubItem>
          )}
          {user.rights.indexOf('editEfficiencyMeasurement') > -1 && (
            <SubItem afterClick={afterClick} link={`/${country}/${store}/efficiency/measurements`}>
              <Trans i18nKey="efficiencyAdmin.navTitle">
                Records Edit
              </Trans>
            </SubItem>
          )}
          {user.rights.indexOf('editEfficiencyMeasurement') > -1 && (
            <SubItem afterClick={afterClick} link={`/${country}/${store}/reporting/efficiency_monitoring`}>
              <Trans i18nKey="navbar.efficiencyDashboard">
                Metabase dashboard
              </Trans>
            </SubItem>
          )}
        </Item>
      )}
      {(storeFeatures.hasRobot || storeFeatures.hasRacket) && (
        <Item
          label={t('navbar.rfidDelivery', 'RFID Delivery control')}
          collapsed={collapsed}
          icon="package"
        >
          <SubItem afterClick={afterClick} link={`/${country}/${store}/real_time/store_reception`}>
            <Trans i18nKey="navbar.realTime.storeDelivery">
              Control receptions
            </Trans>
          </SubItem>
          <SubItem afterClick={afterClick} link={`/${country}/${store}/reporting/discrepancies`}>
            <Trans i18nKey="navbar.reporting.discrepancies">
              Discrepancies
            </Trans>
          </SubItem>
          <SubItem afterClick={afterClick} link={`/${country}/${store}/monitoring/gate`}>
            <Trans i18nKey="navbar.monitoring.rfidMonitoring">
              Monitoring delivery control
            </Trans>
          </SubItem>
          <SubItem afterClick={afterClick} link={`/${country}/${store}/reporting/usage_monitoring`}>
            <Trans i18nKey="navbar.reporting.usageMonitoring">
              Metabase dashboard
            </Trans>
          </SubItem>
        </Item>
      )}
      {storeFeatures.hasRobot && (
        <Item
          label={
            <div className="vtmn-relative">
              <Trans i18nKey="navbar.rfidRobot">RFID Robot</Trans>
              {!!monitoringNotificationCount && (
                <span className="vtmn-ml-3 vtmn-absolute vtmn-top-0">
                  <VpBadge>
                    {monitoringNotificationCount}
                  </VpBadge>
                </span>
              )}
            </div>
          }
          collapsed={collapsed}
          icon="scan-code"
        >
          <SubItem afterClick={afterClick} link={`/${country}/${store}/monitoring/robot`}>
            <Trans i18nKey="navbar.monitoring.robot">Robot inventories</Trans>
            {hasRobotNotification && (
              <i
                className="notification icon vtmn-icon_notif_information"
                title={robotNotificationTooltip}
              ></i>
            )}
          </SubItem>
        </Item>
      )}
      
      <VpDivider className="!vtmn-my-3" />
      <Item label={t('navbar.help.toggle', 'Help')} collapsed={collapsed} icon="question">
        <SubItem afterClick={afterClick} link={`/${country}/${store}/releases`}>
          <Trans i18nKey="navbar.administrating.releases">
            Releases
          </Trans>
        </SubItem>
        <SubItem onClick={() => openDocumentation('EN')}>
          <Trans i18nKey="navbar.help.english">User guide in English</Trans>
        </SubItem>
      </Item>
    </>
  )
}

const WarehouseMenu = ({ user, updateTokens, collapsed, afterClick }) => {
  const { t } = useTranslation();
  const { warehouse, country } = useParams();

  return (
    <>
      <SiteSelectMenu user={user} updateTokens={updateTokens} collapsed={collapsed} className="vtmn-hidden mobile:vtmn-flex"/>
      <Item
        label={t('navbar.rfidDeliveryControl', 'RFID Delivery control')}
        collapsed={collapsed}
        icon="package"
      >
        <SubItem afterClick={afterClick} link={`/${country}/warehouse/${warehouse}/contenants/declare`}>
          <Trans i18nKey="navbar.warehouse.contenants.declareToControl">
            Declare to control
          </Trans>
        </SubItem>
        <SubItem afterClick={afterClick} link={`/${country}/warehouse/${warehouse}/contenants/control`}>
          <Trans i18nKey="navbar.warehouse.contenants.control">
            Control
          </Trans>
        </SubItem>
        <SubItem afterClick={afterClick} link={`/${country}/warehouse/${warehouse}/contenants/report`}>
        <Trans i18nKey="navbar.warehouse.contenants.report">
          Picking control results
        </Trans>
        </SubItem>
        <SubItem afterClick={afterClick} link={`/${country}/warehouse/${warehouse}/reporting/wrongStore`}>
          <Trans i18nKey="navbar.warehouse.contenants.wrongStore">
            Sent to wrong store
          </Trans>
        </SubItem>
        <SubItem afterClick={afterClick} link={`/${country}/warehouse/${warehouse}/reporting/discrepancies`}>
          <Trans i18nKey="navbar.warehouse.contenants.discrepancies">
            Discrepancies
          </Trans>
        </SubItem>
      </Item>

      <Item
        label={t('navbar.warehouse.monitoring.toggle', 'Monitoring')}
        collapsed={collapsed}
        icon="bar-chart"
      >
        <SubItem afterClick={afterClick} link={`/${country}/warehouse/${warehouse}/reporting/usage_monitoring`}>
          <Trans i18nKey="navbar.warehouse.monitoring.usageMonitoring">
            Usage monitoring dashboard
          </Trans>
        </SubItem>
      </Item>

      <VpDivider className="!vtmn-my-3" />
      <Item label={t('navbar.help.toggle', 'Help')} collapsed={collapsed} icon="question">
        <SubItem afterClick={afterClick} link={`/${country}/${warehouse}/releases`}>
          <Trans i18nKey="navbar.administrating.releases">
            Releases
          </Trans>
        </SubItem>
        <SubItem afterClick={afterClick} onClick={() => openDocumentation('EN')}>
          <Trans i18nKey="navbar.help.english">User guide in English</Trans>
        </SubItem>
      </Item>
    </>
  )
}

const Navigation = ({ user, updateTokens, logOut }) => {
  const isMobile = window.innerWidth < 600;
  const [collapsed, setCollapsed] = useState(isMobile);
  const { warehouse, store } = useParams();
  const isStoreSelected = !!store;
  const isWarehouseSelected = !!warehouse;

  const crumbs = getBreadCrumbs();

  const toogleMenu = () => {
    setCollapsed(!collapsed);
  }

  return (
    <>
      <div className="vtmn-w-full !vtmn-fixed vtmn-z-50 vtmn-background-white">
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-justify-start vtmn-h-[47px] mobile:vtmn-justify-between">
          <div
            className={cn(
              "vtmn-flex vtmn-px-3 vtmn-cursor-pointer",
              "mobile:vtmn-mb-0"
            )}
            onClick={toogleMenu}
          >
            <VpIcon
              name="menu"
              size="24"
            />
          </div>
          <img src={DecathlonLogo} alt="logo Decathlon" className="vtmn-mx-5 mobile:vtmn-mx-0" />
          <div className="vtmn-h-full">
            <Link to="/">
              <img className="vtmn-max-h-full vtmn-max-w-[120px]" src={logoEnd2end}  alt="logo end2end" />
            </Link>
          </div>
          <div className="vtmn-flex vtmn-flex-row vtmn-justify-between vtmn-w-full vtmn-items-center mobile:vtmn-hidden">
            <div className="vtmn-h-full">
              {!!crumbs && (
                <VpBreadcrumbs aria-label="Breadcrumbs" className="!vtmn-pl-[64px]">
                  {crumbs.map((c) => (
                    <VpBreadcrumbsItem>{c}</VpBreadcrumbsItem>
                  ))}
                </VpBreadcrumbs>
              )}
            </div>
            <SiteSelectMenu user={user} updateTokens={updateTokens} collapsed={collapsed} className="mobile:vtmn-hidden" />
          </div>
        </div>
        <VpDivider />
      </div>

      <div className={cn(
        "vtmn-flex vtmn-flex-row z-index-49",
        collapsed ? "vtmn-w-[48px] mobile:vtmn-hidden" : "vtmn-min-w-1/5",
      )}>
        <div
          className={cn(
            "vtmn-justify-between vtmn-h-screen vtmn-left-0 vtmn-flex vtmn-flex-col vtmn-items-center vtmn-overflow-auto [&_span]:vtmn-select-none",
            "mobile:vtmn-left-auto mobile:vtmn-top-8",
            "mobile:vtmn-w-full mobile:vtmn-absolute",
            !collapsed && "vtmn-bg-white mobile:vtmn-z-50"
          )}
          style={{transition: 'width .3s ease-in-out'}}
        >
          <div className="vtmn-fixed vtmn-h-screen vtmn-contents mobile:vtmn-flex-row">
            <div className={cn(
              "vtmn-w-full vtmn-mt-[48px] mobile:vtmn-mt-0",
              collapsed ? "mobile:vtmn-flex mobile:vtmn-flex-row-reverse" : ""
            )}>
              <div className={cn(
                " vtmn-flex vtmn-w-full vtmn-items-center",
                collapsed ? "mobile:vtmn-hidden" : ""
              )}>
                <div className="vtmn-w-full">
                  {isStoreSelected && (
                    <SiteMenu
                      user={user}
                      updateTokens={updateTokens}
                      collapsed={collapsed}
                      afterClick={() => isMobile && toogleMenu()}
                    />
                  )}
                  {isWarehouseSelected && (
                    <WarehouseMenu
                      user={user}
                      updateTokens={updateTokens}
                      collapsed={collapsed}
                      afterClick={() => isMobile && toogleMenu()}
                    />
                  )}
                  {!isStoreSelected && !isWarehouseSelected && (
                    <AdminMenu
                      user={user}
                      updateTokens={updateTokens}
                      collapsed={collapsed}
                      afterClick={() => isMobile && toogleMenu()}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={cn(
              "vtmn-flex vtmn-w-full vtmn-px-3 vtmn-flex-row vtmn-h-6 vtmn-mb-3",
              collapsed ? "mobile:vtmn-hidden" : ""
            )}>
              {!collapsed && (
                <div className="vtmn-flex vtmn-w-full vtmn-flex-row vtmn-items-center vtmn-justify-between">
                  <VpIconButton
                    size="small"
                    variant="ghost"
                    aria-label="logout"
                    onClick={logOut}
                    // className="vtmn-mr-5"
                  >
                    <VpLogoutIcon />
                  </VpIconButton>
                  <RoleSelect
                    user={user}
                    updateTokens={updateTokens}
                  />
                  <LangSelect />
                </div>
              )}
            </div>
          </div>
        </div>
        <VpDivider orientation="vertical" />
      </div>
      
    </>
    
  )
}

export default Navigation;
