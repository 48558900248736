import * as React from "react"

import { cn } from "../../lib/utils"

const Table = React.forwardRef(({ className, ...props }, ref) => (
  <div className="vtmn-relative vtmn-w-full vtmn-overflow-auto">
    <table
      ref={ref}
      className={cn("vtmn-w-full vtmn-caption-bottom vtmn-text-sm", className)}
      {...props} />
  </div>
))
Table.displayName = "Table"

const TableHeader = React.forwardRef(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn("[&_tr]:vtmn-border-b", className)} {...props} />
))
TableHeader.displayName = "TableHeader"

const TableBody = React.forwardRef(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn("[&_tr:last-child]:vtmn-border-0", className)}
    {...props} />
))
TableBody.displayName = "TableBody"

const TableFooter = React.forwardRef(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      "vtmn-border-t vtmn-bg-muted/50 vtmn-font-medium [&>tr]:last:vtmn-border-b-0",
      className
    )}
    {...props} />
))
TableFooter.displayName = "TableFooter"

const TableRow = React.forwardRef(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      "vtmn-border-b vtmn-transition-colors vtmn-table-border-gray hover:vtmn-bg-muted/50 data-[state=selected]:vtmn-bg-muted",
      className
    )}
    {...props} />
))
TableRow.displayName = "TableRow"

const TableHead = React.forwardRef(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "vtmn-h-8 vtmn-px-2 vtmn-text-left vtmn-align-middle vtmn-font-medium vtmn-text-muted-foreground [&:has([role=checkbox])]:vtmn-pr-0 [&>[role=checkbox]]:vtmn-translate-y-[2px]",
      className
    )}
    {...props} />
))
TableHead.displayName = "TableHead"

const TableCell = React.forwardRef(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      "vp-body-s vtmn-p-2 mobile:vtmn-p-1 vtmn-align-middle [&:has([role=checkbox])]:vtmn-pr-0 [&>[role=checkbox]]:vtmn-translate-y-[2px]",
      className
    )}
    {...props} />
))
TableCell.displayName = "TableCell"

const TableCaption = React.forwardRef(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("vtmn-mt-4 vtmn-text-sm vtmn-text-muted-foreground", className)}
    {...props} />
))
TableCaption.displayName = "TableCaption"

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
}
