import React, { useEffect, useState } from 'react'
import _ from "lodash"
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { VpSticker, VpIconButton } from "@vtmn-play/react";
import { VpCloseIcon } from "@vtmn-play/icons/react";

import fetchWithJWT from '../../functions/fetchWithJWT'
import handleApiResponse from '../../functions/handleApiResponse'
import { parseFilters } from './filters'
import getQueryString from '../../functions/getQueryString'
import useRequest from '../../functions/useRequest'

import DataTable from "../../components/widgets/vtmn/DataTable";


const getOutliers = (user, updateTokens, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;

  let queryString = getQueryString({ filters: {
    ...filters,
    records_type: 'incorrect'
  } });
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/dashboard/outliers?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse)
}

const OutliersWarning = ({ user, updateTokens, filters, storeView, cleanRecords, mode, store, country }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [outliersTotal, setOutliersTotal] = useState(0);

  const [
    { data, loading },
    fetchOutliers,
  ] = useRequest(getOutliers);

  useEffect(() => {
    const parsedFilters = parseFilters(filters, mode, store);
    fetchOutliers(user, updateTokens, parsedFilters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, mode, store]);

  useEffect(() => {
    if (!data || !data.length) {
      return;
    }
    setShow(true);
    setOutliersTotal(_.sumBy(data, 'total'));
  }, [data]);

  const makeRedirectUrl = (id, site_id) => {
    const qFilter = new URLSearchParams();
    qFilter.append('id', id);
    qFilter.append('site', site_id);
    qFilter.append('type', 'incorrect');
    if (filters) {
      const parsedFilters = parseFilters(filters, mode, store);
      if (parsedFilters.unloading_start_date_from) {
        qFilter.append('date_from', parsedFilters.unloading_start_date_from);
      }
      if (parsedFilters.unloading_start_date_to) {
        qFilter.append('date_to', parsedFilters.unloading_start_date_to);
      }
    }

    let url = `/${country}/${store}/efficiency/measurements?${qFilter}`;
    if (mode === 'admin') {
      url = `/admin/efficiency?${qFilter}`;
    }
    return url;
  }

  return (
    <div className="vtmn-px-4">
    {show && data && !!data.length && !loading && (
      <div className="vtmn-flex vtmn-flex-col vtmn-relative vtmn-background-brand-secondary-new vtmn-p-4 vtmn-mb-4">
        <VpIconButton
          size="small"
          variant="secondary"
          aria-label="close"
          onClick={() => setShow(!show)}
          className="!vtmn-absolute vtmn-top-2 vtmn-right-2"
        >
          <VpCloseIcon />
        </VpIconButton>
        <span className="vp-body-m">
          <Trans
            i18nKey="efficiencyAdmin.outliersWarning"
            defaults=""
            values={{
              total: outliersTotal + cleanRecords,
              outliers: outliersTotal
            }}
          >
            For those calculations, {outliersTotal} out of {outliersTotal + cleanRecords} records were not used as they appear to have extreme values. You can review them on the Records edit page.
          </Trans>
        </span>
        <span className="vp-body-m">
          <Trans i18nKey="efficiencyAdmin.outliersWarningEnding">
            Here is the list:
          </Trans>
        </span>

        <DataTable
          className="vtmn-h-full"
          columns={[{
            title: t('efficiencyAdmin.columns.store', 'Store'),
            field: 'site_id',
            className: storeView && "vtmn-hidden",
          }, {
            title: t('efficiencyAdmin.columns.deliveries', 'Deliveries'),
            field: 'outliers',
            cell: ({ row }) => {
              return row.original.outliers.map(({ id, deliveries }) => (
                <Link key={id} to={makeRedirectUrl(id, row.original.site_id)}>
                  <VpSticker size="small" variant="secondary" className="vtmn-mr-3">
                    {deliveries}
                  </VpSticker>
                </Link>
              ))
            }
          }]}
          data={data}
        />
      </div>

    )}
    </div>
  );
}

export default OutliersWarning;
