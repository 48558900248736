import * as React from "react"
import * as SliderPrimitive from "@radix-ui/react-slider"

import { cn } from "../../lib/utils"

const Slider = React.forwardRef(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      "vtmn-relative vtmn-flex vtmn-w-full vtmn-touch-none vtmn-select-none vtmn-items-center vtmn-py-3",
      className
    )}
    {...props}
  >
    <SliderPrimitive.Track className="vtmn-background-brand-secondary-new vtmn-relative vtmn-h-2 vtmn-w-full vtmn-grow vtmn-overflow-hidden vtmn-flex vtmn-items-center">
      <SliderPrimitive.Range className="vtmn-absolute vtmn-h-full vtmn-background-brand-primary-new" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb
      className={cn(
        "vtmn-bg-white vtmn-block vtmn-h-4 vtmn-w-4 vtmn-rounded-full vtmn-border vtmn-border-primary/50 vtmn-bg-background vtmn-shadow",
        "vtmn-transition-colors focus-visible:vtmn-outline-none focus-visible:vtmn-ring-1 focus-visible:vtmn-ring-ring",
        "disabled:vtmn-pointer-events-none disabled:vtmn-opacity-50"
      )}/>
  </SliderPrimitive.Root>
))
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
