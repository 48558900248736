const COLORS = {
  VTMN_BLACK: '#001018',
  VTMN_BRAND_DIGITAL: '#3643BA',
  VTMN_BRAND_DIGITAL_DARK_1: '#00689d',
  VTMN_BRAND_DIGITAL_DARK_2: '#00537d',
  VTMN_BRAND_DIGITAL_LIGHT_1: '#7ebee0',
  VTMN_BRAND_DIGITAL_LIGHT_2: '#bedeef',
  VTMN_BRAND_DIGITAL_LIGHT_3: '#e7f3f9',
  VTMN_GREEN: '#02be8a',
  VTMN_GREEN_DARK_1: '#029e73',
  VTMN_GREEN_DARK_2: '#017f5c',
  VTMN_GREEN_LIGHT_1: '#7cdfc4',
  VTMN_GREEN_LIGHT_2: '#beefe2',
  VTMN_GREEN_LIGHT_3: '#e4f9f3',
  VTMN_GREY: '#738086',
  VTMN_GREY_DARK_1: '#5c666b',
  VTMN_GREY_DARK_2: '#454d50',
  VTMN_GREY_DARK_3: '#2e3336',
  VTMN_GREY_LIGHT_1: '#b9c0c3',
  VTMN_GREY_LIGHT_2: '#dbdfe0',
  VTMN_GREY_LIGHT_3: '#f1f3f3',
  VTMN_GREY_LIGHT_4: '#f8f9f9',
  VTMN_ORANGE: '#fe5800',
  VTMN_ORANGE_LIGHT_1: '#feab7b',
  VTMN_ORANGE_LIGHT_2: '#fed5bd',
  VTMN_RED: '#ff2934',
  VTMN_RED_LIGHT_1: '#fd9297',
  VTMN_RED_LIGHT_2: '#fec9cb',
  VTMN_WHITE: '#ffffff',
  VTMN_YELLOW: '#ffea28',
  VTMN_YELLOW_LIGHT_1: '#fff58d',
  VTMN_YELLOW_LIGHT_2: '#fffbc7',
  VTMN_SUCCESS: '#149b65',
  VTMN_INFO: '#007dbc',
  VTMN_WARNING: '#db6900',
  VTMN_DANGER: '#e3262f',
  PROGRESS_BG: '#e9ecef',
};

export default COLORS;
