import React, { useEffect, useState } from "react"
import { addDays, format } from "date-fns"
import {
  VpFormControl,
  VpFormError,
  VpFormHelper,
  VpFormLabel,
} from "@vtmn-play/react"
import { VpCalendarIcon } from "@vtmn-play/icons/react";

import { cn } from "../../../../shadcn/lib/utils"
import { Button } from "../../../../shadcn/components/ui/button"
import { Calendar } from "../../../../shadcn/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../shadcn/components/ui/popover"

const DateRangePicker = ({
  label,
  className,
  onChange,
  valueFrom,
  valueTo,
}) => {
  const [date, setDate] = useState({
    from: valueFrom ? new Date(valueFrom) : null,
    to: valueTo ? new Date(valueTo) : null,
  });

  useEffect(() => {
    if (!date) {
      return;
    }
    
    onChange && onChange(date.from, date.to);
  }, [date]);

  return (
    <VpFormControl
      className={cn(
        "vtmn-flex vtmn-flex-col form-content",
        "vtmn-w-full vtmn-grid vtmn-bg-card [&>button]:vtmn-px-3",
        className
      )}
    >
      <VpFormLabel className="!vtmn-items-start">
        {label}
      </VpFormLabel>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "vtmn-px-7 !vtmn-justify-start vtmn-text-left vtmn-font-normal",
              "vp-select vp-select--default vp-select--small",
              !date && "vtmn-text-muted-foreground"
            )}
          >
            <VpCalendarIcon />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "dd/MM/yyyy")} -{" "}
                  {format(date.to, "dd/MM/yyyy")}
                </>
              ) : (
                format(date.from, "dd/MM/yyyy")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="vtmn-bg-card vtmn-bg-background-primary vtmn-w-auto vtmn-p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </VpFormControl>
  )
}

export default DateRangePicker;
