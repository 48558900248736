import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useRequest from '../../../../../functions/useRequest';
import fetchWithJWT from '../../../../../functions/fetchWithJWT';
import handleApiResponse from '../../../../../functions/handleApiResponse';
import { cn } from '../../../../../shadcn/lib/utils';

import MultiSelectFilter from '../MultiSelectFilter'
import Toggle from '../Toggle';

const getPickers = (user, updateTokens, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/controls/pickers`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const ControlsPickerSelect = ({
  user,
  updateTokens,
  disabled,
  selected,
  onChange,
  label,
  invertable,
  inverted,
  onInvert,
  controlId,
}) => {
  const { t } = useTranslation();
  const [{
    loading,
    data,
    error,
  }, fetchPickers] = useRequest(getPickers);
  const { warehouse } = useParams();
  useEffect(() => {
    fetchPickers(user, updateTokens, warehouse);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse]);
  const options = (data && data.map(({ picker }) => ({
    label: (
      <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-py-2">
        {picker}
      </div>
    ),
    id: picker,
  }))) || [];

  return (
    <MultiSelectFilter
      withSearch={false}
      label={(
        <div className={cn(
          "vtmn-flex vtmn-flex-row vtmn-items-center vtmn-w-full",
          invertable && "vtmn-justify-between"
        )}>
          <span>{label}</span>
          {invertable && (
            <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
              <span className="vp-caption vtmn-mr-3">
                {t('filters.excludes', ' excludes')}
              </span>
              <Toggle value={inverted || false} onChange={onInvert} />
            </div>
          )}
        </div>
      )}
      options={options}
      selected={selected}
      onChange={onChange}
      disabled={disabled}
      className={cn(
        "vtmn-min-w-[200px]",
        invertable && "[&_label]:vtmn-mb-[2px]",
      )}
    />
  );
}

export default ControlsPickerSelect;
