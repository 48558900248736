import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { VpButton, VpLoader, VpAsset, VpIconButton } from "@vtmn-play/react";
import { VpEditIcon } from "@vtmn-play/icons/react";

import capitalize from '../../functions/capitalize';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';

import DataTable from "../widgets/vtmn/DataTable";
import ErrorMessage from '../errorMessage';
import SettingsForm from './SettingsForm';

const getCountries = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/countries/deployment`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const columns = [{
  title: <Trans i18nKey="efficiencyCountryDeployment.headers.name">Name</Trans>,
  field: 'country',
  cell: ({ getValue, row }) => (
    <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
      <VpAsset className="vtmn-mr-2" name={`flag-${row.original.country_code.toLowerCase()}`} width="24" />
      {capitalize(getValue())}
    </div>
  )
}, {
  title: <Trans i18nKey="efficiencyCountryDeployment.headers.settings">Settings</Trans>,
  id: 'action',
  cell: ({ table, row }) => {
    const { onEditCountry } = table.options.meta;
    const empty = !row.original.cost_per_hour && !row.original.shelving_productivity
      && !row.original.shelving_teammates && !row.original.unloading_duration && !row.original.unloading_teammates;
    const edit = row.original.cost_per_hour || row.original.shelving_productivity 
      || row.original.shelving_teammates || row.original.unloading_duration || row.original.unloading_teammates;

    return (
      <>
        {!empty
          ? (
            <VpButton
              size="small"
              variant="secondary"
              onClick={() => onEditCountry(row.original.country_id)}
            >
              {edit && (
                <Trans i18nKey="efficiencyCountryDeployment.editedSettings">Edit</Trans>
              )}
            </VpButton>
          )
          : (
            <VpIconButton
              size="small"
              variant="secondary"
              aria-label="Edit"
              onClick={() => onEditCountry(row.original.country_id)}
            >
              <VpEditIcon />
            </VpIconButton>
          )
        }
      </>
    )
  },
}];

const EfficiencyCountryDeployment = ({ user, updateTokens }) => {
  const [showForm, setShowForm] = useState(false);
  const [editedCountryId, setEditedCountryId] = useState();
  const [data, setData] = useState(null);
  const [{
    loading,
    error,
  }, fetchCountries] = useRequest(
    getCountries, {
      onSuccess: setData,
      onError: () => setData(null),
    }
  );

  useEffect(() => {
    fetchCountries(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="adminDeploymentManagement mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
      {loading && (
        <div className="vtmn-w-full vtmn-h-screen vtmn-flex vtmn-items-center vtmn-justify-center">
          <VpLoader size="extra-large" />
        </div>
      )}
      {!loading && error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <DataTable
          className="vtmn-h-full"
          columns={columns}
          data={data || []}
          meta={{
            onEditCountry: (id) => {
              setEditedCountryId(id);
              setShowForm(true);
            }
          }}
        />
      )}
      <SettingsForm
        user={user}
        updateTokens={updateTokens}
        isOpen={showForm}
        onClose={() => {
          setEditedCountryId();
          setShowForm(false);
        }}
        countryId={editedCountryId}
        callbackFn={() => fetchCountries(user, updateTokens)}
      />
    </div>
  );
};

export default EfficiencyCountryDeployment;
