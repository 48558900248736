import React from 'react';
import { useTranslation } from 'react-i18next';

import Combobox from '../form/Combobox';

const BoolSelect = ({
  controlId,
  label,
  selected,
  onChange,
}) => {
  const { t } = useTranslation();
  const options = [{
    id: 'true',
    label: <div className="vtmn-py-2">{t('filters.bool.yes', 'Yes')}</div>,
  }, {
    id: 'false',
    label: <div className="vtmn-py-2">{t('filters.bool.no', 'No')}</div>,
  }];
  return (
    <Combobox
      label={label}
      options={options}
      selected={selected}
      onChange={onChange}
      valueClassName="[&>div]:vtmn-py-0"
      withSearch={false}
    />
  );
}

export default BoolSelect;
