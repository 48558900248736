import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import getSymbolFromCurrency from 'currency-symbol-map';
import { VpDivider, VpCheckbox, VpLoader, VpButton, VpIcon } from "@vtmn-play/react";

import fetchWithJWT from '../../../../../functions/fetchWithJWT';
import handleApiResponse from '../../../../../functions/handleApiResponse';
import useRequest from '../../../../../functions/useRequest';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../../shadcn/components/ui/tooltip";
import Drawer from '../../../../widgets/vtmn/Drawer';
import NumberInput from '../../../../widgets/vtmn/form/NumberInput';
import Modal from '../../../../widgets/vtmn/Modal';
import ErrorMessage from '../../../../../components/errorMessage';
import DismissErrorModal from './dismissErrorModal';

const MAX_FILE_SIZE = 32; // 32 Megabytes
const defaultImportSettings = {
  expedition: true,
  unexpected: true,
};

const importRequest = (user, updateTokens, warehouse, file, signal, settings) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/file`;
  const body = new FormData();
  body.append('file', file);
  body.append('settings', JSON.stringify(settings));
  return fetchWithJWT(url, {
    method: 'POST',
    signal,
    headers: {},
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body,
  })
  .then(handleApiResponse);
}

const checkRequest = (user, updateTokens, warehouse, path) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/file/state?path=${path}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
}

const cancelProcessing = (user, updateTokens, warehouse, path) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/file/cancel`;
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify({
      path
    }),
  })
  .then(handleApiResponse);
}

const getMinCessionPrices = (user, updateTokens, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/admin`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
};

const StopProcessingModal = ({ show, onClose, onConfirm }) => (
  <Modal
    show={show}
    title={<Trans i18nKey="declareToControl.uploadingCancel.warning">Warning</Trans>}
  >
    <div>
      <Trans i18nKey="declareToControl.uploadingCancel.text">
        Are you sure you want to interrupt processing of the uploaded file?
      </Trans>
    </div>
    <Modal.Footer>
      <VpButton
        shape="squared"
        size="small"
        variant='secondary'
        onClick={onClose}
      >
        <Trans i18nKey="declareToControl.uploadingCancel.cancel">Cancel</Trans>
      </VpButton>
      <VpButton
        shape="squared"
        size="small"
        onClick={onConfirm}
      >
        <Trans i18nKey="declareToControl.uploadingCancel.confirm">Confirm</Trans>
      </VpButton>
    </Modal.Footer>
  </Modal>
)

const ImportProgress = ({
  show,
  onUploadCancel,
  onClose,
  isUploaded,
  count,
  total,
  createdEntities,
  existingEntities,
  error,
  isProcessed,
  onDismissError,
}) => (
  <Modal show={show}>
    <div>
      {show && !error && (
        <>
          {!isProcessed && (
            <div className='vtmn-flex vtmn-justify-center vtmn-mb-4'>
              <VpLoader className='vtmn-mr-3' />
              {!isUploaded ? 'Uploading...' : 'Processing...'}
            </div>
          )}
          
          <div className='vtmn-flex vtmn-flex-row vtmn-items-center'>
            {!isUploaded && <VpLoader size="small" className='vtmn-mr-3' />}
            {isUploaded && <VpIcon name="check" className='vtmn-text-success vtmn-mr-1' />}
            <Trans i18nKey="declareToControl.uploadingProgress.uploading">
              File uploading
            </Trans>
          </div>

          {isUploaded && (
            <>
              {!isProcessed && (
                <VpLoader size="small" className='vtmn-mr-3' />
              )}
              {isProcessed && (
                <i className="vtmn-icon_tiny_bold_valid file-description-icon"></i>
              )}
              {!( count && total ) && (
                <Trans i18nKey="declareToControl.uploadingProgress.processing">Processing</Trans>
              )}
              {!!(count && total) && (
                <Trans
                  i18nKey="declareToControl.uploadingProgress.rowsCounter"
                  values={{
                    count: count > total ? total : count,
                    total,
                  }}
                />
              )}
              {typeof createdEntities === 'number' && (
                <>
                  <br/><br/>
                  <Trans
                    i18nKey="declareToControl.uploadingProgress.created"
                    values={{
                      count: createdEntities,
                    }}
                  /><br/>
                  <Trans
                    i18nKey="declareToControl.uploadingProgress.existing"
                    values={{
                      count: existingEntities,
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
      {show && error && <ErrorMessage error={error} />}
    </div>
    <Modal.Footer>
      {!error && (
        <VpButton
          shape="squared"
          size="small"
          variant="secondary"
          onClick={onUploadCancel}
          disabled={isProcessed}
        >
          <Trans i18nKey="declareToControl.actions.uploadCancel">Upload Cancel</Trans>
        </VpButton>
      )}
      <VpButton
        shape="squared"
        size="small"
        onClick={onClose}
        variant="secondary"
        disabled={!isProcessed && !error}
      >
        <Trans i18nKey="declareToControl.uploadingCancel.close">Close</Trans>
      </VpButton>
      {error && (
        <VpButton
          shape="squared"
          size="small"
          onClick={onDismissError}
        >
          <Trans i18nKey="declareToControl.actions.dismissError">Dismiss error</Trans>
        </VpButton>
      )}
    </Modal.Footer>
  </Modal>
)

const MinCessionPrice = ({
  price,
  currency,
  checkedApply,
  onChange,
  onChangePrice,
  onChangeUsePrice,
  needUsePrice,
  onChangeUseValue,
  needUseValue,
  value,
  onChangeValue,
}) => {
  const currencySymbol = getSymbolFromCurrency(currency);
  return (
    <>
      <VpCheckbox
        checked={checkedApply}
        onChange={() => onChange(!checkedApply)}
      >
        <Trans i18nKey="declareToControl.uploading.minPriceCurrency" values={{ currency }}>
          Create discrepancies control in 
        </Trans>
        {currencySymbol ? ` (${currencySymbol})` : ''}
      </VpCheckbox>
      
      {!!checkedApply && (
        <div className='vtmn-flex vtmn-flex-col vtmn-pl-4'>
          <div className='vtmn-flex vtmn-flex-row vtmn-items-center vtmn-mb-2 [&>label]:vtmn-mb-0'>
            <VpCheckbox
              checked={needUsePrice}
              onChange={() => onChangeUsePrice(!needUsePrice)}
            >
              <Trans i18nKey="declareToControl.uploading.minPrice">
                Minimum cession price
              </Trans>
            </VpCheckbox>
            <span className='vp-body-m vtmn-ml-4 vtmn-mr-1'>{currencySymbol ? currencySymbol : currency}</span>
            <NumberInput
              min={0}
              max={9999999}
              value={price}
              onChange={(value) => onChangePrice(value)}
            />
          </div>
          <div className='vtmn-flex vtmn-flex-row vtmn-items-center [&>label]:vtmn-mb-0'>
            <VpCheckbox
              checked={needUseValue}
              onChange={() => onChangeUseValue(!needUseValue)}
            >
              <Trans i18nKey="declareToControl.uploading.minValue">
                Minimum cession value
              </Trans>
            </VpCheckbox>
            <span className='vp-body-m vtmn-ml-4 vtmn-mr-1'>{currencySymbol ? currencySymbol : currency}</span>
            <NumberInput
              min={0}
              max={9999999}
              value={value}
              onChange={(value) => onChangeValue(value)}
            />
          </div>
        </div>
      )}
    </>
  )
}

const ImportForm = ({
  user,
  updateTokens,
  isOpen,
  onClose,
  warehouse,
  path,
  error,
  count,
  total,
  created,
  existing,
  onConfirm,
  onFinished,
}) => {
  const { t } = useTranslation();
  const [importSettings, setImportSettings] = useState(defaultImportSettings);
  const [showProgress, setShowProgress] = useState(false);
  const [backendError, setBackendError] = useState();
  const [serverPath, setServerPath] = useState();
  const [processedRows, setProcessedRows] = useState(count);
  const [totalRows, setTotalRows] = useState(total);
  const [isUploaded, setIsUploaded] = useState(!!path);
  const [showQuestion, setShowQuestion] = useState(false);
  const [finished, setFinished] = useState(false);
  const [showDismiss, setShowDismiss] = useState(false);
  const [createdEntities, setCreatedEntities] = useState(created);
  const [existingEntities, setExistingEntities] = useState(existing);
  const timerRef = useRef();
  const controllerRef = useRef();
  const inputFileRef = useRef();

  const [, doImportRequest] = useRequest(importRequest, {
    onError: setBackendError,
    onSuccess: (resp) => {
      setIsUploaded(true);
      setServerPath(resp.path);
    }
  });

  const [, doCheckState] = useRequest(checkRequest, {
    onError: setBackendError,
    onSuccess: (resp) => {
      const { count, total, created, existing } = resp;
      setProcessedRows(count);
      setTotalRows(total);
      setCreatedEntities(created);
      setExistingEntities(existing);
    }
  });

  const [, doCancelProcessing] = useRequest(cancelProcessing, {
    onError: setBackendError,
  });

  const [{
    loading,
    data: prices,
    error: pricesError,
  }, fetchMinCessionPrices] = useRequest(getMinCessionPrices);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setShowProgress(!!path);
    setBackendError(error);
    setServerPath(path);
  }, [isOpen]);

  useEffect(() => {
    if (!isUploaded || finished || backendError) {
      return;
    }
    timerRef.current = setInterval(() => {
      doCheckState(user, updateTokens, warehouse, serverPath)
    }, 1000);
    
    return () => clearInterval(timerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploaded, serverPath, warehouse, finished, backendError]);

  useEffect(() => {
    if (!!(processedRows && totalRows && processedRows >= totalRows) && timerRef.current) {
      setFinished(true);
      onFinished();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedRows, totalRows]);

  useEffect(() => {
    fetchMinCessionPrices(user, updateTokens, warehouse);
  }, [warehouse, user, updateTokens]);

  useEffect(() => {
    if (!prices) {
      return;
    }
    setImportSettings({
      ...importSettings,
      prices: prices.map((p) => ({
        ...p,
        apply: true,
        takePrice: false,
        takeValue: false,
      }))
    })
  }, [prices]);

  useEffect(() => {
    if (!error || !isOpen) {
      return;
    }
    onClose && onClose();
    setShowProgress(true);
  }, [error]);

  const onChangePrice = (value, field, index) => {
    setImportSettings({
      ...importSettings,
      prices: [
        ...importSettings.prices.slice(0, index),
        {
          ...importSettings.prices[index],
          [field]: value,
        },
        ...importSettings.prices.slice(index + 1),
      ]
    });
  }

  const uploadFiles = (list) => {
    if (!list || !list.length) {
      return;
    }

    onClose && onClose();
    setShowProgress(true);
    const mbSize = _.round(list[0].size / Math.pow(10, 6), 2);
    if (mbSize >= MAX_FILE_SIZE) {
      setBackendError({
        message: t('declareToControl.uploading.maxSizeError', {
          defaultValue: '{{size}}Mb more then maximum file size {{max}}Mb',
          size: mbSize,
          max: MAX_FILE_SIZE,
        })
      });
      return;
    }

    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;
    
    doImportRequest(user, updateTokens, warehouse, list[0], signal, importSettings);
  }

  const onUploadingCancel = () => {
    controllerRef.current && controllerRef.current.abort('Interrupted by user');
    doCancelProcessing(user, updateTokens, warehouse, serverPath);
    setFinished(true);
    onConfirm();
    setShowQuestion(false);
    setShowProgress(false);
  }

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={onClose}
        title={t('declareToControl.actions.import', 'Import picking extract (LQT)')}
        onSave={() => {
          inputFileRef.current.click()
        }}
        className="!vtmn-w-1/2 !vtmn-max-w-1/2"
        buttonTitle={t('declareToControl.actions.upload', 'Upload file')}
      >
        <input
          className='vtmn-hidden'
          type="file"
          ref={inputFileRef}
          onChange={(e) => uploadFiles(e.target.files)}
        />
        <div className="vtmn-flex vtmn-flex-row [&>div]:vtmn-mb-3">
          <div className='vtmn-flex vtmn-flex-col vtmn-w-1/2 vtmn-pr-3'>
            <span className='vp-subtitle-m vtmn-mb-4'>
              <Trans i18nKey="declareToControl.uploading.requirements">
                Requirements for uploaded files:
              </Trans>
            </span>
            <span className='vp-body-m'>
              <Trans i18nKey="declareToControl.uploading.columnNames">
                1. The uploaded file must contain columns with the following names:
              </Trans>
            </span>
            <span className='vp-caption'>- ADRESSE</span>
            <span className='vp-caption'>- ARTICLE</span>
            <span className='vp-caption'>- CONTENANT</span>
            <span className='vp-caption'>- COLIS</span>
            <span className='vp-caption'>- QTY_PICKED</span>
            <span className='vp-caption'>- QTY_LEFT_ON_CONTENAN</span>
            <span className='vp-caption'>- PICKED_BY_WHOM</span>
            <span className='vp-caption'>- DATE_OF_PICKING</span>
            <span className='vp-caption'>- SECTEUR</span>
            <span className='vp-caption'>- UNIVERS</span>
            <span className='vp-body-m vtmn-mt-4'>
              <Trans i18nKey="declareToControl.uploading.fileSize" values={{ max: MAX_FILE_SIZE }}>
                2. Maximum file size {{MAX_FILE_SIZE}}Mb
              </Trans><br/>
            </span>
            <span className='vp-body-m vtmn-mt-4'>
              <Trans i18nKey="declareToControl.uploading.fileTypes">
                3. Supported file formats: .csv, .xls
              </Trans>
            </span>
          </div>

          <VpDivider orientation='vertical' />

          <div className='vtmn-flex vtmn-flex-col vtmn-pl-3'>
            <span className='vp-subtitle-m vtmn-mb-4'>
              <Trans i18nKey="declareToControl.uploading.settings">
                Import settings:
              </Trans>
            </span>
            <VpCheckbox
              checked={importSettings.expedition}
              onChange={() => setImportSettings({...importSettings, expedition: !importSettings.expedition})}
            >
              <Trans i18nKey="declareToControl.uploading.createExpedition">
                Create expedition discrepancies controls
              </Trans>
            </VpCheckbox>
            <VpCheckbox
              checked={importSettings.unexpected}
              onChange={() => setImportSettings({...importSettings, unexpected: !importSettings.unexpected})}
            >
              <Trans i18nKey="declareToControl.uploading.createUnexpected">
                Create unexpected discrepancies controls
              </Trans>
            </VpCheckbox>

            {prices && !!prices.length && (
              <div className='vtmn-flex vtmn-flex-col vtmn-my-4 [&>button]:vtmn-text-left'>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span className='vp-subtitle-m'>
                        <Trans i18nKey="declareToControl.uploading.currencyFiltering">
                          Currency filtering
                        </Trans>
                      </span>
                    </TooltipTrigger>
                    <TooltipContent side="bottom">
                      <div className='vtmn-max-w-[200px]'>
                        <Trans i18nKey="declareToControl.uploading.currencyTooltip">
                          If a currency is selected, then controls will be created only for discrepancies with cession price in this currency. 
                          If a minimal price is specified, then controls will only be created for discrepancies with a greater cession price than the minimal.
                        </Trans>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            )}

            {importSettings.prices && importSettings.prices.map((p, i) => (
              <MinCessionPrice
                key={i}
                checkedApply={p.apply}
                price={p.price}
                currency={p.currency}
                onChange={(value) => onChangePrice(value, 'apply', i)}
                onChangePrice={(value) => onChangePrice(value, 'price', i)}
                onChangeUsePrice={(value) => onChangePrice(value, 'takePrice', i)}
                needUsePrice={p.takePrice}
                onChangeUseValue={(value) => onChangePrice(value, 'takeValue', i)}
                needUseValue={p.takeValue}
                value={p.value}
                onChangeValue={(value) => onChangePrice(value, 'value', i)}
              />
            ))}
          </div>
        </div>
      </Drawer>
      {showProgress && (
        <ImportProgress
          show={showProgress}
          onUploadCancel={() => setShowQuestion(true)}
          onClose={() => {
            onConfirm && onConfirm();
            setShowProgress(false);
          }}
          isUploaded={isUploaded}
          count={processedRows}
          total={totalRows}
          createdEntities={createdEntities}
          existingEntities={existingEntities}
          error={backendError}
          isProcessed={finished}
          onDismissError={() => {
            setShowDismiss(true);
            setShowProgress(false);
          }}
        />
      )}
      <StopProcessingModal
        show={showQuestion}
        onClose={() => setShowQuestion(false)}
        onConfirm={onUploadingCancel}
      />
      <DismissErrorModal
        show={showDismiss}
        user={user}
        updateTokens={updateTokens}
        warehouse={warehouse}
        path={serverPath}
        onClose={() => setShowDismiss(false)}
        onConfirm={() => {
          setShowDismiss(false);
          onConfirm && onConfirm();
        }}
      />
    </>
  )
}

export default ImportForm;
