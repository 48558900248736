import React from 'react';
import { useTranslation } from 'react-i18next';
import { VpAsset } from "@vtmn-play/react";

import FiltersPannel from '../widgets/vtmn/FiltersPanel';
import DateRangePicker from '../widgets/vtmn/filters/DateRangePicker';
import Combobox from '../widgets/vtmn/form/Combobox';
import CountrySelect from '../widgets/vtmn/filters/CountrySelect';
import MultiSelectFilter from '../widgets/vtmn/filters/MultiSelectFilter'

const MainFilters = ({
  filters,
  setFilters,
  stores,
  countries,
  personalStores,
  storesLoading,
  countriesLoading,
  personalStoresLoading,
  user,
  updateTokens,
}) => {
  const { t } = useTranslation();
  let storesFiltered = stores;
  if (filters.countryCode) {
    storesFiltered = storesFiltered.filter(x => x.countryCode === filters.countryCode);
  }
  const storesOptions = storesFiltered.map(x => ({
    id: x.id,
    label: (
      <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-py-2">
        <VpAsset className="vtmn-mr-2" name={`flag-${x.countryCode.toLowerCase()}`} width="24" />
        {x.id} - {x.name}
      </div>
    ),
  }));
  const countryMap = countries.reduce((acc, x) => ({
    ...acc,
    [x.code]: x.id
  }), {});
  const oserOptions = personalStores.map(x => ({
    id: x.id,
    label: (
      <div className="vtmn-py-2">
        {x.name}
      </div>
    ),
  }));
  const showOserFilter = filters.countryCode === 'RU';

  return (
    <>
      <DateRangePicker
        controlId="filter-date"
        label={t('monitoringRobots.dateFilter', 'Date filter')}
        valueFrom={filters.dateFrom}
        valueTo={filters.dateTo}
        onChange={(dateFrom, dateTo) => setFilters({
          ...filters,
          dateFrom,
          dateTo,
        })}
      />
      <CountrySelect
        controlId="filter-countries"
        label={t('monitoringRobots.country', 'Country')}
        selected={countryMap[filters.countryCode]}
        onChange={(countryCode, v) => {
          setFilters({
            ...filters,
            countryCode: v.code,
            storeIn: [],
            oser: undefined,
          })
        }}
        loading={countriesLoading}
        user={user}
        updateTokens={updateTokens}
      />
      <MultiSelectFilter
        controlId="filter-stores"
        label={t('monitoringRobots.stores', 'Stores')}
        options={storesOptions}
        selected={filters.storeIn}
        onChange={(storeIn) => {
          setFilters({
            ...filters,
            storeIn,
          })
        }}
        disabled={filters.oser}
        loading={storesLoading}
      />

      {showOserFilter && (
        <Combobox
          controlId="filter-oser"
          className="vtmn-min-w-[200px]"
          valueClassName="[&>div]:vtmn-py-0"
          label={t('monitoringRobots.oserFilter', 'OSER filter')}
          options={oserOptions}
          selected={filters.oser}
          onChange={(id) => setFilters({
            ...filters,
            storeIn:  personalStores.find(x => x.id === id)?.store_ids,
            oser: id
          })}
          loading={personalStoresLoading}
        />
      )}
    </>
  )
}

const Filters = ({
  filters,
  setFilters,
  stores,
  countries,
  personalStores,
  storesLoading,
  countriesLoading,
  personalStoresLoading,
  user,
  updateTokens,
}) => {
  const filtered = !!(filters.storeIn && filters.storeIn.length);

  return (
    <FiltersPannel
      filtered={filtered}
      listClassName="vtmn-flex vtmn-flex-row vtmn-flex-wrap [&>div]:vtmn-mr-5"
      mainFilters={
        <MainFilters
          filters={filters}
          setFilters={setFilters}
          stores={stores}
          countries={countries}
          personalStores={personalStores}
          storesLoading={storesLoading}
          countriesLoading={countriesLoading}
          personalStoresLoading={personalStoresLoading}
          user={user}
          updateTokens={updateTokens}
        />
      }
    />
  );
};

export default Filters;
