import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { VpButton } from "@vtmn-play/react";

import useRequest from '../../../../../functions/useRequest';
import fetchWithJWT from '../../../../../functions/fetchWithJWT';
import handleApiResponse from '../../../../../functions/handleApiResponse';

import Modal from '../../../../widgets/vtmn/Modal';
import ErrorMessage from '../../../../../components/errorMessage';

const dismissRequest = (user, updateTokens, warehouse, path) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/file/state`;
  return fetchWithJWT(url, {
    method: 'PUT',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify({
      path,
      status: 'finished',
    }),
  })
  .then(handleApiResponse);
}

const DismissErrorModal = ({
  user,
  updateTokens,
  warehouse,
  path,
  onClose,
  onConfirm,
  show,
}) => {
  const { t } = useTranslation();

  const [{ error }, doImportRequest] = useRequest(dismissRequest, {
    onSuccess: onConfirm
  });

  const confirm = () => {
    doImportRequest(user, updateTokens, warehouse, path);
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      title={t('declareToControl.dismissError.warning', 'Warning')}
    >
      <div className='vtmn-flex vtmn-flex-col'>
        <Trans i18nKey="declareToControl.dismissError.text">
          Mark this error as read?
        </Trans>
        {error && <ErrorMessage error={error} />}
      </div>
      <Modal.Footer>
        <VpButton
          shape="squared"
          size="small"
          variant='secondary'
          onClick={onClose}
        >
          <Trans i18nKey="declareToControl.dismissError.cancel">Cancel</Trans>
        </VpButton>
        <VpButton
          shape="squared"
          size="small"
          onClick={confirm}
        >
          <Trans i18nKey="declareToControl.dismissError.confirm">Confirm</Trans>
        </VpButton>
      </Modal.Footer>
    </Modal>
  )
}

export default DismissErrorModal;
