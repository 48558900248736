import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import fetchWithJWT from '../../functions/fetchWithJWT';
import { handlePaginatedApiResponse, handleApiResponse } from '../../functions/handleApiResponse';
import { getFiltersParams, getSortParams, getQueryString } from '../../functions/getQueryString';
import useTableRequest from '../../functions/useTableRequest';
import { formatDateForBigQuery } from '../../functions/formatDate';
import { parseFilters } from './filters'
import { getColumns, getDefaultSelection, getSelectedFields } from './BreakdownColumns';

import DataTable from "../../components/widgets/vtmn/DataTable";
import ExportButton from '../widgets/vtmn/ExportButton';
import SelectColumns from '../widgets/vtmn/filters/SelectColumns';

const getBreakdown = (user, updateTokens, { filters, pagination, sort }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({
    filters,
    pagination,
    sort,
  })
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/dashboard/breakdown?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handlePaginatedApiResponse)
};

const exportReport = (fields) => (user, updateTokens, oauthToken, { filters, sort }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (formatedFilters.create_date_from) {
    formatedFilters.create_date_from = formatDateForBigQuery(formatedFilters.create_date_from);
  }
  if (formatedFilters.create_date_to) {
    formatedFilters.create_date_to = formatDateForBigQuery(formatedFilters.create_date_to);
  }

  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/dashboard/breakdown/export`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      oauthToken,
      filters: getFiltersParams({ filters: formatedFilters }),
      sort: getSortParams({ sort }),
      fields,
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const BreakdownTable = ({ user, updateTokens, filters, showInvestment }) => {
  const { t } = useTranslation();
  const allFields = getColumns({ t, showInvestment });
  const [fieldsSelection, setFieldsSelection] = useState(getDefaultSelection(allFields));
  const columns = getSelectedFields({
    fields: allFields,
    selection: fieldsSelection,
  });

  const memoizedExportReport = useMemo(
    () => exportReport(fieldsSelection),
    [fieldsSelection]
  );

  const [
    { loading, error, data, sort, totals, pagination }, // eslint-disable-next-line
    fetchBreakdown, setFilters, setPagination, setSort
  ] = useTableRequest(getBreakdown, {
    initialState: {
      pagination: {
        page: 0,
        size: 200,
      },
    }
  });

  useEffect(() => {
    const parsedFilters = parseFilters(filters);
    fetchBreakdown(user, updateTokens, {
      filters: parsedFilters,
      pagination,
      sort,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination, sort]);

  return (
    <div className="vtmn-flex vtmn-flex-col vtmn-mt-3">
      <div className="vtmn-flex vtmn-flex-row vtmn-justify-between vtmn-mb-3 vtmn-px-4">
        <SelectColumns
          fields={allFields}
          fieldsSelection={fieldsSelection}
          setFieldsSelection={setFieldsSelection}
        />

        <ExportButton
          size="small"
          user={user}
          updateTokens={updateTokens}
          filters={filters}
          sort={sort}
          totalItems={totals && totals.items}
          exportReport={memoizedExportReport}
        />
      </div>
      <DataTable
        className="vtmn-h-full"
        columns={columns}
        data={data || []}
        totals={totals}
        pagination={pagination}
        setPagination={setPagination}
        meta={{
          sort, setSort
        }}
      />
    </div>
  );
};

export default BreakdownTable;
