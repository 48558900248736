import React from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '../../../../shadcn/lib/utils';

import MultiSelectFilter from './MultiSelectFilter';
import Toggle from './Toggle';

const RfidFilter = ({
  selected,
  onChange,
  invertable,
  inverted,
  onInvert,
}) => {
  const { t } = useTranslation();
  const options = [{
    id: 'P',
    label: <div className="vtmn-py-2">{t('declareToControl.filters.rfid.p', 'P - Placed in production')}</div>,
  }, {
    id: 'L',
    label: <div className="vtmn-py-2">{t('declareToControl.filters.rfid.l', 'L - Placed in logisticts')}</div>,
  }, {
    id: 'N',
    label: <div className="vtmn-py-2">{t('declareToControl.filters.rfid.n', 'N - No tag placed')}</div>,
  }, {
    id: 'empty',
    label: <div className="vtmn-py-2">{t('declareToControl.filters.rfid.empty', 'Empty')}</div>,
  }];
  return (
    <MultiSelectFilter
      label={(
        <div className={cn(
          "vtmn-flex vtmn-flex-row vtmn-items-center vtmn-w-full",
          invertable && "vtmn-justify-between"
        )}>
          <span>RFID</span>
          {invertable && (
            <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
              <span className="vp-caption vtmn-mr-3">
                {t('filters.excludes', ' excludes')}
              </span>
              <Toggle value={inverted || false} onChange={onInvert} />
            </div>
          )}
        </div>
      )}
      options={options}
      selected={selected}
      onChange={onChange}
      invertable={invertable}
      inverted={inverted}
      onInvert={onInvert}
      withSearch={false}
      className={cn(
        "vtmn-min-w-[200px]",
        invertable && "[&_label]:vtmn-mb-[2px]",
      )}
    />
  );
}

export default RfidFilter;
