import React from 'react';
import { useTranslation } from 'react-i18next';
import formatMoney from '../../../functions/formatMoney';

const ProgressBar = ({ indicator, onBarClick, currency }) => {
  const { t } = useTranslation();

  const { total } = indicator;
  let percentFoundAtMovements = indicator.foundAtMovementsQties / total * 100;
  let percentRevisedAtMovements = indicator.revisedAtMovementsQties / total * 100;
  let percentMissingAtMovements = indicator.missingAtMovementsQties / total * 100;
  if (indicator.foundAtMovementsQties >= total) {
    percentFoundAtMovements = 0; //for 100% block width is not checked which will change color to green
  }
  if (indicator.revisedAtMovementsQties >= total) {
    percentRevisedAtMovements = 0; //for 100% block width is not checked which will change color to orange
  }
  if (indicator.missingAtMovementsQties >= total) {
    percentMissingAtMovements = 0; //for 100% block width is not checked which will change color to red
  }
  const percentNotChecked = 100 - percentFoundAtMovements - percentRevisedAtMovements - percentMissingAtMovements;

  const notCheckedLabel = t(
    'liveStoreReception.progress.notChecked',
    'Not checked:',
  );
  const readByRobotLabel = t(
    'liveStoreReception.progress.notUnexpected',
    'Not unexpected:',
  );
  const revisedAtMovementsLabel = t(
    'liveStoreReception.progress.revisedAtMovements',
    'Has movement correction:',
  );
  const confirmedMissingLabel = t(
    'liveStoreReception.progress.confirmedUnexpected',
    'Confirmed unexpected:',
  );

  let missingValueToDisplay = "";
  if (indicator.additionalValue > 0){
    missingValueToDisplay = ` = Additional ${parseInt(indicator.additionalValue, 10)} ${currency}`;
  }

  const notCheckedQties = indicator.total
    - indicator.foundAtMovementsQties
    - indicator.revisedAtMovementsQties
    - indicator.missingAtMovementsQties;
  const checkedItems = indicator.foundAtMovementsQties + indicator.revisedAtMovementsQties + indicator.missingAtMovementsQties;

  let foundedTitle = `${readByRobotLabel} ${indicator.foundAtMovementsQties}`;
  if (indicator.foundAtMovementsValue) {
    foundedTitle += ` = ${formatMoney(indicator.foundAtMovementsValue, currency)}`;
  }

  let hasMovementsTitle = `${revisedAtMovementsLabel} ${indicator.revisedAtMovementsQties}`;
  if (indicator.revisedAtMovementsValue) {
    hasMovementsTitle += ` = ${formatMoney(indicator.revisedAtMovementsValue, currency)}`;
  }

  let confirmedMissingTitle = `${confirmedMissingLabel} ${indicator.missingAtMovementsQties}`;
  if (indicator.missingAtMovementsValue) {
    confirmedMissingTitle += ` = ${formatMoney(indicator.missingAtMovementsValue, currency)}`;
  }

  let notCheckedTitle = `${notCheckedLabel} ${notCheckedQties}`;
  if (indicator.additionalValue) {
    notCheckedTitle += ` = ${formatMoney(indicator.additionalValue, currency)}`;
  }

  let myClassProgressBar = "vtmn-progress-bar vtmn-text-white";
  if (indicator.foundAtMovementsQties >= indicator.total) {
    myClassProgressBar += " vtmn-bg-success";
    notCheckedTitle = foundedTitle;
  } else if (indicator.revisedAtMovementsQties >= indicator.total) {
    myClassProgressBar += " bg-warning";
    notCheckedTitle = hasMovementsTitle;
  } else if (indicator.missingAtMovementsQties >= indicator.total) {
    myClassProgressBar += " bg-danger";
    notCheckedTitle = confirmedMissingTitle;
  }
  
  return (
    <div className={`vtmn-cursot-pointer vtmn-progress vtmn-relative vtmn-h-4 ${!!onBarClick ? 'progress-clickable' : ''}`} onClick={onBarClick}>
      <div className="vtmn-absolute vtmn-w-full vtmn-justify-center vp-caption vtmn-text-center">
        <span>
          {checkedItems}
          {' / '}
          {indicator.total}
          {missingValueToDisplay}
        </span>
      </div>
      {indicator.foundAtMovementsQties ? (
        <div className="vtmn-progress-bar progress-bar-striped vtmn-bg-success"
          role="progressbar"
          style={{width: percentFoundAtMovements + "%"}}
          aria-valuenow={percentFoundAtMovements}
          aria-valuemin="0"
          aria-valuemax="100"
          title={foundedTitle}
        ></div>
      ) : null}
      <div className={myClassProgressBar}
        role="progressbar"
        style={{width: percentNotChecked + "%", backgroundColor: 'transparent', color: 'initial'}}
        aria-valuenow={percentNotChecked}
        aria-valuemin="0"
        aria-valuemax="100"
        title={notCheckedTitle}
      ></div>
      {indicator.revisedAtMovementsQties ? (
        <div className="progress-bar progress-bar-striped bg-warning"
          role="progressbar"
          style={{width: percentRevisedAtMovements + "%"}}
          aria-valuenow={percentRevisedAtMovements}
          aria-valuemin="0"
          aria-valuemax="100"
          title={hasMovementsTitle}
        ></div>
      ) : null}
      {indicator.missingAtMovementsQties ? (
        <div className="progress-bar bg-danger"
          role="progressbar"
          style={{width: percentMissingAtMovements + "%"}}
          aria-valuenow={percentMissingAtMovements}
          aria-valuemin="0"
          aria-valuemax="100"
          title={confirmedMissingTitle}
        ></div>
      ) : null}
    </div>
  )
};

export default ProgressBar;
