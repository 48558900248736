import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MDEditor from '@uiw/react-md-editor';

import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import useRequest from '../../functions/useRequest';

import Drawer from '../widgets/vtmn/Drawer';
import InputFilter from '../widgets/vtmn/filters/InputFilter';
import Combobox from '../widgets/vtmn/form/Combobox';
import FileEditor from '../widgets/FileEditor';

const RELEASE_TYPES = ['web', 'mobile'];
const RELEASE_OPTIONS = RELEASE_TYPES.map((t) => ({
  id: t,
  label: (
    <div className="vtmn-py-2">
      {t}
    </div>
  )
}));

const initialContent = {
  version: '',
  type: RELEASE_TYPES[0],
  summary: '',
  image: '',
};

const saveRelease = ({ user, updateTokens, content }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const releaseId = content.id ? `/${content.id}` : '';
  const url = `${process.env.REACT_APP_base_URL}/api/releases${releaseId}`;
  return fetchWithJWT(url, {
    method: content.id ? 'PUT' : 'POST',
    body: JSON.stringify(content),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const ReleaseDrawer = ({
  user,
  updateTokens,
  isOpen,
  onClose,
  release,
  callbackFn,
}) => {
  const { token } = user;
  const { t } = useTranslation();
  const [content, setContent] = useState(initialContent);

  const title = release && release.id
    ? t('releases.editRelease', 'Edit release')
    : t('releases.newRelease', 'New release');

  const [{
    loading: saving,
    error: saveError,
  }, doSaveRelease] = useRequest(saveRelease, {
    onSuccess: onClose,
  });

  useEffect(() => {
    if (!release || !release.id) {
      setContent(initialContent);
      return;
    }
    setContent(release);
  }, [release]);

  const onSave = async () => {
    await doSaveRelease({
      content,
      user,
      updateTokens,
    });
    callbackFn && callbackFn();
  }

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={title}
      onSave={onSave}
      className="!vtmn-w-1/2"
      disabledSave={saving}
    >
      <div className="vtmn-px-1">
        <div className="vtmn-flex vtmn-flex-row vtmn-mb-3">
          {!!content.image && (
            <div className='vtmn-shadow-100 vtmn-mr-3 vtmn-w-[200px] vtmn-min-w-[200px]'>
              <img src={`${process.env.REACT_APP_base_URL}/api/files/${content.image}?token=${token}`} alt="slide" />
            </div>
          )}
          <div className='vtmn-flex vtmn-flex-col vtmn-w-full [&>div]:vtmn-mb-3'>
            <InputFilter
              label={t('releases.releaseForm.version', 'Version')}
              value={content.version}
              onChange={(value) => setContent({ ...content, version: value })}
            />
            <Combobox
              controlId="filter-oser"
              className="vtmn-min-w-[200px]"
              valueClassName="[&>div]:vtmn-py-0"
              label={t('releases.releaseForm.type', 'Type')}
              selected={content.type}
              options={RELEASE_OPTIONS}
              onChange={(value) => setContent({ ...content, type: value })}
            />
            <FileEditor
              id="robot-mass-plan-file"
              user={user}
              updateTokens={updateTokens}
              url={content.image}
              setUrl={(massPlanFile) => {
                setContent({ ...content, image: massPlanFile });
              }}
            />
          </div>
        </div>
        <div className="vtmn-py-1">
          <MDEditor
            value={content.summary}
            previewOptions={{className: "mobile-markdown"}}
            className="vtmn-min-h-[300px]"
            onChange={(value) => setContent({ ...content, summary: value })}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default ReleaseDrawer;
