import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "../../lib/utils.js"
import { Button } from "./button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"

export const Select = React.forwardRef(
  (
    {
      label,
      options,
      onValueChange,
      variant,
      value,
      placeholder = "Select option",
      animation = 0,
      modalPopover = false,
      asChild = false,
      className,
      withSearch,
      valueClassName,
      disabled,
      ...props
    },
    ref
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    return (
      <Popover
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
      >
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            variant="outline"
            role="combobox"
            aria-expanded={isPopoverOpen}
            disabled={disabled}
            className={cn(
              "vp-select vp-select--default vp-select--small vtmn-justify-between !vtmn-px-3",
              valueClassName,
            )}
          >
            {value
              ? options.find((option) => option.id == value)?.label
              : <div className="vtmn-block">&nbsp;</div>}
            <ChevronsUpDown className="vtmn-opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          className={cn(
            "vtmn-w-auto vtmn-p-1",
            "!vtmn-z-50 vtmn-bg-white vtmn-left-0",
            // '!vtmn-z-50 vtmn-dropdown_items vtmn-min-w-max vtmn-p-0 vtmn-min-h-10 vtmn-p-1'
          )}
        >
          <Command>
            {withSearch && (
              <div className="vtmn-p-1">
                <CommandInput
                  placeholder="Search..."
                  className={cn(
                    "vp-input vp-input--small"
                  )}
                />
              </div>
            )}
            <CommandList>
              <CommandEmpty className="vp-body-m vtmn-p-4">Not found.</CommandEmpty>
              <CommandGroup>
                {/* <div className="vtmn-h-[300px] vtmn-overflow-y-auto"> */}
                  {options.map((option) => (
                    <CommandItem
                      key={option.id}
                      className={cn(
                        "vtmn-cursor-pointer hover:vtmn-new-bg-hover-secondary-transparent"
                      )}
                      value={option.id}
                      onSelect={() => {
                        const curOption = option.id === value ? null : option;
                        onValueChange && onValueChange(curOption.id, curOption)
                        setIsPopoverOpen(false)
                      }}
                    >
                      <div className="vtmn-flex vtmn-flex-row vtmn-w-full">
                        <div className="vtmn-flex vtmn-flex-row vtmn-w-full vtmn-items-center vtmn-px-3">
                          <span
                            className={cn(
                              "vtmn-w-full vp-body-m",
                            )}
                          >
                            {option.label}
                          </span>
                        </div>
                      </div>
                      <Check
                        className={cn(
                          "vtmn-ml-auto vtmn-mr-3",
                          value === option.id ? "vtmn-opacity-100" : "vtmn-opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                {/* </div> */}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    )
  }
);

Select.displayName = "Select";