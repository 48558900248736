import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';

import Drawer from '../widgets/vtmn/Drawer';
import NumberInput from '../widgets/vtmn/form/NumberInput';

const getSettings = ({
  user,
  updateTokens,
  id,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/store/settings/${id}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateSettings = ({
  user,
  updateTokens,
  id,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/store/settings/${id}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const SettingsForm = ({
  user,
  updateTokens,
  siteId,
  isOpen,
  onClose,
  callbackFn,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});

  const title = t('storesDeployment.editSettings', 'Edit settings');

  const [{
    loading,
    error,
  }, fetchSettings] = useRequest(getSettings, {
    onSuccess: setFormData
  });

  const [{
    loading: saving,
    error: saveError,
  }, saveSettings] = useRequest(updateSettings, {
    onSuccess:() => {
      callbackFn && callbackFn();
      onClose && onClose();
    },
  });

  useEffect(() => {
    if (!siteId) return;

    fetchSettings({
      id: siteId,
      user,
      updateTokens,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, fetchSettings]);

  const saveData = () => saveSettings({
    id: formData && formData.id,
    settings: formData,
    user,
    updateTokens,
  });

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={title}
      onSave={saveData}
      disabledSave={saving || loading}
    >
      <div className="vtmn-px-1 [&>div]:vtmn-mb-3">
        <NumberInput
          label={t('deploymentSettings.timeFrom', '"Time from" for match tags')}
          helper={t('deploymentSettings.hours', 'Hours')}
          value={formData.storeTagTimeframeFromHours}
          onChange={(value) => setFormData({
            ...formData,
            storeTagTimeframeFromHours: value
          })}
        />
        <NumberInput
          label={t('deploymentSettings.timeTo', '"Time to" for match tags')}
          helper={t('deploymentSettings.hours', 'Hours')}
          value={formData.storeTagTimeframeToHours}
          onChange={(value) => setFormData({
            ...formData,
            storeTagTimeframeToHours: value
          })}
        />
      </div>
    </Drawer>
  )
}

export default SettingsForm;
