import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';
import { VpLoader, VpSticker } from "@vtmn-play/react";

import { formatDateTime, formatDateForBigQuery, formatDateTimeForBigQuery } from '../../../../functions/formatDate';
import useRequest from '../../../../functions/useRequest';
import useTableRequest from '../../../../functions/useTableRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import { handleApiResponse, handlePaginatedApiResponse } from '../../../../functions/handleApiResponse';
import getQueryString from '../../../../functions/getQueryString';
import formatMoney from '../../../../functions/formatMoney';
import getFiltersWithInverted from '../../../../functions/getFiltersWithInverted';
import { useUserFilters } from '../../../../functions/filtersKeeper';

import Filters from './Filters';
import { getMovementCategories } from './MovementCategoriesFilter';
import Tabs from "../../../widgets/vtmn/Tabs";
import DataTable from "../../../widgets/vtmn/DataTable";

const PAGE_NAME = 'Discrepancies';
const EXCLUDE_FROM_KEEPING = [
  'receptionDateFrom',
  'receptionDateTo',
  'movementCategories',
  'qtyConfirmedFrom',
  'qtyConfirmedTo',
  'qtyReadBeforeWarehouseGateFrom',
  'qtyReadBeforeWarehouseGateTo',
  'qtyReadByStoreGateFrom',
  'qtyReadByStoreGateTo',
  'missingFrom',
  'missingTo',
  'realizedMovementFrom',
  'realizedMovementTo',
  'stayedAtWarehouse',
  'unexpectedQtyFrom',
  'unexpectedQtyTo',
];

const getReport = (user, updateTokens, { filters, pagination, sort }, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (warehouse) {
    formatedFilters.warehouse = warehouse;
  }
  if (formatedFilters.receptionDateFrom) {
    formatedFilters.receptionDateFrom = formatDateForBigQuery(formatedFilters.receptionDateFrom);
  }
  if (formatedFilters.receptionDateTo) {
    formatedFilters.receptionDateTo = formatDateTimeForBigQuery(formatedFilters.receptionDateTo);
  }
  if (filters.discrepancyType === 'transport') {
    formatedFilters.withTags = 'true';
  }
  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
    sort,
  })
  const url = `${process.env.REACT_APP_base_URL}/api/reporting/discrepancies?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const getReportTotals = (user, updateTokens, { filters }, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (warehouse) {
    formatedFilters.warehouse = warehouse;
  }
  if (formatedFilters.receptionDateFrom) {
    formatedFilters.receptionDateFrom = formatDateForBigQuery(formatedFilters.receptionDateFrom);
  }
  if (formatedFilters.receptionDateTo) {
    formatedFilters.receptionDateTo = formatDateForBigQuery(formatedFilters.receptionDateTo);
  }
  const queryString = getQueryString({
    filters: formatedFilters,
  })
  const url = `${process.env.REACT_APP_base_URL}/api/reporting/discrepancies/totals?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};


const getFields = ({ t, discrepancyType, withWarehouse }) => [
  ...(withWarehouse ? [
    {
      title: t('reportingDiscrepancies.columns.warehouse', 'Warehouse'),
      field: 'warehouse',
      sortable: true,
    },
  ] : []),
  {
    title: t('reportingDiscrepancies.columns.store', 'Store'),
    field: 'store',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.delivery', 'Delivery'),
    field: 'delivery',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.date', 'Date'),
    field: 'receptionDate',
    sortable: true,
    cell: ({ getValue }) => getValue() ? formatDateTime(new Date(getValue())) : null,
  },
  ...(discrepancyType !== 'unexpected' ? [
    {
      title: t('reportingDiscrepancies.columns.pallets', 'Pallets'),
      field: 'content.pallet',
      sortable: false,
      cell: ({ row }) => {
        return (
          <div className='vtmn-flex vtmn-flex-row vtmn-flex-wrap'>
            {
              (row.original.content || []).map((x) => (
                <VpSticker
                  size="small"
                  variant="secondary"
                  key={`${x.pallet}_${x.parcel}`}
                >
                  {x.pallet}
                </VpSticker>
              ))
            }
          </div>
        )
      }
    },
    {
      title: t('reportingDiscrepancies.columns.parcels', 'Parcels'),
      field: 'content',
      sortable: false,
      cell: ({ getValue }) => {
        return (
          <div className='vtmn-flex vtmn-flex-row vtmn-flex-wrap'>
            {
              (getValue() || []).map((x) => (
                <VpSticker
                  size="small"
                  variant="secondary"
                  key={x.parcel}
                >
                  {x.parcel}
                </VpSticker>
              ))
            }
          </div>
        )
      },
    },
  ] : []),
  {
    title: (
      discrepancyType === 'wrong_size'
        ? t('reportingDiscrepancies.columns.receivedItem', 'Received item')
        : t('reportingDiscrepancies.columns.item', 'Item')
    ),
    field: 'item',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.modelId', 'Model Id'),
    field: 'modelId',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.rfid', 'RFID'),
    field: 'articleFlag',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.price', 'Selling price'),
    field: 'price',
    align: 'right',
    sortable: true,
    cell: ({ getValue, row }) => (
      <VpSticker size="small">
        {formatMoney(getValue(), row.original.currency, '-')}
      </VpSticker>
    ),
  },
  {
    title: t('reportingDiscrepancies.columns.cessionPrice', 'Cession price'),
    field: 'cessionPrice',
    align: 'right',
    sortable: true,
    cell: ({ getValue, row }) => (
      <VpSticker size="small">
        {formatMoney(getValue(), row.original.cessionCurrency, '-')}
      </VpSticker>
    ),
  },
  {
    title: t('reportingDiscrepancies.columns.itemLib', 'Item description'),
    field: 'itemLib',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.family', 'Family'),
    field: 'familyLabel',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.subDepartment', 'Sub department'),
    field: 'subDepartmentLabel',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.universe', 'Universe'),
    field: 'universeLabel',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.movementCategories', 'Movement categories'),
    field: 'movementCategories',
    sortable: true,
    cell: ({ getValue }) => {
      const categories = getMovementCategories(t, discrepancyType);
      return categories[getValue()] || getValue();
    }
  },
  ...((discrepancyType !== 'unexpected' && discrepancyType !== 'wrong_size') ? [
    {
      title: t('reportingDiscrepancies.columns.shippingList', 'Shipping list'),
      field: 'qtyConfirmed',
      sortable: true,
    },
    {
      title: t('reportingDiscrepancies.columns.readInWarehouse', 'Read in warehouse'),
      field: 'qtyReadBeforeWarehouseGate',
      sortable: true,
    },
    {
      title: t('reportingDiscrepancies.columns.storeGate', 'Store inbound'),
      field: 'qtyReadByStoreGate',
      sortable: true,
    },
    {
      title: t('reportingDiscrepancies.columns.missing', 'Missing'),
      field: 'missing',
      sortable: true,
    },
  ] : [
    {
      title: t('reportingDiscrepancies.columns.unexpected', 'Unexpected'),
      field: 'unexpectedQty',
      sortable: true,
    },
  ]),
  {
    title: t('reportingDiscrepancies.columns.realizedMovement', 'Realized movement'),
    field: 'realizedMovement',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.sellingValue', 'Selling value'),
    field: 'value',
    align: 'right',
    sortable: true,
    width: 100,
    cell: ({ getValue, row }) => {
      if (getValue() == null) {
        return '-';
      }
      if (getValue() instanceof Array) {
        return getValue().map(totalValRow => {
          const currency = getSymbolFromCurrency(totalValRow.currency) || totalValRow.currency;
          const totalValRowVal = totalValRow.value;
          return <div key={totalValRow.currency}>{formatMoney(totalValRowVal, currency)}</div>;
        })
      }
      return (
        <VpSticker size="small">
          {formatMoney(getValue(), row.original.currency)}
        </VpSticker>
      )
    }
  },
  {
    title: t('reportingDiscrepancies.columns.cessionValue', 'Cession value'),
    field: 'cessionValue',
    align: 'right',
    sortable: true,
    width: 100,
    cell: ({ getValue, row }) => {
      if (getValue() == null) {
        return '-';
      }
      if (getValue() instanceof Array) {
        return getValue().map(totalValRow => {
          const currency = getSymbolFromCurrency(totalValRow.cessionCurrency) || totalValRow.cessionCurrency;
          const totalValRowVal = totalValRow.cessionValue;
          return <div key={totalValRow.currency}>{formatMoney(totalValRowVal, currency)}</div>;
        })
      }
      return (
        <VpSticker size="small">
          {formatMoney(getValue(), row.original.cessionCurrency)}
        </VpSticker>
      )
    }
  },
  ...(discrepancyType === 'transport' ? [{
    title: t('reportingDiscrepancies.columns.epcTags', 'EPC tags'),
    field: 'epcTags',
    sortable: false,
    width: 100,
    cell: ({ getValue }) => getValue() && getValue().map(tag => <div key={tag}>{tag}</div>),
  }] : []),
  ...(discrepancyType === 'wrong_size' ? [{
    title: t('reportingDiscrepancies.columns.wrongSizes', 'Missing items'),
    field: 'wrongSizes',
    sortable: false,
    cell: ({ getValue }) =>
      getValue() && getValue().length
        ? getValue().map((x, index) => <div key={index}>{`${x.item} - ${x.itemLib}`}</div>)
        : null,
  }] : []),
];

const getFilters = (filters, invertedFilters, discrepancyType) => {
  const withInverted = getFiltersWithInverted(filters, invertedFilters);
  return { ...withInverted, discrepancyType };
};


const DiscrepanciesTab = ({ user, updateTokens, discrepancyType }) => {
  const { t } = useTranslation();
  const { warehouse } = useParams();
  const [savedFilters, setSavedFilters] = useState({});
  const initialFilters = {
    receptionDateFrom: dayjs().startOf('day').subtract(7, 'day').toDate(),
    receptionDateTo: dayjs().endOf('day').toDate(),
  };

  const withWarehouse = !warehouse;
  const [{
    loading,
    data,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetchReport, setTableFilters, setPagination, setSort] = useTableRequest(
    getReport, {
      initialState: {
        filters: initialFilters,
      }
    }
  );

  const filtersPacking = (filters, invertedFilters) => ({
    ...savedFilters,
    common: {
      filters: _.omit(filters, EXCLUDE_FROM_KEEPING),
      invertedFilters: _.omit(invertedFilters, EXCLUDE_FROM_KEEPING),
    },
    [discrepancyType]: {
      filters: _.omit(
        _.pick(filters, EXCLUDE_FROM_KEEPING),
        ['receptionDateFrom', 'receptionDateTo']
      ),
      invertedFilters: _.omit(
        _.pick(invertedFilters, EXCLUDE_FROM_KEEPING),
        ['receptionDateFrom', 'receptionDateTo']
      ),
    }
  });
  const filtersUnpacking = (saved) => {
    setSavedFilters(saved);
    const formated = {
      ...saved.common.filters,
      ...(saved[discrepancyType] ? saved[discrepancyType].filters : {}),
    };

    return [
      formated,
      {
        ...saved.common.invertedFilters,
        ...(saved[discrepancyType] ? saved[discrepancyType].invertedFilters : {}),
      },
    ]
  };
  const pageName = `${(!warehouse ? 'site' : 'warehouse')}${PAGE_NAME}`;
  const {
    isLoading: isLoadingFilters,
    error: actionError,
    setFilters,
    invertedFilters,
    setInvertedFilters,
  } = useUserFilters({
    page: pageName,
    user,
    updateTokens,
    initial: {
      common: {
        filters: initialFilters,
      }
    },
    setTableFilters,
    filtersPacking,
    filtersUnpacking,
  });

  useEffect(() => {
    if (isLoadingFilters) {
      return;
    }

    fetchReport(user, updateTokens, {
      filters: getFilters(filters, invertedFilters, discrepancyType),
      pagination,
      sort,
    }, warehouse);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse, filters, invertedFilters, discrepancyType, pagination, sort, isLoadingFilters]);

  const [{
    loading: totalsLoading,
    data: totalsData,
    error: totalsError,
  }, fetchTotals] = useRequest(getReportTotals);

  useEffect(() => {
    if (isLoadingFilters) {
      return;
    }

    fetchTotals(user, updateTokens, { filters: getFilters(filters, invertedFilters, discrepancyType) }, warehouse);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse, filters, invertedFilters, discrepancyType, isLoadingFilters]);

  return (
    <>
      <div className="vtmn-px-4">
        <Filters
          withWarehouse={withWarehouse}
          user={user}
          updateTokens={updateTokens}
          filters={filters}
          totals={totals}
          discrepancyType={discrepancyType}
          sort={sort}
          setFilters={setFilters}
          onClear={() => {
            setFilters(initialFilters);
            setInvertedFilters({});
          }}
          invertedFilters={invertedFilters}
          setInvertedFilters={setInvertedFilters}
        />
      </div>

      <DataTable
        className="vtmn-h-full"
        columns={getFields({ t, discrepancyType, withWarehouse })}
        data={data || []}
        totals={totals}
        pagination={pagination}
        setPagination={setPagination}
        meta={{
          sort, setSort
        }}
        summaryRow={{ ...totalsData, movementCategories: 'grand_total'}}
      />
    </>
  );
};

const ReportingDiscrepancies = ({ user, updateTokens }) => {
  return (
    <>
      <div className="warehouseReportingDiscrepancies vtmn-mx-0 mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
        <Tabs defaultValue="expedition">
          <Tabs.Header>
            <Tabs.Item value="expedition">
              <Trans i18nKey="reportingDiscrepancies.expedition">Expedition</Trans>
            </Tabs.Item>
            <Tabs.Item value="unexpected">
              <Trans i18nKey="reportingDiscrepancies.unexpected">Unexpected</Trans>
            </Tabs.Item>
            <Tabs.Item value="wrong_size">
              <Trans i18nKey="reportingDiscrepancies.wrongSize">Wrong size</Trans>
            </Tabs.Item>
          </Tabs.Header>
          <Tabs.Content value="expedition">
            <DiscrepanciesTab user={user} updateTokens={updateTokens} discrepancyType="expedition" />
          </Tabs.Content>
          <Tabs.Content value="unexpected">
            <DiscrepanciesTab user={user} updateTokens={updateTokens} discrepancyType="unexpected" />
          </Tabs.Content>
          <Tabs.Content value="wrong_size">
            <DiscrepanciesTab user={user} updateTokens={updateTokens} discrepancyType="wrong_size" />
          </Tabs.Content>
        </Tabs>
      </div>
    </>
  )
}

export default ReportingDiscrepancies;
