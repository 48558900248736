import React, { useEffect, useMemo } from 'react';
import {
  VpAsset,
} from "@vtmn-play/react";

import { cn } from "../../../../shadcn/lib/utils";
import useRequest from '../../../../functions/useRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import getQueryString from '../../../../functions/getQueryString';
import handleApiResponse from '../../../../functions/handleApiResponse';

import Combobox from './Combobox';

const getAvailableStores = (user, updateTokens, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({
    filters
  });

  const url = `${process.env.REACT_APP_base_URL}/api/stores?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const getWarehouses = (user, updateTokens, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({
    filters
  });

  const url = `${process.env.REACT_APP_base_URL}/api/warehouses?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const StoreSelect = ({
  user,
  updateTokens,
  label,
  filter,
  value,
  onChange,
  className,
  disabled,
  inverted,
  onInvert,
}) => {
  const [{
    loading: storesLoading,
    data: storesData,
    error: storesError,
  }, fetchAvailableStores] = useRequest(getAvailableStores);
  const [{
    loading: warehouseLoading,
    data: warehouseData,
    error: warehouseError,
  }, fetchWarehouses] = useRequest(getWarehouses);
  const loading = storesLoading || warehouseLoading;
  const error = storesError || warehouseError;

  useEffect(() => {
    fetchAvailableStores(user, updateTokens, filter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, updateTokens, filter]);
  useEffect(() => {
    fetchWarehouses(user, updateTokens, filter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, updateTokens, filter]);

  const options = useMemo(() => {
    const stores = (storesData && storesData.map(store => ({
      label: (
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-py-2">
          <VpAsset className="vtmn-mr-2" name={`flag-${store.countryCode.toLowerCase()}`} width="24" />
          {store.id} - {store.name}
        </div>
      ),
      id: store.id,
    }))) || [];
    const warehouses = (warehouseData && warehouseData.map(warehouse => ({
      label: (
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-py-2">
          <VpAsset className="vtmn-mr-2" name={`flag-${warehouse.countryCode.toLowerCase()}`} width="24" />
          {warehouse.id} - {warehouse.name}
        </div>
      ),
      id: warehouse.id,
    }))) || [];
    return [
      ...stores,
      ...warehouses,
    ];
  }, [storesData, warehouseData]);

  return (
    <Combobox
      label={label}
      options={options}
      selected={value}
      onChange={onChange}
      disabled={disabled}
      className={cn("vtmn-min-w-[200px]", className)}
      valueClassName="[&>div]:vtmn-py-0"
    />
  )
}

export default StoreSelect;
