import React from 'react';
import _ from 'lodash';
import { Trans } from 'react-i18next';
import { VpButton, VpSticker, VpCheckbox } from "@vtmn-play/react";
import { VpEditIcon } from "@vtmn-play/icons/react";

import { cn } from '../../shadcn/lib/utils';
import formatMoney from '../../functions/formatMoney';
import { formatDate, formatDurationSec } from '../../functions/formatDate';

import { getStatusMap, getTypesMap } from './statuses';

export const dateRenderer = (value) => value ? formatDate(new Date(value)) : null;
const moneyRenderer = ({ getValue, row }) => formatMoney(getValue(), row.original.currency);
const numberRenderer = (value) => _.isNil(value) ? null : Number(value.toFixed(2));

export const getColumns = ({ t, askValidation }) => {
  const typesMap = getTypesMap(t);
  const statusMap = getStatusMap(t);

  return [{
    title: t('efficiencyAdmin.columns.recordType ', 'Record type '),
    field: 'type',
    sortable: true,
    default: true,
    cell: ({ row, getValue }) => {
      return (
        <VpButton
          size="small"
          endSlot={getValue() === 'incorrect' && <VpEditIcon />}
          onClick={() => getValue() === 'incorrect' && askValidation(row.original.id)}
          variant={getValue() === 'incorrect' ? 'negative' : 'primary'}
          className={cn(
            "!vtmn-px-3 vtmn-max-w-[110px]",
            getValue() !== 'incorrect' && "vtmn-bg-success"
          )}
        >
          {typesMap[getValue()].label || getValue()}
        </VpButton>
      )
    }
  }, {
    title: t('efficiencyAdmin.columns.date', 'Date'),
    field: 'unloading_start_date',
    sortable: true,
    default: true,
    cell: ({ getValue }) => dateRenderer(getValue())
  }, {
    title: t('efficiencyAdmin.columns.store', 'Store'),
    field: 'site_id',
    sortable: true,
    default: true,
    cell: ({ row }) => `${row.getValue("site_id")} - ${row.original.site_name}`
  }, {
    title: t('efficiencyAdmin.columns.leader', 'Leader'),
    field: 'leader',
    sortable: true,
    default: true,
  }, {
    title: t('efficiencyAdmin.columns.warehouse', 'Warehouse'),
    field: 'warehouse_id',
    sortable: true,
    default: false,
  }, {
    title: t('efficiencyAdmin.columns.deliveries', 'Deliveries'),
    field: 'deliveries',
    default: true,
    cell: ({ row }) => {
      return row.getValue("deliveries").map(({ id }) => (
        <VpSticker size="small" variant="secondary">{id}</VpSticker>
      ))
    }
  }, {
    title: t('efficiencyAdmin.columns.quantities', 'Quantities'),
    field: 'quantities',
    sortable: true,
    default: true,
  }, {
    title: t('efficiencyAdmin.columns.parcels', 'Parcels'),
    field: 'parcels',
    sortable: true,
    default: false,
  }, {
    title: t('efficiencyAdmin.columns.pallets', 'Pallets'),
    field: 'pallets',
    sortable: true,
    default: false,
  }, {
    title: t('efficiencyAdmin.columns.unloadingTeammates', 'Unloading teammates'),
    field: 'unloading_teammates',
    sortable: true,
    default: true,
    cell: ({ row }) => `${row.getValue("unloading_teammates")} (${numberRenderer(row.original.unloading_teammates_involved)})`
  }, {
    title: t('efficiencyAdmin.columns.shelvingTeammates', 'Shelving teammates'),
    field: 'shelving_teammates',
    sortable: true,
    default: true,
    cell: ({ row }) => `${row.getValue("unloading_teammates")} (${numberRenderer(row.original.shelving_teammates_involved)})`
  }, {
    title: t('efficiencyAdmin.columns.plannedOverallProductivity', 'Planned overall productivity'),
    field: 'planned_overall_productivity',
    sortable: true,
    default: false,
    cell: ({ getValue }) => numberRenderer(getValue())
  }, {
    title: t('efficiencyAdmin.columns.plannedUnloadingProductivity', 'Planned unloading productivity'),
    field: 'planned_unloading_productivity',
    sortable: true,
    default: false,
    cell: ({ getValue }) => numberRenderer(getValue())
  }, {
    title: t('efficiencyAdmin.columns.plannedShelvingProductivity', 'Planned shelving productivity'),
    field: 'planned_shelving_productivity',
    sortable: true,
    default: false,
    cell: ({ getValue }) => numberRenderer(getValue())
  }, {
    title: t('efficiencyAdmin.columns.plannedUnloadingInvestment', 'Planned unloading investment'),
    field: 'planned_unloading_investment',
    sortable: true,
    default: false,
    cell: moneyRenderer
  }, {
    title: t('efficiencyAdmin.columns.plannedShelvingInvestment', 'Planned shelving investment'),
    field: 'planned_shelving_investment',
    sortable: true,
    default: false,
    cell: moneyRenderer
  }, {
    title: t('efficiencyAdmin.columns.plannedTotalInvestment', 'Planned total investment'),
    field: 'planned_total_investment',
    sortable: true,
    default: false,
    cell: moneyRenderer
  }, {
    title: t('efficiencyAdmin.columns.plannedUnloadingDuration', 'Planned unloading duration'),
    field: 'planned_unloading_duration',
    sortable: true,
    default: false,
    cell: ({ getValue }) => formatDurationSec(getValue())
  }, {
    title: t('efficiencyAdmin.columns.plannedShelvingDuration', 'Planned shelving duration'),
    field: 'planned_shelving_duration',
    sortable: true,
    default: false,
    cell: ({ getValue }) => formatDurationSec(getValue())
  }, {
    title: t('efficiencyAdmin.columns.plannedTotalDuration', 'Planned total duration'),
    field: 'planned_total_duration',
    sortable: true,
    default: false,
    cell: ({ getValue }) => formatDurationSec(getValue())
  }, {
    title: t('efficiencyAdmin.columns.unloadingDuration', 'Unloading duration'),
    field: 'unloading_duration',
    sortable: true,
    default: false,
    cell: ({ getValue }) => formatDurationSec(getValue())
  }, {
    title: t('efficiencyAdmin.columns.shelvingDuration', 'Shelving duration'),
    field: 'shelving_duration',
    sortable: true,
    default: false,
    cell: ({ getValue }) => formatDurationSec(getValue())
  }, {
    title: t('efficiencyAdmin.columns.totalDuration', 'Total duration'),
    field: 'total_duration',
    sortable: true,
    default: true,
    cell: ({ getValue }) => formatDurationSec(getValue())
  }, {
    title: t('efficiencyAdmin.columns.overallProductivity', 'Overall productivity'),
    field: 'overall_productivity',
    sortable: true,
    default: true,
    cell: ({ getValue }) => numberRenderer(getValue())
  }, {
    title: t('efficiencyAdmin.columns.productivity', 'Shelving productivity'),
    field: 'shelving_productivity',
    sortable: true,
    default: true,
    cell: ({ getValue }) => numberRenderer(getValue())
  }, {
    title: t('efficiencyAdmin.columns.unloadingInvestment', 'Unloading investment'),
    field: 'unloading_investment',
    sortable: true,
    default: false,
    cell: moneyRenderer
  }, {
    title: t('efficiencyAdmin.columns.shelvingInvestment', 'Shelving investment'),
    field: 'shelving_investment',
    sortable: true,
    default: false,
    cell: moneyRenderer
  }, {
    title: t('efficiencyAdmin.columns.totalInvestment', 'Total investment'),
    field: 'total_investment',
    sortable: true,
    default: true,
    cell: moneyRenderer
  }, {
    title: t('efficiencyAdmin.columns.status', 'Status'),
    field: 'status',
    sortable: true,
    default: true,
    cell: ({ getValue }) => (
      <VpSticker
        size="small"
        variant="secondary"
        className={cn(
          "!vtmn-text-white",
          getValue() === 'incomplete' ? "vtmn-bg-danger" : "vtmn-bg-success"
        )}
      >
        {statusMap[getValue()].label || getValue()}
      </VpSticker>
    )
  }];
}

const getActionColumn = ({ onEdit, onDel }) => ({
  id: 'action',
  sortable: false,
  width: 150,
  cell: ({ row }) => (
    <div className="d-flex justify-content-center">
      <VpButton size="small" className="!vtmn-px-3 vtmn-max-w-[90px] vtmn-mr-1" onClick={() => onEdit(row.original)}>
        <Trans i18nKey="efficiencyAdmin.edit">Edit</Trans>
      </VpButton>
      <VpButton variant="negative" size="small" className="!vtmn-px-3 vtmn-max-w-[90px]" onClick={() => onDel(row.original)}>
        <Trans i18nKey="efficiencyAdmin.del">Del</Trans>
      </VpButton>
    </div>
  )
})

export const getDefaultSelection = (fields) => {
  const defaultSelection = {};
  for (const { field } of fields.filter(x => x.default)) {
    defaultSelection[field] = true;
  }
  return defaultSelection;
};

export const getSelectedFields = ({ fields, selection, onEdit, onDel }) => {
  const selected = [
    {
      id: 'selection',
      sortable: false,
      toolbar: ({ table }) => (
        <VpCheckbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onChange={(event) => table.toggleAllPageRowsSelected(!!event.currentTarget.checked)}
          aria-label="Select all"
          className="[&>div]:vtmn-h-4 [&>div]:vtmn-max-w-4 vtmn-mb-0"
        />
      ),
      cell: ({ row }) => (
        <VpCheckbox
          checked={row.getIsSelected()}
          onChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
          className="[&>div]:vtmn-h-4 [&>div]:vtmn-max-w-4 vtmn-mb-0"
        />
      ),
    },
    getActionColumn({ onEdit, onDel }),
    ...fields.filter(x => selection[x.field]),
  ];
  return selected;
};
