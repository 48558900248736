import React from 'react';
import formatMoney from '../../../../functions/formatMoney';
import { formatDateTime } from '../../../../functions/formatDate';

export const getFields = (t) => [
  {
    title: t('controlReport.columns.address', 'Address'),
    field: 'address',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.contenant', 'Contenant'),
    field: 'contenant',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.picker', 'Picker'),
    field: 'picker',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.confirmed', 'Confirmed'),
    field: 'confirmed',
    sortable: true,
    default: true,
    cell: ({ getValue }) => (
      getValue()
        ? t('controlReport.columns.yes', 'Yes')
        : t('controlReport.columns.no', 'No')
    ),
  },
  {
    title: t('controlReport.columns.controlDate', 'Control date'),
    field: 'controlDate',
    sortable: true,
    default: true,
    cell: ({ getValue }) => getValue() ? formatDateTime(new Date(getValue())) : null,
  },
  {
    title: t('controlReport.columns.discrepancyType', 'Discrepancy type'),
    field: 'discrepancyType',
    sortable: true,
    default: true,
    cell: ({ getValue }) => {
      const discrepancyTypes = {
        'expedition': t('controlReport.columns.discrepancyTypeVelues.expedition', 'Expedition'),
        'unexpected': t('controlReport.columns.discrepancyTypeVelues.unexpected', 'Unexpected'),
      };
      return discrepancyTypes[getValue()];
    }
  },
  {
    title: t('controlReport.columns.store', 'Store'),
    field: 'store',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.delivery', 'Delivery'),
    field: 'delivery',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.receptionDate', 'Reception date'),
    field: 'receptionDate',
    sortable: true,
    default: true,
    cell: ({ getValue }) => getValue() ? formatDateTime(new Date(getValue())) : null,
  },
  {
    title: t('controlReport.columns.warehouseSector', 'Warehouse sector'),
    field: 'warehouse_sector',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.parcels', 'Parcels'),
    field: 'content',
    sortable: false,
    default: true,
    cell: ({ getValue }) => (getValue() || []).map(x => <div key={x.parcel}>{x.parcel}</div>),
  },
  {
    title: t('controlReport.columns.item', 'Item'),
    field: 'item',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.modelId', 'Model Id'),
    field: 'modelId',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.rfid', 'RFID'),
    field: 'articleFlag',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.price', 'Selling price'),
    field: 'price',
    align: 'right',
    sortable: true,
    default: true,
    cell: ({ getValue, row }) => formatMoney(getValue(), row.original.currency, '-'),
  },
  {
    title: t('controlReport.columns.cessionPrice', 'Cession price'),
    field: 'cessionPrice',
    align: 'right',
    sortable: true,
    default: true,
    cell: ({ getValue, row }) => formatMoney(getValue(), row.original.cessionCurrency, '-'),
  },
  {
    title: t('controlReport.columns.itemLib', 'Item description'),
    field: 'itemLib',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.family', 'Family'),
    field: 'familyLabel',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.subDepartment', 'Sub department'),
    field: 'subDepartmentLabel',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.universe', 'Universe'),
    field: 'universeLabel',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.shippingList', 'Shipping list'),
    field: 'qtyConfirmed',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.pickedParty', 'Picked party'),
    field: 'picked_party',
    sortable: false,
    default: true,
    cell: ({ getValue }) => (getValue() || []).join(', '),
  },
  {
    title: t('controlReport.columns.readInWarehouse', 'Read in warehouse'),
    field: 'qtyReadBeforeWarehouseGate',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.storeGate', 'Store inbound'),
    field: 'qtyReadByStoreGate',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.missing', 'Missing'),
    field: 'missing',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.unexpected', 'Unexpected'),
    field: 'unexpectedQty',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.realizedMovement', 'Realized movement'),
    field: 'realizedMovement',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.ipFound', 'IP found'),
    field: 'foundQuantity',
    sortable: true,
    default: true,
  },
  {
    title: t('controlReport.columns.sellingValue', 'Selling value'),
    field: 'value',
    align: 'right',
    sortable: true,
    default: true,
    width: 100,
    cell: ({ getValue, row }) => formatMoney(getValue(), row.original.currency, '-'),
  },
  {
    title: t('controlReport.columns.cessionValue', 'Cession value'),
    field: 'cessionValue',
    align: 'right',
    sortable: true,
    default: true,
    width: 100,
    cell: ({ getValue, row }) => formatMoney(getValue(), row.original.cessionCurrency, '-'),
  },
  {
    title: t('controlReport.columns.notHere', 'Contenant not here'),
    field: 'notHere',
    sortable: false,
    default: true,
    cell: ({ getValue }) => getValue() ? 'Yes' : 'No',
  },
];

export const getDefaultSelection = (fields) => {
  const defaultSelection = {};
  for (const { field } of fields.filter(x => x.default)) {
    defaultSelection[field] = true;
  }
  return defaultSelection;
};

export const getSelectedFields = ({
  fields,
  selection,
}) => {
  const selected = fields.filter(x => selection[x.field]);
  return selected;
};
