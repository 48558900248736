import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  VpFormControl,
  VpFormError,
  VpFormHelper,
  VpFormLabel,
  VpInput,
} from "@vtmn-play/react";
import { VpSearchIcon } from "@vtmn-play/icons/react";

import useDebounce from '../../../../functions/useDebounce';
import { cn } from '../../../../shadcn/lib/utils';

const Search = ({
  value,
  onChange,
  onRefresh,
  loading,
  label,
  className,
}) => {
  const { t } = useTranslation();
  const [stateValue, setStateValue] = useState(value);
  const debouncedValue = useDebounce(stateValue, 500);

  useEffect(
    () => {
      if (value !== debouncedValue) {
        onChange(debouncedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );
  useEffect(
    () => { setStateValue(value); },
    [value],
  );

  return (
    <VpFormControl
      className={cn(
        "vtmn-flex vtmn-flex-col form-content",
        className,
      )}
    >
      {!!label && (
        <VpFormLabel className="!vtmn-items-start">
          <div className={cn(
            "vtmn-flex vtmn-flex-row vtmn-items-center vtmn-w-full",
          )}>
            <span>{label}</span>
            
          </div>
        </VpFormLabel>
      )}
      <VpInput
        size="small"
        value={value}
        disabled={loading}
        endSlot={<VpSearchIcon />}
        onChange={(e) => {
          if (e) setStateValue(e.target.value);
        }}
      />
    </VpFormControl>
  );
};

export default Search;
