import React from 'react';
import { Trans } from 'react-i18next';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn/components/ui/tooltip";

const StayedAtWarehouseLabel = ({ validated }) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger>
        <div>
          <Trans i18nKey="filters.stayedAtWarehouse">
            Stayed at warehouse
          </Trans>
        </div>
      </TooltipTrigger>
      <TooltipContent side="bottom">
        <div className="vtmn-w-[200px]">
          <ul className="">
            {validated && (
              <li>
                - <Trans i18nKey="filters.stayedAtWarehouseTitleValidated">
                  validated
                </Trans>
              </li>
            )}
            <li>
              - <Trans i18nKey="filters.stayedAtWarehouseTitleBeforeGate">
                security activated at warehouse {'>'} both read in store and by warehouse gate
              </Trans>
            </li>
            <li>
              - <Trans i18nKey="filters.stayedAtWarehouseTitleShipping">
                by shipping list {'>'} both read in store and by warehouse gate
              </Trans>
            </li>
          </ul>
        </div>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export default StayedAtWarehouseLabel;
