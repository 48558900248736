import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import _ from 'lodash';

import { getFields, getDefaultSelection, getSelectedFields} from './fields';
import { formatDateForBigQuery, formatDateTimeForBigQuery, startOfDay } from '../../../../functions/formatDate';
import useTableRequest from '../../../../functions/useTableRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import { handlePaginatedApiResponse } from '../../../../functions/handleApiResponse';
import getQueryString from '../../../../functions/getQueryString';
import getFiltersWithInverted from '../../../../functions/getFiltersWithInverted';
import { useUserFilters } from '../../../../functions/filtersKeeper';

import Filters from './Filters';
import DataTable from "../../../widgets/vtmn/DataTable";

const PAGE_NAME = 'contenantsReport';

const getReport = (user, updateTokens, { filters, pagination, sort }, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (formatedFilters.receptionDateFrom) {
    formatedFilters.receptionDateFrom = formatDateForBigQuery(formatedFilters.receptionDateFrom);
  }
  if (formatedFilters.receptionDateTo) {
    formatedFilters.receptionDateTo = formatDateTimeForBigQuery(formatedFilters.receptionDateTo);
  }
  if (formatedFilters.controlDateFrom) {
    formatedFilters.controlDateFrom = startOfDay(formatedFilters.controlDateFrom);
  }
  if (formatedFilters.controlDateTo) {
    formatedFilters.controlDateTo = formatDateTimeForBigQuery(formatedFilters.controlDateTo);
  }
  if (formatedFilters.stayedAtWarehouse) {
    formatedFilters.stayedAtWarehouse = 'true';
  }
  if (formatedFilters.notHere) {
    formatedFilters.notHere = 'true';
  }

  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
    sort,
  })
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/controls/report?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const Report = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const { warehouse } = useParams();
  const initialFilters = {
    controlDateFrom: dayjs().startOf('day').subtract(7, 'day').toDate(),
    controlDateTo: dayjs().endOf('day').toDate(),
  };

  const [{
    loading,
    data,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetchReport, setTableFilters, setPagination, setSort] = useTableRequest(
    getReport, {
      initialState: {
        filters: initialFilters,
      }
    }
  );

  const filtersPacking = (filters, invertedFilters) => ({
    filters: _.omit(filters, ['receptionDateFrom', 'receptionDateTo', 'controlDateFrom', 'controlDateTo']),
    invertedFilters: invertedFilters || {},
  });
  const filtersUnpacking = (saved) => [saved.filters, saved.invertedFilters || {}];
  const {
    isLoading: isLoadingFilters,
    error: actionError,
    setFilters,
    invertedFilters,
    setInvertedFilters,
  } = useUserFilters({
    page: PAGE_NAME,
    user,
    updateTokens,
    initial: {
      filters: initialFilters,
    },
    setTableFilters,
    filtersPacking,
    filtersUnpacking,
  });

  useEffect(() => {
    if (isLoadingFilters) {
      return;
    }

    fetchReport(user, updateTokens, {
      filters: getFiltersWithInverted(filters, invertedFilters),
      pagination,
      sort,
    }, warehouse);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse, filters, invertedFilters, pagination, sort, isLoadingFilters]);

  const allFields = getFields(t);
  const [fieldsSelection, setFieldsSelection] = useState(getDefaultSelection(allFields));
  const fields = getSelectedFields({
    fields: allFields,
    selection: fieldsSelection,
  });

  return (
    <div className="warehouseContenantsReport mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
      <div className="vtmn-px-4">
        <Filters
          user={user}
          updateTokens={updateTokens}
          filters={filters}
          totals={totals}
          sort={sort}
          setFilters={setFilters}
          onClear={() => {
            setFilters(initialFilters);
            setInvertedFilters({});
          }}
          invertedFilters={invertedFilters}
          setInvertedFilters={setInvertedFilters}
          fields={allFields}
          fieldsSelection={fieldsSelection}
          setFieldsSelection={setFieldsSelection}
        />
      </div>
      
      <DataTable
        className="vtmn-h-full"
        columns={fields}
        data={data || []}
        totals={totals}
        pagination={pagination}
        setPagination={setPagination}
        meta={{
          sort, setSort
        }}
      />
    </div>
  );
};

export default Report;

