import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { VpAsset } from "@vtmn-play/react";

import useRequest from '../../../../../functions/useRequest';
import fetchWithJWT from '../../../../../functions/fetchWithJWT';
import handleApiResponse from '../../../../../functions/handleApiResponse';
import { cn } from '../../../../../shadcn/lib/utils';

import MultiSelectFilter from '../MultiSelectFilter'
import Toggle from '../Toggle';

const getAvailableWarehouses = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/reporting/discrepancies/warehouses`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const WarehouseFilter = ({
  user,
  updateTokens,
  disabled,
  selected,
  onChange,
  invertable,
  inverted,
  onInvert,
}) => {
  const [{
    loading,
    data,
    error,
  }, fetchAvailableWarehouses] = useRequest(getAvailableWarehouses);
  const { t } = useTranslation();
  useEffect(() => {
    fetchAvailableWarehouses(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = (data && data.map(warehouse => ({
    // label: <div className="vtmn-py-2">{warehouse.id} - {warehouse.name}</div>,
    label: (
      <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-py-2">
        <VpAsset className="vtmn-mr-2" name={`flag-${warehouse.countryCode.toLowerCase()}`} width="24" />
        <span className='vtmn-overflow-ellipsis vtmn-overflow-hidden'>
          {warehouse.id} - {warehouse.name}
        </span>
      </div>
    ),
    id: warehouse.id,
  }))) || [];
  return (
    <MultiSelectFilter
      label={(
        <div className={cn(
          "vtmn-flex vtmn-flex-row vtmn-items-center vtmn-w-full",
          invertable && "vtmn-justify-between"
        )}>
          <span>{t('reportingDiscrepancies.filters.warehouse','Warehouse')}</span>
          {invertable && (
            <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
              <span className="vp-caption vtmn-mr-3">
                {t('filters.excludes', ' excludes')}
              </span>
              <Toggle value={inverted || false} onChange={onInvert} />
            </div>
          )}
        </div>
      )}
      options={options}
      selected={selected}
      onChange={onChange}
      disabled={disabled}
      className={cn(
        "vtmn-min-w-[200px]",
        invertable && "[&_label]:vtmn-mb-[2px]",
      )}
      valueClassName="[&_svg]:vtmn-hidden [&_button_svg]:vtmn-flex [&_span]:vtmn-w-[44px]"
    />
  );
}

export default WarehouseFilter;
