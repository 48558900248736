import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  VpButton,
  VpIconButton,
} from "@vtmn-play/react";

import { generateRandomString56, generateCodeChallengeFromVerifier } from '../functions/pkce';
import logoEnd2end from './logoEnd2end.jpg';
import productFlowMobile from './productFlowMobile.png';

const fedId_clientID = process.env.REACT_APP_fedID_clientID;
const fedID_URL = process.env.REACT_APP_fedID_URL;
const fedID_redirect_URI = process.env.REACT_APP_fedID_redirect_URI;

class Login extends Component {

  connectToFedid = async () => {
    const state = generateRandomString56();
    const codeVerifier = generateRandomString56();
    sessionStorage.setItem(`login-code-verifier-${state}`, codeVerifier);
    const codeChallenge = await generateCodeChallengeFromVerifier(codeVerifier);
    let authUrl = `${fedID_URL}?response_type=code&client_id=${fedId_clientID}&state=${state}&redirect_uri=${fedID_redirect_URI}&code_challenge=${codeChallenge}&code_challenge_method=S256&scope=profile openid`;
    if (this.props.reauthenticate) {
      authUrl += '&prompt=login'
    }
    window.location.replace(authUrl)
  }

  render(){
    const { t } = this.props;
    return(
      <div className="vtmn-w-full vtmn-h-full vtmn-flex vtmn-flex-col vtmn-items-center vtmn-justify-center vtmn-pb-5">
        <img className="vtmn-max-w-[210px]" src={logoEnd2end} alt="logo end2end"/>
        <div className="back vtmn-flex vtmn-w-full vtmn-justify-center">
          <img className="vtmn-max-w-[400px]" src={productFlowMobile} alt="product flow"/>
        </div>
        <VpButton onClick={this.connectToFedid} className="vtmn-mt-5">
          <span className='vp-body-l vtmn-px-5'>{t('login.login', 'Login')}</span>
        </VpButton>
      </div>
    )
  }
}

export default withTranslation()(Login);
