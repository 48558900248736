import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import _ from 'lodash';
import { VpLoader, VpIconButton, VpIcon, VpButton, VpBadge, VpSticker } from "@vtmn-play/react";
import { VpChevronDownIcon, VpChevronUpIcon, VpRefreshIcon, VpFilterIcon } from "@vtmn-play/icons/react";

import getPrice from '../../functions/getPrice';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { handleApiResponse, handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import getQueryString from '../../functions/getQueryString';
import { cn } from '../../shadcn/lib/utils';

import NumberInput from '../widgets/vtmn/form/NumberInput';
import Toggle from '../widgets/vtmn/filters/Toggle';
import DataTable from "../widgets/vtmn/DataTable";
import Tabs from "../widgets/vtmn/Tabs";
import ReadingRateModal from './ReadingRateModal';
import ProgressBar from './ProgressBar';
import PalletDetails from './palletDetails';
import { DisplayItem, calculateItemProps } from './displayItem';
import robotIconSuccessSrc from '../../images/icons/robot-icon-success.svg';
import robotIconWarningSrc from '../../images/icons/robot-icon-warning.svg';
import ReleaseNotification from '../releases/ReleaseNotification';
import {
  PAGE_NAME,
  REFRESH_INTERVAL,
  VALID_NATURE,
  NO_SPORT_LABEL,
  DEFAULT_FILTERS,
  DEFAULT_FILTER_VALUES,
  MOVEMENT_STATUSES_VARIANT,
} from './consts';
import UnexpectedTab from './unexpectedTab';
import CountDown from './CountDown';

export const CHECK_STOCK_OUT_RISK_INTERVAL = 36; //hours

const getTagsColorCls = (qty_confirmed, qty_read) => {
  if (!qty_read || !qty_confirmed) {
    return '';
  }
  if (qty_read > qty_confirmed * 0.9) {
    return 'vtmn-text-success';
  }
  if (qty_read > qty_confirmed * 0.5) {
    return 'vtmn-text-warning';
  }
  return 'vtmn-text-danger';
};

const calculateFullyReceived = (parcels) => {
  const fullyReceivedArray = parcels.filter(parcel => parcel.qty_read_by_store_gate >= parcel.qty_confirmed)
  return fullyReceivedArray;
}

const robotStatusTitles = {
  'robot_finished': 'Robot finished inventory after delivery ended',
  'robot_started': 'Robot started new inventory after delivery ended',
};

const capitalize = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

const columns = [{
  field: "id",
  title: <Trans i18nKey="liveStoreReception.table.reception">Reception</Trans>,
  className: "vtmn-flex-row-reverse vtmn-justify-end",
  toolbar: ({ table }) => {
    const { updateTable } = table.options.meta;
    return (
      <VpIconButton
        size="small"
        variant="secondary"
        onClick={updateTable}
        aria-label="Update receptions"
        className="!vtmn-h-6 !vtmn-w-6 vtmn-mr-2"
      >
        <VpRefreshIcon />
      </VpIconButton>
    )
  },
  cell: ({ row, table, renderValue }) => {
    const { getDataFromDelivery } = table.options.meta;
    const tagsCls = getTagsColorCls(row.original.qty_confirmed, row.getValue("qty_read"));

    return (
      <div
        className="mobile:vtmn-flex mobile:vtmn-flex-col"
        onClick={() => {
          table.toggleAllPageRowsSelected(false);
          row.toggleSelected(true);
          getDataFromDelivery(row.original);
        }}
      >
        {renderValue()}
        <div className="vtmn-hidden vp-caption mobile:vtmn-flex">
          <span className={`${tagsCls}`}>
            {`${row.getValue("qty_read")} / ${row.original.qty_confirmed}`}
          </span>
        </div>
      </div>
    )
  }
}, {
  field: "qty_read",
  title: <Trans i18nKey="liveStoreReception.table.tags">Tags</Trans>,
  className: "mobile:vtmn-hidden",
  cell: ({ row, table }) => {
    const { getDataFromDelivery } = table.options.meta;
    const tagsCls = getTagsColorCls(row.original.qty_confirmed, row.getValue("qty_read"));
    return (
      <div
        className={`${tagsCls}`}
        onClick={() => {
          table.toggleAllPageRowsSelected(false);
          row.toggleSelected(true);
          getDataFromDelivery(row.original);
        }}
      >
        {`${row.getValue("qty_read")} / ${row.original.qty_confirmed}`}
      </div>
    );
  }
}, {
  field: "reception_date",
  title: <Trans i18nKey="liveStoreReception.table.date">Date</Trans>,
  cell: ({ row, table }) => {
    const { getDataFromDelivery } = table.options.meta;
    const date = new Date(row.getValue("reception_date"));
    const formattedDate = date.toLocaleDateString('ru');
    return (
      <div
        onClick={() => {
          table.toggleAllPageRowsSelected(false);
          row.toggleSelected(true);
          getDataFromDelivery(row.original);
        }}
      >
        {formattedDate}
      </div>
    );
  }
}, {
  field: "status",
  title: <Trans i18nKey="liveStoreReception.table.status">Status</Trans>,
  cell: ({ row, table, renderValue }) => {
    const { getDataFromDelivery } = table.options.meta;
    const formattedChangeDate = row.getValue("status_change_date") && (new Date(row.getValue("status_change_date"))).toLocaleString('ru');

    return (
      <div
        className="mobile:vtmn-flex mobile:vtmn-flex-col"
        onClick={() => {
          table.toggleAllPageRowsSelected(false);
          row.toggleSelected(true);
          getDataFromDelivery(row.original);
        }}
      >
        {renderValue()}
        <div className="vtmn-hidden vp-caption mobile:vtmn-flex">
          <span>
            {formattedChangeDate}
          </span>
        </div>
      </div>
    )
  }
}, {
  field: "status_change_date",
  title: <Trans i18nKey="liveStoreReception.table.statusChange">Status change date</Trans>,
  className: "mobile:vtmn-hidden",
  cell: ({ row, table }) => {
    const { getDataFromDelivery } = table.options.meta;
    const formattedChangeDate = row.getValue("status_change_date") && (new Date(row.getValue("status_change_date"))).toLocaleString('ru');
    return (
      <div
        onClick={() => {
          table.toggleAllPageRowsSelected(false);
          row.toggleSelected(true);
          getDataFromDelivery(row.original);
        }}
      >
        {formattedChangeDate}
      </div>
    );
  }
}, {
  field: "movement_status",
  title: (
    <div className="vtmn-flex vtmn-w-full mobile:vtmn-hidden">
      <Trans i18nKey="liveStoreReception.table.movementsStatus">Movements status</Trans>
    </div>
  ),
  className: "mobile:vtmn-hidden",
  cell: ({ getValue, row, table }) => {
    return (
      <VpSticker
        size="small"
        variant="secondary"
        className={cn(
          getValue() !== 'NOT RECEIVED' ? "!vtmn-text-white" : "",
          `vtmn-bg-${MOVEMENT_STATUSES_VARIANT[getValue()]}`,
        )}
      >
        {
          getValue() === 'CHECKING TIME'
          ? <CountDown value={row.original.for_movements} />
          : getValue()
        }
      </VpSticker>
    )
  }
}, {
  field: "robot_status",
  title: (
    <div className="vtmn-flex vtmn-justify-center vtmn-w-full mobile:vtmn-hidden">
      <Trans i18nKey="liveStoreReception.table.robotStatus">Robot status</Trans>
    </div>
  ),
  cell: ({ row, table }) => {
    const { getDataFromDelivery } = table.options.meta;
    const robotIconSrc = row.getValue("robot_status") === 'robot_finished' ? robotIconSuccessSrc : robotIconWarningSrc;

    return (
      <div
        onClick={() => {
          table.toggleAllPageRowsSelected(false);
          row.toggleSelected(true);
          getDataFromDelivery(row.original);
        }}
      >
        {row.getValue("robot_status") && (
          <div
            className="iconCell mobile:vtmn-w-4"
            title={robotStatusTitles[row.getValue("robot_status")]}
          >
            <img className="robot-icon" src={robotIconSrc} alt="robot-status" />
          </div>
        )}
      </div>
    )
  },
  toolbar: ({ table }) => {
    const { toogleCollapse, selectedReception, isCollapsedList } = table.options.meta;
    return (
      <>
        {selectedReception && (
          <VpIconButton
            size="small"
            variant="secondary"
            onClick={() => toogleCollapse(!isCollapsedList)}
            aria-label={isCollapsedList ? "Expand" : "Collapse"}
            className="!vtmn-h-6 !vtmn-w-6"
          >
            {isCollapsedList ? <VpChevronDownIcon /> : <VpChevronUpIcon />}
          </VpIconButton>
        )}
      </>
    )
  }
}];

const getFilters = ({ user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/saved_filters?page=${PAGE_NAME}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
}

const splitEvenly = (plan, fact, byParcelPlan) => {
  let perParcelFact = byParcelPlan.map((parcelPlan) => Math.floor(fact * parcelPlan / plan));
  let factLeft = fact - _.sum(perParcelFact);
  if (factLeft) {
    perParcelFact.forEach((parcelFact, index) => {
      if (factLeft) {
        factLeft -= 1;
        perParcelFact[index] += 1;
      }
    });
  }
  return perParcelFact;
};

const defaultState = {
  currency: '$',
  receptions: [],
  myReception: { id: undefined },
  reveptionOpenTime: null,
  lastReceivedTagId: null,
  forceDisplayReceptions: null,
  shippingLists: [],
  receivedEPC: new Set(),
  itemDetails: [],
  unexpectedItems: [],
  unexpectedItemsStored: [],
  unexpectedItemsSizeMatches: [],
  movements: {},
  movementsUpdateError: null,
  stockExtractorQuotaLimitReached: false,
  movementAPICallsLimitReached: false,
  unexpectedSportItems: {},
  sportIndicators: [],
  palletIndicators: [],
  totalIndicator: {},
  selectedIndicator: null,
  palletsToDisplayContent: [],
  sportsToDisplayContent: [],
  sportsToDisplayEvenFullyReceivedItems: [],
  loadingReceptions: true,
  loadingPallets: false,
  loadingItemDetails: false,
  loadingNewTags: false,
  alert: false,
  alertMessage: null,
  warehouseReadingActivated: false,
  activeList: "by sport",
  shipmentsPage: 0,
  shipmentsPageSize: 10,
  shipmentsTotal: {
    pages: 0,
    items: 0,
  },
  selectedReception: null,
  isCollapsedList: false,
};

class LiveStoreReception extends Component {
  constructor(props){
    super(props);

    this.state = {
      ...defaultState,
      filters: DEFAULT_FILTERS,
      filterValues: DEFAULT_FILTER_VALUES,
      filtersVisible: false,
      authExpired: false,
      defaultMinimumPrice: 0,
      defaultMinimumValue: 0,
    };
  }

  displayAlerts = () => {
    const { t } = this.props;
    return(
      <>
        {this.state.authExpired ? <div className="alert alert-danger" role="alert">
          {t('liveStoreReception.errors.authExpired', 'Your authorization has expired, please relogin')}
          <VpButton
            size="small"
            shape="squared"
            onClick={() => window.location.reload()}
          >
            {t('liveStoreReception.errors.login', 'Login')}
          </VpButton>
        </div> : null}
        {this.state.alert ? <div className="alert alert-danger" role="alert">
          {t('liveStoreReception.errors.error', 'Something went wrong')}
          {' : '}
          {this.state.alertMessage}
        </div> : null}
        {this.state.movementsUpdateError ? <div className="alert alert-warning" role="alert">
          {t('liveStoreReception.errors.movementsUpdateError', 'Error during movements calculation')}
          {' : '}
          {`${this.state.movementsUpdateError}`}
        </div> : null}
        {this.state.stockExtractorQuotaLimitReached ? <div className="alert alert-warning" role="alert">
          {t('liveStoreReception.errors.stockExtractorQuotaLimitReached', 'We have reached limit of API calls to Stock Extractor')}
        </div> : null}
        {this.state.movementAPICallsLimitReached ? <div className="alert alert-warning" role="alert">
          {t('liveStoreReception.errors.movementAPICallsLimitReached', 'Partialy calculated movements: some movements might be omitted')}
        </div> : null}
      </>
    );
  }

  updateDeliveryTags = async () => {
    const hasSelectedDelivery = !!this.state.myReception.id;
    if (!hasSelectedDelivery) {
      return;
    }

    const newTags = await this.getDeliveryNewTags();
    if (newTags && newTags.length) {
      const lastTag = newTags[newTags.length - 1];
      this.setState({ lastReceivedTagId: lastTag.id });
      this.onNewTags(newTags);
    }
  }

  getDeliveryNewTags = () => {
    const delivery = this.state.myReception.id;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const storeId = this.props.match.params.store;
    const openTime = this.state.reveptionOpenTime.toISOString();
    const fromId = this.state.lastReceivedTagId;
    let url = `${process.env.REACT_APP_base_URL}/api/${storeId}/deliveries/v2/${delivery}/newTags?openTime=${openTime}`;
    if (fromId) {
      url += `&fromId=${fromId}`
    }

    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(handleApiResponse)
    .catch(error => {
      if (error.status === 401) {
        this.onAuthExpired();
      } else {
        console.warn('Error at getStoreEvents', error);
      }
    });
  };

  onAuthExpired = () => {
    this.setState({ authExpired: true });
  }

  onNewTags = async (tags) => {
    const hasSelectedDelivery = !!this.state.myReception.id;
    const isDeliveryLoading = this.state.loadingPallets || this.state.loadingItemDetails;
    if (!hasSelectedDelivery || isDeliveryLoading) {
      return;
    }

    this.setState({ loadingNewTags: true });

    const newTags = tags.filter(tag => !this.state.receivedEPC.has(tag.epc));
    const updatedShippingLists = [...this.state.shippingLists];
    const newReceivedEPC = new Set(this.state.receivedEPC);
    const newUnexpectedItems = [...this.state.unexpectedItems];

    const newItemsCodes = [];
    const newUnexpectedItemsCodes = [];

    newTags.forEach(tag => {
      newReceivedEPC.add(tag.epc);
      const chosenShippingListIndex = updatedShippingLists.findIndex(
        pickingLine => pickingLine.item === tag.item_code && pickingLine.qty_read_by_store_gate < pickingLine.qty_confirmed
      );
      const iFoundAShippingListToAllocateThisTag = chosenShippingListIndex !== -1;

      if (iFoundAShippingListToAllocateThisTag) {
        updatedShippingLists[chosenShippingListIndex].qty_read_by_store_gate++;
      } else {
        if (newUnexpectedItems.filter(item => item.item === tag.item_code).length === 0) {
          newUnexpectedItems.push({
            item: tag.item_code,
            qty_read_unexpected: 1,
            qty_read_by_warehouse_gate: 0, // loaded later
          });
          newUnexpectedItemsCodes.push(tag.item_code);
        } else {
          newUnexpectedItems.filter(item => item.item === tag.item_code)[0].qty_read_unexpected++;
        }
      }
      if (
        this.state.itemDetails.filter(details => details.item_id === tag.item_code).length === 0
        && newItemsCodes.indexOf(tag.item_code) === -1
      ) {
        newItemsCodes.push(tag.item_code);
      }
    });

    const delivery = this.state.myReception.id;

    if (newUnexpectedItemsCodes && newUnexpectedItemsCodes.length) {
      // loading qty_read_by_warehouse_gate for new unexpected items
      const warehouseUnexpected = await this.getWarehouseUnexpected(delivery, newUnexpectedItemsCodes);
      (warehouseUnexpected || []).forEach(undexpected => {
        const match = newUnexpectedItems.find(item => item.item === undexpected.item);
        if (match) {
          match.qty_read_by_warehouse_gate = undexpected.qty_read_by_warehouse_gate;
        }
      });
    }

    let updatedItemDetails = this.state.itemDetails;
    if (newItemsCodes && newItemsCodes.length) {
      // loading details for new items
      const newItemDetails = await this.getItemDetails(newItemsCodes)
      updatedItemDetails = [
        ...this.state.itemDetails,
        ...(newItemDetails.itemDetails || []),
      ];
    }

    const { movements } = this.state;
    const {
      sportIndicators,
      palletIndicators,
      totalIndicator,
    } = this.calculateDelivery(
      updatedItemDetails,
      updatedShippingLists,
      movements,
    );
    const unexpectedItemsSizeMatches = this.matchUnexpectedToWrongSizes(
      updatedShippingLists,
      newUnexpectedItems,
      updatedItemDetails,
    );
    const { totalUnexpected, unexpectedSportItems } = this.getUnexpectedSportItems(
      newUnexpectedItems,
      updatedItemDetails,
      movements,
      updatedShippingLists,
      unexpectedItemsSizeMatches,
    );
    this.updateReceptionTotalQties(delivery, totalIndicator);
    this.setState({
      receivedEPC: newReceivedEPC,
      unexpectedItems: newUnexpectedItems,
      unexpectedItemsStored: newUnexpectedItems,
      unexpectedItemsSizeMatches,
      shippingLists: updatedShippingLists,
      itemDetails: updatedItemDetails,
      sportIndicators,
      palletIndicators,
      totalIndicator: {
        ...totalIndicator,
        unexpected: totalUnexpected
      },
      loadingNewTags: false,
      unexpectedSportItems,
    });
  }

  componentDidMount() {
    const { user, updateTokens } = this.props;
    this.updateDeliveryTagsIntervalRef = setInterval(this.updateDeliveryTags, REFRESH_INTERVAL);
    this.getDeliveries();
    this.getDefaultValues().then(() => {
      getFilters({ user, updateTokens })
      .then(({ filters }) => {
        if (filters) {
          this.setState({
            filters: filters.filters ? filters.filters : DEFAULT_FILTERS,
            filterValues: filters.filterValues ? filters.filterValues : DEFAULT_FILTER_VALUES,
          });
        }
      })
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const prevStoreId = prevProps.match.params.store;
    const storeId = this.props.match.params.store;
    const prevPage = prevState.shipmentsPage;
    const prevPageSize = prevState.shipmentsPageSize;
    const { shipmentsPage, shipmentsPageSize } = this.state;

    if (storeId !== prevStoreId) {
      this.setState({
        ...defaultState,
        receptions: [],
      }, this.getDeliveries);
      this.getDefaultValues();
    }
    if (prevPage !== shipmentsPage || prevPageSize !== shipmentsPageSize) {
      this.getDeliveries();
    }
  }

  componentWillUnmount(){
    if (this.updateDeliveryTagsIntervalRef) {
      clearInterval(this.updateDeliveryTagsIntervalRef);
    }
  }

  updateReceptionTotalQties = (deliveryId, totalIndicator) => {
    const { receptions } = this.state;
    const receptionIndex = receptions.findIndex(x => x.id === deliveryId);
    if (receptionIndex !== -1) {
      const reception = receptions[receptionIndex];
      const updatedReception = {
        ...reception,
        qty_read: totalIndicator.receivedWithTagTotalQties + totalIndicator.foundAtMovementsQties,
        qty_confirmed: totalIndicator.expectedWithTagTotalQties,
      };
      const updatedReceptions = [
        ...receptions.slice(0, receptionIndex),
        updatedReception,
        ...receptions.slice(receptionIndex+1),
      ];
      this.setState({ receptions: updatedReceptions });
    }
  }

  getDeliveries = () => {
    const storeId = this.props.match.params.store;
    const { t, user, updateTokens } = this.props;
    const { shipmentsPage, shipmentsPageSize } = this.state;
    const { token, refreshToken, tokenExpireDate } = user;
    const queryString = getQueryString({
      pagination: {
        page: shipmentsPage,
        size: shipmentsPageSize
      },
    });
    const url = `${process.env.REACT_APP_base_URL}/api/${storeId}/deliveries/v2?${queryString}`;
    this.setState({ loadingReceptions: true, alert: false });
    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    })
    .then(handlePaginatedApiResponse)
    .then(({result: receptions, contentRange}) => {
      this.setState({
        receptions,
        loadingReceptions: false,
        shipmentsTotal: {
          pages: Math.max(1, Math.ceil(contentRange.max / shipmentsPageSize)),
          items: contentRange.max
        }
      });
    })
    .catch(error => {
      if (error.status === 401) {
        this.onAuthExpired();
      } else {
        const errorTitle = t('liveStoreReception.errors.apiError', 'Error when loading deliveries from stores.com API')
        console.warn(`Error while getting receptions for store ${storeId} : ${error}`);
        this.setState({
          loadingReceptions: false,
          alert: true,
          alertMessage: `${errorTitle} : ${error}`
        });
      }
    });
  }

  getDefaultValues = () => {
    const storeId = this.props.match.params.store;
    const { t, user, updateTokens } = this.props;
    const { filterValues } = this.state;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${process.env.REACT_APP_base_URL}/api/${storeId}/defaultValues`;
    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    })
    .then(handleApiResponse)
    .then(defaults => {
      this.setState({
        defaultMinimumPrice: defaults.filter_minimum_price,
        defaultMinimumValue: defaults.filter_minimum_value,
        filterValues: {
          ...filterValues,
          hideCheaper: defaults.filter_minimum_price,
          hideCheaperValue: defaults.filter_minimum_value,
        },
      })
    })
    .catch(error => {
      if (error.status === 401) {
        this.onAuthExpired();
      } else {
        const errorTitle = t('liveStoreReception.errors.defaultsError', 'Error when loading country default values')
        console.warn(`Error while getting default values for country of store ${storeId} : ${error}`);
        this.setState({
          loadingReceptions: false,
          alert: true,
          alertMessage: `${errorTitle} : ${error}`
        })
      }
    })
  }

  forceDisplayReceptions = () => {
    this.setState({
      forceDisplayReceptions: !this.state.forceDisplayReceptions
    })
  }

  fetchDeliveryContent = (reception) => {
    const { t } = this.props;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    let deliveryContent = null;
    let error = null;
    const url = `${process.env.REACT_APP_base_URL}/api/${this.props.match.params.store}/deliveries/v2/${reception.id}/withMovements`;
    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    })
    .then(result => {
      if (result.status === 404) {
        error = t(
          'liveStoreReception.errors.noData',
          'There is no data for this delivery in the database. Please alert End2End team.',
        );
      } else if (result.status !== 200) {
        const errorTitle = t(
          'liveStoreReception.errors.contentError',
          'Error in fetchDeliveryContent for reception',
        );
        return result.text().then(e => {
          console.warn(`Error in fetchDeliveryContent for reception ${reception.id} : ${e}`);
          error = `${errorTitle} ${reception.id} : ${error}`;
        })
      } else {
        return result.json()
        .then((data) => { deliveryContent = data; })
      }
    })
    .catch(e => {
      console.warn(`Error in fetchDeliveryContent for reception ${reception.id} : ${e}`);
      const errorTitle = t(
        'liveStoreReception.errors.contentError',
        'Error in fetchDeliveryContent for reception',
      );
      error = `${errorTitle} ${reception.id} : ${e}`;
    })
    .then(() => ({ error, deliveryContent }));
  }

  getDataFromDelivery = async (reception) => {
    this.setState({
      alert: false,
      loadingItemDetails: false,
      loadingPallets: true,
      myReception: reception,
      reveptionOpenTime: new Date(),
      lastReceivedTagId: null,
      shippingLists: [],
      receivedEPC: new Set(),
      itemDetails: [],
      movements: {},
      movementsUpdateError: null,
      stockExtractorQuotaLimitReached: false,
      movementAPICallsLimitReached: false,
      unexpectedItems: [],
      unexpectedItemsStored: [],
      unexpectedItemsSizeMatches: [],
      forceDisplayReceptions: false,
      sportIndicators: [],
      palletIndicators: [],
      totalIndicator: {},
    });

    const { error, deliveryContent } = await this.fetchDeliveryContent(reception);
    if (error || !deliveryContent) {
      this.setState({
        loadingPallets: false,
        alert: true,
        alertMessage: error || 'Error: empty delivery content',
      });
      return;
    }

    this.setState({ loadingPallets: false, loadingItemDetails: true });
    const warehouseReadingActivated = _.sumBy(deliveryContent.shippingLists, 'qty_read_by_warehouse_gate') > 0;
    const receivedEPC = new Set([
      ...deliveryContent.shippingLists.map(l => l.read_by_store_gate).flat(),
      ...deliveryContent.unexpected.map(u => u.read_unexpected).flat(),
    ]);
    const movements = deliveryContent.movements.reduce((accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.item]: currentValue
    }), {});
    const shippingLists = deliveryContent.shippingLists;
    const uniqueItems = [...new Set([
      ...deliveryContent.shippingLists.map(l => l.item),
      ...deliveryContent.unexpected.map(u => u.item),
    ])];
    const { error: detailsError, itemDetails } = await this.getItemDetails(uniqueItems);

    if (detailsError || !itemDetails) {
      this.setState({
        loadingItemDetails: false,
        alert: true,
        alertMessage: detailsError || 'Empty ItemDetails',
      });
      return;
    }

    const unexpectedItems = deliveryContent.unexpected;

    const {
      sportIndicators,
      palletIndicators,
      totalIndicator,
    } = this.calculateDelivery(itemDetails, shippingLists, movements);
    const unexpectedItemsSizeMatches = this.matchUnexpectedToWrongSizes(
      shippingLists,
      unexpectedItems,
      itemDetails,
    );

    const currencyDetails = itemDetails.find(itemDetails => (
      itemDetails
      && itemDetails.currency
      && itemDetails.price
    ));
    const currency = currencyDetails ? currencyDetails.currency.toLowerCase() : '$';

    const stockExtractorQuotaLimitReached = !!(deliveryContent.movementsStatistics && deliveryContent.movementsStatistics.stockExtractorQuotaLimitReached);
    const movementAPICallsLimitReached = !!(deliveryContent.movementsStatistics && deliveryContent.movementsStatistics.movementAPICallsLimitReached);
    const movementsUpdateError = stockExtractorQuotaLimitReached ? null : deliveryContent.movementsError;

    const { totalUnexpected, unexpectedSportItems } = this.getUnexpectedSportItems(
      unexpectedItems,
      itemDetails,
      movements,
      shippingLists,
      unexpectedItemsSizeMatches,
    );
    this.updateReceptionTotalQties(reception.id, totalIndicator);
    this.setState({
      currency,
      shippingLists,
      receivedEPC,
      movements,
      movementsUpdateError,
      stockExtractorQuotaLimitReached,
      movementAPICallsLimitReached,
      itemDetails,
      sportIndicators,
      palletIndicators,
      totalIndicator: {
        ...totalIndicator,
        unexpected: totalUnexpected
      },
      unexpectedItems,
      unexpectedItemsStored: unexpectedItems,
      unexpectedItemsSizeMatches,
      warehouseReadingActivated: warehouseReadingActivated,
      forceDisplayReceptions: false,
      loadingPallets: false,
      loadingItemDetails: false,
      unexpectedSportItems,
    });
  }

  getItemDetails = (uniqueItems) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const store = this.props.match.params.store;
    const country = this.props.match.params.country;

    let itemDetails = [];
    let error = null;
    const url = `${process.env.REACT_APP_base_URL}/api/${country}/${store}/itemDetails`;
    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: 'POST',
      body:JSON.stringify({
        itemsSeparatedByCommas: uniqueItems.join(","),
        language: 'en'
      })
    })
    .then(handleApiResponse)
    .then(resp => {
      const itemDetailsWithoutNull = resp.filter(e => e !== null);
      this.sortItemDetails(itemDetailsWithoutNull);
      //I receive null if there is an error in retrieveing details of the item
      itemDetails = itemDetailsWithoutNull;
    })
    .catch(e => {
      error = `Error in getExpectedItemDetails : ${e}`;
      console.warn(error);
    })
    .then(() => ({ error, itemDetails }));
  }

  sortItemDetails = (itemDetails) => {
    itemDetails.sort((a,b) => (
      compareItems('universe_label', a, b)
      || compareItems('department_label', a, b)
      || compareItems('sub_department_label', a, b)
      || compareItems('family_label', a, b)
      || compareItems('model_lib', a, b)
      || compareItems('item_id', a, b)
    ));
    function compareItems(key, a, b){
      if (a[key] < b[key]){
        return - 1;
      }
      if (a[key] > b[key]){
        return 1;
      }
      return 0;
    }
  }

  associateTagToParcels = (shippingLists) => {
    const byParcelContent = [];
    shippingLists.forEach(l => {
      const byParcelConfirmed = l.content.map(c => c.qty_confirmed);
      const byParcelReadByStoreGate = splitEvenly(l.qty_confirmed, l.qty_read_by_store_gate, byParcelConfirmed);
      const byParcelReadByWarehouseGate = splitEvenly(l.qty_confirmed, l.qty_read_by_warehouse_gate, byParcelConfirmed);
      const byParcelReadBeforeWarehouseGate = splitEvenly(l.qty_confirmed, l.qty_read_before_warehouse_gate, byParcelConfirmed);

      l.content.forEach((c, index) => {
        byParcelContent.push({
          store: l.store,
          delivery: l.delivery,
          item: l.item,
          pallet: c.pallet,
          parcel: c.parcel,
          qty_confirmed: c.qty_confirmed,
          qty_predicted: c.qty_predicted,
          qty_read_by_store_gate: byParcelReadByStoreGate[index],
          qty_read_by_warehouse_gate: byParcelReadByWarehouseGate[index],
          qty_read_before_warehouse_gate: byParcelReadBeforeWarehouseGate[index],
        })
      });
    });

    return byParcelContent;
  }

  calculateDelivery = (itemDetails, shippingLists, movements) => {
    const expectedItems = shippingLists.map(l => l.item);
    const expectedItemDetails = itemDetails
      .filter(item => (
        expectedItems.indexOf(item.item_id) !== -1
        && (item.universe_label || item.nature === VALID_NATURE)
      ));
    const sports = expectedItemDetails.map(item => item.universe_label ? item.universe_label.toUpperCase() : NO_SPORT_LABEL);
    const uniqueSports = [...new Set(sports)];
    const sportIndicators = uniqueSports.map(sport => ({
      name: sport,
      indicator: this.calculateSportIndicators(sport, expectedItemDetails, shippingLists, movements),
    }));

    const byParcelContent = this.associateTagToParcels(shippingLists);

    const pallets = [...new Set(byParcelContent.map(p => p.pallet))];
    const palletIndicators = pallets.map(palletID => ({
      name: palletID,
      indicator: this.calculatePalletIndicators(palletID, itemDetails, byParcelContent, movements)
    }));
    const indicatorSorter = (a, b) => {
      const {
        expectedWithTagTotalQties: expectedWithTagTotalQtiesB,
        receivedWithTagTotalQties: receivedWithTagTotalQtiesB,
        foundAtMovementsQties: foundAtMovementsQtiesB,
        missingValue: missingValueB,
      } = b.indicator;
      const {
        expectedWithTagTotalQties: expectedWithTagTotalQtiesA,
        receivedWithTagTotalQties: receivedWithTagTotalQtiesA,
        foundAtMovementsQties: foundAtMovementsQtiesA,
        missingValue: missingValueA,
      } = a.indicator;
      const diffMissingValue = missingValueB - missingValueA;
      const diffMissingQties = (
        (expectedWithTagTotalQtiesB - receivedWithTagTotalQtiesB - foundAtMovementsQtiesB)
        - (expectedWithTagTotalQtiesA - receivedWithTagTotalQtiesA - foundAtMovementsQtiesA)
      );
      let diffName = 0;
      if (a.name > b.name) {
        diffName = 1;
      } else if (a.name < b.name) {
        diffName = -1;
      }
      return diffMissingValue || diffMissingQties || diffName;
    };
    sportIndicators.sort(indicatorSorter);
    palletIndicators.sort(indicatorSorter);
    const totalIndicator = this.calculateTolalIndicator(sportIndicators);
    return { sportIndicators, palletIndicators, totalIndicator };
  }

  matchUnexpectedToWrongSizes = (parcelsContent, unexpectedItems, itemDetails) => {
    const missingItems = parcelsContent.filter(item => item.qty_confirmed > item.qty_read_by_store_gate);
    const missingItemsDeltas = missingItems.reduce((accumulator, item) => {
      accumulator[item.item] = (accumulator[item.item] || 0) + item.qty_confirmed - item.qty_read_by_store_gate;
      return accumulator;
    }, {});
    const unexpectedItemsDeltas = unexpectedItems.reduce((accumulator, item) => {
      accumulator[item.item] = (accumulator[item.item] || 0) + item.qty_read_unexpected;
      return accumulator;
    }, {});

    let unexpectedItemsSizeMatches = [];

    const byModel = _.groupBy(itemDetails, 'model_id');
    Object.keys(byModel).forEach(model_id => {
      const modelItems = byModel[model_id];
      if (modelItems.length <= 1) {
        return;
      }
      const unexpectedModelItems = modelItems.filter(item => unexpectedItemsDeltas[item.item_id]);
      if (!unexpectedModelItems.length) {
        return;
      }
      const missingModelItems = modelItems.filter(item => missingItemsDeltas[item.item_id]);
      if (!missingModelItems.length) {
        return;
      }

      unexpectedModelItems.forEach(unexpectedItem => {
        missingModelItems.forEach(missingItem => {
          if (unexpectedItemsDeltas[unexpectedItem.item_id] === missingItemsDeltas[missingItem.item_id]) {
            unexpectedItemsSizeMatches.push({
              unexpectedItemId: unexpectedItem.item_id,
              missingItemId: missingItem.item_id,
              missingItem,
            });
          }
        })
      })
    });
    return unexpectedItemsSizeMatches;
  }

  calculateIndicator = (
    itemsDetails,
    uniqueItems,
    shippingLists,
    shippingListsWithTag,
    movements,
  ) => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const expectedTotalQties = shippingLists.map(l => l.qty_confirmed).reduce(reducer, 0);
    const expectedWithTagTotalQties = shippingListsWithTag.map(l => l.qty_confirmed).reduce(reducer, 0);
    const expectedWithTagValue = shippingListsWithTag.map(l => l.qty_confirmed * getPrice(l.item, itemsDetails)).reduce(reducer, 0);
    const receivedTotalQties = shippingLists.map(l => l.qty_read_by_store_gate).reduce(reducer, 0);
    const receivedWithTagTotalQties = shippingListsWithTag.map(l => l.qty_read_by_store_gate).reduce(reducer, 0);
    const warehousTotalQties = shippingLists.map(l => l.qty_read_before_warehouse_gate).reduce(reducer, 0);
    const warehouseWithTagTotalQties = shippingLists.map(l => l.qty_read_before_warehouse_gate).reduce(reducer, 0);

    const shippingListsWithMovements = shippingLists.filter(l => !!movements[l.item]);
    const byItemWithMovements = shippingListsWithMovements.reduce((accumulator, l) => {
      if (accumulator[l.item]) {
        accumulator[l.item].qty_confirmed += l.qty_confirmed;
        accumulator[l.item].qty_read_by_store_gate += l.qty_read_by_store_gate;
      } else {
        accumulator[l.item] = {
          item: l.item,
          qty_confirmed: l.qty_confirmed,
          qty_read_by_store_gate: l.qty_read_by_store_gate,
        };
      }
      return accumulator;
    }, {});
    
    const missingItemsWithMovements = Object.values(byItemWithMovements).filter(item => item.qty_confirmed > item.qty_read_by_store_gate);
    const foundMovementsItems = [];

    let foundAtMovementsQties = 0;
    let missingAtMovementsQties = 0;
    let revisedAtMovementsQties = 0;
    let leftQties = 0;
    let foundAtMovementsValue = 0;
    let missingAtMovementsValue = 0;
    let revisedAtMovementsValue = 0;
    missingItemsWithMovements.forEach(item => {
      const movement = movements[item.item];
      const missingCount = item.qty_confirmed - item.qty_read_by_store_gate;
      if (movement.status === 'stock_found' || movement.delta === 0) {
        foundAtMovementsQties += missingCount;
        foundMovementsItems.push(item.item);
        const price = getPrice(item.item, itemsDetails);
        foundAtMovementsValue += missingCount * price;
      } else if (movement.delta === -missingCount) {
        missingAtMovementsQties += missingCount;
        const price = getPrice(item.item, itemsDetails);
        missingAtMovementsValue += missingCount * price;
      } else {
        revisedAtMovementsQties += missingCount;
        const price = getPrice(item.item, itemsDetails);
        revisedAtMovementsValue += missingCount * price;
      }
    });

    leftQties = (
      expectedWithTagTotalQties
      - receivedWithTagTotalQties
      - foundAtMovementsQties
      - missingAtMovementsQties
      - revisedAtMovementsQties
    );

    let warehouseVisibleQties = 0;
    if (warehouseWithTagTotalQties > receivedWithTagTotalQties + foundAtMovementsQties) {
      warehouseVisibleQties = warehouseWithTagTotalQties - receivedWithTagTotalQties - foundAtMovementsQties;
      if (warehouseVisibleQties > leftQties) {
        warehouseVisibleQties = leftQties;
      }
    }

    let percentDone = 100;
    let percentFoundAtMovements = 0;
    let percentMissingAtMovements = 0;
    let percentRevisedAtMovements = 0;
    let percentLeft = 0;
    let percentWarehouseVisible = 0;

    if (expectedWithTagTotalQties > 0){
      percentDone = receivedWithTagTotalQties / expectedWithTagTotalQties * 100;
      percentWarehouseVisible = warehouseVisibleQties / expectedWithTagTotalQties * 100;
      percentFoundAtMovements = foundAtMovementsQties / expectedWithTagTotalQties * 100;
      percentMissingAtMovements = missingAtMovementsQties / expectedWithTagTotalQties * 100;
      percentRevisedAtMovements = revisedAtMovementsQties / expectedWithTagTotalQties * 100;
      percentLeft = (
        100
        - percentDone
        - percentFoundAtMovements
        - percentWarehouseVisible
        - percentMissingAtMovements
        - percentRevisedAtMovements
      );
      if (percentLeft < 0.0000000001) {
        percentLeft = 0;
      }
    }

    const receivedValue = shippingListsWithTag.map(
      l => l.qty_read_by_store_gate * getPrice(l.item, itemsDetails)
    ).reduce(reducer, 0);
    const warehouseValue = shippingListsWithTag.map(
      l => l.qty_read_before_warehouse_gate * getPrice(l.item, itemsDetails)
    ).reduce(reducer, 0);
    let missingValue = 0;
    let leftValue = 0;
    if (expectedWithTagValue > 0){
      missingValue = (
        expectedWithTagValue
        - receivedValue
        - foundAtMovementsValue
      );
      leftValue = (
        missingValue
        - missingAtMovementsValue
        - revisedAtMovementsValue
      );
    }

    return {
      myItemsDetails: itemsDetails,
      uniqueItems,
      myParcels: shippingLists,
      expectedTotalQties,
      expectedWithTagTotalQties,
      receivedTotalQties,
      receivedWithTagTotalQties,
      warehousTotalQties,
      warehouseWithTagTotalQties,
      warehouseVisibleQties,
      foundAtMovementsQties,
      missingAtMovementsQties,
      revisedAtMovementsQties,
      leftQties,
      percentDone,
      percentWarehouseVisible,
      percentFoundAtMovements,
      percentMissingAtMovements,
      percentRevisedAtMovements,
      percentLeft,
      expectedWithTagValue,
      receivedValue,
      warehouseValue,
      missingValue,
      foundAtMovementsValue,
      missingAtMovementsValue,
      revisedAtMovementsValue,
      leftValue,
    }
  }

  calculateSportIndicators = (sport, itemDetails, shippingLists, movements) => {
    const sportItemsDetails = itemDetails.filter(item => (
      item.universe_label
      || item.nature === VALID_NATURE
    )).filter(item => (item.universe_label ? item.universe_label.toUpperCase() : NO_SPORT_LABEL) === sport);
    const sportItems = sportItemsDetails.map(itemDetails => itemDetails.item_id);
    const sportShippingLists = shippingLists.filter(parcel => sportItems.includes(parcel.item));
    const sportItemsDetailsWithTag = sportItemsDetails.filter(item => item.article_flag === 'P' || item.article_flag === 'L');
    const sportItemsWithTag = sportItemsDetailsWithTag.map(itemDetails => itemDetails.item_id);
    const sportShippingListsWithTag = shippingLists.filter(parcel => sportItemsWithTag.includes(parcel.item));
    const sortedUniqueItems = _.orderBy(
      sportShippingLists,
      [
        (item) => {
          const details = sportItemsDetails.filter((details) => details.item_id === item.item);
          const price = details.length ? details[0].price : 0;
          return (item.qty_confirmed - item.qty_read_by_store_gate) * price;
        },
        (item) => {
          const details = sportItemsDetails.filter((details) => details.item_id === item.item);
          const price = details.length ? details[0].price : 0;
          return item.qty_confirmed * price;
        },
      ],
      ['desc', 'desc'],
    ).map(({ item }) => item);

    return this.calculateIndicator(
      sportItemsDetails,
      sortedUniqueItems,
      sportShippingLists,
      sportShippingListsWithTag,
      movements,
    );
  }

  calculatePalletIndicators = (palletID, itemDetails, byParcelContent, movements) => {
    const myParcels = byParcelContent.filter(parcel => parcel.pallet === palletID);
    const myItems = myParcels.map(pickingLine => pickingLine.item);
    const uniqueItems = [...new Set(myItems)];
    const myItemsDetails = itemDetails.filter(item => (
      item.universe_label
      || item.nature === VALID_NATURE
    )).filter(item => uniqueItems.includes(item.item_id));
    const myItemsDetailsWithTag = myItemsDetails.filter(item => item.article_flag === 'P' || item.article_flag === 'L');
    const myItemsWithTag = myItemsDetailsWithTag.map(itemDetails => itemDetails.item_id);
    const myParcelsWithTag = myParcels.filter(parcel => myItemsWithTag.includes(parcel.item));

    return this.calculateIndicator(
      myItemsDetails,
      uniqueItems,
      myParcels,
      myParcelsWithTag,
      movements,
    );
  }

  getUnexpectedSportItems = (
    unexpectedItems,
    itemDetails,
    movements,
    shippingLists,
    unexpectedItemsSizeMatches,
  ) => {
    const {
      filters,
      filterValues,
    } = this.state;
    const sportIndicators = {};
    const total = {
      foundAtMovementsQties: 0,
      foundAtMovementsValue: 0,
      missingAtMovementsQties: 0,
      missingAtMovementsValue: 0,
      revisedAtMovementsQties: 0,
      revisedAtMovementsValue: 0,
      notCheckedValue: 0,
      total: 0,
      additionalValue: 0,
    };
    const filteredUnexpectedItems = unexpectedItems.filter(
      (item) => (!filters.hideCheaper && !filters.hideCheaperValue)
        || (!!filters.hideCheaper && getPrice(item.item, itemDetails) >= filterValues.hideCheaper)
        || (!!filters.hideCheaperValue && getPrice(item.item, itemDetails) * item.qty_read_unexpected >= filterValues.hideCheaperValue)
    );

    filteredUnexpectedItems.forEach((item) => {
      item.unexpectedMatchesSize = unexpectedItemsSizeMatches.filter(
        match => match.unexpectedItemId === item.item
      );
      const listItem = shippingLists.find(pickingLine => pickingLine.item === item);
      const qtyReadUnexpected = unexpectedItems.find(e => e.item === item.item).qty_read_unexpected;
      item.expectedPickingLinesForThisItem = listItem ? [{
        ...listItem,
        qty_read_by_store_gate: listItem.qty_read_by_store_gate + qtyReadUnexpected,
      }] : [{
        item: item.item,
        qty_confirmed: 0,
        qty_read_by_store_gate: qtyReadUnexpected,
        qty_read_by_warehouse_gate: item.qty_read_by_warehouse_gate,
      }];
      item.details = itemDetails.find((details) => details.item_id === item.item);

      if (item.details) {
        const itemPrice = (item.details.price || 0);
        item.amount = item.qty_read_unexpected * itemPrice;
        const universe = item.details.universe_label ? item.details.universe_label.toUpperCase() : NO_SPORT_LABEL;
        let foundAtMovementsQties = 0;
        let missingAtMovementsQties = 0;
        let revisedAtMovementsQties = 0;
        let notCheckedValue = 0;
        const movement = movements[item.item];
        if (movement) {
          item.movement = movement;
          if (movement.status === 'stock_found' || movement.delta === 0) {
            foundAtMovementsQties = item.qty_read_unexpected;
          } else if (movement.delta === item.qty_read_unexpected) {
            missingAtMovementsQties = item.qty_read_unexpected;
          } else {
            revisedAtMovementsQties = item.qty_read_unexpected;
          }
        } else {
          notCheckedValue = item.qty_read_unexpected * itemPrice;
        }
        if (!sportIndicators[universe]) {
          sportIndicators[universe] = {
            items: {
              [item.item]: item,
            },
            total: item.qty_read_unexpected,
            amount: item.qty_read_unexpected * itemPrice,
            foundAtMovementsQties, //green
            foundAtMovementsValue: foundAtMovementsQties * itemPrice,
            missingAtMovementsQties, //red
            missingAtMovementsValue: missingAtMovementsQties * itemPrice,
            revisedAtMovementsQties, //orange
            revisedAtMovementsValue: revisedAtMovementsQties * itemPrice,
            additionalValue: notCheckedValue,
          };
        } else {
          sportIndicators[universe].items[item.item] = item;
          sportIndicators[universe].total += item.qty_read_unexpected;
          sportIndicators[universe].foundAtMovementsQties += foundAtMovementsQties;
          sportIndicators[universe].foundAtMovementsValue += foundAtMovementsQties * itemPrice;
          sportIndicators[universe].missingAtMovementsQties += missingAtMovementsQties;
          sportIndicators[universe].missingAtMovementsValue += missingAtMovementsQties * itemPrice;
          sportIndicators[universe].revisedAtMovementsQties += revisedAtMovementsQties;
          sportIndicators[universe].revisedAtMovementsValue += revisedAtMovementsQties * itemPrice;
          sportIndicators[universe].additionalValue += notCheckedValue;
        }
        total.foundAtMovementsQties += foundAtMovementsQties;
        total.foundAtMovementsValue += foundAtMovementsQties * itemPrice;
        total.missingAtMovementsQties += missingAtMovementsQties;
        total.missingAtMovementsValue += missingAtMovementsQties * itemPrice;
        total.revisedAtMovementsQties += revisedAtMovementsQties;
        total.revisedAtMovementsValue += revisedAtMovementsQties * itemPrice;
        total.additionalValue += notCheckedValue;
        total.total += item.qty_read_unexpected;
      }
    });

    return { unexpectedSportItems: sportIndicators, totalUnexpected: total };
  }

  calculateTolalIndicator = (sportIndicators) => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const receivedWithTagTotalQties = sportIndicators.map(sport => sport.indicator.receivedWithTagTotalQties).reduce(reducer, 0);
    const warehouseWithTagTotalQties = sportIndicators.map(sport => sport.indicator.warehouseWithTagTotalQties).reduce(reducer, 0);
    const expectedWithTagTotalQties = sportIndicators.map(sport => sport.indicator.expectedWithTagTotalQties).reduce(reducer, 0);
    const foundAtMovementsQties = sportIndicators.map(sport => sport.indicator.foundAtMovementsQties).reduce(reducer, 0);
    const missingAtMovementsQties = sportIndicators.map(sport => sport.indicator.missingAtMovementsQties).reduce(reducer, 0);
    const revisedAtMovementsQties = sportIndicators.map(sport => sport.indicator.revisedAtMovementsQties).reduce(reducer, 0);
    const leftQties = sportIndicators.map(sport => sport.indicator.leftQties).reduce(reducer, 0);
    const receivedValue = sportIndicators.map(sport => sport.indicator.receivedValue).reduce(reducer, 0);
    const warehouseValue = sportIndicators.map(sport => sport.indicator.warehouseValue).reduce(reducer, 0);
    const expectedWithTagValue = sportIndicators.map(sport => sport.indicator.expectedWithTagValue).reduce(reducer, 0);
    const foundAtMovementsValue = sportIndicators.map(sport => sport.indicator.foundAtMovementsValue).reduce(reducer, 0);
    const missingAtMovementsValue = sportIndicators.map(sport => sport.indicator.missingAtMovementsValue).reduce(reducer, 0);
    const revisedAtMovementsValue = sportIndicators.map(sport => sport.indicator.revisedAtMovementsValue).reduce(reducer, 0);
    const missingValue = sportIndicators.map(sport => sport.indicator.missingValue).reduce(reducer, 0);
    const leftValue = sportIndicators.map(sport => sport.indicator.leftValue).reduce(reducer, 0);

    let warehouseVisibleQties = 0;
    if (warehouseWithTagTotalQties > receivedWithTagTotalQties + foundAtMovementsQties) {
      warehouseVisibleQties = warehouseWithTagTotalQties - receivedWithTagTotalQties - foundAtMovementsQties;
      if (warehouseVisibleQties > leftQties) {
        warehouseVisibleQties = leftQties;
      }
    }

    let percentDone = 100;
    let percentWarehouseVisible = 0;
    let percentFoundAtMovements = 0;
    let percentMissingAtMovements = 0;
    let percentRevisedAtMovements = 0;
    let percentLeft = 0;
    if (expectedWithTagTotalQties > 0){
      percentDone = receivedWithTagTotalQties / expectedWithTagTotalQties * 100;
      percentWarehouseVisible = warehouseVisibleQties / expectedWithTagTotalQties * 100;
      percentFoundAtMovements = foundAtMovementsQties / expectedWithTagTotalQties * 100;
      percentMissingAtMovements = missingAtMovementsQties / expectedWithTagTotalQties * 100;
      percentRevisedAtMovements = revisedAtMovementsQties / expectedWithTagTotalQties * 100;
      percentLeft = (
        100
        - percentDone
        - percentFoundAtMovements
        - percentWarehouseVisible
        - percentMissingAtMovements
        - percentRevisedAtMovements
      );
      if (percentLeft < 0.0000000001) {
        percentLeft = 0;
      }
    }

    return {
      receivedWithTagTotalQties,
      warehouseWithTagTotalQties,
      expectedWithTagTotalQties,
      foundAtMovementsQties,
      missingAtMovementsQties,
      revisedAtMovementsQties,
      leftQties,
      receivedValue,
      warehouseValue,
      expectedWithTagValue,
      foundAtMovementsValue,
      missingAtMovementsValue,
      revisedAtMovementsValue,
      missingValue,
      leftValue,
      percentDone,
      percentWarehouseVisible,
      percentFoundAtMovements,
      percentMissingAtMovements,
      percentRevisedAtMovements,
      percentLeft,
    };
  }

  sportHasVisibleItems = (sport, sportIndicators) => {
    const { filters, filterValues } = this.state;
    const displayFullyReceivedItems = this.state.sportsToDisplayEvenFullyReceivedItems.includes(sport);

    const hasVisibleItems = sportIndicators.uniqueItems.map((item_id) => {
      const itemDetails = sportIndicators.myItemsDetails.filter(item => item.item_id === item_id)[0];
      const allParcels = sportIndicators.myParcels;
      const movement = this.state.movements[item_id];
      const { visible } = calculateItemProps({
        allParcels,
        itemDetails,
        movement,
        filters,
        displayFullyReceivedItems,
        filterValues,
      });
      return visible;
    }).filter(x => x).length > 0;
    return hasVisibleItems;
  }

  palletHasVisibleItems = (palletID, palletIndicators) => {
    const { filters, filterValues } = this.state;
    const hasVisibleItems = palletIndicators.uniqueItems.map((item_id) => {
      const itemDetails = palletIndicators.myItemsDetails.filter(item => item.item_id === item_id)[0];
      const allParcels = palletIndicators.myParcels;
      const movement = this.state.movements[item_id];
      const { visible } = calculateItemProps({
        allParcels,
        itemDetails,
        movement,
        filters,
        displayFullyReceivedItems: false,
        filterValues,
      });
      return visible;
    }).filter(x => x).length > 0;
    return hasVisibleItems;
  }

  displaySport = (sport, sportIndicators) => {
    const { filters, filterValues } = this.state;
    const noTagQties = sportIndicators.expectedTotalQties - sportIndicators.expectedWithTagTotalQties;
    const filtered = _.some(filters);
    const isOpen = this.state.sportsToDisplayContent.includes(sport);
    const isShowFullyReceived = this.state.sportsToDisplayEvenFullyReceivedItems.includes(sport);
    const showFullyReceived = isOpen
      && (
        calculateFullyReceived(sportIndicators.myParcels).length > 0
        || sportIndicators.expectedTotalQties > sportIndicators.expectedWithTagTotalQties
      );

    if (filtered && !this.sportHasVisibleItems(sport, sportIndicators)) {
      return null;
    }

    let myClassCard = "vtmn-block";
    let fullyReceivedSport = false;
    if (sportIndicators.receivedWithTagTotalQties >= sportIndicators.expectedWithTagTotalQties) {
      myClassCard += " [&_div]:vtmn-text-success [&_svg]:vtmn-text-neutral [&_path]:vtmn-text-neutral";
      fullyReceivedSport = true;
    } else if (sportIndicators.receivedWithTagTotalQties + sportIndicators.foundAtMovementsQties >= sportIndicators.expectedWithTagTotalQties) {
      myClassCard += " [&_div]:vtmn-text-success [&_svg]:vtmn-text-neutral [&_path]:vtmn-text-neutral";
      fullyReceivedSport = true;
    }

    return (
      <>
      <div
        key={sport}
        className={cn(
          "vtmn-border-background-secondary-new vtmn-pb-3 vtmn-mt-4 vtmn-mb-1",
          "vtmn-inline-block vtmn-w-full mobile:vtmn-w-full mobile:vtmn-flex-auto mobile:vtmn-max-w-none",
          isOpen ? "" : "vtmn-border-bottom-1"
        )}
      >
        <div className="vtmn-block vtmn-px-2">
          <div className="vtmn-flex vtmn-flex-row vtmn-justify-between vtmn-mb-3">
            <span className={cn(
              "vp-subtitle-m",
              fullyReceivedSport ? "vtmn-text-success" : ""
            )}>
              {capitalize(sport)}
            </span>
            <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
              {this.displayNoRFIDQties(noTagQties, sportIndicators.expectedTotalQties)}
              {isOpen && showFullyReceived && (
                <VpIcon
                  name={isShowFullyReceived ? "subtract" : "add"}
                  size="24"
                  onClick={() => this.hideUnhideItems(sport)}
                  className="vtmn-cursor-pointer vtmn-mr-2"
                />
              )}
              <VpIcon
                name={isOpen ? "chevron-up" : "chevron-down"}
                size="24"
                onClick={() => this.hideUnhideSport(sport)}
                className="vtmn-cursor-pointer"
              />
            </div>
          </div>
          <ProgressBar
            indicator={sportIndicators}
            currency={this.state.currency}
            onBarClick={() => this.onProgressClick({ indicator: sportIndicators, sport })}
          />
        </div>
      </div>
      {isOpen && (
        <div className="[&>div]:vtmn-mb-7 last:[&>div]:vtmn-mb-0">
          {sportIndicators.uniqueItems.map((item_id) => (
            <DisplayItem
              className={cn(
                "vtmn-inline-block vtmn-w-full mobile:vtmn-w-full mobile:vtmn-flex-auto mobile:vtmn-max-w-none",
                fullyReceivedSport ? "vtmn-text-success" : "",
              )}
              reception={this.state.myReception}
              key={item_id}
              filters={filters}
              filterValues={filterValues}
              itemDetails={sportIndicators.myItemsDetails.filter(item => item.item_id === item_id)[0]}
              allParcels={sportIndicators.myParcels}
              displayFullyReceivedItems={isShowFullyReceived}
              warehouseReadingActivated={this.state.warehouseReadingActivated}
              user={this.props.user}
              updateTokens={this.props.updateTokens}
              store={this.props.match.params.store}
              movement={this.state.movements[item_id]}
            />
          ))}
        </div>
      )}
      </>
    )
  }

  onTotalProgressClick = () => {
    const { totalIndicator } = this.state;
    this.setState({
      selectedIndicator: {
        indicator: totalIndicator,
        isTotal: true,
      }
    });
  }

  onProgressClick = ({ indicator, sport, pallet }) => {
    this.setState({
      selectedIndicator: {
        indicator,
        sport,
        pallet,
      }
    });
  }

  displayPallet = (palletID, palletIndicators) => {
    const { t } = this.props;
    const { filters } = this.state;
    const uniqueParcels = [...new Set(palletIndicators.myParcels.map(pickingLine => pickingLine.parcel))];
    const noTagQties = palletIndicators.expectedTotalQties - palletIndicators.expectedWithTagTotalQties;
    const isOpen = this.state.palletsToDisplayContent.includes(palletID);

    let myClassCard = "card card-container";
    if (palletIndicators.receivedWithTagTotalQties >= palletIndicators.expectedWithTagTotalQties) {
      myClassCard += " bg-success";
    } else if (palletIndicators.receivedWithTagTotalQties + palletIndicators.foundAtMovementsQties >= palletIndicators.expectedWithTagTotalQties) {
      myClassCard += " bg-success";
    }

    const filtered = _.some(filters);
    if (filtered && !this.palletHasVisibleItems(palletID, palletIndicators)) {
      return null;
    }

    return (
      <>
        <div
          key={palletID}
          className={cn(
            "vtmn-border-background-secondary-new vtmn-pb-3 vtmn-mt-4 vtmn-mb-1",
            "vtmn-3-column-card vtmn-block mobile:vtmn-w-full mobile:vtmn-flex-auto mobile:vtmn-max-w-none",
            isOpen ? "" : "vtmn-border-bottom-1"
          )}
        >
          <div className="vtmn-block vtmn-px-2">
            <div className="vtmn-flex vtmn-flex-row vtmn-justify-between vtmn-mb-3">
              <span className={cn(
                "vp-subtitle-m",
              )}>
                {palletID}
              </span>
              <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
                <VpIcon
                  name={isOpen ? "chevron-up" : "chevron-down"}
                  size="24"
                  onClick={() => this.hideUnhidePallets(palletID)}
                  className="vtmn-cursor-pointer"
                />
              </div>
            </div>
            <span className="vp-body-s">
              {t('liveStoreReception.card.parcels', 'Parcels')}
              {': '}
              {uniqueParcels.length}
            </span>
            {this.displayNoRFIDQties(noTagQties, palletIndicators.expectedTotalQties)}
            <ProgressBar
              indicator={palletIndicators}
              currency={this.state.currency}
              onBarClick={() => this.onProgressClick({ indicator: palletIndicators, pallet: palletID })}
            />
          </div>
        </div>
        {isOpen && (
          <PalletDetails
            reception={this.state.myReception}
            user = {this.props.user}
            updateTokens = {this.props.updateTokens}
            filters = {this.state.filters}
            filterValues = {this.state.filterValues}
            parcels = {uniqueParcels}
            palletID = {palletID}
            pickingLines = {palletIndicators.myParcels}
            store = {this.props.match.params.store}
            warehouseReadingActivated = {this.state.warehouseReadingActivated}
            itemDetails = {this.state.itemDetails}
            movements = {this.state.movements}
          />
        )}
      </>
    )
  }

  displayNoRFIDQties = (noTagQties, totalQties) => {
    const { t } = this.props;
    if (noTagQties > 0){
      const tNoTagQties = t('liveStoreReception.card.qties', {
        defaultValue: '{{count}} qty',
        count: noTagQties,
      });
      const tTotalQties = t('liveStoreReception.card.qties', {
        defaultValue: '{{count}} qty',
        count: totalQties,
      });
      const translatedQties = t(
        'liveStoreReception.card.totalQties',
        {
          defaultValue: 'Total {{totalQties}} - {{noTagQties}} no RFID',
          totalQties: tTotalQties,
          noTagQties: tNoTagQties,
        },
      );
      return(
        <div className="vp-caption vtmn-mr-2">{translatedQties}</div>
      )
    }
  }

  hideUnhideItems = (sport) => {
    let sportsList = this.state.sportsToDisplayEvenFullyReceivedItems;
    if (sportsList.includes(sport)){
      sportsList = sportsList.filter(e => e !== sport);
      this.setState({
        sportsToDisplayEvenFullyReceivedItems: sportsList
      });
    } else {
      sportsList.push(sport);
      this.setState({
        sportsToDisplayEvenFullyReceivedItems: sportsList
      });
    }
  }

  hideUnhidePallets = (palletID) => {
    let newPalletsToDisplayContent = this.state.palletsToDisplayContent;
    if (newPalletsToDisplayContent.includes(palletID)){
      newPalletsToDisplayContent = newPalletsToDisplayContent.filter(e => e !== palletID);
      this.setState({
        palletsToDisplayContent: newPalletsToDisplayContent
      })
    } else {
      newPalletsToDisplayContent.push(palletID);
      this.setState({
        palletsToDisplayContent: newPalletsToDisplayContent
      })
    }
  }

  displayChevronPallet = (palletID) => {
    if (this.state.palletsToDisplayContent.includes(palletID)){
      return(
        <div onClick={() => this.hideUnhidePallets(palletID)} className="clickableText textRightAligned col-2"><i className="vtmn-icon_arrow2_up"></i></div>
      )
    } else {
      return(
        <div onClick={() => this.hideUnhidePallets(palletID)} className="clickableText textRightAligned col-2"><i className="vtmn-icon_arrow2_down"></i></div>
      )
    }
  }

  hideUnhideSport = (sport) => {
    let newSportToDisplayContent = this.state.sportsToDisplayContent;
    if (newSportToDisplayContent.includes(sport)){
      newSportToDisplayContent = newSportToDisplayContent.filter(e => e !== sport);
      this.setState({
        sportsToDisplayContent: newSportToDisplayContent
      })
    } else {
      newSportToDisplayContent.push(sport);
      this.setState({
        sportsToDisplayContent: newSportToDisplayContent
      })
    }
  }

  loader = (myBoolean, message) => {
    if (this.state[myBoolean]) {
      return(
        <div className="vtmn-flex vtmn-flex-row vtmn-w-full vtmn-justify-center">
          <VpLoader
            size="dm"
            className="vtmn-mr-3"
          />
          <div className="align-self-center">{message}</div>
        </div>
      )
    }
  }

  setFilters = (filters) => {
    this.setState({ filters });
    // await this.keepFilters(filters, filterValues);
  }

  setFilterValues = async (filterValues) => {
    const { filters } = this.state;
    this.setState({ filterValues });
    // await this.keepFilters(filters, filterValues);
  }

  displayFiltersPanel = () => {
    const { t } = this.props;
    const { filters, filterValues, filtersVisible, currency } = this.state;

    if (!filtersVisible) {
      return null;
    }

    return (
      <div className="vtmn-flex vtmn-flex-row vtmn-flex-wrap vtmn-px-4 vtmn-mt-5 [&>div]:vtmn-mr-5 [&>div]:vtmn-mb-5">
        <Toggle
          key="filter-both-gates-missing"
          value={filters.bothGatesMissing}
          onChange={() => this.setFilters({ ...filters, bothGatesMissing: !filters.bothGatesMissing })}
          rightLabel={t('liveStoreReception.filters.bothGatesMissing', 'Missing both at warehouse and store')}
        />
        <Toggle
          key="filter-store-gate-zero"
          value={filters.storeGateZero}
          onChange={() => this.setFilters({ ...filters, storeGateZero: !filters.storeGateZero })}
          rightLabel={t('liveStoreReception.filters.storeGateZero', 'Store has seen 0')}
        />
        <Toggle
          key="filter-hide-incomplete-boxes"
          value={filters.hideIncompleteBoxes}
          onChange={() => this.setFilters({ ...filters, hideIncompleteBoxes: !filters.hideIncompleteBoxes })}
          rightLabel={t('liveStoreReception.filters.hideIncompleteBoxes', 'Hide if read > 50% PCB')}
        />
        <Toggle
          key="filter-hide-controlled"
          value={filters.hideIncompleteBoxes}
          onChange={() => this.setFilters({ ...filters, hideControlled: !filters.hideControlled })}
          rightLabel={t('liveStoreReception.filters.hideControlled', 'Hide controlled')}
        />
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
          <Toggle
            key="filter-minimum-price-switch"
            value={filters.hideCheaper}
            onChange={() => this.onChangeHideCheaper()}
            rightLabel={t('liveStoreReception.filters.priceMoreThan', 'Price ≥')}
          />
          <NumberInput
            value={filterValues.hideCheaper}
            onChange={(v) => this.onChangeMinimumPriceFilter(v)}
            className="vtmn-w-10 vtmn-mx-1"
          />
          <span>{currency}</span>
        </div>
        <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
          <Toggle
            key="filter-minimum-value-switch"
            value={filters.hideCheaperValue}
            onChange={() => this.onChangeHideCheaperValue()}
            rightLabel={t('liveStoreReception.filters.valueMoreThan', 'Value ≥')}
          />
          <NumberInput
            value={filterValues.hideCheaper}
            onChange={(v) => this.onChangeMinimumValueFilter(v)}
            className="vtmn-w-10 vtmn-mx-1"
          />
          <span>{currency}</span>
        </div>
      </div>
    )
  }

  onChangeHideCheaperValue = () => {
    const { filters } = this.state;
    this.setFilters({ ...filters, hideCheaperValue: !filters.hideCheaperValue })
  }

  onChangeHideCheaper = () => {
    const { filters } = this.state;
    this.setFilters({ ...filters, hideCheaper: !filters.hideCheaper })
  }

  onChangeMinimumValueFilter = (value) => {
    const { defaultMinimumValue, filters, filterValues } = this.state;

    if (value !== defaultMinimumValue) {
      this.setState({
        defaultMinimumValue: null,
        filters: {
          ...filters,
          hideCheaperValue: true,
        },
      });
    }
    this.setFilterValues({
      ...filterValues,
      hideCheaperValue: value
    });
  }

  onChangeMinimumPriceFilter = (value) => {
    const { defaultMinimumPrice, filters, filterValues } = this.state;

    if (value !== defaultMinimumPrice) {
      this.setState({
        defaultMinimumPrice: null,
        filters: {
          ...filters,
          hideCheaper: true,
        },
      });
    }
    this.setFilterValues({
      ...filterValues,
      hideCheaper: value
    });
  }

  displayList = () => {
    const { t } = this.props;
    const { user, updateTokens } = this.props;
    const {
      filters,
      filterValues,
      warehouseReadingActivated,
      currency,
      myReception,
      unexpectedSportItems,
      filtersVisible,
    } = this.state;
    const { store } = this.props.match.params;
    const filtered = _.some(filters);

    if (this.state.myReception.id !== undefined) {
      const hasContentLoaded = this.getHasContentLoaded();
      return(
        <div>
          {this.displayTotal()}
          {this.displayFiltersPanel()}
          <Tabs defaultValue="by sport">
            <Tabs.Header>
              <VpButton
                size="small"
                startSlot={<VpFilterIcon />}
                shape="squared"
                variant="tertiary"
                onClick={() => this.setState({ filtersVisible: !filtersVisible })}
              >
                {filtered && <VpBadge />}
              </VpButton>
              <Tabs.Item value="by sport">
                <Trans i18nKey="liveStoreReception.card.headers.bySport">By sport</Trans>
              </Tabs.Item>
              <Tabs.Item value="by pallet">
                <Trans i18nKey="liveStoreReception.card.headers.byPallet">By pallet</Trans>
              </Tabs.Item>
              <Tabs.Item value="unexpected">
                <Trans i18nKey="liveStoreReception.card.headers.unexpected">Unexpected</Trans>
              </Tabs.Item>
            </Tabs.Header>
            <Tabs.Content value="by sport">
              {hasContentLoaded && (
                <div className="vtmn-columns-3 mobile:vtmn-columns-1">
                  {this.state.sportIndicators.map(({ name, indicator }) => this.displaySport(name, indicator))}
                </div>
              )}
              {!hasContentLoaded && this.loader(
                'loadingPallets',
                t('liveStoreReception.loader.pallets', 'Loading shipping list')
              )}
              {!hasContentLoaded && this.loader(
                'loadingItemDetails',
                t('liveStoreReception.card.loadingItemDetails', 'Loading item details')
              )}
            </Tabs.Content>
            <Tabs.Content value="by pallet">
              {hasContentLoaded && (
                <div className="vtmn-flex vtmn-flex-wrap vtmn-gap-x-4 mobile:vtmn-gap-x-0">
                  {this.state.palletIndicators.map(({ name, indicator }) => this.displayPallet(name, indicator))}
                </div>
              )}
              {!hasContentLoaded && this.loader(
                'loadingPallets',
                t('liveStoreReception.loader.pallets', 'Loading shipping list')
              )}
              {!hasContentLoaded && this.loader(
                'loadingItemDetails',
                t('liveStoreReception.card.loadingItemDetails', 'Loading item details')
              )}
            </Tabs.Content>
            <Tabs.Content value="unexpected">
              {hasContentLoaded && (
                <UnexpectedTab
                  store={store}
                  user={user}
                  updateTokens={updateTokens}
                  filters={filters}
                  filterValues={filterValues}
                  warehouseReadingActivated={warehouseReadingActivated}
                  currency={currency}
                  delivery={myReception && myReception.id}
                  unexpectedSportItems={unexpectedSportItems}
                />
              )}
              {!hasContentLoaded && this.loader(
                'loadingPallets',
                t('liveStoreReception.loader.pallets', 'Loading shipping list')
              )}
              {!hasContentLoaded && this.loader(
                'loadingItemDetails',
                t('liveStoreReception.card.loadingItemDetails', 'Loading item details')
              )}
            </Tabs.Content>
          </Tabs>
        </div>
      );
    }
  }

  getHasContentLoaded = () => {
    const { palletIndicators, sportIndicators, unexpectedItems } = this.state;
    const hasContentLoaded = !!(
      (palletIndicators && palletIndicators.length)
      || (sportIndicators && sportIndicators.length)
      || (unexpectedItems && unexpectedItems.length)
    );
    return hasContentLoaded;
  }

  displayTotal = () => {
    if (this.state.itemDetails.length > 0){
      return (
        <div className='vtmn-px-4'>
          <ProgressBar
            isTotal
            indicator={this.state.totalIndicator}
            currency={this.state.currency}
            onBarClick={this.onTotalProgressClick}
          />
        </div>
      )
    }
  }

  getWarehouseUnexpected = (delivery, items) => {
    const store = this.props.match.params.store;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${process.env.REACT_APP_base_URL}/api/${store}/deliveries/v2/${delivery}/items/unexpected`;
    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: 'POST',
      body: JSON.stringify({ items }),
    })
    .then(handleApiResponse)
    .catch(error => {
      console.warn(`Error in getWarehouseUnexpected for delivery : ${delivery}, items : ${items} , error : ${error}`);
    })
  }

  displayReadingRateModal = () => {
    const { selectedIndicator, myReception, currency } = this.state;

    return (
      <ReadingRateModal
        show={!!selectedIndicator}
        indicator={selectedIndicator && selectedIndicator.indicator}
        sport={selectedIndicator && selectedIndicator.sport}
        pallet={selectedIndicator && selectedIndicator.pallet}
        delivery={myReception && myReception.id}
        currency={currency}
        onHide={() => this.setState({ selectedIndicator: null })}
      />
    );
  }

  render() {
    const { shipmentsPage, shipmentsPageSize, shipmentsTotal, selectedReception, isCollapsedList } = this.state;
    const { user, updateTokens } = this.props;

    return(
      <div className="liveStoreReception mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
        {!this.state.loadingReceptions && (
          <div className={cn(
            selectedReception ? "[&_div#footer]:!vtmn-hidden" : "vtmn-h-full"
          )}>
            <DataTable
              className={cn(
                "vtmn-h-full",
                "[&_tbody_tr]:vtmn-cursor-pointer [&_tbody_tr]:hover:vtmn-new-bg-background-brand-secondary",
                // "[&_tbody_tr]:data-[state=selected]:vtmn-new-bg-background-brand-primary [&_tbody_tr]:data-[state=selected]:vtmn-new-typo-color",
                "[&_tbody_tr]:data-[state=selected]:vtmn-new-bg-background-hover-tertiary",
                isCollapsedList ? "[&_tbody_tr]:data-[state=false]:vtmn-hidden" : ""
              )}
              columns={columns}
              data={this.state.receptions}
              totals={shipmentsTotal}
              pagination={{
                page: shipmentsPage,
                size: shipmentsPageSize,
              }}
              setPagination={({ page, size }) => {
                let myReception = this.state.myReception;
                if (shipmentsPage !== page) {
                  myReception = { id: undefined };
                }
                this.setState({
                  forceDisplayReceptions: null,
                  shipmentsPage: page,
                  shipmentsPageSize: size,
                  myReception,
                }, this.getDeliveries);
              }}
              meta={{
                getDataFromDelivery: (reception) => {
                  this.setState({
                    selectedReception: reception.id,
                    isCollapsedList: true,
                  });
                  this.getDataFromDelivery(reception);
                },
                selectedReception,
                isCollapsedList,
                toogleCollapse: (value) => this.setState({ isCollapsedList: value }),
                updateTable: () => {
                  this.setState({
                    selectedReception: false,
                    isCollapsedList: false,
                  });
                  this.getDeliveries();
                },
              }}
            />
          </div>
        )}
        {this.state.loadingReceptions && (
          <div className="vtmn-w-full vtmn-h-full vtmn-flex vtmn-items-center vtmn-justify-center">
            <VpLoader size="extra-large" />
          </div>
        )}
        {this.displayAlerts()}
        {this.displayReadingRateModal()}
        {this.displayList()}
        <ReleaseNotification user={user} updateTokens={updateTokens} />
      </div>
    )
  }
}

export default withTranslation()(LiveStoreReception);
