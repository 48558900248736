import React, { useState, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

// import DateRangePicker from '../widgets/vtmn/filters/DateRangePicker';
import DaterangeFilter from '../widgets/filters/DaterangeFilter';

const Filters = ({ filters, setFilters }) => {
  const { t } = useTranslation();

  return (
    <DaterangeFilter
      controlId="filter-date"
      label={t('monitoringRobot.dateFilter', 'Date filter')}
      valueFrom={filters.dateFrom}
      valueTo={filters.dateTo}
      onChange={(dateFrom, dateTo) => setFilters({
        ...filters,
        dateFrom,
        dateTo,
      })}
    />
  )
}

export default Filters;
