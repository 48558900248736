import * as React from "react"
import { Command as CommandPrimitive } from "cmdk"
import { cn } from "../../lib/utils"
import { Dialog, DialogContent } from "./dialog"

const Command = React.forwardRef(({ className, ...props }, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cn(
      "vtmn-flex vtmn-h-full vtmn-w-full vtmn-flex-col vtmn-overflow-hidden vtmn-bg-popover vtmn-text-popover-foreground",
      className
    )}
    {...props} />
))
Command.displayName = CommandPrimitive.displayName

const CommandDialog = ({
  children,
  ...props
}) => {
  return (
    (<Dialog {...props}>
      <DialogContent className="vtmn-overflow-hidden vtmn-p-0">
        <Command
          className="[&_[cmdk-group-heading]]:vtmn-px-2 [&_[cmdk-group-heading]]:vtmn-font-medium [&_[cmdk-group-heading]]:vtmn-text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:vtmn-pt-0 [&_[cmdk-group]]:vtmn-px-2 [&_[cmdk-input-wrapper]_svg]:vtmn-h-5 [&_[cmdk-input-wrapper]_svg]:vtmn-w-5 [&_[cmdk-input]]:vtmn-h-12 [&_[cmdk-item]]:vtmn-px-2 [&_[cmdk-item]]:vtmn-py-3 [&_[cmdk-item]_svg]:vtmn-h-5 [&_[cmdk-item]_svg]:vtmn-w-5">
          {children}
        </Command>
      </DialogContent>
    </Dialog>)
  );
}

const CommandInput = React.forwardRef(({ className, ...props }, ref) => (
  <CommandPrimitive.Input
    ref={ref}
    className={cn(
      // "vtmn-flex vtmn-w-full vtmn-rounded-100 vtmn-bg-transparent vtmn-py-3 vtmn-text-sm vtmn-outline-none placeholder:vtmn-text-muted-foreground disabled:vtmn-cursor-not-allowed disabled:vtmn-opacity-50",
      className
    )}
    {...props}
  />
))

CommandInput.displayName = CommandPrimitive.Input.displayName

const CommandList = React.forwardRef(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn(
      "vtmn-max-h-[300px] vtmn-overflow-y-auto vtmn-overflow-x-hidden",
      className
    )}
    {...props} />
))

CommandList.displayName = CommandPrimitive.List.displayName

const CommandEmpty = React.forwardRef((props, ref) => (
  <CommandPrimitive.Empty ref={ref} className="vtmn-py-6 vtmn-text-center vtmn-text-sm" {...props} />
))

CommandEmpty.displayName = CommandPrimitive.Empty.displayName

const CommandGroup = React.forwardRef(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      "vtmn-overflow-hidden vtmn-py-1 vtmn-text-foreground [&_[cmdk-group-heading]]:vtmn-px-2 [&_[cmdk-group-heading]]:vtmn-py-1.5 [&_[cmdk-group-heading]]:vtmn-text-xs [&_[cmdk-group-heading]]:vtmn-font-medium [&_[cmdk-group-heading]]:vtmn-text-muted-foreground",
      className
    )}
    {...props} />
))

CommandGroup.displayName = CommandPrimitive.Group.displayName

const CommandSeparator = React.forwardRef(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn("vtmn--mx-1 vtmn-h-px vtmn-bg-border", className)}
    {...props} />
))
CommandSeparator.displayName = CommandPrimitive.Separator.displayName

const CommandItem = React.forwardRef(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      "vtmn-relative vtmn-flex vtmn-cursor-default vtmn-gap-2 vtmn-select-none vtmn-items-center vtmn-outline-none data-[disabled=true]:vtmn-pointer-events-none data-[selected=true]:vtmn-bg-accent data-[selected=true]:vtmn-text-accent-foreground data-[disabled=true]:vtmn-opacity-50 [&_svg]:vtmn-pointer-events-none [&_svg]:vtmn-size-4 [&_svg]:vtmn-shrink-0",
      className
    )}
    {...props} />
))

CommandItem.displayName = CommandPrimitive.Item.displayName

const CommandShortcut = ({
  className,
  ...props
}) => {
  return (
    (<span
      className={cn(
        "vtmn-ml-auto vtmn-text-xs vtmn-tracking-widest vtmn-text-muted-foreground",
        className
      )}
      {...props} />)
  );
}
CommandShortcut.displayName = "CommandShortcut"

export {
  Command,
  CommandDialog,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandShortcut,
  CommandSeparator,
}
