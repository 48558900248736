import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { VpButton, VpLoader, VpAsset, VpIconButton } from "@vtmn-play/react";
import { VpAddIcon } from "@vtmn-play/icons/react";

import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import capitalize from '../../functions/capitalize';

import DataTable from "../widgets/vtmn/DataTable";
import Toggle from '../widgets/vtmn/filters/Toggle';
import ErrorMessage from '../errorMessage';
import Confirm from '../widgets/vtmn/Confirm';
import SettingsForm from './SettingsForm';

const isNil = (val) => val === null || val === undefined;

const columns = [{
  title: <Trans i18nKey="efficiencyDeployment.headers.site">Site</Trans>,
  field: 'id',
}, {
  title: <Trans i18nKey="efficiencyDeployment.headers.description">Description</Trans>,
  field: 'name',
  cell: ({ getValue }) => capitalize(getValue()),
}, {
  title: <Trans i18nKey="efficiencyDeployment.headers.country">Country</Trans>,
  field: 'countryCode',
  cell: ({ getValue }) => <VpAsset className="vtmn-mr-2" name={`flag-${getValue().toLowerCase()}`} width="24" />,
}, {
  title: <Trans i18nKey="efficiencyDeployment.headers.settings">Settings</Trans>,
  field: 'storeSettingsId',
  cell: ({ getValue, row, table }) => {
    const { onEditSite } = table.options.meta;

    return (
      <>
      {!!row.original.storeSettingsId
        ? (
          <VpButton
            size="small"
            variant="secondary"
            onClick={() => onEditSite(row.original.id, false)}
          >
            <Trans i18nKey="efficiencyDeployment.editedSettings">Edit</Trans>
          </VpButton>
        ) : (
          <VpIconButton
            size="small"
            variant="secondary"
            onClick={() => onEditSite(row.original.id, true)}
          >
            <VpAddIcon />
          </VpIconButton>
        )
      }
      </>
    )
  },
}, {
  title: <Trans i18nKey="efficiencyDeployment.headers.efficiencyActivity">Efficiency activity</Trans>,
  field: 'efficiencyActive',
  cell: ({ getValue, row, table }) => {
    const { onSwitchActive } = table.options.meta;

    return (
      getValue()
      ? <span className='vtmn-text-success vp-body-m'>Yes</span>
      : <span className='vtmn-text-danger vp-body-m'>No</span>
      // <Toggle
      //   key={`activity-filter-${row.original.id}`}
      //   value={!!row.original.efficiencyActive}
      //   // onChange={() => onSwitchActive(row.original)}
      // />
    )
  },
}];

const getStoresDeployment = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/stores/deployment`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const switchActivity = (user, updateTokens, storeId, isActive) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/stores/${storeId}/activity`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ active: !isActive }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const EfficiencyDeployment = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [switchedSite, setSwitchedSite] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editedSiteId, setEditedSiteId] = useState();
  const [isNewSettings, setIsNewSettings] = useState(true);
  const [{
    loading,
    error,
  }, fetchStoresDeployment] = useRequest(
    getStoresDeployment, {
      onSuccess: setData,
      onError: () => setData(null),
    }
  );

  useEffect(() => {
    fetchStoresDeployment(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateActivity = (row) => {
    const tData = [...data];
    const record = tData.find((r) => r.id === switchedSite.id);
    if (!record) {
      return;
    }
    record.efficiencyActive = !record.efficiencyActive;
    record.storeSettingsId = row.storeSettingsId;
    setData(tData);
    setSwitchedSite();
  };

  const [{
    loading: loadingActivity,
    error: errorActivity,
  }, fetchActivity] = useRequest(
    switchActivity, {
      onSuccess: updateActivity
    }
  );

  const changeActivity = async () => {
    if (!switchedSite || !switchedSite.id) {
      return;
    }
    await fetchActivity(user, updateTokens, switchedSite.id, switchedSite.efficiencyActive);
  };

  return (
    <div className="adminDeploymentManagement mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
      <div className="vtmn-flex vtmn-flex-row vtmn-justify-end vtmn-px-4 vtmn-mb-3">
        <VpButton
          size="small"
          className="nowrap !vtmn-bg-content-positive"
          startSlot={<VpAddIcon />}
          shape='squared'
          onClick={() => {
            setIsNewSettings(true);
            setShowForm(true);
          }}
        >
          <Trans i18nKey="efficiencyDeployment.enable">Enable efficiency measurement</Trans>
        </VpButton>
      </div>
      {loading && (
        <div className="vtmn-w-full vtmn-h-screen vtmn-flex vtmn-items-center vtmn-justify-center">
          <VpLoader size="extra-large" />
        </div>
      )}
      {!loadingActivity && errorActivity && <ErrorMessage error={errorActivity} />}
      {!loading && error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <DataTable
          className="vtmn-h-full"
          columns={columns}
          data={data || []}
          meta={{
            onSwitchActive: (site) => {
              // setShowConfirm(true);
              // setSwitchedSite(site);
            },
            onEditSite: (id, isNew) => {
              setIsNewSettings(isNew);
              setEditedSiteId(id);
              setShowForm(true);
            }
          }}
        />
      )}
      <SettingsForm
        user={user}
        updateTokens={updateTokens}
        isOpen={showForm}
        onClose={() => {
          setEditedSiteId();
          setShowForm(false);
        }}
        siteId={editedSiteId}
        isNewSettings={isNewSettings}
        callbackFn={() => fetchStoresDeployment(user, updateTokens)}
      />
      <Confirm
        title={t('efficiencyDeployment.activeConfirm.warning', 'Warning')}
        body={t('efficiencyDeployment.activeConfirm.text', 'Are you sure you want to change the site\'s efficiency measurement status?')}
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        loading={loadingActivity}
        onConfirm={async () => {
          await changeActivity();
          setShowConfirm(false);
        }}
      />
    </div>
  );
};

export default EfficiencyDeployment;
