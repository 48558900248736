import * as React from "react"
import * as PopoverPrimitive from "@radix-ui/react-popover"

import { cn } from "../../lib/utils"

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverAnchor = PopoverPrimitive.Anchor

const PopoverContent = React.forwardRef(({ className, align = "center", sideOffset = 0, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      style={{ zIndex: 50 }}
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        "vtmn-z-50 vtmn-bg-popover vtmn-text-popover-foreground vtmn-shadow-md vtmn-outline-none data-[state=open]:vtmn-animate-in data-[state=closed]:vtmn-animate-out data-[state=closed]:vtmn-fade-out-0 data-[state=open]:vtmn-fade-in-0 data-[state=closed]:vtmn-zoom-out-95 data-[state=open]:vtmn-zoom-in-95 data-[side=bottom]:vtmn-slide-in-from-top-2 data-[side=left]:vtmn-slide-in-from-right-2 data-[side=right]:vtmn-slide-in-from-left-2 data-[side=top]:vtmn-slide-in-from-bottom-2",
        className
      )}
      {...props} />
  </PopoverPrimitive.Portal>
))
PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor }
