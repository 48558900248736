import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { VpIconButton, VpLoader, VpAsset } from "@vtmn-play/react";
import { VpEditIcon, VpDeleteBinIcon } from "@vtmn-play/icons/react";

import useRequest from '../../functions/useRequest';
import useTableRequest from '../../functions/useTableRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse, { handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import getQueryString from '../../functions/getQueryString';

import DataTable from "../../components/widgets/vtmn/DataTable";
import Confirm from '../widgets/vtmn/Confirm';
import ErrorMessage from '../errorMessage';
import Filters from './Filters';
import UserForm from './userForm';

const columns = [
  {
    field: "email",
    title: <Trans i18nKey="usersManagement.email">Email</Trans>,
  },
  {
    field: "countries",
    title: <Trans i18nKey="usersManagement.countries">Countries</Trans>,
    cell: ({ row }) => {
      const countries = row.getValue("countries").map((c) => (
        <div key={c.id} className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-mr-2 vtmn-flex-wrap">
          <VpAsset className="vtmn-mr-1" name={`flag-${c.code.toLowerCase()}`} width="24" />
          {c.name}
        </div>
      ))
 
      return <div className="vtmn-flex vtmn-flex-row">{countries}</div>
    },
  },
  {
    field: "countryLeader",
    title: <Trans i18nKey="usersManagement.userRole">User role</Trans>,
    cell: ({ row }) => {
      return <div>{!row.getValue("countryLeader") ? 'Efficiency Manager' : 'Country Efficiency Leader'}</div>
    }
  },
  {
    id: "actions",
    cell: ({ row, table }) => {
      const { deleteCallback, setShowForm } = table.options.meta;
      const { id } = row.original;
      return (
        <div className="vtmn-flex vtmn-flex-row vtmn-justify-end">
          <VpIconButton
            size="small"
            variant="secondary"
            aria-label="Edit"
            className="vtmn-mr-2"
            onClick={() => setShowForm(row.original)}
          >
            <VpEditIcon />
          </VpIconButton>
          <VpIconButton
            size="small"
            variant="secondary"
            onClick={() => deleteCallback(row.index, id)}
            aria-label="Delete"
          >
            <VpDeleteBinIcon />
          </VpIconButton>
        </div>
      )
    },
  }
];

const getUsers = (user, updateTokens, { filters, pagination }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({
    filters,
    pagination,
  })
  const url = `${process.env.REACT_APP_base_URL}/api/users?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const deleteUser = (
  user,
  updateTokens,
  userId,
) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/users/${userId}`;
  return fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const UsersAdmin = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [deletedUser, setDeletedUser] = useState(null);
  const [deletedRow, setDeletedRow] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editedRecord, setEditedRecord] = useState(null);
  const [rerenderCounter, setRerenderCounter] = useState(0);
  const [{
      data,
      loading,
      error,
      filters,
      pagination,
      totals,
    },
    fetchUsers,
    setFilters,
    setPagination,
    setSort, //eslint-disable-line
    updateDataRow, //eslint-disable-line
    insertDataRow, //eslint-disable-line
    deleteDataRow,
  ] = useTableRequest(getUsers);

  const dataUpdate = () => {
    fetchUsers(user, updateTokens, {
      filters,
      pagination,
    });
  }
  useEffect(() => {
    dataUpdate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination]);

  useEffect(() => {
    setRerenderCounter(rerenderCounter + 1);
  }, [loading])

  const updateUsersList = () => {
    deleteDataRow(deletedRow);
    setDeletedRow();
    setDeletedUser();
  };

  const [{
    loading: loadingDelete,
    error: errorDelete,
  }, fetchDelete] = useRequest(
    deleteUser, {
      onSuccess: updateUsersList
    }
  );

  const removeUser = async () => {
    if (!deletedUser) {
      return;
    }
    await fetchDelete(user, updateTokens, deletedUser);
  };

  return (
    <div className="adminUsers mobile:vtmn-mx-1 vtmn-h-full vtmn-pt-2">
      <div className="vtmn-px-4">
        <Filters
          user={user}
          updateTokens={updateTokens}
          filters={filters}
          setFilters={setFilters}
          onClear={() => setFilters({})}
          onAddUser={() => setShowForm(true)}
        />
      </div>
      {loading && (
        <div className="vtmn-w-full vtmn-h-screen vtmn-flex vtmn-items-center vtmn-justify-center">
          <VpLoader size="extra-large" />
        </div>
      )}
      {!loadingDelete && errorDelete && <ErrorMessage error={errorDelete} />}
      {!loading && error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <DataTable
          columns={columns}
          data={data || []}
          totals={totals}
          pagination={pagination}
          setPagination={setPagination}
          meta={{
            deleteCallback: (i, id) => {
              setShowConfirm(true);
              setDeletedRow(i);
              setDeletedUser(id);
            },
            setShowForm: (record) => {
              setEditedRecord(record);
              setShowForm(true);
            },
          }}
        />
      )}
      
      
      {(rerenderCounter === 0 || rerenderCounter > 1) && (
        <UserForm
          key="user-form"
          user={user}
          updateTokens={updateTokens}
          record={editedRecord}
          isOpen={showForm}
          onClose={() => {
            setShowForm(false);
            setEditedRecord(null);
          }}
          tableUpdate={dataUpdate}
        />
      )}
      
      <Confirm
        title={t('usersManagement.deleteConfirm.warning', 'Warning')}
        body={t('usersManagement.deleteConfirm.text', 'Are you sure you want to delete the user?')}
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        loading={loadingDelete}
        onConfirm={async () => {
          await removeUser();
          setShowConfirm(false);
        }}
      />
    </div>
  );
};

export default UsersAdmin;
