import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { formatCurrency } from '../../../functions/formatMoney';
import { numberRenderer, getTime } from './index';

import QuantityEdit from './QuantityEdit';
import InvolvementInput from './InvolvementInput';
import InputFilter from '../../widgets/vtmn/filters/InputFilter';
import DateTimePicker from '../../widgets/vtmn/filters/DateTimePicker';

const Realized = ({ efficiency, setEfficiency }) => {
  const { t } = useTranslation();
  const currency = formatCurrency(efficiency.currency);
  const duration = {
    unloading: _.round(
      efficiency.unloading_finish_date && efficiency.unloading_start_date
      ? (getTime(efficiency.unloading_finish_date) - getTime(efficiency.unloading_start_date)) / 1000
      : 1
    ),
    shelving: _.round(
      efficiency.shelving_finish_date && efficiency.shelving_start_date
      ? (getTime(efficiency.shelving_finish_date) - getTime(efficiency.shelving_start_date)) / 1000
      : 1
    ),
  }
  
  const teammateHours = {
    unloading: (efficiency.unloading_involvement
      ? _.sum(efficiency.unloading_involvement) 
      : efficiency.unloading_teammates) * (duration.unloading / 3600),
    shelving: (efficiency.shelving_involvement
      ? _.sum(efficiency.shelving_involvement) 
      : efficiency.shelving_teammates) * (duration.shelving / 3600),
  }
  const quantities = efficiency.deliveries.reduce((acc, d) => acc + d.qty, 0);
  const shelvingProductivity = teammateHours.shelving
    ? quantities / teammateHours.shelving
    : null;

  // const incorrectUnloadingDate = dayjs(efficiency.unloading_start_date).isAfter(dayjs(efficiency.unloading_finish_date));
  // const incorrectShelvingDate = dayjs(efficiency.shelving_start_date).isAfter(dayjs(efficiency.shelving_finish_date));

  return (
    <div className="vtmn-grid vtmn-grid-cols-2 [&>div]:vtmn-mt-3 vtmn-pr-1">
      <QuantityEdit
        label={t('efficiencyAdmin.columns.quantities', 'Quantities')}
        minValue={0}
        onChange={(deliveries) => setEfficiency({
          ...efficiency,
          deliveries,
        })}
        deliveries={efficiency.deliveries}
        className="vtmn-mr-3"
      />
      <div className="vtmn-w-full">&nbsp;</div>
      <InvolvementInput
        label={t('efficiencyAdmin.columns.unloadingTeammates', 'Unloading teammates')}
        teammates={efficiency.unloading_teammates}
        involvement={efficiency.unloading_involvement}
        onChange={(unloading_teammates, unloading_involvement) => setEfficiency({
          ...efficiency,
          unloading_teammates,
          unloading_involvement,
        })}
        maxValue={duration.unloading}
        className="vtmn-mr-3"
      />
      <InvolvementInput
        label={t('efficiencyAdmin.columns.shelvingTeammates', 'Shelving teammates')}
        teammates={efficiency.shelving_teammates}
        involvement={efficiency.shelving_involvement}
        onChange={(shelving_teammates, shelving_involvement) => setEfficiency({
          ...efficiency,
          shelving_teammates,
          shelving_involvement,
        })}
        maxValue={duration.shelving}
      />
      <DateTimePicker
        label={t('efficiencyAdmin.columns.unloadingStartDate', 'Unloading start date')}
        value={efficiency.unloading_start_date}
        onChange={(unloading_start_date) => setEfficiency({ ...efficiency, unloading_start_date })}
        maxDate={efficiency.unloading_finish_date ? new Date(efficiency.unloading_finish_date) : null}
        className="vtmn-mr-3"
      />
      <DateTimePicker
        label={t('efficiencyAdmin.columns.shelvingStartDate', 'Shelving start date')}
        value={efficiency.shelving_start_date}
        onChange={(shelving_start_date) => setEfficiency({ ...efficiency, shelving_start_date })}
        maxDate={efficiency.shelving_finish_date ? new Date(efficiency.shelving_finish_date) : null}
      />
      <DateTimePicker
        label={t('efficiencyAdmin.columns.unloadingFinishDate', 'Unloading finish date')}
        value={efficiency.unloading_finish_date}
        onChange={(unloading_finish_date) => setEfficiency({ ...efficiency, unloading_finish_date })}
        minDate={efficiency.unloading_start_date ? new Date(efficiency.unloading_start_date) : null}
        className="vtmn-mr-3"
      />
      <DateTimePicker
        label={t('efficiencyAdmin.columns.shelvingFinishDate', 'Shelving finish date')}
        value={efficiency.shelving_finish_date}
        onChange={(shelving_finish_date) => setEfficiency({ ...efficiency, shelving_finish_date })}
        minDate={efficiency.shelving_start_date ? new Date(efficiency.shelving_start_date) : null}
      />

      <InputFilter
        disabled
        label={t('efficiencyAdmin.columns.unloadingDuration', 'Unloading duration')}
        value={duration.unloading}
        className="vtmn-mr-3"
      />
      <InputFilter
        disabled
        label={t('efficiencyAdmin.columns.shelvingProductivity', 'Shelving productivity')}
        value={numberRenderer(shelvingProductivity)}
      />
      <InputFilter
        disabled
        label={`${t('efficiencyAdmin.columns.unloadingInvestment', 'Unloading investment')} (${currency})`}
        value={numberRenderer((teammateHours.unloading * efficiency.cost_per_hour) || efficiency.unloading_investment)}
        className="vtmn-mr-3"
      />
      <InputFilter
        disabled
        label={`${t('efficiencyAdmin.columns.shelvingInvestment', 'Shelving investment')} (${currency})`}
        value={numberRenderer((teammateHours.shelving * efficiency.cost_per_hour) || efficiency.shelving_investment)}
      />
    </div>
  )
}

export default Realized;
