import React, { useState, useEffect } from 'react';
import MDEditor from '@uiw/react-md-editor';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../../../shadcn/components/ui/carousel';

const ReleasePreviewItem = ({ user, title, description, image }) => {
  const { token } = user;

  return (
    <div className="vtmn-flex vtmn-flex-col vtmn-h-full">
      <div
        className="vtmn-flex vtmn-h-1/2 vtmn-bg-no-repeat vtmn-bg-contain vtmn-bg-center"
        style={{backgroundImage: `url(${process.env.REACT_APP_base_URL}/api/files/${image}?token=${token})`}}
      >
        &nbsp;
      </div>
      <div className="slide-content">
        <div className="vtmn-flex vtmn-justify-center vtmn-py-4">
          <span className='vp-subtitle-m'>{title}</span>
        </div>
        <div className="slide-description">
          <MDEditor.Markdown className="mobile-markdown" source={description} />
        </div>
      </div>
    </div>
  )
};

const ReleasePreview = ({ user, slides }) => {
  const [api, setApi] = useState()
  const [current, setCurrent] = useState(0)
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (!api) {
      return
    }
 
    setCount(api.scrollSnapList().length)
    setCurrent(api.selectedScrollSnap() + 1)
 
    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1)
    })
  }, [api])

  useEffect(() => {
    setCount(slides.length)
  }, [slides])

  return (
    <div className="vtmn-min-w-[365px] vtmn-h-[700px] vtmn-shadow-100">
      <Carousel setApi={setApi}>
        <CarouselContent>
          {!!slides.length && slides.map((slide) => (
            <CarouselItem>
              <ReleasePreviewItem
                user={user}
                title={slide.title}
                image={slide.image}
                description={slide.description}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
      <div className="vtmn-py-2 vtmn-text-center vp-caption">
        Slide {current} of {count}
      </div>
    </div>
  )
};

export default ReleasePreview;
