import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva } from "class-variance-authority";

import { cn } from "../../lib/utils"

const buttonVariants = cva(
  "vtmn-inline-flex vtmn-items-center vtmn-justify-center vtmn-gap-2 vtmn-whitespace-nowrap vtmn-rounded-100 vtmn-font-medium vtmn-transition-colors focus-visible:vtmn-outline-none focus-visible:vtmn-ring-1 focus-visible:vtmn-ring-ring disabled:vtmn-pointer-events-none disabled:vtmn-opacity-50 [&_svg]:vtmn-pointer-events-none [&_svg]:vtmn-shrink-0",
  {
    variants: {
      variant: {
        default:
          "vtmn-bg-primary vtmn-text-primary-foreground hover:vtmn-bg-primary/90",
        destructive:
          "vtmn-bg-destructive vtmn-text-destructive-foreground hover:vtmn-bg-destructive/90",
        outline:
          "vtmn-border vtmn-border-input vtmn-bg-background hover:vtmn-bg-accent hover:vtmn-text-accent-foreground",
        secondary:
          "vtmn-bg-secondary vtmn-text-secondary-foreground hover:vtmn-bg-secondary/80",
        ghost: "hover:vtmn-bg-accent hover:vtmn-text-accent-foreground",
        link: "vtmn-text-primary vtmn-underline-offset-4 hover:vtmn-underline",
      },
      size: {
        // default: "vtmn-px-3 vtmn-py-3",
        sm: "vtmn-h-7 vtmn-rounded-100 vtmn-px-3 vtmn-text-xs",
        lg: "vtmn-h-10 vtmn-rounded-100 vtmn-px-8",
        icon: "vtmn-w-8",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

const Button = React.forwardRef(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "button"
  return (
    (<Comp
      className={cn(buttonVariants({ variant, size, className }))}
      ref={ref}
      {...props} 
      />)
  );
})
Button.displayName = "Button"

export { Button, buttonVariants }
