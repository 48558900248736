import React, { useEffect, useState } from 'react';
import { format, setHours, setMinutes } from "date-fns";
import dayjs from 'dayjs';
import {
  VpFormControl,
  VpFormError,
  VpFormLabel,
  VpInput,
} from "@vtmn-play/react"
import { VpCalendarIcon } from "@vtmn-play/icons/react";

import { cn } from "../../../../shadcn/lib/utils"
import { Button } from "../../../../shadcn/components/ui/button"
import { Calendar } from "../../../../shadcn/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../shadcn/components/ui/popover"

const DateTimePicker = ({
  label,
  className,
  onChange,
  value,
  maxDate,
}) => {
  const [selected, setSelected] = useState(value);
  const [timeValue, setTimeValue] = useState(dayjs(value).format('HH:mm'));

  console.log('selected', selected)
  useEffect(() => {
    if (value === selected) return;

    const valueTime = dayjs(value).format('HH:mm');
    if (valueTime === timeValue) return;
    setTimeValue(valueTime);
    setSelected(value);
  }, [value]);

  useEffect(() => {
    onChange && onChange(selected);
  }, [selected]);

  const handleTimeChange = (e) => {
    const time = e.target.value;
    if (!selected) {
      setTimeValue(time);
      return;
    }
    const [hours, minutes] = time.split(":").map((str) => parseInt(str, 10));
    const newSelectedDate = setHours(setMinutes(selected, minutes), hours);
    setSelected(newSelectedDate);
    setTimeValue(time);
  };

  const handleDaySelect = (date) => {
    if (!timeValue || !date) {
      setSelected(date);
      return;
    }
    const [hours, minutes] = timeValue
      .split(":")
      .map((str) => parseInt(str, 10));
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes
    );
    setSelected(newDate);
  };

  return (
    <div className={className}>
      <VpFormControl
        className={cn(
          "vtmn-flex vtmn-flex-col form-content",
          "vtmn-w-full vtmn-grid vtmn-bg-card [&>button]:vtmn-px-3",
        )}
      >
        <VpFormLabel className="!vtmn-items-start">
          {label}
        </VpFormLabel>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "vtmn-px-7 !vtmn-justify-start vtmn-text-left vtmn-font-normal",
                "vp-select vp-select--default vp-select--small",
                !value && "text-muted-foreground"
              )}
            >
              <VpCalendarIcon className="mr-2 h-4 w-4" />
              {value ? format(value, "dd/MM/yyyy HH:mm:ss") : <span>Pick a date</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent align="start" className="vtmn-bg-card vtmn-bg-background-primary vtmn-w-auto vtmn-p-0">
            <div className="vtmn-pt-4 vtmn-pl-4">
              <VpFormControl
                className={cn(
                  "vtmn-flex vtmn-flex-row vtmn-items-center",
                )}
              >
                <VpFormLabel className="!vtmn-items-start !vtmn-mb-0 vtmn-mr-3 vtmn-whitespace-nowrap">
                  Set the time
                </VpFormLabel>
                <input
                  type="time"
                  value={timeValue}
                  onChange={handleTimeChange}
                  className="vp-input vp-input--small !vtmn-w-auto"
                />
              </VpFormControl>
            </div>
            <Calendar
              mode="single"
              selected={selected}
              onSelect={handleDaySelect}
              disabled={{ after: maxDate || undefined }}
              initialFocus={true}
            />
          </PopoverContent>
        </Popover>
      </VpFormControl>
    </div>
  )
}

export default DateTimePicker;
