import React from "react"
import { useTranslation } from 'react-i18next';
import {
  VpFormControl,
  VpFormLabel,
} from "@vtmn-play/react"

import { cn } from "../../../../shadcn/lib/utils"

import { Select } from "../../../../shadcn/components/ui/select"
import Toggle from '../filters/Toggle';

const Combobox = ({
  label,
  options,
  selected,
  onChange,
  disabled,
  className,
  withSearch = true,
  valueClassName,
  ref,
  invertable,
  inverted,
  onInvert,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn("filter-select", className)}>
      <VpFormControl
        className="form-content"
        disabled={disabled}
        // required={isRequired}
      >
        {!!label && (
          <VpFormLabel className="!vtmn-items-start">
            <div className={cn(
              "vtmn-flex vtmn-flex-row vtmn-items-center vtmn-w-full",
              invertable && "vtmn-justify-between"
            )}>
              <span>{label || t('declareToControl.filters.store','Store')}</span>
              {invertable && (
                <div className="vtmn-flex vtmn-flex-row vtmn-items-center vtmn-ml-4">
                  <span className="vp-caption vtmn-mr-3">
                    {t('filters.excludes', ' excludes')}
                  </span>
                  <Toggle value={inverted || false} onChange={onInvert} />
                </div>
              )}
            </div>
          </VpFormLabel>
        )}
        <Select
          options={options}
          onValueChange={onChange}
          value={selected}
          withSearch={withSearch}
          ref={ref}
          valueClassName={valueClassName}
          disabled={disabled}
        />
      </VpFormControl>
    </div>
  )
}

export default Combobox;
