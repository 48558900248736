import React from 'react'
import { Trans } from 'react-i18next'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn/components/ui/tooltip";

const KeyPerformanceIndicators = ({ kpiInfo }) => {
  const percentAccuracyRounded = Math.round(kpiInfo.accuracy * 10) / 10
  const percentAverageControlRounded =
    Math.round(kpiInfo.averageControlRate * 10) / 10
  const logisticalErrorRateRounded =
    Math.round(kpiInfo.logisticalErrorRate * 1000) / 1000
  const extendedLogisticalErrorRateRounded =
    Math.round(kpiInfo.extendedLogisticalErrorRate * 1000) / 1000
  const percentWarehouseAccuracyRounded = Math.round(kpiInfo.warehouseAccuracy * 10) / 10

  let progressClassName = 'progress-bar'
  let progressPercentClass = ''
  if (kpiInfo.accuracy < 60) {
    progressPercentClass = ' progress-percent-dark'
  }
  if (kpiInfo.accuracy > 90) {
    progressClassName += ' progress-bar-success'
  } else if (kpiInfo.accuracy > 50) {
    progressClassName += ' progress-bar-warning'
  } else {
    progressClassName += ' progress-bar-danger'
  }

  let progressWarehouseClassName = 'progress-bar'
  let progressWarehousePercentClass = ''
  if (kpiInfo.warehouseAccuracy < 60) {
    progressWarehousePercentClass = ' progress-percent-dark'
  }
  if (kpiInfo.warehouseAccuracy > 90) {
    progressWarehouseClassName += ' progress-bar-success'
  } else if (kpiInfo.warehouseAccuracy > 50) {
    progressWarehouseClassName += ' progress-bar-warning'
  } else {
    progressWarehouseClassName += ' progress-bar-danger'
  }

  let progressControlClassName = 'progress-bar'
  let progressControlPercentClass = ''
  if (kpiInfo.averageControlRate < 60) {
    progressControlPercentClass = ' progress-percent-dark'
  }
  if (kpiInfo.averageControlRate >= 20) {
    progressControlClassName += ' progress-bar-success'
  } else {
    progressControlClassName += ' progress-bar-danger'
  }

  let errorClassName = 'color-success'
  if (logisticalErrorRateRounded > 0.3) {
    errorClassName = 'color-danger'
  } else if (
    logisticalErrorRateRounded < 0.3 &&
    logisticalErrorRateRounded > 0.2
  ) {
    errorClassName = 'color-warning'
  }

  let extendedErrorClassName = 'color-success'
  if (extendedLogisticalErrorRateRounded > 0.3) {
    extendedErrorClassName = 'color-danger'
  } else if (
    extendedLogisticalErrorRateRounded < 0.3 &&
    extendedLogisticalErrorRateRounded > 0.2
  ) {
    extendedErrorClassName = 'color-warning'
  }

  return (
    <>
      <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <div className="vtmn-flex-grow vp-body-m vtmn-w-[252px] vtmn-text-right">
                <Trans i18nKey="monitoringGate.kpi.warehouseAccuracy">
                  In warehouse scanning accuracy:
                </Trans>{' '}
              </div>
            </TooltipTrigger>
            <TooltipContent side="bottom" className="vtmn-w-[252px]">
              <Trans i18nKey="monitoringGate.kpi.tooltip.warehouseAccuracy">
                The accuracy is the average in warehouse reading rate of each
                reception. If a reception has a reading rate below 50% it is not
                included in the average calculation.
              </Trans>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        
        <div className="col progress-value">
          <div className="progress">
            <div
              className={progressWarehouseClassName}
              role="progressbar"
              style={{ width: `${Math.max(percentWarehouseAccuracyRounded, 1)}%` }}
              aria-valuenow={percentWarehouseAccuracyRounded}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span className={`progress-percent${progressWarehousePercentClass}`}>
                {percentWarehouseAccuracyRounded} %
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <div className="vtmn-flex-grow vp-body-m vtmn-w-[252px] vtmn-text-right">
                <Trans i18nKey="monitoringGate.kpi.accuracy">
                  In store scanning accuracy:
                </Trans>{' '}
              </div>
            </TooltipTrigger>
            <TooltipContent side="bottom" className="vtmn-w-[252px]">
              <Trans i18nKey="monitoringGate.kpi.tooltip.accuracy">
                The accuracy is the average in store reading rate of each
                reception. If a reception has a reading rate below 50% it is not
                included in the average calculation.
              </Trans>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
       
        <div className="col progress-value">
          <div className="progress">
            <div
              className={progressClassName}
              role="progressbar"
              style={{ width: `${Math.max(percentAccuracyRounded, 1)}%` }}
              aria-valuenow={percentAccuracyRounded}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span className={`progress-percent${progressPercentClass}`}>
                {percentAccuracyRounded} %
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <div className="vtmn-flex-grow vp-body-m vtmn-w-[252px] vtmn-text-right">
                <Trans i18nKey="monitoringGate.kpi.averageControlRate">
                  Average control rate:
                </Trans>{' '}
              </div>
            </TooltipTrigger>
            <TooltipContent side="bottom" className="vtmn-w-[252px]">
              <Trans i18nKey="monitoringGate.kpi.tooltip.averageControlRate">
                Percent of stock control done on items with quantities that were
                expected and not read by RFID in receptions.
              </Trans>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <div className="col progress-value">
          <div className="progress">
            <div
              className={progressControlClassName}
              role="progressbar"
              style={{ width: `${Math.max(percentAverageControlRounded, 1)}%` }}
              aria-valuenow={percentAverageControlRounded}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span
                className={`progress-percent${progressControlPercentClass}`}
              >
                {percentAverageControlRounded} %
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <div className="vtmn-flex-grow vp-body-m vtmn-w-[252px] vtmn-text-right">
                <Trans i18nKey="monitoringGate.kpi.logisticalErrorRate">
                  Logistical error rate:
                </Trans>{' '}
              </div>
            </TooltipTrigger>
            <TooltipContent side="bottom" className="vtmn-w-[252px]">
              <Trans i18nKey="monitoringGate.kpi.tooltip.logisticalErrorRate">
                Percent of quantities not received from logistics.
              </Trans>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <div className={`col info-value ${extendedErrorClassName}`}>
          {logisticalErrorRateRounded} %
        </div>
      </div>
      <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <div className="vtmn-flex-grow vp-body-m vtmn-w-[252px] vtmn-text-right">
                <Trans i18nKey="monitoringGate.kpi.extendedErrorRate">
                  Extended logistical error rate:
                </Trans>{' '}
              </div>
            </TooltipTrigger>
            <TooltipContent side="bottom" className="vtmn-w-[252px]">
              <Trans i18nKey="monitoringGate.kpi.tooltip.extendedErrorRate">
                Percent of quantities not received from logistics and with in
                store stock inacurracies identified by End2End.
              </Trans>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <div className={`col info-value ${errorClassName}`}>
          {extendedLogisticalErrorRateRounded} %
        </div>
      </div>
    </>
  )
}

export default KeyPerformanceIndicators
