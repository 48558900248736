import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useRequest from '../../functions/useRequest';
import { handleApiResponse } from '../../functions/handleApiResponse';
import fetchWithJWT from '../../functions/fetchWithJWT';

import FiltersPannel from '../widgets/vtmn/FiltersPanel';
import MultiSelectFilter from '../widgets/vtmn/filters/MultiSelectFilter';
import Combobox from '../widgets/vtmn/form/Combobox';
import DateRangePicker from '../widgets/vtmn/filters/DateRangePicker';
import Toggle from '../widgets/vtmn/filters/Toggle';

const getAvailableStores = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/stores/pilot/deployment`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const getPreloadCodes = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/states/preload/codes`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const statuses = [{
  label: 'In progress',
  name: 'In progress',
  id: 'in_progress',
}, {
  label: 'Ended',
  name: 'Ended',
  id: 'ended',
}];

const MainFilters = ({
  filters,
  setFilters,
  user,
  updateTokens,
}) => {
  const { t } = useTranslation();

  const [{
    loading: loadingStores,
    data: stores,
    error: errorStores,
  }, fetchAvailableStores] = useRequest(getAvailableStores);
  const [{
    loading: loadingPreloadCodes,
    data: preloadCodes,
    error: errorPreloadCodes,
  }, fetchPreloadCodes] = useRequest(getPreloadCodes);

  useEffect(() => {
    fetchAvailableStores(user, updateTokens);
    fetchPreloadCodes(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = (stores && stores.map(store => ({
    label: `${store.id} - ${store.name}`,
    name: store.name,
    id: store.id,
  }))) || [];

  return (
    <>
      <MultiSelectFilter
        className="vtmn-w-full vtmn-filter-min-width vtmn-filter-max-width"
        label={t('adminPreloadState.filters.code','Preload code')}
        options={preloadCodes}
        selected={filters.code}
        onChange={(v) => setFilters({...filters, code: v})}
        loading={loadingPreloadCodes}
        error={errorPreloadCodes}
      />
      <MultiSelectFilter
        className="vtmn-w-full vtmn-filter-min-width vtmn-filter-max-width"
        label={t('adminPreloadState.filters.store','Store')}
        options={options}
        selected={filters.store}
        onChange={(v) => setFilters({...filters, store: v})}
        loading={loadingStores}
        error={errorStores}
      />
      <Combobox
        className="vtmn-w-full vtmn-filter-min-width vtmn-filter-max-width"
        label={t('adminPreloadState.filters.status','Status')}
        options={statuses}
        selected={filters.status || undefined}
        onChange={(v) => setFilters({...filters, status: v})}
      />
      <DateRangePicker
        className="vtmn-w-full vtmn-filter-min-width vtmn-filter-max-width"
        label={t('adminPreloadState.filters.startDate', 'Start date')}
        valueFrom={filters.start_date_from}
        valueTo={filters.start_date_to}
        onChange={(start_date_from, start_date_to) => setFilters({
          ...filters,
          start_date_from,
          start_date_to,
        })}
      />
      <DateRangePicker
        className="vtmn-w-full vtmn-filter-min-width vtmn-filter-max-width"
        label={t('adminPreloadState.filters.endDate', 'End date')}
        valueFrom={filters.end_date_from}
        valueTo={filters.end_date_to}
        onChange={(end_date_from, end_date_to) => setFilters({
          ...filters,
          end_date_from,
          end_date_to,
        })}
      />
      <DateRangePicker
        className="vtmn-w-full vtmn-filter-min-width vtmn-filter-max-width"
        label={t('adminPreloadState.lastDate', 'Last processed date')}
        valueFrom={filters.processed_date_from}
        valueTo={filters.processed_date_to}
        onChange={(processed_date_from, processed_date_to) => setFilters({
          ...filters,
          processed_date_from,
          processed_date_to,
        })}
      />
      <Toggle
        key={`recordsToggle-${filters.has_error}`}
        label={t('adminPreloadState.filters.allRecords', 'All records')}
        rightLabel={t('adminPreloadState.filters.hasError', 'Has error')}
        value={filters && filters.has_error ? true : false}
        onChange={(value) => setFilters({
          ...filters,
          has_error: value,
        })}
      />
    </>
  )
}

const Filters = ({
  filters,
  setFilters,
  onClear,
  user,
  updateTokens,
}) => {
  return (
    <FiltersPannel
      onClear={onClear}
      listClassName="vtmn-flex vtmn-flex-row vtmn-flex-wrap [&>div]:vtmn-mr-5"
      mainFilters={
        <MainFilters
          filters={filters}
          setFilters={setFilters}
          user={user}
          updateTokens={updateTokens}
        />
      }
    />
  )
}

export default Filters;
