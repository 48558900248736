import React, { useState } from 'react';
import _ from "lodash";
import { VpIcon } from "@vtmn-play/react";

import { cn } from '../../../shadcn/lib/utils';
import { DisplayItem } from '../displayItem';
import ProgressBar from './ProgressBar';
import ProgressDetailsModal from './ProgressDetailsModal';

const capitalize = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

const SportCard = ({
  user,
  updateTokens,
  sport,
  indicator,
  store,
  filters,
  filterValues,
  warehouseReadingActivated,
  currency,
  delivery,
}) => {
  const [hideItems, setHideItems] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const { total } = indicator;
  const [doNotShowItems, setDoNotShowItems] = useState(indicator.foundAtMovementsQties >= total);
  let myClassCard = "card card-container";
  const items = _.orderBy(Object.values(indicator.items), ['amount'], ['desc']);
  if (indicator.foundAtMovementsQties >= total) {
    myClassCard += " bg-success";
  }

  return (
    <>
      <div
        key={sport}
        className={cn(
          "vtmn-border-background-secondary-new vtmn-pb-3 vtmn-mt-4 vtmn-mb-1 vtmn-px-2",
          "vtmn-inline-block vtmn-w-full  mobile:vtmn-w-full mobile:vtmn-flex-auto mobile:vtmn-max-w-none",
          !hideItems ? "" : "vtmn-border-bottom-1"
        )}
      >
        <div className="vtmn-block">
          <div className="vtmn-flex vtmn-flex-row vtmn-justify-between vtmn-mb-3">
            <span className={cn(
              "vp-subtitle-m",
            )}>
              {capitalize(sport)}
            </span>
            <div className="vtmn-flex vtmn-flex-row vtmn-items-center">
              <VpIcon
                name={!hideItems ? "chevron-up" : "chevron-down"}
                size="24"
                onClick={() => setHideItems(!hideItems)}
                className="vtmn-cursor-pointer"
              />
            </div>
          </div>
        </div>
        <span className="vp-body-s">
          Total {total} qty
        </span>
        <ProgressBar
          indicator={indicator}
          currency={currency}
          onBarClick={() => setShowDetails(true)}
        />
      </div>
      {!hideItems && (
        <div className="[&>div]:vtmn-mb-7 last:[&>div]:vtmn-mb-0">
          {!doNotShowItems && items.map((item) => (
            <DisplayItem
              className={cn(
                "vtmn-inline-block vtmn-w-full mobile:vtmn-w-full mobile:vtmn-flex-auto mobile:vtmn-max-w-none",
              )}
              key={item.item}
              filters={filters}
              filterValues={filterValues}
              itemDetails={item.details}
              allParcels={item.expectedPickingLinesForThisItem}
              displayFullyReceivedItems={false}
              warehouseReadingActivated={warehouseReadingActivated}
              user={user}
              updateTokens={updateTokens}
              store={store}
              unexpectedMatchesSize={item.unexpectedMatchesSize}
              unexpected
              movement={item.movement}
            />
          ))}
        </div>
      )}
      {showDetails && (
        <ProgressDetailsModal
          show={showDetails}
          onHide={() => setShowDetails(false)}
          sport={sport}
          delivery={delivery}
          indicator={indicator}
          currency={currency}
        />
      )}
    </>
  );
};

export default SportCard;
